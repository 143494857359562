import {
  useGetReservationStatuses,
  usePatchReservationStatuses,
} from "@api/statuses";
import { GetReservationStatusesResponse } from "../../../../../api-contracts/reservations";
import { Switch } from "@primitives/switch";
import { Tooltip } from "@primitives/tooltip";
import { H4 } from "@primitives/typography";
import { Info } from "lucide-react";

import { currentSite } from "@context/site-context";
import { useTranslation } from "react-i18next";
import { useToast } from "@hooks/use-toast";
import { Card, CardTitle } from "@primitives/card";
import { DefaultListItem } from "@components/default-list";
import { useEffect } from "react";
import { queryClient } from "query-client";

const TooltipContent: React.FC<{
  hint: string;
  title: string;
}> = ({ hint, title }) => {
  return (
    <div className="flex max-w-[400px] gap-2 bg-solid-backplate p-2">
      <span className="p-1">
        <Info size="20" className="text-primary-text" />
      </span>
      <span className="mt-0 flex flex-col justify-start gap-1 pt-0">
        <H4 className="text-lg text-primary-text">{title}</H4>
        <p className="leading-relaxed text-secondary-text">{hint}</p>
      </span>
    </div>
  );
};

const StatusItem: React.FC<{
  tooltip: string;
  name: string;
  checked: boolean | undefined;
  toggleable: boolean;
  active?: boolean;
  onChange: (arg: boolean) => void;
}> = (props) => (
  <DefaultListItem
    title={props.name}
    leftIconComponent={
      <Tooltip
        className=" flex items-center justify-center"
        content={<TooltipContent title={props.name} hint={props.tooltip} />}
      >
        <Info size="20" />
      </Tooltip>
    }
  >
    <Switch
      disabled={!props.toggleable || !props.active}
      checked={props.checked}
      onCheckedChange={props.onChange}
    />
  </DefaultListItem>
);

export const StatusPage = () => {
  const { toast } = useToast();

  useEffect(() => {
    queryClient.invalidateQueries({
      queryKey: useGetReservationStatuses.getKey(),
    });
  }, []);

  const { data, error, isLoading } = useGetReservationStatuses();

  const { mutate, isPending: loadingPatch } = usePatchReservationStatuses({
    onError(_, args) {
      if (
        Object.keys(args).some(
          (key) => key === "guaranteed" || key === "confirmed",
        )
      ) {
        toast({
          variant: "destructive",
          title: t("bookable-status-bad-request"),
          className: "text-status-error",
          duration: 2000,
        });
      }
    },
  });

  const { t } = useTranslation();

  const ready = !!data && !isLoading && !error && !loadingPatch;

  const onChange = (data: Partial<GetReservationStatusesResponse>) => {
    mutate(data);
  };

  return (
    <div className="p-4">
      <Card
        className="max-w-[500px]"
        aria-label="booking status configuration switches"
      >
        <CardTitle>{t("bookable-status-title")}</CardTitle>
        <div className=" mx-[-1rem]">
          <h3 className="p-3 pl-6 text-xs">{t("bookable-status-standard")}</h3>
          <ul
            className="flex flex-col border-t-[0.5px] border-highlighted-backplate"
            aria-label={t("bookable-status-standard")}
          >
            <StatusItem
              name={t("bookable-status-ongoing")}
              tooltip={t("bookable-status-ongoing-tooltip")}
              toggleable={false}
              checked={data?.ongoing ?? true}
              onChange={(val) => onChange({ ongoing: val })}
            />
            <StatusItem
              name={t("bookable-status-cancelled")}
              tooltip={t("bookable-status-cancelled-tooltip")}
              toggleable={false}
              checked={data?.cancelled ?? true}
              onChange={(val) => onChange({ cancelled: val })}
            />
            <StatusItem
              name={t("bookable-status-no-show")}
              tooltip={t("bookable-status-no-show-tooltip")}
              toggleable={false}
              checked={data?.noShow ?? true}
              onChange={(val) => onChange({ noShow: val })}
            />
            <StatusItem
              name={t("bookable-status-turn-away")}
              tooltip={t("bookable-status-turn-away-tooltip")}
              toggleable={false}
              checked={data?.turnAway ?? true}
              onChange={(val) => onChange({ turnAway: val })}
            />
            <StatusItem
              name={t("bookable-status-aborted")}
              tooltip={t("bookable-status-aborted-tooltip")}
              toggleable={false}
              checked={data?.aborted}
              onChange={(val) => onChange({ aborted: val })}
            />
          </ul>
          <h3 className="p-4 pl-6 text-xs">
            {t("bookable-status-saved-reservations")}
          </h3>
          <ul
            className="flex flex-col border-t-[0.5px] border-highlighted-backplate"
            aria-label={t("bookable-status-saved-reservations")}
          >
            <StatusItem
              name={t("bookable-status-unconfirmed")}
              tooltip={t("bookable-status-unconfirmed-tooltip")}
              toggleable={ready}
              checked={data?.unconfirmed}
              active
              onChange={(val) => onChange({ unconfirmed: val })}
            />
            <StatusItem
              name={t("bookable-status-confirmed")}
              tooltip={t("bookable-status-confirmed-tooltip")}
              toggleable={ready}
              checked={data?.confirmed}
              active
              onChange={(val) => onChange({ confirmed: val })}
            />
            <StatusItem
              name={t("bookable-status-guaranteed")}
              tooltip={t("bookable-status-guaranteed-tooltip")}
              toggleable={ready}
              checked={data?.guaranteed}
              active
              onChange={(val) => onChange({ guaranteed: val })}
            />
          </ul>
          <h3 className="p-4 pl-6 text-xs">{t("bookable-status-requests")}</h3>
          <ul
            className="flex flex-col border-t-[0.5px] border-highlighted-backplate"
            aria-label={t("bookable-status-requests")}
          >
            <StatusItem
              name={t("bookable-status-preliminary")}
              tooltip={t("bookable-status-preliminary-tooltip")}
              toggleable={ready}
              checked={data?.preliminary}
              active
              onChange={(val) => onChange({ preliminary: val })}
            />
            <StatusItem
              name={t("bookable-status-inquiry")}
              tooltip={t("bookable-status-inquiry-tooltip")}
              toggleable={ready}
              checked={data?.inquiry}
              active
              onChange={(val) => onChange({ inquiry: val })}
            />
            <StatusItem
              name={t("bookable-status-lost")}
              tooltip={t("bookable-status-lost-tooltip")}
              toggleable={false}
              checked={data?.lost}
              onChange={(val) => onChange({ lost: val })}
            />
          </ul>
          <h3 className="p-4 pl-6 text-xs">{t("bookable-status-others")}</h3>
          <ul
            className="flex flex-col border-t-[0.5px] border-highlighted-backplate"
            aria-label={t("bookable-status-others")}
          >
            <StatusItem
              name={t("bookable-status-waiting-list")}
              tooltip={t("bookable-status-waiting-list-tooltip")}
              toggleable={ready}
              checked={data?.waitingList}
              active
              onChange={(val) => onChange({ waitingList: val })}
            />
          </ul>
        </div>
      </Card>
    </div>
  );
};
