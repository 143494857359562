import { createQuery } from "react-query-kit";
import {
  CalculateCategoryPricingRequest,
  CalculateCategoryPricingResponse,
  CalculateMultipleCategoryPricingRequest,
  CalculateMultipleCategoryPricingResponse,
} from "../../../../api-contracts/categories/pricing";
import { api } from "./api";
import { currentSite } from "@shared/context/site-context";

export const PRICING_KEY = "categories/pricing";

export const useGetMultiplePricing = createQuery<
  CalculateMultipleCategoryPricingResponse,
  CalculateMultipleCategoryPricingRequest
>({
  primaryKey: PRICING_KEY,
  queryFn: async ({ queryKey: [, { ...data }] }) => {
    return api.post<CalculateMultipleCategoryPricingResponse>(
      `/api/${currentSite?.id}/${PRICING_KEY}`,
      data,
    );
  },
});

export const useGetPricing = createQuery<
  CalculateCategoryPricingResponse,
  CalculateCategoryPricingRequest & { id: string }
>({
  primaryKey: PRICING_KEY,
  queryFn: async ({ queryKey: [, { id, ...data }] }) => {
    return api.post<CalculateCategoryPricingResponse>(
      `/api/${currentSite?.id}/${PRICING_KEY}/${id}`,
      data,
    );
  },
});
