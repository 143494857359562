import { TargetGroupsPageProvider } from "./target-groups-context.tsx";
import { TargetGroupsView } from "@pages/settings/target-groups/sections/target-groups-view.tsx";

const TargetGroupsPage = () => {
  return (
    <TargetGroupsPageProvider>
      <TargetGroupsView />
    </TargetGroupsPageProvider>
  );
};

export default TargetGroupsPage;
