import { useGetFireList } from "@api/reception";
import { Loading } from "@primitives/loading";
import {
  SortingState,
  VisibilityState,
  createColumnHelper,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import {
  FireList,
  LocationWithFireList,
} from "../../../../../../api-contracts/reception";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  flattenLocationsWithFireList,
  formatGuests,
} from "@pages/reception/reception-utils";
import { ReceptionTable } from "@pages/reception/components/reception-table";
import { FireListTableHeader } from "./fire-list-table-header";
import { LocationReceptionTable } from "@pages/reception/components/location-reception-table";
import { ROUTES } from "@shared/types/navigation";
import { useNavigate } from "react-router-dom";
import { useProfileContext } from "@context/profile-context";

export const FireListTableContainer = () => {
  const [showAllGuests, setShowAllGuests] = useState<boolean>(false);
  const [showLocation, setShowLocation] = useState<boolean>(false);
  const [sorting, setSorting] = useState<SortingState>([]);
  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>({});
  const { t, i18n } = useTranslation();
  const { module } = useProfileContext();
  const navigate = useNavigate();

  const { data: fireListData, isLoading } = useGetFireList({
    variables: {
      showLocation,
    },
  });

  const handleRowClick = (booking: FireList) => {
    navigate(`/${module}/${ROUTES.RESERVATIONS}/${booking.reservationId}`);
  };

  const filterFireListTree = (
    location: LocationWithFireList,
  ): LocationWithFireList | null => {
    if (!location.fireList && !location.subLocations) return null;

    const filteredSubLocations = location.subLocations
      ? location.subLocations
          .map((subLocation) =>
            filterFireListTree(subLocation as LocationWithFireList),
          )
          .filter(
            (subLocation): subLocation is LocationWithFireList =>
              subLocation !== null,
          )
      : [];

    if (
      (location.fireList?.length ?? 0) === 0 &&
      filteredSubLocations.length === 0
    ) {
      return null;
    }

    return {
      ...location,
      fireList: location.fireList,
      subLocations: filteredSubLocations,
    };
  };

  const columnHelper = createColumnHelper<FireList>();

  const fireListColumnDefinition = [
    columnHelper.accessor(
      (row) => ({ assetName: row.asset.name, categoryType: row.category.type }),
      {
        id: "asset",
        header: t("asset"),
        cell: (info) => (
          <div className="flex items-center">
            <p className="whitespace-nowrap">{info.getValue().assetName}</p>
            <div className=" ml-10 rounded-md bg-primary-card-backplate ">
              <p className="mx-2 font-extrabold">
                {t(info.getValue().categoryType) || "\u00A0"}
              </p>
            </div>
          </div>
        ),
      },
    ),
    columnHelper.accessor(
      (row) => formatGuests(row.guestCount, i18n.languages[0]),
      {
        id: "guestCount",
        header: t("number-of-guests"),
        cell: (info) => <p className="flex items-start">{info.getValue()}</p>,
      },
    ),
    columnHelper.accessor("guests", {
      id: "guestFirstName",
      header: t("first-name"),
      cell: (info) => (
        <div
          className={`flex flex-col items-start ${
            showAllGuests ? "space-y-6" : "space-y-2"
          }`}
        >
          {(showAllGuests ? info.getValue() : [info.getValue()[0]]).map(
            (guest) => (
              <p key={guest.id}>{guest.name || "\u00A0"}</p>
            ),
          )}
        </div>
      ),
    }),
    columnHelper.accessor("guests", {
      id: "guestSurname",
      header: t("surname"),
      cell: (info) => (
        <div
          className={`flex flex-col items-start ${
            showAllGuests ? "space-y-6" : ""
          }`}
        >
          {(showAllGuests ? info.getValue() : [info.getValue()[0]]).map(
            (guest) => (
              <p key={guest.id}>{guest.surname || "\u00A0"}</p>
            ),
          )}
        </div>
      ),
    }),
    columnHelper.accessor("guests", {
      id: "guestType",
      header: t("guest-type"),
      cell: (info) => (
        <div className="flex flex-col items-start space-y-6">
          {(showAllGuests ? info.getValue() : [info.getValue()[0]]).map(
            (guest) => (
              <div
                key={guest.id}
                className="rounded-md bg-primary-card-backplate px-2"
              >
                <p className="font-extrabold">{t(guest.ageType) || "\u00A0"}</p>
              </div>
            ),
          )}
        </div>
      ),
    }),
    columnHelper.accessor("category.short", {
      header: t("category"),
      cell: (info) => <p className="flex items-start">{info.getValue()}</p>,
    }),
    columnHelper.accessor("reservationId", {
      header: t("booking"),
      cell: (info) => <p className="flex items-start">{info.getValue()}</p>,
    }),
  ];

  const displayedGroupedByLocationsFireList = useMemo(() => {
    const groupedByLocation = fireListData?.locations
      ?.map((location) => filterFireListTree(location))
      .filter(
        (location): location is LocationWithFireList => location !== null,
      );

    return flattenLocationsWithFireList(groupedByLocation || []);
  }, [fireListData]);

  const fireListTable = useMemo(
    () =>
      useReactTable({
        data: showLocation
          ? displayedGroupedByLocationsFireList || []
          : fireListData?.fireList || [],
        columns: fireListColumnDefinition,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        onColumnVisibilityChange: setColumnVisibility,
        onSortingChange: (newSorting) => {
          if (!showLocation) {
            setSorting(newSorting);
          } else {
            setSorting([]);
          }
        },
        state: {
          columnVisibility,
          sorting,
        },
      }),
    [
      fireListData,
      fireListColumnDefinition,
      showLocation,
      showAllGuests,
      displayedGroupedByLocationsFireList,
    ],
  );

  useEffect(() => {
    setSorting([]);
  }, [showLocation]);

  const cols = fireListTable.getAllLeafColumns();

  if (isLoading || !fireListData) return <Loading />;

  return (
    <div className="h-full overflow-auto">
      <div className="p-4">
        <FireListTableHeader
          columns={cols}
          showLocations={showLocation}
          setShowLocations={setShowLocation}
          showAllGuests={showAllGuests}
          setShowAllGuests={setShowAllGuests}
        />
      </div>
      {!showLocation ? (
        <ReceptionTable<FireList>
          table={fireListTable}
          onRowClick={handleRowClick}
          showAllGuests={showAllGuests}
        />
      ) : (
        <LocationReceptionTable
          table={fireListTable}
          onRowClick={handleRowClick}
          showAllGuests={showAllGuests}
        />
      )}
    </div>
  );
};
