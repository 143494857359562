import { Tabs, TabsList, TabsTrigger } from "@primitives/tabs.tsx";
import { capitalizeFirstLetter } from "@shared/utils/helpers";
import { useTranslation } from "react-i18next";

export const CardHeaderTabs = () => {
  const { t } = useTranslation();
  const tabItems = [
    { value: "bookings", label: t("bookings"), disabled: false },
    { value: "priceRules", label: t("price-codes"), disabled: true },
  ];
  return (
    <Tabs value={"bookings"} onValueChange={() => null}>
      <TabsList>
        {tabItems.map((item, index) => (
          <TabsTrigger
            key={`${item}-${index}`}
            value={item.value}
            disabled={item.disabled}
          >
            {item.label}
          </TabsTrigger>
        ))}
      </TabsList>
    </Tabs>
  );
};
