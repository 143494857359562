import { Card, CardTitle } from "@primitives/card";
import { Info, Trash } from "lucide-react";
import { Tooltip } from "@primitives/tooltip";
import { Button } from "@primitives/button";
import {
  useDeleteFixtureGroup,
  useDeleteFixturesAndItems,
  useGetFixturesAndItems,
  usePatchFixtureGroups,
  usePatchFixuresAndItems,
  usePostFixtureGroups,
  usePostFixturesAndItems,
} from "@api/fixtures-and-items";
import { DefaultListItem } from "@components/default-list";
import {
  CreateFixtureGroupRequest,
  CreateFixtureRequest,
  Fixture,
  FixtureGroupWithFixtures,
  FixtureWithGroups,
  PatchFixtureGroupRequest,
  PatchFixtureRequest,
} from "../../../../../../api-contracts/fixtures-and-items";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FixtureSheet } from "./fixture-sheet";
import { Loading } from "@primitives/loading";
import { queryClient } from "query-client";
import { FixtureGroupSheet } from "./group-sheet";
import { toast } from "@hooks/use-toast";
import { DropdownMenuItem } from "@primitives/dropdown-menu";

const initialFixturesWithGroup: FixtureWithGroups = {
  id: "",
  name: "",
  groups: [],
};

const initialGroupWithFixtures: FixtureGroupWithFixtures = {
  id: "",
  name: "",
  fixtures: [],
};

export const FixtureCard = () => {
  const { t } = useTranslation();
  const [openFixtureSheet, setOpenFixtureSheet] = useState<boolean>(false);
  const [openGroupSheet, setOpenGroupSheet] = useState<boolean>(false);

  const [fixtureToEdit, setFixtureToEdit] = useState<FixtureWithGroups>(
    initialFixturesWithGroup,
  );
  const [groupToEdit, setGroupToEdit] = useState<FixtureGroupWithFixtures>(
    initialGroupWithFixtures,
  );

  useEffect(() => {
    queryClient.invalidateQueries({
      queryKey: useGetFixturesAndItems.getKey(),
    });
  }, []);

  const { data: fixtures, isLoading } = useGetFixturesAndItems();
  const { mutateAsync: postFixture } = usePostFixturesAndItems();
  const { mutateAsync: patchFixture } = usePatchFixuresAndItems();
  const { mutateAsync: deleteFixture } = useDeleteFixturesAndItems();
  const { mutateAsync: patchFixtureGroup } = usePatchFixtureGroups();
  const { mutateAsync: postFixtureGroup } = usePostFixtureGroups();
  const { mutateAsync: deleteGroup } = useDeleteFixtureGroup();

  const fixturesWithGroups = fixtures?.fixtureGroupsWithFixtures || [];
  const fixturesWithoutGroups = fixtures?.fixturesWithoutGroups || [];

  const flattenedFixtures: Fixture[] =
    fixturesWithGroups?.flatMap((group) => group.fixtures) || [];

  const allFixtures: Fixture[] = flattenedFixtures
    .concat(fixturesWithoutGroups)
    .filter(
      (fixture, index, self) =>
        index === self.findIndex((f) => f.id === fixture.id),
    );

  const onEditFixture = (fixture: Fixture) => {
    if (fixturesWithGroups) {
      const groupsContainingFixture = fixturesWithGroups
        .filter((grp) => grp.fixtures.some((fix) => fix.id === fixture.id))
        .map((grp) => ({ id: grp.id, name: grp.name }));

      setFixtureToEdit({ ...fixture, groups: groupsContainingFixture });
      setOpenFixtureSheet(true);
    }
  };

  const onCreateNewFixture = () => {
    setFixtureToEdit(initialFixturesWithGroup);
    setOpenFixtureSheet(true);
  };
  const onCreateNewGroup = () => {
    setGroupToEdit(initialGroupWithFixtures);
    setOpenGroupSheet(true);
  };
  const onEditGroup = (group: FixtureGroupWithFixtures) => {
    setGroupToEdit(group);
    setOpenGroupSheet(true);
  };

  const handleCreateNewFixture = async (fixture: FixtureWithGroups) => {
    const requestData: CreateFixtureRequest = {
      name: fixture.name,
      groupIds: fixture.groups.map((grp) => grp.id),
    };
    try {
      await postFixture(requestData);
      toast({
        title: t("saved-succesfully", { name: fixture.name }),
        variant: "success",
      });
      setOpenFixtureSheet(false);
    } catch (error) {
      toast({
        title:
          t("request-failed-with") +
          ": " +
          t(decodeURIComponent((error as any)?.message || t("no-message"))),
        variant: "destructive",
      });
    }
  };

  const handleEditFixture = async (fixture: FixtureWithGroups) => {
    const requestData: PatchFixtureRequest = {
      name: fixture.name,
      id: fixture.id,
      groupIds: fixture.groups.map((grp) => grp.id),
    };
    try {
      await patchFixture(requestData);
      toast({
        title: t("saved-succesfully", { name: fixture.name }),
        variant: "success",
      });
      setOpenFixtureSheet(false);
    } catch (error) {
      toast({
        title:
          t("request-failed-with") +
          ": " +
          t(decodeURIComponent((error as any)?.message || t("no-message"))),
        variant: "destructive",
      });
    }
  };

  const removeFixtureFromGroup = async (
    fixture: Fixture,
    group: FixtureGroupWithFixtures,
  ) => {
    const updatedFixtures = group.fixtures.filter((f) => f.id !== fixture.id);

    const requestData: PatchFixtureGroupRequest = {
      id: group.id,
      fixtureIds: updatedFixtures.map((fix) => fix.id),
    };

    try {
      await patchFixtureGroup(requestData);
      toast({
        title: t("deleted-succesfully", { name: fixture.name }),
        variant: "success",
      });
    } catch (error) {
      console.log(error);
      toast({
        title:
          t("request-failed-with") +
          ": " +
          t(decodeURIComponent((error as any)?.message || t("no-message"))),
        variant: "destructive",
      });
    }
  };

  const handleDeleteFixture = async (fixture: Fixture) => {
    try {
      await deleteFixture({ id: fixture.id });
      toast({
        title: t("deleted-succesfully", { name: fixture.name }),
        variant: "success",
      });
    } catch (error) {
      toast({
        title:
          t("request-failed-with") +
          ": " +
          t(decodeURIComponent((error as any)?.message || t("no-message"))),
        variant: "destructive",
      });
    }
  };

  const handleOnSaveFixtures = (fixture: FixtureWithGroups) => {
    fixtureToEdit.id.length > 0
      ? handleEditFixture(fixture)
      : handleCreateNewFixture(fixture);
  };

  const handleCreateNewGroup = async (group: FixtureGroupWithFixtures) => {
    const requestData: CreateFixtureGroupRequest = {
      name: group.name,
      fixtureIds: group.fixtures.map((fix) => fix.id),
    };

    try {
      await postFixtureGroup(requestData);
      toast({
        title: t("saved-succesfully", { name: group.name }),
        variant: "success",
      });
      setOpenGroupSheet(false);
    } catch (error) {
      toast({
        title:
          t("request-failed-with") +
          ": " +
          t(decodeURIComponent((error as any)?.message || t("no-message"))),
        variant: "destructive",
      });
    }
  };

  const handleEditGroup = async (group: FixtureGroupWithFixtures) => {
    const requestData: PatchFixtureGroupRequest = {
      id: group.id,
      name: group.name,
      fixtureIds: group.fixtures.map((fix) => fix.id),
    };

    try {
      await patchFixtureGroup(requestData);
      toast({
        title: t("saved-succesfully", { name: group.name }),
        variant: "success",
      });
      setOpenGroupSheet(false);
    } catch (error) {
      toast({
        title:
          t("request-failed-with") +
          ": " +
          t(decodeURIComponent((error as any)?.message || t("no-message"))),
        variant: "destructive",
      });
    }
  };

  const handleRemoveGroup = async (group: FixtureGroupWithFixtures) => {
    try {
      await deleteGroup({ id: group.id });
      toast({
        title: t("deleted-succesfully", { name: group.name }),
        className: "text-status-success",
        variant: "success",
      });
    } catch (error) {
      toast({
        title:
          t("request-failed-with") +
          ": " +
          t(decodeURIComponent((error as any)?.message || t("no-message"))),
        variant: "destructive",
      });
    }
  };

  const handleOnSaveGroups = (group: FixtureGroupWithFixtures) => {
    groupToEdit.id.length > 0
      ? handleEditGroup(group)
      : handleCreateNewGroup(group);
  };

  return (
    <>
      {isLoading && <Loading />}
      <Card className="flex h-full w-full flex-col">
        <CardTitle className="flex">
          {t("fixtures")}
          <Tooltip content={"Press the add button to create a new fixture"}>
            <Info size="20" className="ml-2" strokeWidth={1.5} />
          </Tooltip>
        </CardTitle>
        <div className="py-4">
          <Button onClick={() => onCreateNewFixture()}>+ {t("fixture")}</Button>
          <Button className="ml-4" onClick={() => onCreateNewGroup()}>
            + {t("group")}
          </Button>
        </div>
        <div className="mx-[-16px] flex flex-col overflow-y-auto">
          {fixturesWithGroups && fixturesWithGroups.length > 0 && (
            <FixturesWithGroupList
              fixturesWithGroups={fixturesWithGroups}
              onEditFixture={onEditFixture}
              onEditGroup={onEditGroup}
              onDeleteFixture={handleDeleteFixture}
              onDeleteGroup={handleRemoveGroup}
              onDeleteFixtureFromGroup={removeFixtureFromGroup}
            />
          )}
          {fixturesWithoutGroups && fixturesWithoutGroups.length > 0 && (
            <FixturesWithoutGroupList
              fixturesWithoutGroups={fixturesWithoutGroups}
              onEdit={onEditFixture}
              onDelete={handleDeleteFixture}
            />
          )}
        </div>
        <div>
          <FixtureSheet
            availableFixtureGroups={fixturesWithGroups}
            openSheet={openFixtureSheet}
            setOpenSheet={setOpenFixtureSheet}
            fixtureToEdit={fixtureToEdit}
            onSave={handleOnSaveFixtures}
          />
          <FixtureGroupSheet
            availableFixtures={allFixtures}
            openSheet={openGroupSheet}
            setOpenSheet={setOpenGroupSheet}
            groupToEdit={groupToEdit}
            onSave={handleOnSaveGroups}
          />
        </div>
      </Card>
    </>
  );
};

const FixturesWithGroupList = ({
  fixturesWithGroups,
  onEditFixture,
  onEditGroup,
  onDeleteFixture,
  onDeleteGroup,
  onDeleteFixtureFromGroup,
}: {
  fixturesWithGroups: FixtureGroupWithFixtures[];
  onEditFixture: (fixture: Fixture) => void;
  onEditGroup: (group: FixtureGroupWithFixtures) => void;
  onDeleteFixture: (fixture: Fixture, group: FixtureGroupWithFixtures) => void;
  onDeleteGroup: (group: FixtureGroupWithFixtures) => void;
  onDeleteFixtureFromGroup: (
    fixture: Fixture,
    group: FixtureGroupWithFixtures,
  ) => void;
}) => {
  const sortedFixturesWithGroups = [...fixturesWithGroups].sort(
    (a, b) => b.fixtures.length - a.fixtures.length,
  );
  const { t } = useTranslation();

  const RemoveFromGroupDropdownItem = ({
    fixture,
    group,
  }: {
    fixture: Fixture;
    group: FixtureGroupWithFixtures;
  }) => {
    return (
      <>
        <DropdownMenuItem
          className="text-status-error"
          onClick={() => onDeleteFixtureFromGroup(fixture, group)}
        >
          <Trash size={16} className="mr-2 text-status-error" />
          {t("remove-from-group")}
        </DropdownMenuItem>
      </>
    );
  };

  return (
    <>
      {sortedFixturesWithGroups?.map((group) => {
        return (
          <div key={group.id}>
            <div>
              <DefaultListItem
                className="border-0"
                titleType="header"
                title={group.name}
                onEdit={() => onEditGroup(group)}
                onDelete={() => onDeleteGroup(group)}
              />
              {group.fixtures.length === 0 && (
                <div>
                  <p className="p-4 text-sm italic text-secondary-text">
                    {t("the-group-contains-no-fixtures")}
                  </p>
                </div>
              )}
            </div>
            {group.fixtures.map((fix) => (
              <div key={fix.id}>
                <DefaultListItem
                  title={fix.name}
                  onEdit={() => onEditFixture(fix)}
                  onDelete={() => onDeleteFixture(fix, group)}
                  customDropdownItems={[
                    <RemoveFromGroupDropdownItem fixture={fix} group={group} />,
                  ]}
                />
              </div>
            ))}
          </div>
        );
      })}
    </>
  );
};

const FixturesWithoutGroupList = ({
  fixturesWithoutGroups,
  onEdit,
  onDelete,
}: {
  fixturesWithoutGroups: Fixture[];
  onEdit: (fixture: Fixture) => void;
  onDelete: (fixture: Fixture) => void;
}) => {
  const { t } = useTranslation();
  return (
    <>
      <div className="text-md w-full py-4 font-itc font-bold">
        <h3 className="mx-[16px]">{t("fixtures-without-group")}</h3>
      </div>
      {fixturesWithoutGroups?.map((fix) => {
        return (
          <div key={fix.id}>
            <DefaultListItem
              title={fix.name}
              onEdit={() => onEdit(fix)}
              onDelete={() => onDelete(fix)}
            />
          </div>
        );
      })}
    </>
  );
};
