import { LocationProps } from "../../../settings/locations/components/locations-hierarchy-tree.tsx";
import {
  LocationsAction,
  useLocationsPageContext,
} from "../locations-context.tsx";
import { findAndSortChildren } from "../locations-utils.tsx";
import { LocationsInputType } from "../../../settings/locations/components/locations-dropdown-menu.tsx";
import { AssetOverview } from "../../../../../../api-contracts/assets";
import { useTranslation } from "react-i18next";
import { DefaultListItem } from "@components/default-list.tsx";
import { useRemoveLocationFromAsset } from "@api/assets.ts";
import { useSharedContext } from "@context/shared-context.tsx";
import { AlertDialog } from "@primitives/alert-dialog.tsx";
import { SetStateAction, useState } from "react";

interface CardListProps<T> {
  label: string;
  type: LocationsInputType;
  item: T;
}
export const LocationsListItem = <T,>({
  label,
  type,
  item,
}: CardListProps<T>) => {
  const { t } = useTranslation();
  const [alertOpen, setAlertOpen] = useState<boolean>(false);
  const {
    sharedState: { locations, actionType },
    sharedFunctions: {
      setActionType,
      setCurrentAsset,
      setCurrentLocation,
      deleteLocation,
      refetchAssetList,
    },
  } = useLocationsPageContext();
  const {
    sharedFunctions: { setLoading, toast },
  } = useSharedContext();
  const { mutate: removeLocation } = useRemoveLocationFromAsset();

  const removeLocationFromAsset = (id: string, name: string) => {
    setLoading(true);

    removeLocation(
      {
        id: id,
      },
      {
        onError: (e) => {
          toast({
            variant: "destructive",
            title: t("remove-bookable-error", { error: e.message }),
          });
        },
        onSuccess: async () => {
          toast({
            variant: "success",
            title: t("bookable-removed", { bookable: name }),
          });
          setCurrentAsset(null);
          await refetchAssetList();
        },
        onSettled: () => {
          setLoading(false);
          setActionType(LocationsAction.DEFAULT);
        },
      },
    );
  };

  const onMove = () => {
    if (type === LocationsInputType.LOCATION) {
      setActionType(LocationsAction.LOCATION_MOVE);
      setCurrentLocation(item as LocationProps);
    }
    if (type === LocationsInputType.ASSET) {
      setActionType(LocationsAction.ASSET_MOVE);
      setCurrentAsset(item as AssetOverview);
    }
  };

  const onEditLocation = () => {
    setCurrentLocation(item as LocationProps);
    setActionType(LocationsAction.LOCATION_EDIT);
  };

  const onRemoveBookable = () => {
    const asset = item as AssetOverview;
    !asset.location?.id && setCurrentLocation(null);
    asset.location?.id &&
      setCurrentLocation(
        locations.find((it) => asset.location.id === it.location.id) ?? null,
      );
    removeLocationFromAsset(asset.id, asset.name);
  };

  const onDeleteLocation = () => {
    const location = item as LocationProps;
    setCurrentLocation(
      locations.find(
        (it) =>
          it.location.id === location.location.partOf &&
          location.location.partOf != null,
      ) ?? null,
    );
    deleteLocation(location.location.id, location.location.name);
  };

  const onDelete = () => {
    type === LocationsInputType.LOCATION
      ? onDeleteLocation()
      : onRemoveBookable();
  };

  const handleOnClick = (item: LocationProps) => {
    type === LocationsInputType.LOCATION &&
      item &&
      setCurrentLocation(() => ({
        ...item,
        isOpen: findAndSortChildren(item.location.id, locations).length > 0,
      }));
  };

  const alertProps = {
    alertTitle:
      type === LocationsInputType.LOCATION
        ? t("are-you-sure-you-want-to-delete-location", {
            location: (item as LocationProps).location.name,
          })
        : t("are-you-sure-you-want-to-remove-bookable", {
            bookable: (item as AssetOverview).name,
          }),
    alertDescription:
      type === LocationsInputType.LOCATION
        ? t("all-underlying-locations-will-be-deleted")
        : t("bookable-will-be-removed-from-allocation"),
    alertSubmitButtonText:
      type === LocationsInputType.LOCATION
        ? t("yes-delete-location", {
            location: (item as LocationProps).location.name,
          })
        : t("yes-remove-bookable", { bookable: (item as AssetOverview).name }),
  };

  return (
    <>
      <DefaultListItem
        chevron={type === LocationsInputType.LOCATION}
        onClickListItem={() => handleOnClick(item as LocationProps)}
        title={label}
        subTitle={
          type === LocationsInputType.LOCATION
            ? t("location")
            : `${(item as AssetOverview).types.map((type) => `${t(type)} `)}`
        }
        badge={
          type !== LocationsInputType.LOCATION
            ? t("bookable").toLowerCase()
            : undefined
        }
        onEdit={
          type === LocationsInputType.LOCATION ? onEditLocation : undefined
        }
        onDelete={(e) => {
          e?.stopPropagation();
          setAlertOpen(true);
        }}
        onMove={onMove}
      />

      <DefaultAlert
        alertOpen={alertOpen}
        setAlertOpen={setAlertOpen}
        title={alertProps.alertTitle}
        description={alertProps.alertDescription}
        primaryButtonText={alertProps.alertSubmitButtonText}
        onPrimaryButton={onDelete}
      />
    </>
  );
};

export const DefaultAlert = ({
  alertOpen,
  setAlertOpen,
  title,
  description,
  onPrimaryButton,
  onCancelButton,
  primaryButtonText,
}: {
  title: string;
  description: string;
  primaryButtonText: string;
  alertOpen: boolean;
  setAlertOpen: React.Dispatch<SetStateAction<boolean>>;
  onPrimaryButton: () => void;
  onCancelButton?: () => void;
}) => {
  const { t } = useTranslation();
  return (
    <AlertDialog.Root open={alertOpen} onOpenChange={() => setAlertOpen(false)}>
      <AlertDialog.Content>
        <AlertDialog.Header>
          <AlertDialog.Title>{title}</AlertDialog.Title>
        </AlertDialog.Header>
        <AlertDialog.Description>{description}</AlertDialog.Description>
        <AlertDialog.Footer>
          <AlertDialog.Cancel
            onClick={onCancelButton ? () => onCancelButton() : undefined}
          >
            {t("cancel")}
          </AlertDialog.Cancel>
          <AlertDialog.Action
            onClick={(e) => {
              e.stopPropagation();
              onPrimaryButton && onPrimaryButton();
              setAlertOpen(false);
            }}
          >
            {primaryButtonText}
          </AlertDialog.Action>
        </AlertDialog.Footer>
      </AlertDialog.Content>
    </AlertDialog.Root>
  );
};
