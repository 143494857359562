import { createMutation, createQuery } from "react-query-kit";
import { api } from "@api/api.ts";
import { currentSite } from "@context/site-context.tsx";
import {
  CreateCategoryClusterRequest,
  CreateCategoryClusterResponse,
  DeleteCategoryClusterRequest,
  DeleteCategoryClusterResponse,
  GetCategoryClustersResponse,
  PatchCategoryClusterRequest,
  PatchCategoryClusterResponse,
} from "../../../../api-contracts/category-clusters";

export const CATEGORY_CLUSTERS_QUERY_KEY = "category-clusters";
export const CATEGORY_CLUSTERS = "category-clusters";

export const useGetCategoryClusters = createQuery<GetCategoryClustersResponse>({
  queryFn: async () => api.get(`/api/${currentSite?.id}/${CATEGORY_CLUSTERS}`),
  primaryKey: CATEGORY_CLUSTERS_QUERY_KEY,
});

export const useCreateCluster = createMutation<
  CreateCategoryClusterResponse,
  CreateCategoryClusterRequest
>({
  mutationFn: async (cluster) =>
    api.post(`/api/${currentSite?.id}/${CATEGORY_CLUSTERS}`, cluster),
});

export const usePatchClusterById = createMutation<
  PatchCategoryClusterResponse,
  { patch: PatchCategoryClusterRequest; id: string }
>({
  mutationFn: async ({ patch, id }) =>
    api.patch(`/api/${currentSite?.id}/${CATEGORY_CLUSTERS}/${id}`, patch),
});

export const useDeleteCluster = createMutation<
  DeleteCategoryClusterResponse,
  { id: string }
>({
  mutationFn: async ({ id }) =>
    api.delete(`/api/${currentSite?.id}/${CATEGORY_CLUSTERS}/${id}`),
});
