import { CamprType, RoomrType } from "../../../../api-contracts/categories";

export const isRoomrCategoryType = (type: string): type is RoomrType => {
  return ["room", "area", "bed", "dormitory"].includes(type);
};

export const isCamprCategoryType = (type: string): type is CamprType => {
  return [
    "tent",
    "motorhome",
    "caravan",
    "cabin",
    "glamping",
    "campr-area",
    "misc",
    "mobile-home",
  ].includes(type);
};
