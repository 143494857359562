import { useGetOrganizations } from "@api/organizations";
import { SearchSelect } from "../components/search-select";
import { Loader2 } from "lucide-react";
import { OrganizationForm } from "../components/forms/enteties";
import { useAdmin } from "../context";
import { Organization } from "../../../../../api-contracts/organizations";

export const OrganizationSection: React.FC<unknown> = () => {
  const { data: orgs, isLoading } = useGetOrganizations();
  const { state, dispatch } = useAdmin();

  const selectOrg = (org: Organization | null) => {
    dispatch({
      type: "ORG_SELECT",
      payload: org ?? null,
    });
  };

  return (
    <div className="min-h[100px]  grid grid-cols-2 divide-x">
      <div className="w-fit ">
        <h2 className="mb-6 w-fit text-xl font-semibold">
          Select Organization
        </h2>
        {isLoading && (
          <div className="z-20 flex h-full w-full items-center  backdrop-blur-sm">
            <Loader2 className="h-20 w-20 animate-spin text-primary-color" />
          </div>
        )}
        {!isLoading && !!orgs && (
          <div className="w-fit">
            <SearchSelect
              payload={orgs.map((org) => ({
                value: org.name,
                id: org.id,
              }))}
              placeholder="Select Organization..."
              trigger="Select Organization"
              empty="No Organizations"
              value={state.organization?.name ?? ""}
              onSelect={(item) =>
                selectOrg(item ? { name: item.value, id: item.id } : null)
              }
            ></SearchSelect>
          </div>
        )}
      </div>
      <div className=" pl-10">
        <h2 className="mb-6 w-fit text-xl font-semibold">
          Create Organization
        </h2>
        <OrganizationForm onCreated={selectOrg} />
      </div>
    </div>
  );
};
