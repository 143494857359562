import { Button } from "@primitives/button";
import { SearchBar } from "@primitives/search-bar";
import { SwitchWithLabel } from "@primitives/switch-with-label";
import { Filter, Printer } from "lucide-react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useReceptionContext } from "../reception-context";
import { Column, RowData } from "@tanstack/react-table";
import {
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuRoot,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@primitives/dropdown-menu";
import { CheckboxWithLabel } from "@primitives/checkbox-with-label";
import { BookingState } from "../../../../../api-contracts/reservations";
import { printTable } from "../reception-utils";

const ICON_SIZE = 17;
const ICON_STROKE_WIDTH = 1;

interface ColumnVisibilityToggleProps<RowData> {
  columns: Column<RowData, unknown>[];
}

declare module "@tanstack/react-table" {
  interface ColumnMeta<TData extends RowData, TValue> {
    initialVisibility?: boolean;
    initialVisibleStates?: BookingState[];
  }
}

export const TableHeader = <RowData,>({
  columns,
  tableType,
}: ColumnVisibilityToggleProps<RowData> & {
  tableType: "arrivals" | "departures" | "occupants";
}) => {
  const { t } = useTranslation();
  const { searchTerm, setSearchTerm, showLocation, setShowLocation } =
    useReceptionContext();

  return (
    <div className="flex items-center justify-between">
      <div className="flex">
        <SearchBar
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="min-w-[250px]"
        />
        <div
          className={
            "mx-2 flex items-center rounded-md bg-primary-button-backplate text-primary-text "
          }
        >
          <div className="mx-2">
            <SwitchWithLabel
              checked={showLocation}
              onCheckedChange={setShowLocation}
              label={t("locations")}
            />
          </div>
        </div>
      </div>
      <div className="flex items-center">
        <ColumnVisibilityToggle columns={columns} tableType={tableType} />
        <Button variant={"secondary"} size="icon" onClick={printTable}>
          <Printer strokeWidth={ICON_STROKE_WIDTH} size={ICON_SIZE} />
        </Button>
      </div>
    </div>
  );
};

export const ColumnVisibilityToggle = <RowData,>({
  columns,
  tableType,
}: ColumnVisibilityToggleProps<RowData> & {
  tableType: "arrivals" | "departures" | "occupants";
}) => {
  const { t } = useTranslation();
  const {
    arrivalsVisibleState,
    setArrivalsVisibleState,
    departuresVisibleState,
    setDeparturesVisibleState,
    occupantsVisibleState,
    setOccupantsVisibleState,
    date,
  } = useReceptionContext();

  const stateColumns = columns.filter((col) => col.id.includes("state"));
  const today = new Date();
  const isToday = date.toDateString() === today.toDateString();
  const isPast = date < today;
  const isFuture = date > today;

  const bookingStates: BookingState[] =
    tableType === "arrivals"
      ? ["planned", "checked-in", "checked-out", "no-show"]
      : tableType === "departures"
        ? ["checked-in", "checked-out"]
        : tableType === "occupants"
          ? ["planned", "checked-in", "no-show"]
          : [
              "planned",
              "checked-in",
              "no-show",
              "checked-out",
              "aborted",
              "cancelled",
            ];

  const visibleStates =
    tableType === "occupants"
      ? occupantsVisibleState
      : tableType === "arrivals"
        ? arrivalsVisibleState
        : departuresVisibleState;

  const setVisibleStates =
    tableType === "occupants"
      ? setOccupantsVisibleState
      : tableType === "arrivals"
        ? setArrivalsVisibleState
        : setDeparturesVisibleState;

  const handleStateToggle = (state: BookingState) => {
    setVisibleStates((prev) =>
      prev.includes(state) ? prev.filter((s) => s !== state) : [...prev, state],
    );
  };

  const resetColumnVisibility = () => {
    columns.forEach((col) => {
      const customMeta = col.columnDef.meta;
      if (customMeta?.initialVisibility !== undefined) {
        col.toggleVisibility(customMeta.initialVisibility);
      }
    });

    if (isToday) {
      setVisibleStates(
        tableType === "arrivals"
          ? ["planned", "checked-in", "checked-out"]
          : tableType === "departures"
            ? ["checked-in"]
            : tableType === "occupants"
              ? ["planned", "checked-in", "no-show"]
              : [
                  "planned",
                  "checked-in",
                  "no-show",
                  "checked-out",
                  "aborted",
                  "cancelled",
                ],
      );
    } else if (isPast) {
      setVisibleStates(
        tableType === "arrivals"
          ? ["checked-in", "checked-out", "no-show"]
          : tableType === "departures"
            ? ["checked-out"]
            : tableType === "occupants"
              ? ["checked-in", "no-show"]
              : [],
      );
    } else if (isFuture) {
      setVisibleStates(
        tableType === "arrivals"
          ? ["planned"]
          : tableType === "departures"
            ? ["checked-in"]
            : tableType === "occupants"
              ? ["planned", "checked-in"]
              : [],
      );
    }
  };

  useEffect(() => {
    resetColumnVisibility();
  }, [date, tableType]);

  return (
    <DropdownMenuRoot>
      <DropdownMenuTrigger asChild>
        <Button className="mx-2 flex" variant={"secondary"}>
          <Filter
            strokeWidth={ICON_STROKE_WIDTH}
            size={ICON_SIZE}
            className="mr-1"
          />
          {t("show")}
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="flex max-h-[55vh] min-w-[200px] flex-col items-start justify-start overflow-y-auto px-4">
        <DropdownMenuLabel>{t("status")}</DropdownMenuLabel>
        {bookingStates.map((state) => {
          const stateColumn = stateColumns.find((col) =>
            col.id.includes("state"),
          );

          if (!stateColumn || stateColumn.columnDef.enableHiding === false)
            return null;

          const isVisible = visibleStates.includes(state);
          return (
            <div key={state} className="flex h-[32px] items-center">
              <CheckboxWithLabel
                label={state === "planned" ? t("not-checked-in") : t(state)}
                checked={isVisible}
                onCheckedChange={() => handleStateToggle(state)}
              />
            </div>
          );
        })}
        <DropdownMenuSeparator />
        <DropdownMenuLabel>{t("columns")}</DropdownMenuLabel>
        <DropdownMenuSeparator />
        {columns.map((col) => {
          if (col.columnDef.enableHiding === false) return null;
          const label = col?.columnDef?.header?.toString();

          return (
            <div key={col.id} className="flex h-[32px] items-center">
              <CheckboxWithLabel
                key={col.id}
                label={label}
                checked={col.getIsVisible()}
                onCheckedChange={() => col.toggleVisibility()}
              />
            </div>
          );
        })}
        <div className="my-1 flex w-full items-center justify-center">
          <Button
            size={"sm"}
            variant={"outline"}
            className="flex items-center justify-center"
            onClick={resetColumnVisibility}
          >
            {t("reset")}
          </Button>
        </div>
      </DropdownMenuContent>
    </DropdownMenuRoot>
  );
};
