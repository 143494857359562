import { ReceptionBooking } from "../../../../../../api-contracts/reception";
import { ConfirmDialog } from "@components/confirm-dialog";
import { useTranslation } from "react-i18next";
import { AllocateRoomDialog } from "./allocate-room-dialog";
import { PatchBookingRequest } from "../../../../../../api-contracts/reservations";
import { UndoNoShowDialog } from "./undo-no-show-dialog";
import { CheckinRequest } from "../../../../../../api-contracts/reception/arrivals";

interface CheckInDialogManagerProps {
  selectedBooking: {
    booking: ReceptionBooking | null;
    isOpen: boolean;
    dialogType:
      | "confirm"
      | "chooseBookable"
      | "checkInAssignedRoom"
      | "paymentRemaining"
      | "undoCheckIn"
      | "undoNoShow"
      | "assignBeforeCheckIn"
      | null;
  };
  onCheckIn: (payload: CheckinRequest) => void;
  onUndoCheckIn: (bookingId: string) => void;
  closeDialogs: () => void;
  onPatchBooking: (
    booking: ReceptionBooking,
    patchData: PatchBookingRequest,
  ) => void;
  onSplitNoShow: (booking: ReceptionBooking, splitDate: string) => void;
}

export const CheckInDialogManager = ({
  selectedBooking,
  onCheckIn,
  onUndoCheckIn,
  closeDialogs,
  onPatchBooking,
  onSplitNoShow,
}: CheckInDialogManagerProps) => {
  const { t } = useTranslation();
  const { booking, isOpen, dialogType } = selectedBooking;

  if (!booking) return;

  return (
    <>
      {/* Delete "paymentRemaining" when there is a Payment/Folio page */}
      {(dialogType === "confirm" || dialogType === "paymentRemaining") &&
        booking &&
        isOpen && (
          <ConfirmDialog
            title={t("check-in")}
            description={t(
              "are-you-sure-to-check-in-{{name}}-{{surname}}-in-{{unit}}?",
              {
                name: booking.holder.name || t("guest"),
                surname: booking.holder.surname || "",
                unit: booking.bookable.name,
              },
            )}
            isOpen={isOpen}
            onProceed={() => {
              onCheckIn({
                bookingId: booking?.bookingId,
                bookableId: booking?.bookable.id,
                categoryId: booking?.category.id,
                keepPrice: false,
              });
              closeDialogs();
            }}
            onOpenChange={closeDialogs}
            proceedBtnText={t("check-in")}
          />
        )}

      {dialogType === "undoCheckIn" && booking && isOpen && (
        <ConfirmDialog
          title={t("undo-check-in")}
          description={t(
            "are-you-sure-to-undo-check-in-for-{{name}}-{{surname}}",
            {
              name: booking.holder.name,
              surname: booking.holder.surname,
            },
          )}
          isOpen={isOpen}
          onProceed={() => {
            onUndoCheckIn(booking.bookingId);
            closeDialogs();
          }}
          onOpenChange={closeDialogs}
          proceedBtnText={t("undo-check-in")}
        />
      )}

      {/*                    WIP 
          Uncomment this when there is a Payment/Folio page
          
      {dialogType === "paymentRemaining" && booking && isOpen && (
        <ConfirmDialog
          title={t("payment-remains")}
          description={t("payment-before-checking-description")}
          isOpen={isOpen}
          onProceed={closeDialogs}
          onOpenChange={closeDialogs}
          proceedBtnText={t("go-to-payment")}
        />
      )} */}

      {(dialogType === "checkInAssignedRoom" ||
        dialogType === "paymentRemaining") &&
        booking &&
        isOpen && (
          <AllocateRoomDialog
            dialogType={dialogType}
            booking={booking}
            title={t("allocate-unit")}
            description={t("do-you-want-to-check-in-{{name}}-{{surname}}?", {
              name: booking.holder.name || t("guest"),
              surname: booking.holder.surname || "",
            })}
            isOpen={isOpen}
            onCheckIn={onCheckIn}
            onPatchBooking={onPatchBooking}
            onOpenChange={closeDialogs}
            proceedBtnText={t("check-in")}
          />
        )}

      {dialogType === "chooseBookable" && booking && isOpen && (
        <AllocateRoomDialog
          dialogType={dialogType}
          booking={booking}
          title={t("allocate-unit")}
          description={t("allocate-unit-for-{{name}}-{{surname}}", {
            name: booking.holder.name || t("guest"),
            surname: booking.holder.surname || "",
          })}
          isOpen={isOpen}
          onCheckIn={onCheckIn}
          onPatchBooking={onPatchBooking}
          onOpenChange={closeDialogs}
          proceedBtnText={t("save")}
        />
      )}
      {dialogType === "assignBeforeCheckIn" && booking && isOpen && (
        <AllocateRoomDialog
          dialogType={dialogType}
          booking={booking}
          title={t("allocate-unit")}
          description={t("allocate-unit-for-{{name}}-{{surname}}", {
            name: booking.holder.name || t("guest"),
            surname: booking.holder.surname || "",
          })}
          isOpen={isOpen}
          onCheckIn={onCheckIn}
          onPatchBooking={onPatchBooking}
          onOpenChange={closeDialogs}
          proceedBtnText={t("check-in")}
        />
      )}
      {dialogType === "undoNoShow" && booking && isOpen && (
        <UndoNoShowDialog
          booking={booking}
          title={t("check-in")}
          description={t(
            "choose-from-what-date-to-check-in-{{name}}-{{surname}}",
            {
              name: booking.holder.name || t("guest"),
              surname: booking.holder.surname || "",
            },
          )}
          isOpen={isOpen}
          onProceed={onSplitNoShow}
          onOpenChange={closeDialogs}
          proceedBtnText={t("check-in")}
        />
      )}
    </>
  );
};
