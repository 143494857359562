import { TabNavbar } from "@components/tab-navbar.tsx";
import { BedTable } from "@pages/settings/bed-types/components/bed-table.tsx";
import { useGetBedTypes } from "@api/bed-types.ts";
import { GetBedTypeResponse } from "../../../../../api-contracts/bed-capacity";
import { ReactElement, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Space } from "@components/space.tsx";
import { AgeRangeTable } from "@pages/settings/bed-types/components/age-range-table.tsx";
import { BedAccessoriesTable } from "@pages/settings/bed-types/components/bed-accessories-table.tsx";
import { BedLinenTable } from "@pages/settings/bed-types/components/bed-linen-table.tsx";
import { queryClient } from "query-client";

type TabType = "bed-types" | "age-range" | "bed-accessories" | "bed-linen";

export const BedTypesPage = () => {
  const { t } = useTranslation();
  const [standardBeds, setStandardBeds] = useState<
    GetBedTypeResponse[] | undefined
  >();
  const [extraBeds, setExtraBeds] = useState<
    GetBedTypeResponse[] | undefined
  >();

  useEffect(() => {
    queryClient.invalidateQueries({
      queryKey: useGetBedTypes.getKey(),
    });
  }, []);

  const { data: bedTypesData } = useGetBedTypes();

  const [tabType, setTabType] = useState<TabType>("bed-types");

  useEffect(() => {
    if (bedTypesData) {
      setExtraBeds(bedTypesData.filter((bed) => bed.extraBed));
      setStandardBeds(bedTypesData.filter((bed) => !bed.extraBed));
    }
  }, [bedTypesData]);

  const renderContent = () => {
    switch (tabType) {
      case "bed-types":
        return (
          <>
            {standardBeds && (
              <BedTable
                type={"standard"}
                bedTypes={standardBeds}
                title={t("standard-beds")}
              />
            )}
            <Space w={18} />
            {extraBeds && (
              <BedTable
                type={"extra"}
                bedTypes={extraBeds}
                title={t("extra-beds")}
              />
            )}
          </>
        );
      case "age-range":
        return <AgeRangeTable />;
      case "bed-accessories":
        return <BedAccessoriesTable />;
      case "bed-linen":
        return <BedLinenTable />;
    }
  };

  return (
    <div className={"h-[calc(100vh_-_115px)] overflow-hidden p-4"}>
      <p className={" pl-1.5 font-itc text-2xl font-bold text-primary-text"}>
        {t("bed-types-and-inventories")}
      </p>
      <div className={"pb-10 pt-4 "}>
        <TabNavbar
          onValueChange={(value) => {
            setTabType(value as TabType);
          }}
          value={tabType}
          items={[
            {
              value: "bed-types",
              label: t("bed-types"),
              disabled: false,
            },
            { value: "age-range", label: t("age-range"), disabled: false },
            {
              value: "bed-accessories",
              label: t("accessory-dropdown-title"),
              disabled: false,
            },
            { value: "bed-linen", label: t("bedlinen"), disabled: false },
          ]}
        />
      </div>
      <div className={"flex h-full"}>{renderContent()}</div>
    </div>
  );
};
