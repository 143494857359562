import { Link } from "react-router-dom";
import { ROUTES } from "@shared/types/navigation.ts";
import { Button } from "@primitives/button.tsx";
import { useTranslation } from "react-i18next";
import {
  AssetType,
  VisualAssetType,
} from "../../../../../../../api-contracts/assets";
import { FC } from "react";
import { useProfileContext } from "@context/profile-context.tsx";

interface Props {
  tab: VisualAssetType;
  onClick: () => void;
}

export const CamprNewButton: FC<Props> = ({ tab, onClick }) => {
  const { module } = useProfileContext();
  const { t } = useTranslation();
  return (
    <>
      {tab === "plot" && (
        <Link to={`/${module}/${ROUTES.BOOKABLES}/${ROUTES.PLOTS}/new`}>
          <Button variant="primary">{t("create-new")}</Button>
        </Link>
      )}
      {tab === "cabin" && (
        <Link to={`/${module}/${ROUTES.BOOKABLES}/${ROUTES.CABINS}/new`}>
          <Button variant="primary">{t("create-new")}</Button>
        </Link>
      )}
      {tab === "misc" && (
        <Link
          to={`/${module}/${ROUTES.BOOKABLES}/${ROUTES.OTHER_ACCOMMODATIONS}/new`}
        >
          <Button variant="primary">{t("create-new")}</Button>
        </Link>
      )}
      {tab === "campr-area" && (
        <Link to={`/${module}/${ROUTES.BOOKABLES}/${ROUTES.AREAS}/new`}>
          <Button variant="primary">{t("create-new")}</Button>
        </Link>
      )}

      {tab === "campr-combination" && (
        <Button variant="primary" onClick={onClick}>
          {t("create-new")}
        </Button>
      )}
    </>
  );
};
