import { Card, CardTitle } from "@primitives/card.tsx";
import { Button } from "@primitives/button.tsx";
import { Info, Plus } from "lucide-react";
import {
  ChannelsActionType,
  useChannelsPageContext,
} from "../channels-context.tsx";
import { Tooltip } from "@primitives/tooltip.tsx";
import { useTranslation } from "react-i18next";
import { ChannelsCardItem } from "@pages/settings/channels/components/channels-card-item.tsx";

export const ChannelsCard = () => {
  const {
    sharedState: { channels },
    sharedFunctions: { setActionType },
  } = useChannelsPageContext();

  const { t } = useTranslation();

  return (
    <Card className="h-full max-w-[500px]">
      <CardTitle className="flex">
        {t("channels")}
        <Tooltip content={"some info here"}>
          <Info size="20" className="ml-1" />
        </Tooltip>
      </CardTitle>
      <div className="py-4">
        <Button
          variant="primary"
          size="icon"
          onClick={() => setActionType(ChannelsActionType.ADD)}
        >
          <Plus size={17} />
        </Button>
      </div>
      <div className="mx-[-1rem] flex h-[calc(100vh-250px)] max-w-[500px] flex-grow flex-col gap-0.5 overflow-y-auto">
        {channels.map((channel, i) => (
          <ChannelsCardItem
            key={`${i}-${channel.id}`}
            channel={channel}
          ></ChannelsCardItem>
        ))}
      </div>
    </Card>
  );
};
