import { z } from "zod";
import { Controller, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useTranslation } from "react-i18next";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@primitives/dialog.tsx";
import { FormField } from "@primitives/form-field.tsx";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@primitives/select.tsx";
import DatePicker from "@primitives/date-picker.tsx";
import { Button } from "@primitives/button.tsx";
import {
  PRICE_RULES_KEY,
  useDisablePriceRule,
  useEnablePriceRule,
  useGetPriceRules,
} from "@api/price-rules.ts";
import { useQueryClient } from "@tanstack/react-query";
import { useToast } from "@hooks/use-toast";
import { GetPriceRule } from "../../../../../../api-contracts/price-rules";

const schema = z.object({
  replacementRateId: z.string().optional(),
  date: z.date(),
});

export type RateValues = z.infer<typeof schema>;

interface Props {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  rule: GetPriceRule;
  tab: "hotel" | "area" | "hostel";
}

const PriceRuleToggleActiveDialog = ({
  open,
  onOpenChange,
  rule,
  tab,
}: Props) => {
  const { handleSubmit, control, formState } = useForm<RateValues>({
    defaultValues: {},
    resolver: zodResolver(schema),
  });
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { toast } = useToast();

  const { mutateAsync: disablePriceRule } = useDisablePriceRule();
  const { mutateAsync: enablePriceRule } = useEnablePriceRule();

  const {
    data: priceRules,
    isLoading: priceRulesLoading,
    isRefetching: isRefetchingPriceRules,
  } = useGetPriceRules({
    variables: {
      categoryTypes:
        tab === "hostel"
          ? ["bed", "dormitory"]
          : tab === "hotel"
            ? ["room"]
            : [tab],
    },
  });

  const onSubmitDeactivateRate = async (values: RateValues) => {
    await disablePriceRule(
      {
        ruleId: rule.id,
        replacementPriceRuleId: values.replacementRateId,
        inactivationDate: values.date,
      },
      {
        onSuccess(data, variables, context) {
          queryClient.invalidateQueries({ queryKey: [PRICE_RULES_KEY] });
          toast({
            variant: "success",
            title: t("saved-succesfully", { name: rule.name }),
            className: "text-status-success",
          });
        },
        onError(error, variables, context) {
          toast({
            variant: "destructive",
            title:
              t("request-failed-with") +
              ": " +
              t(decodeURIComponent((error as any)?.message || t("no-message"))),
            className: "text-status-error",
          });
        },
      },
    );

    onOpenChange(false);
  };

  const onSubmitActivateRate = async (values: RateValues) => {
    await enablePriceRule(
      {
        ruleId: rule.id,
        reactivationDate: values.date,
      },
      {
        onSuccess(data, variables, context) {
          queryClient.invalidateQueries({ queryKey: [PRICE_RULES_KEY] });
          toast({
            variant: "success",
            title: t("saved-succesfully", { name: rule.name }),
            className: "text-status-success",
          });
        },
        onError(error, variables, context) {
          toast({
            variant: "destructive",
            title:
              t("request-failed-with") +
              ": " +
              t(decodeURIComponent((error as any)?.message || t("no-message"))),
            className: "text-status-error",
          });
        },
      },
    );

    onOpenChange(false);
  };

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent>
        <form
          onSubmit={handleSubmit(
            !rule.active ? onSubmitActivateRate : onSubmitDeactivateRate,
          )}
          className="flex flex-col"
        >
          <DialogHeader className="mb-8 space-y-2">
            <DialogTitle>
              {!rule.active
                ? t("price-rules-activate-title", { rate: rule.name })
                : t("price-rules-deactivate-title", { rate: rule.name })}
            </DialogTitle>
            <DialogDescription>
              {!rule.active
                ? t("price-rules-activate-description", {
                    rate: rule.name,
                  })
                : t("price-rules-deactivate-description")}
            </DialogDescription>
          </DialogHeader>

          <div className="flex-1 space-y-4">
            {rule.active && (
              <FormField.Root>
                <FormField.Label>
                  {t("price-rules-replace-rate")}
                </FormField.Label>
                <FormField.Control>
                  <Controller
                    name="replacementRateId"
                    control={control}
                    render={({ field }) => {
                      return (
                        <Select
                          onValueChange={field.onChange}
                          defaultValue={field.value}
                          autoComplete="off"
                        >
                          <SelectTrigger>
                            <SelectValue
                              placeholder={t("price-rules-select-rate")}
                            />
                          </SelectTrigger>
                          <SelectContent>
                            <SelectItem value={null as unknown as string}>
                              {t("price-rules-no-replacement-rate")}
                            </SelectItem>
                            {priceRules?.rules
                              .filter((r) => r.id !== rule.id)
                              .map((r) => (
                                <SelectItem
                                  key={r.id}
                                  value={r.id}
                                  disabled={!r.active}
                                >
                                  {r.name}
                                </SelectItem>
                              ))}
                          </SelectContent>
                        </Select>
                      );
                    }}
                  />
                </FormField.Control>
              </FormField.Root>
            )}

            <FormField.Root>
              <FormField.Label>
                {!rule.active
                  ? t("price-rules-activation-date")
                  : t("price-rules-deactivation-date")}
                <span className="ml-1 text-red-400">*</span>
              </FormField.Label>
              <FormField.Control>
                <Controller
                  name="date"
                  control={control}
                  render={({ field }) => (
                    <DatePicker
                      onValueChange={field.onChange}
                      calendarProps={{
                        disabled: {
                          before: new Date(),
                        },
                      }}
                    />
                  )}
                />
              </FormField.Control>
            </FormField.Root>
          </div>
          <DialogFooter className="mt-4">
            <Button
              variant={"ghost"}
              onClick={() => onOpenChange(false)}
              type="button"
            >
              {t("cancel")}
            </Button>
            <Button
              variant={"default"}
              type="submit"
              disabled={!formState.isValid}
            >
              {!rule.active ? t("activate") : t("deactivate")}
            </Button>
          </DialogFooter>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default PriceRuleToggleActiveDialog;
