import { Button } from "@primitives/button";
import { Calendar } from "@primitives/calendar";
import { Popover } from "@primitives/popover";
import {
  addDays,
  format,
  isToday,
  isTomorrow,
  isYesterday,
  subDays,
} from "date-fns";
import { enGB, sv } from "date-fns/locale";
import { ChevronLeft, ChevronRight } from "lucide-react";
import { Dispatch, SetStateAction } from "react";
import { SelectSingleEventHandler } from "react-day-picker";
import { useTranslation } from "react-i18next";

export const DatePicker = ({
  date,
  setDate,
}: {
  date: Date;
  setDate: Dispatch<SetStateAction<Date>>;
}) => {
  const { t, i18n } = useTranslation();
  const today = new Date();
  const locale = i18n.language === "sv-se" ? sv : enGB;

  const onDateSelected: SelectSingleEventHandler = (date) => {
    const selecedDate = date || new Date();
    setDate(selecedDate);
  };

  const renderDateText = () => {
    if (date) {
      if (isYesterday(date)) {
        return t("yesterday");
      } else if (isToday(date)) {
        return t("today");
      } else if (isTomorrow(date)) {
        return t("tomorrow");
      } else {
        return format(date, "PPP", {
          locale: locale,
        });
      }
    }
    return t("date-picker.placeholder");
  };

  const increaseDate = () => {
    setDate((prevDate) => (prevDate ? addDays(prevDate, 1) : new Date()));
  };

  const decreaseDate = () => {
    setDate((prevDate) => (prevDate ? subDays(prevDate, 1) : new Date()));
  };
  return (
    <div className="flex items-center justify-center">
      <Popover.Root>
        <Button variant={"secondary"} size={"icon"} onClick={decreaseDate}>
          <ChevronLeft />
        </Button>
        <Popover.Trigger asChild className="mx-1">
          <Button variant={"secondary"}>
            <span>{renderDateText()}</span>
          </Button>
        </Popover.Trigger>
        <Button variant={"secondary"} size={"icon"} onClick={increaseDate}>
          <ChevronRight />
        </Button>

        <Popover.Content className="w-auto shadow-xl">
          <Calendar
            locale={locale}
            mode="single"
            className="w-full"
            selected={date}
            onSelect={onDateSelected}
          />
          <div className="flex items-center justify-center pt-2">
            <Button
              disabled={isToday(date ? date : "")}
              variant={"outline"}
              onClick={() => setDate(today)}
            >
              {t("jump-to-today")}
            </Button>
          </div>
        </Popover.Content>
      </Popover.Root>
    </div>
  );
};
