import { CardTitle } from "@primitives/card";
import { useTranslation } from "react-i18next";
import { MessageOfDay } from "../../components/message-of-day";
import { useReceptionContext } from "../../reception-context";
import { DatePicker } from "../../components/date-picker";

export const OccupantsCardHeader = () => {
  const { t } = useTranslation();
  const { setDate, date } = useReceptionContext();

  return (
    <>
      <div className="flex items-center justify-between border-b border-highlighted-backplate p-4">
        <div className="flex flex-col">
          <CardTitle>{t("occupants")}</CardTitle>
        </div>
        <div className="flex h-16 flex-grow items-center justify-center text-center">
          <>
            <div>
              <DatePicker date={date} setDate={setDate} />
            </div>
            <div className="mx-2">
              <MessageOfDay />
            </div>
          </>
        </div>
      </div>
    </>
  );
};
