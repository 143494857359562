import { useState } from "react";
import { BreadCrumbs } from "./components/breadcrumbs";
import { AdminNode, OrganizationNode } from "./sections/nodes";
import { Button } from "@primitives/button";
import { ArrowLeft, ArrowRight } from "lucide-react";
import { AdminProvider, useAdmin } from "./context";

/**
 * TODO
 *  - Implement Sections:
 *    - sections/sites.tsx
 *    - sections/org-users.tsx
 *    - sections/site-users.tsx
 *    - Maybe sections with departments and department-users
 */
const View = () => {
  const [selectedNode, setSelectedNode] = useState<AdminNode>(OrganizationNode);
  const { state } = useAdmin();
  return (
    <section className="flex h-full w-full flex-col content-center items-center p-12">
      <div className="grid w-full grid-cols-3  pb-4">
        <h1 className="p-1 text-2xl font-semibold text-primary-color">
          Admin View
        </h1>
        <BreadCrumbs node={selectedNode} onSelect={setSelectedNode} />
      </div>
      <div className="w-full p-10 shadow">
        <div className="m-auto rounded-md border-2 p-10">
          {/* Displays the current view/section e.g. Organization or Sites */}
          {<selectedNode.component />}
        </div>
        <span className="flex justify-between pt-10 ">
          <div>
            {selectedNode.from && (
              <Button
                key={selectedNode.from.name + selectedNode.name}
                variant="outline"
                className="mr-2"
                onClick={() => setSelectedNode(selectedNode.from as AdminNode)}
              >
                <ArrowLeft className=" mr-1" />
                {selectedNode.from.name}
              </Button>
            )}
          </div>
          <div>
            {selectedNode.to.map((node) => (
              <Button
                key={node.name + node.to[0]?.name}
                variant="outline"
                className="mr-2"
                disabled={!node.enabled(state)}
                onClick={() => setSelectedNode(node)}
              >
                <ArrowRight className=" mr-1" />
                {node.name}
              </Button>
            ))}
          </div>
        </span>
      </div>
    </section>
  );
};

export const AdminView = () => (
  <AdminProvider>
    <View />
  </AdminProvider>
);
