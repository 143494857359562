import {
  addDays,
  eachDayOfInterval,
  format,
  getDay,
  parseISO,
  startOfMonth,
  startOfWeek,
} from "date-fns";
import {
  CalendarYear,
  GetPriceCalendarResponse,
  Weather,
} from "../../../../../api-contracts/price-calendar";
import { sv, enGB } from "date-fns/locale";
import {
  Sun,
  Moon,
  CloudSun,
  CloudMoon,
  Cloud,
  CloudRain,
  CloudLightning,
  Wind,
  Snowflake,
} from "lucide-react";

export const generateYearCalendarData = async (
  apiData: GetPriceCalendarResponse,
  year: number,
  locale: string,
  weatherData?: any,
): Promise<CalendarYear> => {
  if (!apiData) {
    return { year: 2024, months: [] };
  }

  const processedWeatherData = weatherData
    ? processWeatherData(weatherData)
    : [];

  const monthNames = generateMonthNames(year, locale);
  const months = apiData.months.map((monthData, index) => {
    const weeks = [];
    let currentWeek = new Array(7).fill(null);
    let weekDay = (getDay(new Date(year, monthData.month - 1, 1)) + 6) % 7;

    monthData.days.forEach((dayData) => {
      const date = new Date(Date.UTC(year, monthData.month - 1, dayData.day));
      const dateString = format(date, "yyyy-MM-dd");

      const weatherForDay = processedWeatherData
        ? processedWeatherData.find(
            (weather: any) => weather.date === dateString,
          )
        : null;

      currentWeek[weekDay] = {
        date: dateString,
        day: dayData.day,
        occupancy: dayData.occupancy,
        priceRule: {
          name: dayData.priceRule,
          id: dayData.priceRuleId,
          amount: dayData.price,
        },
        distinctPriceRules: dayData.distinctPriceRules,
        notes: dayData.notes,
        price: dayData.price,
        weather: weatherForDay
          ? {
              tempMin: weatherForDay.weather.tempMin,
              tempMax: weatherForDay.weather.tempMax,
              weatherCondition: weatherForDay.weather.weatherCondition,
              weatherIcon: weatherForDay.weather.weatherIcon,
            }
          : null,
      };
      weekDay++;
      if (weekDay > 6) {
        weeks.push(currentWeek);
        currentWeek = new Array(7).fill(null);
        weekDay = 0;
      }
    });

    if (!currentWeek.every((day) => day === null)) {
      weeks.push(currentWeek);
    }
    return {
      name: monthNames[monthData.month - 1],
      weeks,
    };
  });

  return { year, months };
};

export const generateWeekdaysShort = (
  locale: string = "en-gb",
  charCount: number = 1,
) => {
  const acceptedLanguage = locale === "sv-se" ? sv : enGB;
  const start = startOfWeek(new Date(), { locale: acceptedLanguage });
  const weekDays = eachDayOfInterval({ start, end: addDays(start, 6) });

  return weekDays.map((day) => {
    const formattedDay = format(day, "eee", { locale: acceptedLanguage });
    return (
      formattedDay.charAt(0).toUpperCase() + formattedDay.slice(1, charCount)
    );
  });
};

export const generateMonthNames = (year: number, locale: string = "en-gb") => {
  const acceptedLanguage = locale === "sv-se" ? sv : enGB;

  return Array.from({ length: 12 }, (_, i) => {
    const month = format(startOfMonth(new Date(year, i)), "MMM", {
      locale: acceptedLanguage,
    }).replace(/\.$/, "");
    return (
      month.slice(0, 3).charAt(0).toUpperCase() +
      month.slice(1, 3).toLowerCase()
    );
  });
};

export const formatMonthName = (date: string, locale: string = "en-gb") => {
  const acceptedLanguage = locale === "sv-se" ? sv : enGB;

  const parsedDate = parseISO(date);
  const formattedDate = format(parsedDate, "d MMM", {
    locale: acceptedLanguage,
  });
  const [day, month] = formattedDate.split(" ");

  const formattedMonth =
    month.charAt(0).toUpperCase() + month.slice(1, 3).toLowerCase();

  return `${day} ${formattedMonth}`;
};

export const formatOccupancyAsPercentage = (occupancy: string) => {
  const number = parseFloat(occupancy);
  if (isNaN(number)) {
    return "";
  }
  const percentage = number * 100;
  return percentage.toFixed(0).toString();
};

/** https://openweathermap.org/api
 * Days/cnt not working on freemium version
 * Position is set to gothenburg for now
 */
export const fetchWeatherForecast = async () => {
  const API_KEY = import.meta.env.VITE_OPEN_WEATHER_API_KEY;
  const units = "metric";
  const position = {
    latitude: "57.708870",
    longitude: "11.974560",
  };

  const days = 16;

  const url = `http://api.openweathermap.org/data/2.5/forecast?lat=${position.latitude}&lon=${position.longitude}&cnt=${days}&units=${units}&appid=${API_KEY}`;

  try {
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Failed to fetch weather data:", error);
  }
};

export const processWeatherData = (
  weatherData: any,
): Array<{ date: string; weather: Weather }> | null => {
  if (!weatherData) {
    return null;
  }

  const weatherByDate: Record<string, Weather> = {};

  weatherData.list.forEach((day: any) => {
    const date = day.dt_txt.split(" ")[0];
    const tempMin = Math.round(day.main.temp_min);
    const tempMax = Math.round(day.main.temp_max);
    const weatherCondition = day.weather[0].main;
    const weatherIcon = day.weather[0].icon;

    if (!weatherByDate[date]) {
      weatherByDate[date] = {
        tempMin,
        tempMax,
        weatherCondition,
        weatherIcon,
      };
    } else {
      weatherByDate[date].tempMin = Math.min(
        weatherByDate[date].tempMin,
        tempMin,
      );
      weatherByDate[date].tempMax = Math.max(
        weatherByDate[date].tempMax,
        tempMax,
      );
    }
  });
  const weatherDays = Object.keys(weatherByDate).map((date) => ({
    date,
    weather: weatherByDate[date],
  }));

  return weatherDays;
};

export const getWeatherIcon = (iconCode: string) => {
  const iconMap = {
    "01d": Sun,
    "01n": Moon,
    "02d": CloudSun,
    "02n": CloudMoon,
    "03d": Cloud,
    "03n": Cloud,
    "04d": Cloud,
    "04n": Cloud,
    "09d": CloudRain,
    "09n": CloudRain,
    "10d": CloudRain,
    "10n": CloudRain,
    "11d": CloudLightning,
    "11n": CloudLightning,
    "13d": Snowflake,
    "13n": Snowflake,
    "50d": Wind,
    "50n": Wind,
  };

  const IconComponent = iconMap[iconCode as keyof typeof iconMap] || Cloud;

  return <IconComponent size={12} />;
};
