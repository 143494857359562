import { createMutation } from "react-query-kit";
import {
  SimpleSearchRequest,
  SimpleSearchResponse,
} from "../../../../api-contracts/search";
import { api } from "./api";
import { currentSite } from "@context/site-context";

export const SIMPLE_SEARCH_QUERY_KEY = "search/simple";

// POST /api/:siteId/search/simple
export const usePostSearch = createMutation<
  SimpleSearchResponse,
  SimpleSearchRequest
>({
  mutationFn: async (searchPayload) =>
    api.post(
      `/api/${currentSite?.id}/${SIMPLE_SEARCH_QUERY_KEY}`,
      searchPayload,
    ),
});
