import { TabItem, TabNavbar } from "@components/tab-navbar.tsx";
import { ROUTES } from "@shared/types/navigation.ts";
import { useEffect, useState } from "react";
import { useProfileContext } from "@context/profile-context.tsx";
import { capitalizeFirstLetter } from "@utils/helpers.ts";

export const ClusterTabNavBar = () => {
  const { module, activeCluster } = useProfileContext();
  const [tabItems, setTabItems] = useState<TabItem[]>(
    activeCluster.map((cl) => ({
      value: cl.categoryTypes.map((ct) => ct), // Mapping over categoryTypes
      label: cl.name,
      disabled: false,
      to: `/${module}/${ROUTES.CATEGORY_GRID}/${cl.name}`,
    })),
  );
  const [value, setValue] = useState(tabItems[0].value[0]);

  useEffect(() => {
    if (activeCluster.length > 0) {
      setTabItems(
        activeCluster.map((cl) => {
          return {
            value: cl.categoryTypes.map((ct) => ct),
            label: cl.name,
            disabled: false,
            to: `/${module}/${ROUTES.CATEGORY_GRID}/${cl.name}`,
          };
        }),
      );
    }
  }, [activeCluster]);

  useEffect(() => {
    setValue(tabItems[0].value[0]);
  }, [tabItems]);

  return <TabNavbar value={value} onValueChange={setValue} items={tabItems} />;
};
