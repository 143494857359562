import { AlertDialog } from "@primitives/alert-dialog.tsx";
import React, { FC } from "react";
import { queryClient } from "../../../../query-client.ts";
import {
  useDeleteCluster,
  useGetCategoryClusters,
} from "@api/category-clusters.ts";
import { toast } from "@hooks/use-toast.ts";
import { useTranslation } from "react-i18next";
import { Cluster } from "@pages/settings/category-cluster/category-cluster-context.tsx";

interface Props {
  clusters: Cluster[];
  open: boolean;
  onOpenChange: (open: boolean) => void;
}
export const DeleteClusterDialog: FC<Props> = ({
  clusters,
  open,
  onOpenChange,
}) => {
  const { mutateAsync: deleteCluster } = useDeleteCluster();
  const { t } = useTranslation();

  const deleteClusters = async (cluster: Cluster) => {
    try {
      await deleteCluster({ id: cluster.id ?? "" }).then();
      await queryClient.invalidateQueries({
        queryKey: useGetCategoryClusters.getKey(),
      });
    } catch (error) {
      toast({
        title: t("request-failed-with"),
        description: t(
          decodeURIComponent(
            (error instanceof Error && error.message) || t("no-message"),
          ),
        ),
        variant: "destructive",
      });
    }
  };
  return (
    <AlertDialog.Root open={open} onOpenChange={onOpenChange}>
      <AlertDialog.Content className="z-[2001]">
        <AlertDialog.Header>
          <AlertDialog.Title>{t("reset-cluster")}</AlertDialog.Title>
          <AlertDialog.Description>
            <p className={"text-bol text-[16px] font-normal"}>
              {t("reset-cluster-desc")}
            </p>
          </AlertDialog.Description>
        </AlertDialog.Header>
        <AlertDialog.Footer className="relative">
          <AlertDialog.Cancel>{t("cancel")}</AlertDialog.Cancel>

          <AlertDialog.Action
            onClick={() => {
              clusters.map((cluster) => {
                deleteClusters(cluster).then();
              });
            }}
          >
            {t("reset")}
          </AlertDialog.Action>
        </AlertDialog.Footer>
      </AlertDialog.Content>
    </AlertDialog.Root>
  );
};
