import { useTranslation } from "react-i18next";
import { Button } from "@primitives/button";
import { GetBooking } from "../../../../../../../api-contracts/reservations";
import {
  Sheet,
  SheetContent,
  SheetHeader,
  SheetTitle,
} from "@primitives/sheet";
import { Label } from "@primitives/label";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@primitives/select";
import { useGetPriceRules } from "@api/price-rules";
import { useEffect, useMemo, useState } from "react";
import {
  format,
  isSameDay,
  subDays,
  max,
  isBefore,
  isAfter,
  eachDayOfInterval,
  addDays,
  addHours,
  addMinutes,
} from "date-fns";
import { useGetCategories } from "@api/categories";
import { useGetAllocableBookables } from "@api/bookables";
import { TimeSlot } from "../../../../../../../api-contracts/reservations/timeslots";
import { RadioGroup, RadioGroupItem } from "@primitives/radio-group";
import DatePicker from "@primitives/date-picker";
import { uniqueId } from "lodash";
import { useGetPricing } from "@api/pricing";
import {
  dateWithTimeString,
  dateWithTimeStringISO,
  addHoursAndMinutesToDateISO,
} from "@shared/utils/helpers";
import { priceRuleOverrideKeyToGuestCounts } from "@shared/utils/price-rules";

interface Props {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  onProceed: (updatedBooking: GetBooking, newBooking?: GetBooking) => void;
  booking: GetBooking;
}

export const BookingChangeAssetDrawer = ({
  open,
  onOpenChange,
  onProceed,
  booking,
}: Props) => {
  const { t, i18n } = useTranslation();
  const [changedBooking, setChangedBooking] = useState<GetBooking>(booking);
  const [newBooking, setNewBooking] = useState<GetBooking>(booking);
  const [priceOption, setPriceOption] = useState<
    | "keep-price"
    | "original-price"
    | "price-according-to-new-category"
    | "enter-new-price-code"
  >("keep-price");

  useEffect(() => {
    const splitDate = max([new Date(), booking.start]);
    setChangedBooking({
      ...booking,
      end: dateWithTimeStringISO(splitDate, format(booking.end, "HH:mm")), // TODO timezone might not be same as site
      slots: booking.slots.filter((s) => isBefore(s.start, splitDate)),
    });
    setNewBooking({
      ...booking,
      state: {
        value: "planned",
      },
      id: "new" + uniqueId(),
      paid: 0,
      start: dateWithTimeStringISO(
        splitDate,
        booking.category.config!.startTimes[0],
      ),
      end: booking.end,
      slots: booking.slots.filter(
        (s) => isAfter(s.start, splitDate) || isSameDay(s.start, splitDate),
      ),
      bookable: null,
    });
    setPriceOption("keep-price");
  }, [booking, open]);

  const {
    data: priceRules,
    isLoading: priceRulesIsLoading,
    isRefetching: priceRulesIsRefetching,
  } = useGetPriceRules({
    variables: {
      categoryTypes: [newBooking.category.type],
    },
    enabled: !!newBooking.category.type,
  });

  const {
    data: categories,
    isLoading: categoriesIsLoading,
    isRefetching: categoriesIsRefetching,
  } = useGetCategories({
    variables: {
      categoryTypes: ["room", "area", "bed", "dormitory"],
    },
  });

  const {
    data: bookables,
    isLoading: bookablesIsLoading,
    isRefetching: bookablesIsRefetching,
  } = useGetAllocableBookables({
    variables: {
      categoryId: newBooking.category.id,
      startDate: format(newBooking.start, "yyyy-MM-dd"),
      endDate: format(newBooking.end, "yyyy-MM-dd"),
    },
  });

  const {
    data: pricing,
    isLoading: pricingIsLoading,
    isRefetching: pricingIsRefetching,
  } = useGetPricing({
    variables: {
      id: newBooking.category.id,
      slots: newBooking.slots.map((s) => ({
        startDate: format(s.start, "yyyy-MM-dd"),
        startTime:
          categories?.find((c) => newBooking.category.id === c.id)?.config!
            .startTimes[0] || "14.00",
      })),
      adults: newBooking.guestCount.adults,
      teenagers: newBooking.guestCount.teenagers,
      children: newBooking.guestCount.children,
      infants: newBooking.guestCount.infants,
    },
    enabled: !!newBooking.category.id && categories && open,
  });

  useEffect(() => {
    handlePriceOptionChange(priceOption);
  }, [pricing, priceRules]);

  const numGuests = useMemo(() => {
    return {
      adults: booking.guests.filter((g) => g.ageType === "adult").length,
      teenagers: booking.guests.filter((g) => g.ageType === "teenager").length,
      children: booking.guests.filter((g) => g.ageType === "child").length,
      infants: booking.guests.filter((g) => g.ageType === "infant").length,
    };
  }, [booking]);

  const total = useMemo(() => {
    return (
      changedBooking.slots.reduce((acc, slot) => {
        return acc + slot.price;
      }, 0) +
      newBooking.slots.reduce((acc, slot) => {
        return acc + slot.price;
      }, 0)
    );
  }, [changedBooking, newBooking]);

  const updateState = (
    rules: {
      date: string | Date;
      priceRuleId: string | null;
      priceOverrideAmount: number | null;
      priceAdjustmentPercent: number | null;
    }[],
    categoryId: string,
    bookableId: string | null,
    from: Date | string,
  ) => {
    const category = categories?.find((c) => c.id === categoryId);
    if (!category) {
      setNewBooking((newBooking) => ({ ...newBooking }));
      return;
    }

    setChangedBooking({
      ...booking,
      end: dateWithTimeStringISO(from, format(booking.end, "HH:mm")), // TODO timezone might not be same as site
      slots: booking.slots.filter((s) => isBefore(s.start, from)),
    });

    const start = from;
    const end = booking.end;

    const bookable =
      bookables?.bookables.find((b) => b.id === bookableId) ||
      (categoryId === booking.category.id && bookableId === booking.bookable?.id
        ? booking.bookable
        : undefined);

    const dates = eachDayOfInterval({
      start: start,
      end: subDays(end, 1),
    });
    const newSlots: TimeSlot[] = dates.map((date) => {
      const pricer = pricing?.slots.find((s) => isSameDay(date, s.startDate));
      const slot = booking.slots.find((s) => isSameDay(s.start, date));
      const rule = rules.find((r) => isSameDay(date, r.date));
      let priceRuleId = rule?.priceRuleId || null;
      const priceOverrideAmount = rule?.priceOverrideAmount || null;
      const priceAdjustmentPercent = rule?.priceAdjustmentPercent || null;

      let pr = priceRules?.rules.find(
        (r) =>
          (priceRuleId && r.id === priceRuleId) ||
          (!priceRuleId && pricer?.ruleId && r.id === pricer.ruleId),
      );
      if (priceRuleId && !pr) {
        pr = priceRules?.rules.find(
          (r) => pricer?.ruleId && r.id === pricer.ruleId,
        );
        priceRuleId = pricer?.ruleId || null;
      }
      const priceRule = pr?.entries.find((e) => e.categoryId === categoryId);
      const priceRuleOverride = priceRule?.overrides.find((o) => {
        const guestCounts = priceRuleOverrideKeyToGuestCounts(o.overrideKey);
        return (
          guestCounts.adults === numGuests.adults &&
          guestCounts.children === numGuests.children &&
          guestCounts.teenagers === numGuests.teenagers &&
          guestCounts.infants === numGuests.infants
        );
      });

      let fullPrice = priceRuleOverride?.price || priceRule?.price || 0;

      let price = priceOverrideAmount || fullPrice;

      if (priceAdjustmentPercent) {
        price = price * (1 - 0.01 * priceAdjustmentPercent);
      }

      return {
        id: slot?.id || "new" + uniqueId(),
        price: price,
        start: dateWithTimeStringISO(date, category.config!.startTimes[0]),
        end: addHoursAndMinutesToDateISO(
          dateWithTimeString(date, category.config!.startTimes[0]),
          category.config!.timeslotHours,
          category.config!.timeslotMinutes,
        ),
        priceRuleId: priceRuleId || pricer?.ruleId || null,
        priceRuleName: pr?.name || "",
        fullPrice: fullPrice,
        priceOverrideAmount: priceOverrideAmount,
        priceAdjustmentPercent: priceAdjustmentPercent,
      };
    });

    setNewBooking((newBooking) => ({
      ...newBooking,
      start: dateWithTimeStringISO(start, category.config!.startTimes[0]),
      end: newSlots[newSlots.length - 1].end,
      slots: newSlots,
      category: category,
      bookable: bookable ? { ...bookable, lockedToBookable: false } : null,
    }));
  };

  const handleFromChange = (date?: Date) => {
    if (date) {
      updateState(
        newBooking.slots.map((s) => ({
          date: s.start,
          priceRuleId: s.priceRuleId,
          priceOverrideAmount: s.priceOverrideAmount,
          priceAdjustmentPercent: s.priceAdjustmentPercent,
        })),
        newBooking.category.id,
        newBooking.bookable?.id || null,
        date,
      );
    }
  };

  const handlePriceOptionChange = (
    option:
      | "keep-price"
      | "original-price"
      | "price-according-to-new-category"
      | "enter-new-price-code",
  ) => {
    setPriceOption(option);

    switch (option) {
      case "keep-price":
        updateState(
          newBooking.slots.map((s) => {
            const oldSlot = booking.slots.find((sOld) => sOld.id === s.id);

            return {
              date: s.start,
              priceRuleId: oldSlot?.priceRuleId || null,
              priceOverrideAmount:
                oldSlot?.priceOverrideAmount || oldSlot?.price || null,
              priceAdjustmentPercent: null,
            };
          }),
          newBooking.category.id,
          newBooking.bookable?.id || null,
          newBooking.start,
        );
        break;
      case "original-price":
        updateState(
          newBooking.slots.map((s) => {
            const oldSlot = booking.slots.find((sOld) => sOld.id === s.id);

            return {
              date: s.start,
              priceRuleId: oldSlot?.priceRuleId || null,
              priceOverrideAmount: oldSlot?.priceOverrideAmount || null,
              priceAdjustmentPercent: oldSlot?.priceAdjustmentPercent || null,
            };
          }),
          newBooking.category.id,
          newBooking.bookable?.id || null,
          newBooking.start,
        );
        break;
      case "price-according-to-new-category":
      case "enter-new-price-code":
        updateState(
          newBooking.slots.map((s) => ({
            date: s.start,
            priceRuleId: s.priceRuleId,
            priceOverrideAmount: null,
            priceAdjustmentPercent: null,
          })),
          newBooking.category.id,
          newBooking.bookable?.id || null,
          newBooking.start,
        );
        break;
    }
  };

  return (
    <>
      <Sheet open={open} onOpenChange={onOpenChange}>
        <SheetContent
          side="left"
          className="flex w-[400px] flex-col overflow-y-auto sm:w-[600px]"
        >
          <SheetHeader>
            <SheetTitle className=" flex items-center justify-between text-primary-text">
              {t("change-unit")}
            </SheetTitle>
          </SheetHeader>

          <div className=" flex-1 overflow-y-auto text-primary-text">
            <div className="mt-4 bg-secondary-card-backplate p-4">
              <h3 className=" mb-4 text-lg font-bold">{t("from-date")}</h3>
              <div>
                <DatePicker
                  value={new Date(newBooking.start)}
                  onValueChange={handleFromChange}
                  clearable={false}
                  calendarProps={{
                    disabled: {
                      before: max([new Date(), changedBooking.start]),
                      after: new Date(subDays(newBooking.end, 1)),
                    },
                  }}
                />
              </div>
            </div>

            <div className="mt-4 bg-secondary-card-backplate p-4">
              <h3 className=" mb-4 text-lg font-bold">{t("unit")}</h3>
              <div>
                <Label htmlFor="booking-change-category">{t("category")}</Label>
                <Select
                  key={newBooking.category.id}
                  value={newBooking.category.id}
                  onValueChange={(value) => {
                    updateState(
                      newBooking.slots.map((s) => ({
                        date: s.start,
                        priceRuleId: s.priceRuleId,
                        priceAdjustmentPercent: s.priceAdjustmentPercent,
                        priceOverrideAmount: s.priceOverrideAmount,
                      })),
                      value,
                      null,
                      newBooking.start,
                    );
                  }}
                >
                  <SelectTrigger
                    className=" w-full"
                    id="booking-change-category"
                  >
                    <SelectValue />
                  </SelectTrigger>
                  <SelectContent>
                    {categories?.map((c) => (
                      <SelectItem key={c.id} value={c.id} className=" text-xs">
                        {t(c.name)}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
                {(newBooking.guestCount.adults +
                  newBooking.guestCount.children +
                  newBooking.guestCount.teenagers +
                  newBooking.guestCount.infants >
                  newBooking.category.capacity.total.max ||
                  newBooking.guestCount.adults >
                    newBooking.category.capacity.adults.max ||
                  newBooking.guestCount.adults <
                    newBooking.category.capacity.adults.min ||
                  newBooking.guestCount.teenagers >
                    newBooking.category.capacity.teenagers.max ||
                  newBooking.guestCount.children >
                    newBooking.category.capacity.children.max ||
                  newBooking.guestCount.infants >
                    newBooking.category.capacity.infants.max) && (
                  <p className=" mt-2 text-xs font-normal text-status-error">
                    {t(
                      "the-{{guests}}-guests-of-the-booking-do-not-fit-in-{{category}}",
                      {
                        category: newBooking.category.name,
                        guests: newBooking.guests.length,
                      },
                    )}
                    .
                  </p>
                )}
              </div>
              <div className="mt-2">
                <Label htmlFor="booking-change-asset">{t("unit")}</Label>
                <Select
                  key={newBooking.bookable?.id}
                  value={newBooking.bookable?.id}
                  onValueChange={(value) => {
                    updateState(
                      newBooking.slots.map((s) => ({
                        date: s.start,
                        priceRuleId: s.priceRuleId,
                        priceAdjustmentPercent: s.priceAdjustmentPercent,
                        priceOverrideAmount: s.priceOverrideAmount,
                      })),
                      newBooking.category.id,
                      value,
                      newBooking.start,
                    );
                  }}
                >
                  <SelectTrigger className=" w-full" id="booking-change-asset">
                    <SelectValue />
                  </SelectTrigger>
                  <SelectContent>
                    {bookables?.bookables?.map((b) => (
                      <SelectItem key={b.id} value={b.id} className=" text-xs">
                        {t(b.name)}
                      </SelectItem>
                    ))}
                    {bookables?.bookables.length === 0 && (
                      <p className="p-2 text-center text-sm font-normal text-secondary-text">
                        {t("no-units-available")}
                      </p>
                    )}
                  </SelectContent>
                </Select>
              </div>
            </div>

            <div className="mt-4 bg-secondary-card-backplate p-4">
              <h3 className=" mb-4 text-lg font-bold">{t("price")}</h3>

              <RadioGroup
                className="mb-4"
                value={priceOption}
                onValueChange={(v) =>
                  handlePriceOptionChange(
                    v as
                      | "keep-price"
                      | "original-price"
                      | "price-according-to-new-category"
                      | "enter-new-price-code",
                  )
                }
              >
                <div className="mt-2 flex items-center space-x-2">
                  <RadioGroupItem value="keep-price" id="keep-price" />
                  <Label htmlFor="keep-price">{t("keep-original-price")}</Label>
                </div>
                <div className="mt-2 flex items-center space-x-2">
                  <RadioGroupItem value="original-price" id="original-price" />
                  <Label htmlFor="original-price">
                    {t("keep-original-price-adjustment")}
                  </Label>
                </div>
                <div className="mt-2 flex items-center space-x-2">
                  <RadioGroupItem
                    value="price-according-to-new-category"
                    id="price-according-to-new-category"
                  />
                  <Label htmlFor="price-according-to-new-category">
                    {t("price-according-to-new-category")}
                  </Label>
                </div>
                <div className="mt-2 flex items-center space-x-2">
                  <RadioGroupItem
                    value="enter-new-price-code"
                    id="enter-new-price-code"
                  />
                  <Label htmlFor="enter-new-price-code">
                    {t("enter-new-price-code")}
                  </Label>
                </div>
              </RadioGroup>

              {priceOption === "enter-new-price-code" && (
                <>
                  <Label htmlFor="booking-change-price-code">
                    {t("change-price-code-for-all-nights")}
                  </Label>
                  <Select
                    value={
                      newBooking.slots.every(
                        (s) =>
                          s.priceRuleId === newBooking.slots[0].priceRuleId,
                      )
                        ? newBooking.slots[0]?.priceRuleId || undefined
                        : undefined
                    }
                    onValueChange={(value) => {
                      updateState(
                        newBooking.slots.map((s) => ({
                          date: s.start,
                          priceRuleId: value || null,
                          priceAdjustmentPercent: null,
                          priceOverrideAmount: null,
                        })),
                        newBooking.category.id,
                        newBooking.bookable?.id || null,
                        newBooking.start,
                      );
                    }}
                  >
                    <SelectTrigger
                      className=" w-full"
                      id="booking-change-price-code"
                    >
                      <SelectValue />
                    </SelectTrigger>
                    <SelectContent>
                      {priceRules?.rules.map((r) => (
                        <SelectItem
                          key={r.id}
                          value={r.id}
                          className=" text-xs"
                        >
                          {t(r.name)}
                        </SelectItem>
                      ))}
                      {priceRules?.rules.length === 0 && (
                        <p className="p-2 text-center text-sm font-normal text-secondary-text">
                          {t("no-price-rules-available")}
                        </p>
                      )}
                    </SelectContent>
                  </Select>

                  <div className="mt-2">
                    {newBooking.slots.map((slot, i) => (
                      <div
                        key={slot.id}
                        className=" flex items-center justify-between border-t border-border-color py-2"
                      >
                        <p className=" text-sm font-bold">
                          {format(slot.start, "d MMMM")}
                        </p>
                        <div className=" w-1/2">
                          <Select
                            value={newBooking.slots[i].priceRuleId || undefined}
                            onValueChange={(value) => {
                              updateState(
                                newBooking.slots.map((s, j) => ({
                                  date: s.start,
                                  priceRuleId:
                                    j === i ? value || null : s.priceRuleId,
                                  priceAdjustmentPercent: null,
                                  priceOverrideAmount: null,
                                })),
                                newBooking.category.id,
                                newBooking.bookable?.id || null,
                                newBooking.start,
                              );
                            }}
                          >
                            <SelectTrigger className=" w-full" id="price-code">
                              <SelectValue />
                            </SelectTrigger>
                            <SelectContent>
                              {priceRules?.rules.map((r) => (
                                <SelectItem
                                  key={r.id}
                                  value={r.id}
                                  className=" text-xs"
                                >
                                  {t(r.name)}
                                </SelectItem>
                              ))}
                              {priceRules?.rules.length === 0 && (
                                <p className="p-2 text-center text-sm font-normal text-secondary-text">
                                  {t("no-price-rules-available")}
                                </p>
                              )}
                            </SelectContent>
                          </Select>
                        </div>
                      </div>
                    ))}
                  </div>
                </>
              )}
            </div>
          </div>

          <div className=" flex items-center justify-between space-x-2">
            <p className=" text-md font-bold">
              {t("total")}:{" "}
              {new Intl.NumberFormat(
                i18n.language === "sv-se" ? "sv-SE" : "en-GB",
                { maximumFractionDigits: 2 },
              ).format(total)}{" "}
              SEK
            </p>
            <div className=" flex justify-end space-x-2 ">
              <Button variant="outline" onClick={() => onOpenChange(false)}>
                {t("cancel")}
              </Button>
              <Button
                disabled={
                  !newBooking.bookable ||
                  newBooking.guestCount.adults +
                    newBooking.guestCount.children +
                    newBooking.guestCount.teenagers +
                    newBooking.guestCount.infants >
                    newBooking.category.capacity.total.max ||
                  newBooking.guestCount.adults >
                    newBooking.category.capacity.adults.max ||
                  newBooking.guestCount.adults <
                    newBooking.category.capacity.adults.min ||
                  newBooking.guestCount.teenagers >
                    newBooking.category.capacity.teenagers.max ||
                  newBooking.guestCount.children >
                    newBooking.category.capacity.children.max ||
                  newBooking.guestCount.infants >
                    newBooking.category.capacity.infants.max
                }
                onClick={() => {
                  if (changedBooking.slots.length) {
                    onProceed(changedBooking, newBooking);
                  } else {
                    onProceed({
                      ...newBooking,
                      id: changedBooking.id,
                      state: changedBooking.state,
                      paid: changedBooking.paid,
                    });
                  }
                  onOpenChange(false);
                }}
              >
                {t("apply")}
              </Button>
            </div>
          </div>
        </SheetContent>
      </Sheet>
    </>
  );
};

export default BookingChangeAssetDrawer;
