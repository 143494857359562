import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@primitives/table";
import {
  ChevronDownIcon,
  ChevronUpIcon,
  ChevronsUpDownIcon,
} from "lucide-react";
import {
  Table as TableType,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
  Column,
  SortingState,
} from "@tanstack/react-table";
import { Checkbox } from "@primitives/checkbox";
import {
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuRoot,
  DropdownMenuTrigger,
} from "@primitives/dropdown-menu";
import { Category } from "../../../../../../api-contracts/categories";
import {
  formatPrice,
  capacitySortingFn,
  stringSortingFn,
  renderHeaderWithLineBreaks,
} from "../categories-utils.tsx";
import { Loading } from "@primitives/loading";
import { useNavigate } from "react-router-dom";
import { DotsHorizontalIcon } from "@radix-ui/react-icons";
import { useCategoriesContext } from "../categories-context";
import { TableActionGroup } from "./table-action-group";
import { HighlightText } from "./highlight-text.tsx";
import { useProfileContext } from "@context/profile-context.tsx";

type TableGroup = {
  groupName: string;
  data: Category[];
  combinedCount: number;
};

const CategoryTable = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { module } = useProfileContext();

  const {
    sorting,
    setSorting,
    columnVisibility,
    setColumnVisibility,
    categories,
    isLoading,
    patchCategory,
    selectedGroupIds,
    uniqueGroups,
    searchTerm,
    setSelectedCategoryType,
  } = useCategoriesContext();

  const columnHelper = createColumnHelper<Category>();

  const hasActiveAndInactive = (data: any[]) => {
    const hasActive = data.some((item) => item.active === true);
    const hasInactive = data.some((item) => item.active === false);
    return hasActive && hasInactive;
  };

  const columnDefinitions = [
    columnHelper.accessor("name", {
      header: t("name"),
      id: "name",
      cell: ({ getValue }) => (
        <HighlightText text={getValue()} textToHighlight={searchTerm} />
      ),
    }),
    columnHelper.accessor("short", {
      header: t("short"),
      id: "short",
      cell: ({ getValue }) => (
        <HighlightText text={getValue()} textToHighlight={searchTerm} />
      ),
    }),
    columnHelper.accessor("description", {
      header: t("internal-description"),
      id: "description",
      cell: ({ getValue }) => {
        const description = getValue() || "";
        return <DescriptionCell description={description.internal || ""} />;
      },
      sortingFn: stringSortingFn,
    }),

    columnHelper.accessor("available.internal", {
      header: renderHeaderWithLineBreaks("bookable-internal"),
      id: "availableInternal",
      cell: (info) => <Checkbox disabled checked={info.getValue()} />,
      enableSorting: false,
    }),
    columnHelper.accessor("available.public", {
      header: renderHeaderWithLineBreaks("bookable-public"),
      id: "availablePublic",
      cell: (info) => <Checkbox disabled checked={info.getValue()} />,
      enableSorting: false,
    }),
    columnHelper.accessor("available.external", {
      header: renderHeaderWithLineBreaks("bookable-external"),
      id: "availableExternal",
      cell: (info) => <Checkbox disabled checked={info.getValue()} />,
      enableSorting: false,
    }),

    columnHelper.accessor("minimumRate", {
      header: t("min-rate"),
      id: "basePrice",
      cell: (info) => {
        const formattedPrice = formatPrice(info.getValue());
        const fullObject = info.row.original;
        return (
          <div className="flex w-full items-center justify-end whitespace-nowrap ">
            <div className={`${!fullObject.hasOverrides && "mr-4"} text-right`}>
              {formattedPrice}
            </div>
            {fullObject.hasOverrides && (
              <span className="ml-2 flex h-2 w-2 items-center rounded-full bg-primary-color" />
            )}
          </div>
        );
      },
    }),
    columnHelper.display({
      id: "actions",
      header: "",
      enableHiding: false,
      cell: ({ row }) => (
        <DropdownMenuRoot>
          <DropdownMenuTrigger>
            <DotsHorizontalIcon />
          </DropdownMenuTrigger>
          <DropdownMenuContent>
            <DropdownMenuItem
              onClick={(e) => {
                e.stopPropagation();
                navigate(`/${module}/categories/${row.original.id}?edit`);
              }}
            >
              {t("edit")}
            </DropdownMenuItem>
            <DropdownMenuItem
              onClick={(e) => {
                e.stopPropagation();
                navigate(`/${module}/categories/${row.original.id}/duplicate`);
              }}
            >
              {t("duplicate")}
            </DropdownMenuItem>
            <DropdownMenuItem
              onClick={(e) => {
                e.stopPropagation();
                patchCategory(row.original.id, {
                  active: !row.original.active as boolean,
                });
              }}
            >
              {row.original.active ? t("inactivate") : t("activate")}
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenuRoot>
      ),
    }),
  ];

  const showActiveColumn = hasActiveAndInactive(categories);
  const activeColumn = columnHelper.accessor("active", {
    header: t("status"),
    id: "active",
    cell: ({ getValue }) => (
      <div
        style={{ maxWidth: 100 }}
        className={`${
          getValue()
            ? "bg-green-100 text-status-success"
            : "bg-red-100 text-status-error"
        } flex h-[24px] items-center justify-center rounded-md px-2 font-bold`}
      >
        <p>{getValue() ? t("active") : t("disabled")}</p>
      </div>
    ),
  });

  if (showActiveColumn) {
    columnDefinitions.unshift(activeColumn);
  }

  const table = useReactTable({
    data: categories,
    columns: columnDefinitions,
    state: {
      sorting,
      columnVisibility,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    onColumnVisibilityChange: setColumnVisibility,
    sortingFns: {
      capacitySortingFn,
      stringSortingFn,
    },
  });
  const cols = table.getAllLeafColumns();

  const DescriptionCell = ({ description }: { description: string }) => {
    const [showMore, setShowMore] = useState<boolean>(false);
    const textRef = useRef<HTMLParagraphElement>(null);
    const [isLongText, setIsLongText] = useState<boolean>(false);

    const calculateTruncation = () => {
      const textElement = textRef.current;
      if (textElement) {
        setTimeout(() => {
          const lineHeight = parseInt(
            getComputedStyle(textElement).lineHeight,
            10,
          );

          const maxLines = 4;
          const maxHeight = lineHeight * maxLines;

          if (textElement.scrollHeight > maxHeight) {
            setIsLongText(true);
          } else {
            setIsLongText(false);
          }
        }, 0);
      }
    };

    useLayoutEffect(() => {
      calculateTruncation();
    }, [description]);

    useEffect(() => {
      const handleResize = () => {
        calculateTruncation();
      };

      window.addEventListener("resize", handleResize);
      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }, [description]);

    const onShowMoreClick = (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      setShowMore((prev) => !prev);
    };

    return (
      <div className="">
        <p ref={textRef} className={` ${showMore ? "" : "line-clamp-4"}`}>
          <HighlightText text={description} textToHighlight={searchTerm} />
        </p>
        {isLongText && (
          <div className="flex justify-end">
            <button
              onClick={(e) => onShowMoreClick(e)}
              className="text-primary-color underline underline-offset-2"
            >
              {showMore ? `- ${t("less")}` : `+ ${t("more")}`}
            </button>
          </div>
        )}
      </div>
    );
  };

  const getSortIcon = (
    column: Column<Category>,
    sortingState: SortingState,
  ) => {
    const sort = sortingState.find((s) => s.id === column.id);
    if (!sort) return <ChevronsUpDownIcon size={13} />;
    return sort.desc ? (
      <ChevronDownIcon size={13} />
    ) : (
      <ChevronUpIcon size={13} />
    );
  };

  const onRowItemClick = (row: any) => {
    setSelectedCategoryType(row.original.type);
    navigate(`/${module}/categories/${row.original.id}`, {
      state: { selectedCategoryType: row.original.type },
    });
  };
  const CategoryTable = ({ table }: { table: TableType<Category> }) => {
    return (
      <>
        <Table className="rounded-md bg-primary-card-backplate">
          <TableHeader>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  const columnHeaderClassName = header.column.getCanSort()
                    ? `cursor-pointer select-none flex items-center text-center`
                    : "text-center whitespace-pre-line";

                  const isDescriptionColumn = header.id === "description";
                  const isBasePrice = header.id === "basePrice";

                  return (
                    <TableHead
                      style={{
                        width: isDescriptionColumn ? 300 : "auto",
                      }}
                      key={header.id}
                      colSpan={header.colSpan}
                    >
                      {header.isPlaceholder ? null : (
                        <div
                          {...{
                            className: !isBasePrice
                              ? columnHeaderClassName
                              : " flex items-center justify-center",
                            onClick: header.column.getToggleSortingHandler(),
                          }}
                        >
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext(),
                          )}
                          {header.column.getCanSort() && (
                            <div className="ml-1">
                              {getSortIcon(
                                header.column,
                                table.getState().sorting,
                              )}
                            </div>
                          )}
                        </div>
                      )}
                    </TableHead>
                  );
                })}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {table.getRowModel().rows?.length ? (
              table.getRowModel().rows.map((row) => (
                <TableRow
                  onClick={() => onRowItemClick(row)}
                  key={row.id}
                  className=" cursor-pointer"
                >
                  {row.getVisibleCells().map((cell) => {
                    const isActionButton = cell.id.includes("actions");
                    const isConnectedChannels = cell.id.includes("available");
                    const isBasePrice = cell.id.includes("basePrice");

                    const connectedChannelsAndActionButtonStyle =
                      isConnectedChannels || isActionButton
                        ? `text-center`
                        : "";

                    const basePriceCellStyle = "text-right flex justify-center";

                    const cellStyles = `${connectedChannelsAndActionButtonStyle}`;

                    return (
                      <TableCell key={cell.id} className={cellStyles}>
                        <div className={isBasePrice ? basePriceCellStyle : ""}>
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext(),
                          )}
                        </div>
                      </TableCell>
                    );
                  })}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell
                  colSpan={table.getAllLeafColumns().length}
                  className="h-24 text-center"
                >
                  {t("no-results")}.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </>
    );
  };
  const groupedDataMap = new Map();

  if (selectedGroupIds.length > 0) {
    categories.forEach((category) => {
      const categoryGroups = category.groups.filter((group) =>
        selectedGroupIds.includes(group.id),
      );

      if (categoryGroups.length > 0) {
        categoryGroups.forEach((group) => {
          const groupName = uniqueGroups.find(
            (uniqueGroup) => uniqueGroup.id === group.id,
          )?.name;
          if (!groupedDataMap.has(groupName)) {
            groupedDataMap.set(groupName, {
              groupName,
              data: [],
              combinedCount: 1,
            });
          }
          groupedDataMap.get(groupName).data.push(category);
        });

        if (categoryGroups.length > 1) {
          const combinedGroupName = categoryGroups
            .map(
              (group) =>
                uniqueGroups.find((uniqueGroup) => uniqueGroup.id === group.id)
                  ?.name,
            )
            .sort()
            .join(", ");

          if (!groupedDataMap.has(combinedGroupName)) {
            groupedDataMap.set(combinedGroupName, {
              groupName: combinedGroupName,
              data: [],
              combinedCount: categoryGroups.length,
            });
          }
          groupedDataMap.get(combinedGroupName).data.push(category);
        }
      }
    });
  }

  const groupedCategories: TableGroup[] = Array.from(groupedDataMap.values());

  const GroupCategoryTable = ({ groupedData }: { groupedData: TableGroup }) => {
    const table = useReactTable({
      data: groupedData.data,
      columns: columnDefinitions,
      state: {
        sorting,
        columnVisibility,
      },
      onSortingChange: setSorting,
      getCoreRowModel: getCoreRowModel(),
      getSortedRowModel: getSortedRowModel(),
      onColumnVisibilityChange: setColumnVisibility,
      sortingFns: {
        capacitySortingFn,
        stringSortingFn,
      },
    });

    return <CategoryTable table={table} />;
  };

  const GroupedCategoryTable = ({
    groupedData,
  }: {
    groupedData: TableGroup[];
  }) => {
    return (
      <>
        {groupedData
          .sort((a, b) => b.combinedCount - a.combinedCount)
          .map((group, index) => (
            <div
              key={index}
              className="mb-4 border border-highlighted-backplate"
            >
              <div className="flex h-[40px] w-full items-center justify-between bg-secondary-card-backplate px-4 text-sm ">
                <h2 className="font-extrabold">
                  {t("cluster")}: {group.groupName}
                </h2>
                <span className="text-secondary-text">
                  {t("search-result")}: {group.data.length}
                </span>
              </div>
              <GroupCategoryTable groupedData={group} />
            </div>
          ))}
      </>
    );
  };

  return (
    <>
      {!isLoading ? (
        <div className="flex flex-col">
          <div className="flex flex-row items-center justify-between py-4">
            <TableActionGroup columns={cols} />
          </div>
          {selectedGroupIds.length > 0 ? (
            <GroupedCategoryTable groupedData={groupedCategories} />
          ) : (
            <CategoryTable table={table} />
          )}
        </div>
      ) : (
        <Loading />
      )}
    </>
  );
};

export default CategoryTable;
