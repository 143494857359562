import { HighlightText } from "@pages/settings/categories/components/highlight-text";
import { History, X } from "lucide-react";
import { RecentItem, saveRecentSearch } from "./search-utils";
import { useNavigate } from "react-router-dom";
import { useProfileContext } from "@context/profile-context";
import { ROUTES } from "@shared/types/navigation";

export const RecentSearchTerm = ({
  item,
  searchTerm,
  onDelete,
}: {
  item: RecentItem;
  searchTerm: string;
  onDelete: (item: RecentItem) => void;
}) => {
  if (item.type !== "searchTerm") return;

  const { module } = useProfileContext();
  const navigate = useNavigate();

  const onClick = () => {
    saveRecentSearch({
      type: "searchTerm",
      searchTerm: item.searchTerm,
    });
    localStorage.setItem("lastSearchTerm", item.searchTerm);
    window.dispatchEvent(new Event("storage"));
    navigate(`${module}/${ROUTES.SIMPLE_SEARCH}`);
  };

  return (
    <button
      onClick={onClick}
      className="group flex w-full items-center justify-between overflow-hidden whitespace-nowrap px-2 py-2 hover:bg-hover"
    >
      <div className="flex items-center">
        <div className="mr-2 rounded-lg bg-secondary-card-backplate p-1 text-primary-icon-color">
          <History strokeWidth={0.5} size={16} />
        </div>
        <span className="mr-2">
          <HighlightText text={item.searchTerm} textToHighlight={searchTerm} />
        </span>
      </div>
      <button
        className="mx-2 hidden items-center justify-end group-hover:flex"
        onClick={(event) => {
          event.stopPropagation();
          onDelete(item);
        }}
      >
        <X size={15} />
      </button>
    </button>
  );
};
