import { createMutation, createQuery } from "react-query-kit";
import { api } from "./api";
import { queryClient } from "../../query-client";
import {
  GetReservationStatusesResponse,
  PatchReservationStatusesRequest,
  PatchReservationStatusesResponse,
} from "../../../../api-contracts/reservations";
import { currentSite } from "@context/site-context";

const RESERVATION_STATUS_KEY = "reservation/status";

export const useGetReservationStatuses = createQuery<
  GetReservationStatusesResponse,
  {}
>({
  primaryKey: RESERVATION_STATUS_KEY,
  queryFn: async () => {
    return api.get(`/api/${currentSite?.id}/${RESERVATION_STATUS_KEY}`);
  },
});

export const usePatchReservationStatuses = createMutation<
  PatchReservationStatusesResponse,
  PatchReservationStatusesRequest
>({
  mutationFn: async (args) => {
    return api.patch(`/api/${currentSite?.id}/${RESERVATION_STATUS_KEY}`, args);
  },
  onSuccess: () =>
    queryClient.invalidateQueries({
      queryKey: [RESERVATION_STATUS_KEY],
    }),
});
