import { format } from "date-fns";
import { SimpleSearchResult } from "../../../../../../api-contracts/search";
import { HighlightText } from "@pages/settings/categories/components/highlight-text";
import { CalendarClock, X } from "lucide-react";
import { useTranslation } from "react-i18next";
import { RecentItem, saveRecentSearch } from "./search-utils";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "@shared/types/navigation";
import { useProfileContext } from "@context/profile-context";

export const BookingResults = ({
  bookings,
  searchTerm,
  isRecentResult = false,
  onDelete,
}: {
  bookings: SimpleSearchResult[];
  searchTerm: string;
  isRecentResult?: boolean;
  onDelete?: (item: RecentItem) => void;
}) => {
  const { t } = useTranslation();
  return (
    <ul>
      {bookings.length > 0 ? (
        bookings.map((item, index) => (
          <li key={index} className="group cursor-pointer py-2 hover:bg-hover">
            <BookingHighlightedBreadcrumb
              booking={item}
              searchTerm={searchTerm}
              isRecentResult={isRecentResult}
              onDelete={onDelete}
            />
          </li>
        ))
      ) : (
        <span className="px-4 text-xs italic">{t("no-results")}</span>
      )}
    </ul>
  );
};

const BookingHighlightedBreadcrumb = ({
  booking,
  searchTerm,
  isRecentResult,
  onDelete,
}: {
  booking: SimpleSearchResult;
  searchTerm: string;
  isRecentResult: boolean;
  onDelete?: (item: RecentItem) => void;
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { module } = useProfileContext();
  const { reservationId, matchedEntry, matchedType, start, end } = booking;

  const formattedStart = format(new Date(start), "dd.MM.yy");
  const formattedEnd = end ? format(new Date(end), "dd.MM.yy") : "";

  const entryParts = matchedEntry.split(" ");
  const searchParts = searchTerm.split(" ");

  const onClick = () => {
    saveRecentSearch({
      type: "reservation",
      reservationId,
      matchedEntry,
      matchedType,
      start,
      end,
    });

    navigate(`/${module}/${ROUTES.RESERVATIONS}/${reservationId}`);
  };

  const handleDelete = () => {
    onDelete &&
      onDelete({
        type: "reservation",
        reservationId,
        matchedEntry,
        matchedType,
        start,
        end,
      });
  };

  return (
    <button
      onClick={onClick}
      className="group flex w-full items-center justify-between overflow-hidden whitespace-nowrap px-2"
    >
      <div className="flex items-center">
        <div className="mr-2 rounded-lg bg-secondary-card-backplate p-1 text-primary-icon-color">
          <CalendarClock
            className="text-primary-icon-color"
            strokeWidth={0.5}
            size={16}
          />
        </div>
        <span className="mr-2 text-primary-text">{reservationId}</span>
        <span className="mr-2 text-secondary-text">
          {matchedType === "holder" ? t("booker") : t(matchedType)}
        </span>
        {entryParts.map((part, index) => (
          <div key={index} className="mr-1">
            <HighlightText
              text={part}
              textToHighlight={searchParts[index] || searchTerm}
              className="text-primary-text"
            />
            {index < entryParts.length - 1 && " "}
          </div>
        ))}
      </div>

      {isRecentResult ? (
        <button
          className="mx-2 hidden items-center group-hover:flex"
          onClick={(event) => {
            event.stopPropagation();
            handleDelete();
          }}
        >
          <X size={15} />
        </button>
      ) : (
        <div className="flex justify-end text-sm text-secondary-text">
          {formattedStart}
          {formattedEnd && ` - ${formattedEnd}`}
        </div>
      )}
    </button>
  );
};
