import { AlertDialog } from "@primitives/alert-dialog.tsx";
import React, { FC, useContext, useState } from "react";
import { CamprType } from "../../../../../../api-contracts/categories";
import {
  CategoryClusterContext,
  ClusterData,
} from "@pages/settings/category-cluster/category-cluster-context.tsx";
import { PatchCategoryClusterRequest } from "../../../../../../api-contracts/category-clusters";
import { queryClient } from "../../../../query-client.ts";
import {
  useGetCategoryClusters,
  usePatchClusterById,
} from "@api/category-clusters.ts";
import { toast } from "@hooks/use-toast.ts";
import { useTranslation } from "react-i18next";

interface Props {
  open: boolean;
  onOpenChange: (open: boolean) => void;
}

export const RemoveCategoriesFromClusterDialog: FC<Props> = ({
  open,
  onOpenChange,
}) => {
  const { clusterData } = useContext(CategoryClusterContext);
  const { mutateAsync: patchCluster } = usePatchClusterById();
  const [clusterId, setClusterId] = useState("");
  const { t } = useTranslation();

  const patchClusters = async (d: {
    clusterName: string;
    id: string;
    unselectedCategories: string[];
  }) => {
    // @ts-ignore
    const cluster: PatchCategoryClusterRequest = {
      name: d.clusterName,
      categoryTypes: d.unselectedCategories as CamprType[],
    };
    try {
      await patchCluster({
        patch: cluster,
        id: d.id,
      });
      await queryClient.invalidateQueries({
        queryKey: useGetCategoryClusters.getKey(),
      });
      toast({
        title: t("saved-succesfully", { name: d.clusterName }),
        variant: "success",
      });
    } catch (error) {
      toast({
        title: t("request-failed-with"),
        description: t(
          decodeURIComponent(
            (error instanceof Error && error.message) || t("no-message"),
          ),
        ),
        variant: "destructive",
      });
    }
  };

  const getUnselectedCategoriesByCluster = (
    clusterData: ClusterData,
  ): { clusterName: string; id: string; unselectedCategories: string[] }[] => {
    return clusterData.clusters
      .map((cl) => ({
        clusterName: cl.name,
        id: cl.id,
        unselectedCategories: cl.categories
          .filter((cat) => !cat.selected) // Only keep unselected categories
          .map((cat) => cat.category), // Extract just the category name
      }))
      .filter((cluster) => cluster.unselectedCategories.length > 0); // Only include clusters with unselected categories
  };

  return (
    <AlertDialog.Root open={open} onOpenChange={onOpenChange}>
      <AlertDialog.Content className="z-[2001]">
        <AlertDialog.Header>
          <AlertDialog.Title>{t("reset-cluster")}</AlertDialog.Title>
          <AlertDialog.Description>
            <p className={"text-bol text-[16px] font-normal"}>
              {t("reset-selected-categories-from-cluster")}
            </p>
          </AlertDialog.Description>
        </AlertDialog.Header>
        <AlertDialog.Footer className="relative">
          <AlertDialog.Cancel>{t("cancel")}</AlertDialog.Cancel>

          <AlertDialog.Action
            onClick={() => {
              const data = getUnselectedCategoriesByCluster(clusterData);
              data.map((d) => {
                patchClusters(d).then();
              });
            }}
          >
            {t("save")}
          </AlertDialog.Action>
        </AlertDialog.Footer>
      </AlertDialog.Content>
    </AlertDialog.Root>
  );
};
