import {
  Column,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";
import {
  ExtendedTag,
  PatchTagRequest,
} from "../../../../../../api-contracts/tags";
import { useTranslation } from "react-i18next";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@primitives/table";
import {
  ChevronDownIcon,
  ChevronsUpDownIcon,
  ChevronUpIcon,
} from "lucide-react";
import { Switch } from "@primitives/switch";
import { DropDown } from "@components/default-list";
import { getTextColor } from "../tags-utils";
import { useState } from "react";
import { ConfirmDialog } from "@components/confirm-dialog";

export const TagTable = ({
  tableData,
  onEdit,
  onPatch,
  onDelete,
}: {
  tableData: ExtendedTag[];
  onEdit: (tag: ExtendedTag) => void;
  onPatch: (id: string, patch: PatchTagRequest) => void;
  onDelete: (id: string) => void;
}) => {
  const [openActivationDialog, setOpenActivationDialog] =
    useState<boolean>(false);
  const [openEnableDialog, setOpenEnableDialog] = useState<boolean>(false);
  const [openInUseDialog, setOpenInUseDialog] = useState<boolean>(false);

  const [selectedTag, setSelectedTag] = useState<ExtendedTag | null>(null);

  const { t } = useTranslation();
  const columnHelper = createColumnHelper<ExtendedTag>();

  const columnDefinitions = [
    columnHelper.accessor("name", {
      header: t("name"),
      id: "name",
    }),
    columnHelper.accessor("short", {
      header: t("abbreviation"),
      id: "short",
    }),
    columnHelper.accessor("color", {
      header: t("color"),
      id: "color",
      cell: (data) => {
        const color = data.getValue();
        return (
          <div
            style={{ backgroundColor: color, color: getTextColor(color) }}
            className="inline-block rounded-md p-1 px-2 font-bold"
          >
            {color.toUpperCase()}
          </div>
        );
      },
    }),

    columnHelper.accessor("external", {
      header: t("active-wbe"),
      id: "external",
      cell: (data) => {
        const isActive = data.row.original.active;
        return (
          <Switch
            disabled={!isActive}
            defaultChecked={data.getValue()}
            onCheckedChange={() => handleOnEnable(data.row.original)}
          />
        );
      },
    }),
    columnHelper.display({
      maxSize: 40,
      id: "actions",
      cell: (data) => {
        return (
          <DropDown
            isVisible={true}
            onEdit={() => handleOnEdit(data.row.original)}
            onActivation={async () => handleOnActivation(data.row.original)}
            isActive={data.row.original.active}
            onDelete={() => handleDeleteTag(data.row.original)}
          />
        );
      },
    }),
  ];

  const table = useReactTable({
    data: tableData,
    columns: columnDefinitions,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  const getSortIcon = (
    column: Column<ExtendedTag>,
    sortingState: SortingState,
  ) => {
    const sort = sortingState.find((s) => s.id === column.id);
    if (!sort) return <ChevronsUpDownIcon size={13} />;
    return sort.desc ? (
      <ChevronDownIcon size={13} />
    ) : (
      <ChevronUpIcon size={13} />
    );
  };

  const patchActivation = (tag: ExtendedTag) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { id, inUse, ...tagWithoutId } = tag;
    onPatch(tag.id, { ...tagWithoutId, active: !tagWithoutId.active });
    setOpenActivationDialog(false);
  };

  const patchEnableExternal = (tag: ExtendedTag) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { id, inUse, ...tagWithoutId } = tag;
    onPatch(tag.id, { ...tagWithoutId, external: !tagWithoutId.external });
  };

  const handleDeleteTag = (tag: ExtendedTag) => {
    if (tag.inUse) {
      setOpenInUseDialog(true);
    } else {
      onDelete(tag.id);
    }
  };

  const handleOnEdit = (tag: ExtendedTag) => {
    if (tag.inUse) {
      setOpenInUseDialog(true);
    } else {
      onEdit(tag);
    }
  };

  const handleOnActivation = (tag: ExtendedTag) => {
    if (tag.active) {
      setSelectedTag(tag);
      setOpenActivationDialog(true);
    } else {
      patchActivation(tag);
    }
  };

  const handleOnEnable = (tag: ExtendedTag) => {
    setSelectedTag(tag);
    setOpenEnableDialog(true);
  };

  return (
    <>
      <Table className="rounded-md bg-primary-card-backplate">
        <TableHeader>
          {table.getHeaderGroups().map((headerGroup) => (
            <TableRow key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <TableHead key={header.id}>
                  {header.isPlaceholder ? null : (
                    <div
                      className="flex cursor-pointer items-center"
                      {...{
                        onClick: header.column.getToggleSortingHandler(),
                      }}
                    >
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext(),
                      )}
                      {header.column.getCanSort() && (
                        <div className="ml-1">
                          {getSortIcon(header.column, table.getState().sorting)}
                        </div>
                      )}
                    </div>
                  )}
                </TableHead>
              ))}
            </TableRow>
          ))}
        </TableHeader>
        <TableBody>
          {table.getRowModel().rows?.length ? (
            table.getRowModel().rows.map((row) => (
              <TableRow key={row.id}>
                {row.getVisibleCells().map((cell) => {
                  return (
                    <TableCell
                      key={cell.id}
                      style={{
                        width: `${cell.column.getSize()}px`,
                      }}
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext(),
                      )}
                    </TableCell>
                  );
                })}
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell
                colSpan={table.getAllLeafColumns().length}
                className="h-24 text-center"
              >
                {t("no-results")}.
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      <ConfirmDialog
        title={t("are-you-sure")}
        description={t("tag-will-be-inactive")}
        isOpen={openActivationDialog}
        onOpenChange={setOpenActivationDialog}
        onProceed={() => {
          if (selectedTag) {
            patchActivation(selectedTag);
          }
        }}
        proceedBtnText={t("inactivate")}
      />
      <ConfirmDialog
        title={t("are-you-sure")}
        description={
          selectedTag?.external
            ? t("tag-will-not-be-visible-in-external-channels")
            : t("tag-will-be-visible-in-external-channels")
        }
        isOpen={openEnableDialog}
        onOpenChange={setOpenEnableDialog}
        onProceed={() => {
          if (selectedTag) {
            patchEnableExternal(selectedTag);
          }
        }}
        proceedBtnText={selectedTag?.external ? t("inactivate") : t("activate")}
      />
      <ConfirmDialog
        title={t("tag-in-use")}
        description={t("tag-in-use-description")}
        isOpen={openInUseDialog}
        onOpenChange={setOpenInUseDialog}
        onProceed={() => setOpenInUseDialog(false)}
        proceedBtnText={t("close")}
      />
    </>
  );
};
