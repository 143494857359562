import React, {
  ChangeEvent,
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import { LocationProps } from "./components/locations-hierarchy-tree.tsx";
import { AssetOverview } from "../../../../../api-contracts/assets";
import {
  convertDtosToProps,
  findAndSortChildren,
  findLocationById,
  openParents,
  updateLocation,
  updateLocationsOnFetch,
} from "./locations-utils.tsx";
import { Loading } from "@primitives/loading.tsx";
import { useGetAssets, useUpdateAsset } from "@api/assets.ts";
import {
  useDeleteLocation,
  useGetLocations,
  useUpdateLocation,
} from "@api/locations.ts";
import { useSharedContext } from "@context/shared-context.tsx";
import { useTranslation } from "react-i18next";
import { queryClient } from "query-client.ts";

interface SharedStateProps {
  locations: LocationProps[];
  currentAsset: AssetOverview | null;
  actionType: LocationsAction;
  assets: AssetOverview[];
  value: string;
  currentLocation: LocationProps | null;
  locationChildren: LocationProps[];
}

interface SharedFunctionsProps {
  setLocations: Dispatch<SetStateAction<LocationProps[]>>;
  setActionType: Dispatch<SetStateAction<LocationsAction>>;
  setCurrentAsset: Dispatch<SetStateAction<AssetOverview | null>>;
  setLocationChildren: Dispatch<SetStateAction<LocationProps[]>>;
  setAssets: Dispatch<SetStateAction<AssetOverview[]>>;
  setCurrentLocation: Dispatch<SetStateAction<LocationProps | null>>;
  onSearchChange: (e: ChangeEvent<HTMLInputElement>) => void;
  handleBreadcrumb: (value: string | null) => void;
  onClickTree: (location: LocationProps) => void;
  onNodeExpand: (location: LocationProps) => void;
  updateBookable: (
    locationId: string,
    id: string,
    onSuccess: (locationId: string) => void,
  ) => void;
  updatingLocation: (
    location: LocationProps,
    parentId: string | null,
    onSuccess: (req?: string, parent?: string | null) => void,
  ) => void;
  deleteLocation: (id: string, name: string) => void;
  refetchLocations: () => Promise<void | null | undefined>;
  refetchAssetList: () => Promise<void | null | undefined>;
}

interface AreasPageContextProps {
  sharedState: SharedStateProps;
  sharedFunctions: SharedFunctionsProps;
}

export enum LocationsAction {
  DEFAULT = "DEFAULT",
  LOCATION_ADD = "LOCATION_ADD",
  LOCATION_EDIT = "LOCATION_EDIT",
  LOCATION_MOVE = "LOCATION_MOVE",
  ASSET_ADD = "ASSET_ADD",
  ASSET_MOVE = "ASSET_MOVE",
}

const LocationsContext = createContext<AreasPageContextProps | undefined>(
  undefined,
);

export const useLocationsPageContext = () => {
  const context = useContext(LocationsContext);

  if (!context) {
    throw new Error("context must be used in correct provider");
  }

  return context;
};

interface AreasPageProviderProps {
  children: ReactNode;
}

export const LocationsPageProvider: React.FC<AreasPageProviderProps> = ({
  children,
}) => {
  const {
    sharedState: { isLoading },
    sharedFunctions: { setLoading, toast },
  } = useSharedContext();

  const [value, setValue] = useState<string>("");
  const [actionType, setActionType] = useState<LocationsAction>(
    LocationsAction.DEFAULT,
  );
  const [currentLocation, setCurrentLocation] = useState<LocationProps | null>(
    null,
  );
  const [locations, setLocations] = useState<LocationProps[]>([]);
  const [locationChildren, setLocationChildren] = useState<LocationProps[]>([]);

  const [currentAsset, setCurrentAsset] = useState<AssetOverview | null>(null);
  const [assets, setAssets] = useState<AssetOverview[]>([]);

  const { mutate: updLocation } = useUpdateLocation();
  const { mutate: delLocation } = useDeleteLocation();
  const { mutate: updBookable } = useUpdateAsset();

  useEffect(() => {
    queryClient.invalidateQueries({
      queryKey: useGetAssets.getKey(),
    });
    queryClient.invalidateQueries({
      queryKey: useGetLocations.getKey(),
    });
  }, []);

  const { data: getAssets, refetch: refetchAssets } = useGetAssets({
    variables: {},
  });
  const { data: getLocations, refetch: refetchLoc } = useGetLocations();

  const { t } = useTranslation();

  useEffect(() => {
    const n = getLocations && convertDtosToProps(getLocations);
    n &&
      setLocations(() =>
        locations.length > 0 ? updateLocationsOnFetch(n, locations) : n,
      );
  }, [getLocations, setLocations]);

  useEffect(() => {
    getAssets && setAssets(() => getAssets);
  }, [getAssets, currentLocation, setAssets]);

  useEffect(() => {
    currentLocation &&
      setLocations(() => openParents(currentLocation.location.id, locations));
  }, [currentLocation]);

  useEffect(() => {
    setLocationChildren(() =>
      findAndSortChildren(currentLocation?.location.id ?? null, locations),
    );
  }, [locations, currentLocation, setLocationChildren]);

  const refetchAssetList = async () => {
    const res = await refetchAssets();
    if (res.error) {
      toast({
        variant: "destructive",
        title: t("refetch-bookables-error", { error: res.error }),
      });
      return null;
    }
    return res.data && setAssets(() => res.data);
  };

  const refetchLocations = async () => {
    const res = await refetchLoc();
    if (res.error) {
      toast({
        variant: "destructive",
        title: t("refetch-locations-error", { error: res.error }),
      });
      return null;
    }
  };

  const handleBreadcrumb = (value: string | null) => {
    const location = value ? findLocationById(value, locations) : null;
    setCurrentLocation(() => location);
  };

  const onClickTree = (location: LocationProps) => {
    setCurrentLocation(() => location);
  };

  const onNodeExpand = (location: LocationProps) => {
    const updLocation = { ...location, isOpen: !location.isOpen };
    setLocations(() => updateLocation(updLocation, locations));
  };

  const onSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    setValue(() => e.target.value);
  };

  const updateBookable = (
    locationId: string,
    id: string,
    onSuccess: (locationId: string) => void,
  ) => {
    setLoading(true);

    updBookable(
      {
        patch: { locationId: locationId },
        id: id,
      },
      {
        onError: (e) => {
          toast({
            variant: "destructive",
            title: t("move-bookable-error", { error: e.message }),
          });
        },
        onSuccess: async () => {
          onSuccess(locationId);
          await refetchAssets();
        },
        onSettled: () => {
          setLoading(false);
          setActionType(LocationsAction.DEFAULT);
        },
      },
    );
  };

  const updatingLocation = (
    req: LocationProps,
    parentId: string | null,
    onSuccess: (locationId: string, parent?: string | null) => void,
  ) => {
    setLoading(true);

    updLocation(
      {
        id: req.location.id,
        name: req.location.name,
        partOf: req.location.partOf,
      },
      {
        onError: (e) => {
          toast({
            variant: "destructive",
            title: t("update-location-error", { error: e.message }),
          });
        },
        onSuccess: async () => {
          await refetchLocations();
          onSuccess(req.location.id, parentId);
        },
        onSettled: () => {
          setLoading(false);
          setActionType(LocationsAction.DEFAULT);
        },
      },
    );
  };

  const deleteLocation = (id: string, name: string) => {
    setLoading(true);

    delLocation(
      {
        id: id,
      },
      {
        onError: (e) => {
          toast({
            variant: "destructive",
            title: t("delete-location-error", { error: e.message }),
          });
        },
        onSuccess: async () => {
          toast({
            variant: "success",
            title: t("location-deleted", { location: name }),
          });
          await refetchLocations();
          await refetchAssetList();
        },
        onSettled: () => {
          setLoading(false);
        },
      },
    );
  };

  const sharedState = {
    locations,
    currentAsset,
    isLoading,
    actionType,
    assets,
    value,
    currentLocation,
    locationChildren,
  };

  const sharedFunctions = {
    setLocations,
    setCurrentAsset,
    setLocationChildren,
    setActionType,
    setAssets,
    setCurrentLocation,
    onSearchChange,
    handleBreadcrumb,
    onClickTree,
    onNodeExpand,
    updatingLocation,
    refetchLocations,
    deleteLocation,
    setLoading,
    refetchAssetList,
    toast,
    updateBookable,
  };

  const closed =
    actionType === LocationsAction.LOCATION_ADD ||
    actionType === LocationsAction.DEFAULT;

  return (
    <LocationsContext.Provider value={{ sharedState, sharedFunctions }}>
      {!closed && (
        <div className="backdrop fixed inset-0 z-[100] flex items-center justify-center overflow-y-auto overflow-x-hidden bg-black/75 outline-none focus:outline-none" />
      )}
      {isLoading && <Loading className={"p0"} />}
      {children}
    </LocationsContext.Provider>
  );
};
