import { createQuery } from "react-query-kit";
import { api } from "@shared/api/api.ts";
import { currentSite } from "@shared/context/site-context.tsx";
import { GetLanguageSettings } from "../../../../api-contracts/language-settings";

export const LANGUAGE_SETTINGS_QUERY_KEY = "language-settings";

export const useGetLanguageSettings = createQuery<GetLanguageSettings>({
  primaryKey: LANGUAGE_SETTINGS_QUERY_KEY,

  queryFn: async () => {
    return api.get(`/api/${currentSite?.id}/${LANGUAGE_SETTINGS_QUERY_KEY}`);
  },
});
