import { CardTitle } from "@primitives/card";
import { useTranslation } from "react-i18next";

export const FireListCardHeader = () => {
  const { t } = useTranslation();

  return (
    <>
      <div className="flex items-center border-b border-highlighted-backplate p-8">
        <CardTitle>{t("fire-list")}</CardTitle>
      </div>
    </>
  );
};
