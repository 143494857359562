import { Button } from "@primitives/button";
import { useTranslation } from "react-i18next";
import { CategoryCapacity } from "../../../../../../api-contracts/categories";
import { SetStateAction, useState } from "react";
import { PlaceholderInput } from "./placeholder-input";
import { BorderedListItem } from "./bordered-list-item";
import { preventNegativeValue } from "@shared/utils/text";

export const GuestCapacity = ({ capacity }: { capacity: CategoryCapacity }) => {
  const { t } = useTranslation();

  const displayValue = (value: number) => (value === 0 ? "st" : `${value} st`);

  return (
    <>
      <span className=" text-sm font-normal">
        {t("possible-guest-capacity")}
      </span>

      <div className="flex min-h-[220px] flex-col justify-between rounded-sm border border-highlighted-backplate">
        <BorderedListItem
          leftSideText={t("max-guests-in-total")}
          rightSideText={displayValue(capacity.total.max)}
        />
        <BorderedListItem
          leftSideText={t("max-adults")}
          rightSideText={displayValue(capacity.adults.max)}
        />
        <BorderedListItem
          leftSideText={t("max-teenagers")}
          rightSideText={displayValue(capacity.teenagers.max)}
        />
        <BorderedListItem
          leftSideText={t("max-children")}
          rightSideText={displayValue(capacity.children.max)}
        />
        <BorderedListItem
          leftSideText={t("max-infants")}
          rightSideText={displayValue(capacity.infants.max)}
        />
      </div>
    </>
  );
};
export const EditCapacity = ({
  capacity,
  setCapacity,
  closeSheet,
}: {
  capacity: CategoryCapacity;
  setCapacity: React.Dispatch<SetStateAction<CategoryCapacity>>;
  closeSheet: () => void;
}) => {
  const { t } = useTranslation();
  const [tempCapacity, setTempCapacity] = useState<CategoryCapacity>(capacity);

  // TODO Translate error messages
  const validateCapacity = () => {
    const maxValue = tempCapacity.total.max;
    const errors: { [key: string]: string | null } = {
      totalMax: null,
      adultsMin: null,
      adultsMax: null,
      nonAdults: null,
    };
    if (!maxValue) {
      errors.totalMax = "Define a total maximum capacity.";
    }

    if (tempCapacity.adults.max > maxValue) {
      errors.adultsMax = "Adults' max exceeds total capacity.";
    }
    if (tempCapacity.adults.min > maxValue) {
      errors.adultsMin = "Adults' minimum exceeds total capacity.";
    }

    const nonAdults: (keyof CategoryCapacity)[] = [
      "teenagers",
      "children",
      "infants",
    ];

    nonAdults.forEach((key) => {
      const nonAdultMax = tempCapacity[key].max;
      if (nonAdultMax > maxValue) {
        errors.nonAdults = `${key} exceeds total max capacity.`;
      }
      if (nonAdultMax + tempCapacity.adults.min > maxValue) {
        errors.nonAdults = `Adults' minimum and ${key} exceed total max.`;
      }
    });

    return errors;
  };
  const errors = validateCapacity();
  const hasErrors = Object.values(errors).some((error) => error !== null);

  const handleSave = () => {
    if (hasErrors) {
      return;
    } else {
      setCapacity(tempCapacity);
      closeSheet();
    }
  };

  return (
    <>
      <div className="flex flex-grow flex-col rounded-sm bg-secondary-card-backplate p-4 text-primary-text">
        <h2 className=" text-base font-bold">{t("in-total")}</h2>
        <div className="py-3">
          <p className="mb-1 text-sm font-medium ">{`${t("max-guests")}`}</p>
          <PlaceholderInput
            min={1}
            value={tempCapacity.total.max.toString()}
            onChange={(e) =>
              setTempCapacity((prev) => ({
                ...prev,
                total: {
                  ...prev.total,
                  max: Number(preventNegativeValue(e.target.value)),
                },
              }))
            }
            onKeyDown={(e) => {
              if (e.key === "-" || e.key === "e" || e.key === "E") {
                e.preventDefault();
              }
            }}
          />
          {errors.totalMax && (
            <span className=" text-xs text-status-error">
              {errors.totalMax}
            </span>
          )}
        </div>
        <h2 className=" mt-4 text-base font-bold">{t("adults")}</h2>
        <div className="py-3">
          <div className="flex justify-between gap-6">
            <div className="flex w-1/2 flex-col">
              <p className="mb-1 text-sm font-medium ">{`${t("min-adults")}`}</p>
              <PlaceholderInput
                min={1}
                value={tempCapacity.adults.min.toString()}
                onChange={(e) =>
                  setTempCapacity((prev) => ({
                    ...prev,
                    adults: {
                      ...prev.adults,
                      min: Number(preventNegativeValue(e.target.value)),
                    },
                  }))
                }
                onKeyDown={(e) => {
                  if (e.key === "-" || e.key === "e" || e.key === "E") {
                    e.preventDefault();
                  }
                }}
              />
              {errors.adultsMin && (
                <span className="text-xs text-status-error">
                  {errors.adultsMin}
                </span>
              )}
            </div>
            <div className="flex w-1/2 flex-col">
              <p className="mb-1 text-sm font-medium ">{`${t("max-adults")}`}</p>

              <PlaceholderInput
                min={1}
                value={tempCapacity.adults.max.toString()}
                onChange={(e) =>
                  setTempCapacity((prev) => ({
                    ...prev,
                    adults: {
                      ...prev.adults,
                      max: Number(preventNegativeValue(e.target.value)),
                    },
                  }))
                }
                onKeyDown={(e) => {
                  if (e.key === "-" || e.key === "e" || e.key === "E") {
                    e.preventDefault();
                  }
                }}
              />
              {errors.adultsMax && (
                <span className=" text-xs text-status-error">
                  {errors.adultsMax}
                </span>
              )}
            </div>
          </div>
        </div>
        <h2 className=" mt-4 text-base font-bold">{t("others")}</h2>
        <div className="py-3">
          <p className="mb-1 text-sm font-medium ">{`${t("max-teenagers")}`}</p>
          <PlaceholderInput
            value={tempCapacity.teenagers.max.toString()}
            onChange={(e) =>
              setTempCapacity((prev) => ({
                ...prev,
                teenagers: {
                  max: Number(preventNegativeValue(e.target.value)),
                },
              }))
            }
            onKeyDown={(e) => {
              if (e.key === "-" || e.key === "e" || e.key === "E") {
                e.preventDefault();
              }
            }}
          />

          <div className="py-4">
            <p className="mb-1 text-sm font-medium ">{`${t("max-children")}`}</p>
            <PlaceholderInput
              value={tempCapacity.children.max.toString()}
              onChange={(e) =>
                setTempCapacity((prev) => ({
                  ...prev,
                  children: {
                    max: Number(preventNegativeValue(e.target.value)),
                  },
                }))
              }
            />
          </div>

          <p className="mb-1 text-sm font-medium ">{`${t("max-infants")}`}</p>
          <PlaceholderInput
            value={tempCapacity.infants.max.toString()}
            onChange={(e) =>
              setTempCapacity((prev) => ({
                ...prev,
                infants: {
                  max: Number(preventNegativeValue(e.target.value)),
                },
              }))
            }
          />
          {errors.nonAdults && (
            <span className=" text-xs text-status-error">
              {errors.nonAdults}
            </span>
          )}
        </div>
      </div>
      <div className="flex justify-end">
        <Button disabled={hasErrors} onClick={handleSave}>
          {t("done")}
        </Button>
      </div>
    </>
  );
};

export const AreaCapacity = ({
  capacity,
  setCapacity,
}: {
  capacity: CategoryCapacity;
  setCapacity: React.Dispatch<SetStateAction<CategoryCapacity>>;
}) => {
  const { t } = useTranslation();
  return (
    <div>
      <p className="mb-1 text-sm font-medium ">{`${t("min-number-of-people")}`}</p>
      <PlaceholderInput
        min={1}
        value={capacity.adults.min.toString()}
        onChange={(e) =>
          setCapacity((prev) => ({
            ...prev,
            adults: {
              ...prev.adults,
              min: Number(preventNegativeValue(e.target.value)),
            },
          }))
        }
        onKeyDown={(e) => {
          if (e.key === "-" || e.key === "e" || e.key === "E") {
            e.preventDefault();
          }
        }}
      />
      <div className="py-2" />
      <p className="mb-1 text-sm font-medium ">{`${t("max-number-of-people")}`}</p>
      <PlaceholderInput
        min={1}
        value={capacity.total.max.toString()}
        onChange={(e) =>
          setCapacity((prev) => ({
            ...prev,
            total: {
              ...prev.total,
              max: Number(preventNegativeValue(e.target.value)),
            },
          }))
        }
        onKeyDown={(e) => {
          if (e.key === "-" || e.key === "e" || e.key === "E") {
            e.preventDefault();
          }
        }}
      />
    </div>
  );
};
