import { RecentItem } from "./search-utils";
import { PageResults } from "./page-results";
import { BookingResults } from "./booking-results";
import { RecentSearchTerm } from "./recent-search-term";

export const RecentResults = ({
  searchTerm,
  onDelete,
  recentResults,
}: {
  searchTerm: string;
  onDelete: (item: RecentItem) => void;
  recentResults: RecentItem[];
}) => {
  const renderRecentItem = (item: RecentItem) => {
    switch (item.type) {
      case "pages":
        return (
          <>
            {recentResults && (
              <PageResults
                searchTerm={searchTerm}
                pages={item.pages}
                isRecentResult={true}
                onDelete={onDelete}
              />
            )}
          </>
        );
      case "reservation":
        return (
          <BookingResults
            searchTerm={searchTerm}
            bookings={[item]}
            isRecentResult={true}
            onDelete={onDelete}
          />
        );
      case "searchTerm":
        return (
          <RecentSearchTerm
            item={item}
            searchTerm={searchTerm}
            onDelete={onDelete}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div>
      <ul>
        {recentResults &&
          recentResults.length > 0 &&
          recentResults.map((item, index) => (
            <span key={`${item}-${index}`}>{renderRecentItem(item)}</span>
          ))}
      </ul>
    </div>
  );
};
