import { useGetAllSitesAdmin } from "@api/sites";
import { useAdmin } from "../context";
import { Loader2 } from "lucide-react";
import { SearchSelect } from "../components/search-select";
import { SitesForm } from "../components/forms/enteties";
import { Site } from "../../../../../api-contracts/sites";

export const SiteSection: React.FC<unknown> = () => {
  const { state, dispatch } = useAdmin();
  const { data: sites, isLoading } = useGetAllSitesAdmin({
    variables: { orgId: state.organization?.id ?? "" },
  });

  const selectSite = (site: Site | null) => {
    dispatch({
      type: "SITE_SELECT",
      payload: site ?? null,
    });
  };

  return (
    <div className="min-h[100px]  grid grid-cols-2 divide-x">
      <div className="w-fit ">
        <h2 className="mb-6 w-fit text-xl font-semibold">Select Site</h2>
        {isLoading && (
          <div className="z-20 flex h-full w-full items-center  backdrop-blur-sm">
            <Loader2 className="h-20 w-20 animate-spin text-primary-color" />
          </div>
        )}
        {!isLoading && !!sites && (
          <div className="w-fit">
            <SearchSelect
              payload={sites.map((site) => ({
                value: site.name,
                id: site.id,
              }))}
              placeholder="Site name..."
              trigger="Select Site"
              empty="No Sites"
              value={state.site?.name ?? ""}
              onSelect={(item) =>
                selectSite(item ? { name: item.value, id: item.id } : null)
              }
            ></SearchSelect>
          </div>
        )}
      </div>

      <div className=" pl-10">
        <h2 className="mb-6 w-fit text-xl font-semibold">Create Site</h2>
        <SitesForm onCreated={selectSite} />
      </div>
    </div>
  );
};
