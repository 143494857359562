import {
  useReactTable,
  getCoreRowModel,
  getSortedRowModel,
  createColumnHelper,
  SortingState,
} from "@tanstack/react-table";
import { CategoryTypeEnum, useReceptionContext } from "../../reception-context";
import { ReceptionTable } from "../../components/reception-table";
import { TableHeader } from "../../components/table-header";
import { ReceptionBooking } from "../../../../../../api-contracts/reception";
import { HighlightText } from "@pages/settings/categories/components/highlight-text";
import { useTranslation } from "react-i18next";
import {
  ReceptionBookingWithLocation,
  formatGuests,
  formatPrice,
} from "../../reception-utils";
import { format } from "date-fns";
import { Button } from "@primitives/button";
import { Lock } from "lucide-react";
import { Loading } from "@primitives/loading";
import {
  useGetDeparturesByDate,
  usePostCheckOutByBookingId,
  usePostUndoCheckOutByBookingId,
} from "@api/reception";
import { queryClient } from "query-client";
import { toast } from "@hooks/use-toast";
import { BookingState } from "../../../../../../api-contracts/reservations";

import { Checkbox } from "@primitives/checkbox";
import { useNavigate } from "react-router-dom";
import { useProfileContext } from "@context/profile-context";
import { useEffect, useState } from "react";
import { CheckOutDialogManager } from "./check-out-dialog-manager";
import { LocationReceptionTable } from "@pages/reception/components/location-reception-table";

export const DeparturesTableContainer = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const { module } = useProfileContext();
  const {
    selectedBookings,
    date,
    showLocation,
    departures,
    columnVisibility,
    setColumnVisibility,
    searchTerm,
    isLoading,
    categoryType,
    setSelectedBookings,
  } = useReceptionContext();
  const [sorting, setSorting] = useState<SortingState>([]);
  const [selectedBooking, setSelectedBooking] = useState<{
    booking: ReceptionBooking | null;
    isOpen: boolean;
    dialogType: "confirm" | "paymentRemaining" | "undoCheckOut" | null;
  }>({
    booking: null,
    isOpen: false,
    dialogType: null,
  });

  const today = new Date();
  const yesterday = new Date(today);
  yesterday.setDate(today.getDate() - 1);
  const formattedToday = today.toISOString().split("T")[0];
  const formattedDate = date.toISOString().split("T")[0];
  const isToday = formattedDate === formattedToday;

  const { mutateAsync: checkOut } = usePostCheckOutByBookingId();
  const { mutateAsync: undoCheckOut } = usePostUndoCheckOutByBookingId();

  const onCheckOut = (bookingId: string) => {
    try {
      checkOut(bookingId);
      queryClient.invalidateQueries({
        queryKey: useGetDeparturesByDate.getKey(),
      });

      toast({
        title: t("changes-saved"),
        variant: "success",
      });
    } catch (error) {
      toast({
        title: t("request-failed-with"),
        description: t(
          decodeURIComponent(
            (error instanceof Error && error.message) || t("no-message"),
          ),
        ),
        variant: "destructive",
      });
    }
  };

  const onUndoCheckOut = async (bookingId: string) => {
    try {
      await undoCheckOut(bookingId);
      queryClient.invalidateQueries({
        queryKey: useGetDeparturesByDate.getKey(),
      });
      toast({
        title: t("changes-saved"),
        variant: "success",
      });
    } catch (error) {
      toast({
        title: t("request-failed-with"),
        description: t(
          decodeURIComponent(
            (error instanceof Error && error.message) || t("no-message"),
          ),
        ),
        variant: "destructive",
      });
    }
  };

  const toggleRowSelection = (row: ReceptionBooking) => {
    if (row.paymentRemaining !== "0.00" || !isToday) return;

    setSelectedBookings((prevSelectedRows) => {
      const isSelected = prevSelectedRows.some(
        (selectedRow) => selectedRow.reservationId === row.reservationId,
      );

      if (isSelected) {
        return prevSelectedRows.filter(
          (selectedRow) => selectedRow.reservationId !== row.reservationId,
        );
      }
      return [...prevSelectedRows, row];
    });
  };

  const toggleAllRowSelection = () => {
    const selectableRows = departures.filter(
      (row) =>
        isToday &&
        row.paymentRemaining === "0.00" &&
        row.state === "checked-in",
    );

    const isAllSelected = selectedBookings.length === selectableRows.length;

    if (isAllSelected) {
      setSelectedBookings([]);
    } else {
      setSelectedBookings(selectableRows);
    }
  };
  const handleRowClick = (booking: ReceptionBooking) => {
    navigate(`/${module}/reservations/${booking.reservationId}`);
  };

  const handleDialogs = (booking: ReceptionBooking) => {
    if (booking.paymentRemaining !== "0.00") {
      setSelectedBooking({
        booking,
        isOpen: true,
        dialogType: "paymentRemaining",
      });
    } else if (booking.state === "checked-in") {
      setSelectedBooking({
        booking,
        isOpen: true,
        dialogType: "confirm",
      });
    } else if (booking.state === "checked-out") {
      setSelectedBooking({ booking, isOpen: true, dialogType: "undoCheckOut" });
    }
  };

  const closeDialogs = () => {
    setSelectedBooking({ booking: null, isOpen: false, dialogType: null });
  };

  const columnHelper = createColumnHelper<
    ReceptionBooking | ReceptionBookingWithLocation
  >();

  const departuresColumnDefinition = [
    columnHelper.display({
      id: "select",
      size: 5,
      enableHiding: false,
      header: () => {
        const isAllRowsSelected = selectedBookings.length === departures.length;
        const hasAnyPaymentRemaining = departures.some(
          (row) => row.paymentRemaining !== "0.00",
        );
        const noDepartures = departures.length === 0;
        const hasAtleastOneSelected = selectedBookings.length > 0;

        if (noDepartures || !isToday || hasAnyPaymentRemaining) return null;
        return (
          <Checkbox
            className={`h-4 w-4 ${hasAtleastOneSelected ? "visible" : "invisible"} group-hover:visible`}
            checked={isAllRowsSelected}
            onClick={(e) => {
              e.stopPropagation();
            }}
            onCheckedChange={() => {
              toggleAllRowSelection();
            }}
          />
        );
      },
      cell: ({ row }) => {
        const isRowSelected = selectedBookings.some(
          (selectedRow) =>
            selectedRow.reservationId === row.original.reservationId,
        );

        const isSelectable =
          isToday &&
          row.original.paymentRemaining === "0.00" &&
          row.original.state === "checked-in";
        if (!isSelectable) return null;

        return (
          <Checkbox
            className={`h-4 w-4 ${isRowSelected ? "visible" : "invisible"} group-hover:visible`}
            checked={isRowSelected}
            onClick={(e) => {
              e.stopPropagation();
            }}
            onCheckedChange={() => {
              toggleRowSelection(row.original);
            }}
          />
        );
      },
    }),
    columnHelper.accessor("reservationId", {
      header: t("booking"),
      id: "reservationId",
      cell: ({ getValue }) => (
        <HighlightText text={getValue()} textToHighlight={searchTerm} />
      ),
      meta: {
        initialVisibility: true,
      },
    }),
    columnHelper.accessor("holder.name", {
      header: t("first-name"),
      id: "firstName",
      cell: ({ getValue }) => {
        const firstName = getValue();
        return (
          <HighlightText
            text={firstName ? firstName : ""}
            textToHighlight={searchTerm}
          />
        );
      },
      meta: {
        initialVisibility: true,
      },
    }),
    columnHelper.accessor("holder.surname", {
      header: t("surname"),
      id: "surname",
      cell: ({ getValue }) => {
        const surname = getValue();
        return (
          <HighlightText
            text={surname ? surname : ""}
            textToHighlight={searchTerm}
          />
        );
      },
      meta: {
        initialVisibility: true,
      },
    }),
    columnHelper.accessor(
      (row) => {
        const { adults, teenagers, children, infants } = row;

        return formatGuests(
          { adults, teenagers, children, infants },
          i18n.languages[0],
        );
      },
      {
        header: t("guests"),
        id: "guests",
        meta: {
          initialVisibility: true,
        },
      },
    ),
    columnHelper.accessor("arrivalDate", {
      header: t("arrival-date"),
      id: "arrivalDate",
      cell: ({ getValue }) => format(getValue(), "yyyy-MM-dd"),
      meta: {
        initialVisibility: false,
      },
    }),
    columnHelper.accessor("departureDate", {
      header: t("departure-date"),
      id: "departureDate",
      cell: ({ getValue }) => format(getValue(), "yyyy-MM-dd"),
      meta: {
        initialVisibility: true,
      },
    }),
    columnHelper.accessor("numberOfNights", {
      header: t("number-of-nights"),
      id: "numberOfNights",
      meta: {
        initialVisibility: true,
      },
    }),
    columnHelper.accessor("category.name", {
      header: t("category"),
      id: "roomCategory",
      meta: {
        initialVisibility: true,
      },
    }),
    columnHelper.accessor("bookable.name", {
      header: categoryType === CategoryTypeEnum.HOTEL ? t("room") : t("unit"),
      id: "room",
      cell: ({ row }) => {
        const { lockedBookable, bookable } = row.original;
        return (
          <div className="flex w-full items-center justify-between">
            <p className="mr-2">{bookable.name}</p>
            {lockedBookable && (
              <Lock strokeWidth={1} size={16} className="ml-auto" />
            )}
          </div>
        );
      },
      meta: {
        initialVisibility: true,
      },
    }),
    columnHelper.accessor("paymentRemaining", {
      header: t("to-be-paid"),
      id: "paymentRemaining",
      cell: ({ getValue }) => formatPrice(getValue()),
      meta: {
        initialVisibility: true,
      },
    }),
    columnHelper.accessor("state", {
      header: t("status"),
      id: "state",
      meta: {
        initialVisibility: true,
      },
      cell: ({ getValue }) => {
        const stateLabels: Partial<Record<BookingState, string>> = {
          planned: t("not-checked-in"),
          "checked-in": t("checked-in"),
          "checked-out": t("checked-out"),
          "no-show": t("no-show"),
        };

        const stateClasses: Partial<Record<BookingState, string>> = {
          planned: "bg-status-warning-100 text-status-warning",
          "checked-in": "bg-status-success-100 text-status-success",
          "checked-out": "bg-status-disabled-100 text-status-disabled",
          "no-show": "bg-status-error-100 text-status-error",
        };

        const stateValue = getValue();
        const label = stateLabels[stateValue];
        const stateClassName = stateClasses[stateValue];
        return (
          <div
            className={`${stateClassName} flex items-center justify-center whitespace-nowrap rounded-[4px] px-2 py-1 font-extrabold`}
          >
            <p>{label}</p>
          </div>
        );
      },
    }),
    columnHelper.display({
      header: t("action"),
      id: "action",
      enableHiding: false,
      cell: ({ row }) => {
        //TODO - Fix dialogs
        const stateValue: BookingState = row.original.state;
        const departureDate = new Date(row.original.departureDate);

        const isToday =
          departureDate.getDate() === today.getDate() &&
          departureDate.getMonth() === today.getMonth() &&
          departureDate.getFullYear() === today.getFullYear();

        const isCheckedOut = stateValue === "checked-out";
        const isCheckedIn = stateValue === "checked-in";

        return (
          <div>
            {isToday && isCheckedIn && (
              <Button
                onClick={(event) => {
                  event.stopPropagation();
                  handleDialogs(row.original);
                }}
                size={"sm"}
              >
                {t("check-out")}
              </Button>
            )}
            {isToday && isCheckedOut && (
              <Button
                onClick={(event) => {
                  event.stopPropagation();
                  handleDialogs(row.original);
                }}
                size={"sm"}
              >
                {t("undo-check-out")}
              </Button>
            )}
          </div>
        );
      },
    }),
  ];

  const departuresTable = useReactTable({
    data: departures,
    columns: departuresColumnDefinition,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    onColumnVisibilityChange: setColumnVisibility,
    onSortingChange: (newSorting) => {
      if (!showLocation) {
        setSorting(newSorting);
      } else {
        setSorting([]);
      }
    },
    state: {
      columnVisibility,
      sorting,
    },
  });

  useEffect(() => {
    setSorting([]);
  }, [showLocation]);

  const cols = departuresTable.getAllLeafColumns();
  if (isLoading || !departures) return <Loading />;
  return (
    <div>
      <div className="p-4">
        <TableHeader columns={cols} tableType="departures" />
      </div>
      {!showLocation ? (
        <ReceptionTable<ReceptionBooking>
          table={departuresTable}
          onRowClick={handleRowClick}
        />
      ) : (
        <LocationReceptionTable
          table={departuresTable}
          onRowClick={handleRowClick}
        />
      )}
      <CheckOutDialogManager
        onCheckOut={onCheckOut}
        onUndoCheckOut={onUndoCheckOut}
        selectedBooking={selectedBooking}
        closeDialogs={closeDialogs}
      />
    </div>
  );
};
