import { AlertDialog } from "@primitives/alert-dialog";
import { FC, SetStateAction } from "react";
import { useTranslation } from "react-i18next";

interface ConfirmDialogProps {
  isOpen: boolean;
  title?: string;
  description?: string;
  onProceed: () => void | Promise<void>;
  onOpenChange: React.Dispatch<SetStateAction<boolean>>;
  proceedBtnText: string;
  onDiscard?: () => void;
  hideCancel?: boolean;
}

export const ConfirmDialog: FC<ConfirmDialogProps> = ({
  isOpen = false,
  title = "Do you really want to deactivate?",
  description = "If you deactivate the category, the rooms linked to the category will no longer be bookable. Are you sure you want to deactivate?",
  onProceed,
  onOpenChange,
  proceedBtnText,
  onDiscard,
  hideCancel,
}) => {
  const { t } = useTranslation();

  const handleProceed = () => {
    onProceed();
  };

  return (
    <AlertDialog.Root open={isOpen} onOpenChange={onOpenChange}>
      <AlertDialog.Content>
        <AlertDialog.Header>
          <AlertDialog.Title>{title}</AlertDialog.Title>
          <AlertDialog.Description>{description}</AlertDialog.Description>
        </AlertDialog.Header>
        <AlertDialog.Footer>
          {!hideCancel && (
            <AlertDialog.Cancel>{t("cancel")}</AlertDialog.Cancel>
          )}
          {onDiscard && (
            <AlertDialog.Cancel onClick={() => onDiscard()}>
              {t("discard")}
            </AlertDialog.Cancel>
          )}
          <AlertDialog.Action onClick={handleProceed}>
            {proceedBtnText}
          </AlertDialog.Action>
        </AlertDialog.Footer>
      </AlertDialog.Content>
    </AlertDialog.Root>
  );
};
