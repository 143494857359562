import { CategoriesContextProvider } from "../categories-context";
import CategoryDetail from "./categories-detail";

const CategoriesDetailPage = () => {
  return (
    <CategoriesContextProvider>
      <CategoryDetail />
    </CategoriesContextProvider>
  );
};

export default CategoriesDetailPage;
