import { useTranslation } from "react-i18next";
import { Dialog, DialogContent, DialogTitle } from "@primitives/dialog";
import { Button } from "@primitives/button";
import { RadioGroup, RadioGroupItem } from "@primitives/radio-group";
import { useEffect, useState } from "react";
import { Label } from "@primitives/label";
import { GetBooking } from "../../../../../../../api-contracts/reservations";
import { uniqueId } from "lodash";
import { useGetPriceRules } from "@api/price-rules";
import { getBedPreferenceFromType } from "@shared/utils/helpers";
import { priceRuleOverrideKeyToGuestCounts } from "@shared/utils/price-rules";

interface Props {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  onProceed: (booking: GetBooking) => void | Promise<void>;
  booking: GetBooking;
  numGuests: {
    adults: number;
    teenagers: number;
    children: number;
    infants: number;
  };
}

export const BookingChangeGuestsDialog = ({
  open,
  onOpenChange,
  onProceed,
  booking,
  numGuests,
}: Props) => {
  const { t } = useTranslation();
  const [priceOption, setPriceOption] = useState<"keep-price" | "adjust-price">(
    "keep-price",
  );

  useEffect(() => {
    setPriceOption("keep-price");
  }, [booking, open]);

  const {
    data: priceRules,
    isLoading: priceRulesIsLoading,
    isRefetching: priceRulesIsRefetching,
  } = useGetPriceRules({
    variables: {
      categoryTypes: [booking.category.type],
    },
    enabled: !!booking.category.type,
  });

  const handleProceed = () => {
    let adults = booking.guests.filter((g) => g.ageType === "adult");
    let teenagers = booking.guests.filter((g) => g.ageType === "teenager");
    let children = booking.guests.filter((g) => g.ageType === "child");
    let infants = booking.guests.filter((g) => g.ageType === "infant");

    if (numGuests.adults < adults.length) {
      adults.splice(-(adults.length - numGuests.adults));
    } else {
      for (let i = 0; i < numGuests.adults - adults.length; i++) {
        adults.push({
          id: "new" + uniqueId(),
          ageType: "adult",
          age: null,
          title: null,
          organization: null,
          start: booking.start,
          end: booking.end,
          name: null,
          surname: null,
          passportNumber: null,
          address: null,
          address2: null,
          city: null,
          state: null,
          zip: null,
          countryCode: null,
          phone: null,
          phone2: null,
          email: null,
          tags: [],
          bedPreference: getBedPreferenceFromType("adult"),
        });
      }
    }

    if (numGuests.teenagers < teenagers.length) {
      teenagers.splice(-(teenagers.length - numGuests.teenagers));
    } else {
      for (let i = 0; i < numGuests.teenagers - teenagers.length; i++) {
        teenagers.push({
          id: "new" + uniqueId(),
          ageType: "teenager",
          age: 14,
          title: null,
          organization: null,
          start: booking.start,
          end: booking.end,
          name: null,
          surname: null,
          passportNumber: null,
          address: null,
          address2: null,
          city: null,
          state: null,
          zip: null,
          countryCode: null,
          phone: null,
          phone2: null,
          email: null,
          tags: [],
          bedPreference: getBedPreferenceFromType("teenager"),
        });
      }
    }

    if (numGuests.children < children.length) {
      children.splice(-(children.length - numGuests.children));
    } else {
      for (let i = 0; i < numGuests.children - children.length; i++) {
        children.push({
          id: "new" + uniqueId(),
          ageType: "child",
          age: 7,
          title: null,
          organization: null,
          start: booking.start,
          end: booking.end,
          name: null,
          surname: null,
          passportNumber: null,
          address: null,
          address2: null,
          city: null,
          state: null,
          zip: null,
          countryCode: null,
          phone: null,
          phone2: null,
          email: null,
          tags: [],
          bedPreference: getBedPreferenceFromType("child"),
        });
      }
    }

    if (numGuests.infants < infants.length) {
      infants.splice(-(infants.length - numGuests.infants));
    } else {
      for (let i = 0; i < numGuests.infants - infants.length; i++) {
        infants.push({
          id: "new" + uniqueId(),
          ageType: "infant",
          age: 2,
          title: null,
          organization: null,
          start: booking.start,
          end: booking.end,
          name: null,
          surname: null,
          passportNumber: null,
          address: null,
          address2: null,
          city: null,
          state: null,
          zip: null,
          countryCode: null,
          phone: null,
          phone2: null,
          email: null,
          tags: [],
          bedPreference: getBedPreferenceFromType("infant"),
        });
      }
    }

    let slots = booking.slots.map((s) => {
      const priceRule = priceRules?.rules
        .find((r) => s.priceRuleId && r.id === s.priceRuleId)
        ?.entries.find((e) => e.categoryId === booking.category.id);
      const priceRuleOverride = priceRule?.overrides.find((o) => {
        const guestCounts = priceRuleOverrideKeyToGuestCounts(o.overrideKey);
        return (
          guestCounts.adults === numGuests.adults &&
          guestCounts.children === numGuests.children &&
          guestCounts.teenagers === numGuests.teenagers &&
          guestCounts.infants === numGuests.infants
        );
      });

      let fullPrice = priceRuleOverride?.price || priceRule?.price || 0;

      if (priceOption === "keep-price") {
        return {
          ...s,
          priceOverrideAmount: s.price,
          priceAdjustmentPercent: null,
          fullPrice,
        };
      } else {
        let price = s.priceOverrideAmount || fullPrice;

        if (s.priceAdjustmentPercent) {
          price = price * (1 - 0.01 * s.priceAdjustmentPercent);
        }
        return {
          ...s,
          price,
          fullPrice,
        };
      }
    });

    onProceed({
      ...booking,
      guests: [...adults, ...teenagers, ...children, ...infants],
      guestCount: numGuests,
      slots,
    });
    onOpenChange(false);
  };

  return (
    <>
      <Dialog open={open} onOpenChange={onOpenChange}>
        <DialogContent className=" h-fit w-fit max-w-fit">
          <div className=" flex h-full flex-col ">
            <DialogTitle className=" text-lg font-extrabold text-primary-text">
              {t("what-do-you-want-to-change?")}
            </DialogTitle>

            <div className=" mt-4">
              <RadioGroup
                className="mb-4"
                value={priceOption}
                onValueChange={(v) =>
                  setPriceOption(v as "keep-price" | "adjust-price")
                }
              >
                <div className="flex items-center space-x-2">
                  <RadioGroupItem value="keep-price" id="keep-price" />
                  <Label htmlFor="keep-price">{t("keep-original-price")}</Label>
                </div>
                <div className="mt-2 flex items-center space-x-2">
                  <RadioGroupItem value="adjust-price" id="adjust-price" />
                  <Label htmlFor="adjust-price">
                    {t("adjust-price-according-to-new-number-of-guests")}
                  </Label>
                </div>
              </RadioGroup>
            </div>

            <div className=" mt-4 flex justify-end space-x-2">
              <Button variant="outline" onClick={() => onOpenChange(false)}>
                {t("cancel")}
              </Button>
              <Button variant="primary" onClick={handleProceed}>
                {t("apply")}
              </Button>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default BookingChangeGuestsDialog;
