import { useTranslation } from "react-i18next";
import { Dialog, DialogContent, DialogTitle } from "@primitives/dialog";
import { Button } from "@primitives/button";
import { ReservationState } from "../../../../../../../api-contracts/reservations";
import { useGetReservationStatuses } from "@api/statuses";
import { Loading } from "@primitives/loading";
import { Loader2 } from "lucide-react";

interface Props {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  onProceed: (state: ReservationState) => void | Promise<void>;
}

export const ConfirmReservationDialog = ({
  open,
  onOpenChange,
  onProceed,
}: Props) => {
  const { t } = useTranslation();

  const {
    data: statuses,
    isLoading: statusesLoading,
    isRefetching: statusesRefetching,
  } = useGetReservationStatuses({});

  const handleUpdateState = async (
    state: "confirmed" | "unconfirmed" | "preliminary" | "guaranteed",
  ) => {
    onOpenChange(false);
    onProceed(state);
  };

  return (
    <>
      <Dialog open={open} onOpenChange={onOpenChange}>
        <DialogContent className=" h-fit w-fit max-w-fit">
          <div className="flex h-full flex-col">
            <DialogTitle className=" text-lg font-extrabold text-primary-text">
              {t("save-reservation-as")}
            </DialogTitle>

            <div className=" mt-4 flex space-x-2">
              <Button variant="outline" onClick={() => onOpenChange(false)}>
                {t("cancel")}
              </Button>
              {statusesLoading || statusesRefetching ? (
                <Loader2
                  className="animate-spin text-primary-color"
                  size={40}
                />
              ) : (
                <>
                  {statuses?.unconfirmed && (
                    <Button
                      variant="primary"
                      onClick={() => handleUpdateState("unconfirmed")}
                    >
                      {t("unconfirmed")}
                    </Button>
                  )}
                  {statuses?.confirmed && (
                    <Button
                      variant="primary"
                      onClick={() => handleUpdateState("confirmed")}
                    >
                      {t("confirmed")}
                    </Button>
                  )}
                  {statuses?.guaranteed && (
                    <Button
                      variant="primary"
                      onClick={() => handleUpdateState("guaranteed")}
                    >
                      {t("guaranteed")}
                    </Button>
                  )}
                  {statuses?.preliminary && (
                    <Button
                      variant="primary"
                      onClick={() => handleUpdateState("preliminary")}
                    >
                      {t("preliminary")}
                    </Button>
                  )}
                </>
              )}
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ConfirmReservationDialog;
