import { Card } from "@primitives/card";
import { FireListCardHeader } from "./components/fire-list-card-header";
import { FireListTableContainer } from "./components/fire-list-table-container";
import { useGetFireList } from "@api/reception";
import { useEffect } from "react";
import { queryClient } from "query-client";

const FireListPage = () => {
  useEffect(() => {
    queryClient.invalidateQueries({ queryKey: useGetFireList.getKey() });
  }, []);
  return (
    <div className="flex h-full w-full flex-col p-4">
      <Card className="mt-4 flex-grow p-0">
        <FireListCardHeader />
        <FireListTableContainer />
      </Card>
    </div>
  );
};

export default FireListPage;
