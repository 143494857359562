import { useTranslation } from "react-i18next";
import {
  Sheet,
  SheetContent,
  SheetHeader,
  SheetTitle,
} from "@primitives/sheet";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@primitives/accordion";
import { format } from "date-fns";
import { GetReservation } from "../../../../../../../api-contracts/reservations";
import { cn } from "@shared/utils/css";
import { Mail, MailOpen, MessageSquare } from "lucide-react";

interface Props {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  reservation: GetReservation;
}

export const ReservationCommunicationDrawer = ({
  open,
  onOpenChange,
  reservation,
}: Props) => {
  const { t } = useTranslation();

  return (
    <>
      <Sheet open={open} onOpenChange={onOpenChange}>
        <SheetContent
          side="left"
          className="flex w-[400px] flex-col overflow-y-auto text-primary-text sm:w-[600px]"
        >
          <SheetHeader>
            <SheetTitle className=" flex items-center justify-between text-primary-text">
              {t("communication")}
            </SheetTitle>
          </SheetHeader>

          <div className=" h-full overflow-y-auto">
            <Accordion type="single" collapsible>
              {reservation.messages.map((m, i) => (
                <AccordionItem key={i} value="item-1" className=" border-none">
                  <AccordionTrigger className=" h-14 py-0 ">
                    <div className=" flex w-full items-center justify-between">
                      <div className=" relative flex h-full flex-nowrap items-center">
                        <div
                          className={cn(
                            " absolute left-[93px] w-[2px] bg-secondary-text",
                            {
                              " top-1/2 h-[28px]":
                                i === 0 && reservation.messages.length > 1,
                              " h-[56px]":
                                i > 0 &&
                                i < reservation.messages.length - 1 &&
                                reservation.messages.length > 1,
                              "bottom-1/2 h-[28px] ":
                                i === reservation.messages.length - 1 &&
                                reservation.messages.length > 1,
                            },
                          )}
                        />
                        <div className="w-[90px] text-xs font-medium ">
                          {format(m.sentAt, "yyyy-MM-dd")}
                        </div>
                        <div className=" h-2 w-2 rounded-full bg-primary-text" />
                        <div className=" mx-2 text-xs font-medium">
                          {format(m.sentAt, "HH:mm")}
                        </div>
                      </div>
                      <div className=" flex flex-nowrap items-center">
                        {m.channel === "email" && <MailOpen size={14} />}
                        {m.channel === "sms" && <MessageSquare size={14} />}
                        <div className=" mx-2 text-xs font-extrabold">
                          {t(m.channel)}
                        </div>
                      </div>
                    </div>
                  </AccordionTrigger>
                  <AccordionContent>
                    <div className=" rounded-md border-2 border-primary-text p-4 ">
                      <div className=" flex items-center justify-between">
                        <p className=" text-sm font-extrabold">
                          {t("recipient")}
                        </p>
                        <p className=" text-sm font-normal text-secondary-text">
                          {" "}
                          {m.recipient}
                        </p>
                      </div>

                      <div className=" mt-2 flex items-center justify-between">
                        <p className=" text-sm font-extrabold">{t("status")}</p>
                        {m.status === "sent" && (
                          <p className="bg-status-success-100 p-2 text-xs font-extrabold text-status-success">
                            {t(m.status)}
                          </p>
                        )}
                        {m.status === "failed" && (
                          <p className="bg-status-error-100 p-2 text-xs font-extrabold text-status-error">
                            {t(m.status)}
                          </p>
                        )}
                      </div>

                      <div className=" mt-2">
                        <p className=" text-sm font-extrabold">
                          {t("message")}
                        </p>
                        <p className="mt-2 text-sm font-normal">{m.message}</p>
                      </div>
                    </div>
                  </AccordionContent>
                </AccordionItem>
              ))}
            </Accordion>
            {!reservation.messages?.length && (
              <div className=" p-2 text-sm font-normal">
                <p>{t("no-communication-found")}</p>
              </div>
            )}
          </div>
        </SheetContent>
      </Sheet>
    </>
  );
};

export default ReservationCommunicationDrawer;
