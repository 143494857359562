import {
  useCreateCommunicationSphere,
  useGetCommunicationSpheres,
} from "@api/communication-spheres";
import { useProfileContext } from "@context/profile-context";
import { Button } from "@primitives/button";
import { Card, CardTitle } from "@primitives/card";
import { CheckboxWithLabel } from "@primitives/checkbox-with-label";
import {
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuRoot,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@primitives/dropdown-menu";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@primitives/table";
import { CaretSortIcon } from "@radix-ui/react-icons";
import { ROUTES } from "@shared/types/navigation";
import {
  ColumnDef,
  SortingState,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import {
  CircleCheck,
  CircleOff,
  Copy,
  Ellipsis,
  Filter,
  History,
  Loader2,
  Trash,
  TriangleAlert,
} from "lucide-react";
import { queryClient } from "query-client";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { GetCommunicationSphere } from "../../../../../api-contracts/communication-spheres";
import { useToast } from "@hooks/use-toast";
import { cn } from "@shared/utils/css";

const CommunicationSpheresPage = () => {
  const { t } = useTranslation();
  const [sorting, setSorting] = useState<SortingState>([]);
  const [createLoading, setCreateLoading] = useState<boolean>(false);
  const [includedStatus, setIncludedStatus] = useState<
    ("active" | "default" | "inactive")[]
  >(["active", "default", "inactive"]);
  const navigate = useNavigate();
  const { module } = useProfileContext();
  const { toast } = useToast();
  const createSphere = useCreateCommunicationSphere();

  useEffect(() => {
    queryClient.invalidateQueries({
      queryKey: useGetCommunicationSpheres.getKey(),
    });
  }, []);

  const { data, isLoading, isRefetching } = useGetCommunicationSpheres({});

  const visibleSpheres = useMemo(() => {
    return (
      data?.filter(
        (s) =>
          (s.status.active
            ? includedStatus.includes("active")
            : includedStatus.includes("inactive")) ||
          (s.status.default && includedStatus.includes("default")),
      ) ?? []
    );
  }, [data, includedStatus]);

  const columns: ColumnDef<GetCommunicationSphere>[] = [];

  columns.push({
    cell: (row) => row.row.original.name,
    accessorKey: "name",
    header: ({ column }) => {
      return (
        <div
          className="flex cursor-pointer items-center"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          {t("name")}
          <CaretSortIcon className="ml-2 h-4 w-4" />
        </div>
      );
    },
    size: 1,
    id: "name",
  });

  columns.push({
    cell: (row) => (
      <div className=" flex flex-nowrap space-x-2">
        {!row.row.original.status.active && (
          <p className=" rounded-lg bg-status-error-100 p-2 text-xs font-extrabold text-status-error">
            {t("inactive")}
          </p>
        )}
        {row.row.original.status.active && (
          <p className=" rounded-lg bg-status-success-100 p-2 text-xs font-extrabold text-status-success">
            {t("active")}
          </p>
        )}
        {row.row.original.status.default && (
          <p className=" rounded-lg bg-secondary-card-backplate p-2 text-xs font-extrabold text-primary-text">
            {t("default")}
          </p>
        )}
      </div>
    ),
    accessorFn: (row: GetCommunicationSphere) =>
      [
        row.status.active ? "active" : "inactive",
        ...(row.status.default ? ["default"] : []),
      ].join(" "),
    header: ({ column }) => {
      return (
        <div
          className="flex cursor-pointer items-center"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          {t("status")}
          <CaretSortIcon className="ml-2 h-4 w-4" />
        </div>
      );
    },
    size: 1,
    id: "status",
  });

  columns.push({
    cell: (row) => (
      <div className=" flex flex-nowrap space-x-2">
        {row.row.original.languages.map((l) => (
          <div
            key={l.code}
            className={cn(
              " flex flex-nowrap items-center space-x-2 rounded-lg bg-secondary-card-backplate p-2 text-xs font-extrabold text-primary-text",
              {
                " bg-status-warning-100 text-status-warning": !l.filled,
              },
            )}
          >
            <p>{l.short}</p>
            {!l.filled && <TriangleAlert size={14} />}
          </div>
        ))}
      </div>
    ),
    accessorFn: (row: GetCommunicationSphere) =>
      row.languages?.map((l) => l.short).join(" "),
    header: ({ column }) => {
      return (
        <div
          className="flex cursor-pointer items-center"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          {t("languages")}
          <CaretSortIcon className="ml-2 h-4 w-4" />
        </div>
      );
    },
    size: 1,
    id: "languages",
  });

  columns.push({
    cell: (row) => (
      <div className=" flex flex-nowrap space-x-2">
        {row.row.original.triggers.map((t) => (
          <p
            key={t.id}
            className=" rounded-lg bg-secondary-card-backplate p-2 text-xs font-extrabold text-primary-text"
          >
            {t.name}
          </p>
        ))}
      </div>
    ),
    accessorFn: (row: GetCommunicationSphere) =>
      row.triggers?.map((t) => t.name).join(" "),
    header: ({ column }) => {
      return (
        <div
          className="flex cursor-pointer items-center"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          {t("triggers")}
          <CaretSortIcon className="ml-2 h-4 w-4" />
        </div>
      );
    },
    size: 1,
    id: "triggers",
  });

  columns.push({
    maxSize: 40,
    cell: (row) => {
      return (
        <DropdownMenuRoot>
          <DropdownMenuTrigger className="w-full">
            <div className=" flex justify-end">
              <Ellipsis size={18} />
            </div>
          </DropdownMenuTrigger>
          <DropdownMenuContent>
            <DropdownMenuItem
              className=" cursor-pointer"
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <div className=" flex flex-nowrap items-center space-x-2">
                <Copy size={16} />
                <p className="text-sm font-medium ">{t("duplicate")}</p>
              </div>
            </DropdownMenuItem>
            <DropdownMenuItem
              className=" cursor-pointer"
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              {row.row.original.status.active ? (
                <div className=" flex flex-nowrap items-center space-x-2">
                  <CircleOff size={16} />
                  <p className="text-sm font-medium ">{t("inactivate")}</p>
                </div>
              ) : (
                <div className=" flex flex-nowrap items-center space-x-2">
                  <CircleCheck size={16} />
                  <p className="text-sm font-medium ">{t("activate")}</p>
                </div>
              )}
            </DropdownMenuItem>
            <DropdownMenuItem
              className=" cursor-pointer"
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <div className=" flex flex-nowrap items-center space-x-2">
                <History size={16} />
                <p className="text-sm font-medium ">{t("show-history")}</p>
              </div>
            </DropdownMenuItem>
            <DropdownMenuItem
              className=" cursor-pointer"
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <div className=" flex flex-nowrap items-center space-x-2 text-status-error">
                <Trash size={16} />
                <p className="text-sm font-medium ">{t("remove")}</p>
              </div>
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenuRoot>
      );
    },
    header: "",
    id: "actions",
    size: 1,
  });

  const table = useReactTable({
    data: visibleSpheres,
    columns,
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    state: {
      sorting,
    },
  });

  const handleCreateNewSphere = async () => {
    try {
      setCreateLoading(true);
      const response = await createSphere.mutateAsync({
        name: t("new-communication-sphere"),
        triggers: [],
        active: false,
        default: false,
      });

      toast({
        title: t("saved-succesfully", { name: t("new-communication-sphere") }),
        className: "text-status-success",
        variant: "success",
      });

      navigate(`/${module}/${ROUTES.COMMUNICATION_SPHERES}/${response.id}`);
    } catch (err) {
      toast({
        title:
          t("request-failed-with") +
          ": " +
          t(decodeURIComponent((err as any)?.message || t("no-message"))),
        variant: "destructive",
        className: "text-status-error",
      });
    }
    setCreateLoading(false);
  };

  return (
    <>
      <div className="p-4 text-primary-text">
        <Card className="p-0">
          <div className="flex items-center justify-between border-b border-highlighted-backplate p-4">
            <CardTitle>{t("communication-spheres")}</CardTitle>
            <div className=" flex flex-nowrap space-x-2">
              <Button
                variant="primary"
                onClick={handleCreateNewSphere}
                loading={createLoading}
              >
                {t("create-new")}
              </Button>
            </div>
          </div>

          <div className="p-4">
            <div className="flex items-center justify-end">
              <DropdownMenuRoot>
                <DropdownMenuTrigger asChild>
                  <Button variant="secondary">
                    <Filter className="mr-2" size={17} />
                    {t("show")}
                  </Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent className=" w-[220px]">
                  <DropdownMenuLabel className="ml-5">
                    {t("status")}
                  </DropdownMenuLabel>
                  <DropdownMenuSeparator />
                  <div className=" flex h-[32px] items-center px-2">
                    <CheckboxWithLabel
                      checked={includedStatus.includes("default")}
                      onCheckedChange={(v) => {
                        if (v) {
                          setIncludedStatus((included) => [
                            ...included,
                            "default",
                          ]);
                        } else {
                          setIncludedStatus((included) =>
                            included.filter((s) => s !== "default"),
                          );
                        }
                      }}
                      label={t("default")}
                    />
                  </div>
                  <div className=" flex h-[32px] items-center px-2">
                    <CheckboxWithLabel
                      checked={includedStatus.includes("active")}
                      onCheckedChange={(v) => {
                        if (v) {
                          setIncludedStatus((included) => [
                            ...included,
                            "active",
                          ]);
                        } else {
                          setIncludedStatus((included) =>
                            included.filter((s) => s !== "active"),
                          );
                        }
                      }}
                      label={t("active")}
                    />
                  </div>
                  <div className=" flex h-[32px] items-center px-2">
                    <CheckboxWithLabel
                      checked={includedStatus.includes("inactive")}
                      onCheckedChange={(v) => {
                        if (v) {
                          setIncludedStatus((included) => [
                            ...included,
                            "inactive",
                          ]);
                        } else {
                          setIncludedStatus((included) =>
                            included.filter((s) => s !== "inactive"),
                          );
                        }
                      }}
                      label={t("inactive")}
                    />
                  </div>
                </DropdownMenuContent>
              </DropdownMenuRoot>
            </div>
            <div className="relative mt-4">
              {(isLoading || isRefetching) && (
                <div className="absolute z-20 flex h-full w-full items-center justify-center backdrop-blur-sm">
                  <Loader2 className="h-20 w-20 animate-spin text-primary-color" />
                </div>
              )}

              <Table>
                <TableHeader>
                  {table.getHeaderGroups().map((headerGroup) => (
                    <TableRow key={headerGroup.id}>
                      {headerGroup.headers.map((header) => {
                        return (
                          <TableHead key={header.id}>
                            {header.isPlaceholder
                              ? null
                              : flexRender(
                                  header.column.columnDef.header,
                                  header.getContext(),
                                )}
                          </TableHead>
                        );
                      })}
                    </TableRow>
                  ))}
                </TableHeader>

                <TableBody>
                  {table.getRowModel().rows?.length ? (
                    table.getRowModel().rows.map((row) => (
                      <TableRow
                        key={row.id}
                        className=" cursor-pointer"
                        onClick={() =>
                          navigate(
                            `/${module}/${ROUTES.COMMUNICATION_SPHERES}/${row.original.id}`,
                          )
                        }
                        data-state={row.getIsSelected() && "selected"}
                      >
                        {row.getVisibleCells().map((cell) => (
                          <TableCell key={cell.id}>
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext(),
                            )}
                          </TableCell>
                        ))}
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell
                        colSpan={columns.length}
                        className="h-24 text-center text-primary-text"
                      >
                        {t("no-results")}.
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </div>
          </div>
        </Card>
      </div>
    </>
  );
};

export default CommunicationSpheresPage;
