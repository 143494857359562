import { NavItem } from "../main-nav";

export type SearchNavItem = Omit<NavItem, "icon">;

export type RecentItem =
  | {
      type: "pages";
      pages: SearchNavItem[];
    }
  | {
      type: "reservation";
      reservationId: string;
      matchedEntry: string;
      matchedType: string;
      start: string;
      end: string;
    }
  | {
      type: "searchTerm";
      searchTerm: string;
    };

const RECENT_SEARCH_KEY = "recent_search";

export const getRecentSearch = (): RecentItem[] => {
  const storedClicks = localStorage.getItem(RECENT_SEARCH_KEY);
  return storedClicks ? JSON.parse(storedClicks) : [];
};

const isSameSearchItem = (item: RecentItem, searchItem: RecentItem) => {
  if (item.type !== searchItem.type) return false;

  switch (searchItem.type) {
    case "pages":
      return (
        item.type === "pages" &&
        JSON.stringify(searchItem.pages) === JSON.stringify(item.pages)
      );
    case "reservation":
      return (
        item.type === "reservation" &&
        searchItem.reservationId === item.reservationId
      );
    case "searchTerm":
      return (
        item.type === "searchTerm" && searchItem.searchTerm === item.searchTerm
      );
    default:
      return false;
  }
};

export const saveRecentSearch = (searchItem: RecentItem) => {
  const recentResults = getRecentSearch();

  const updatedRecentResults = [
    searchItem,
    ...recentResults.filter((item) => !isSameSearchItem(item, searchItem)),
  ].slice(0, 5);

  localStorage.setItem(RECENT_SEARCH_KEY, JSON.stringify(updatedRecentResults));
};

export const removeRecentSearch = (searchItem: RecentItem) => {
  const recentResults = getRecentSearch();

  const updatedRecentResults = recentResults.filter(
    (item) => !isSameSearchItem(item, searchItem),
  );

  localStorage.setItem(RECENT_SEARCH_KEY, JSON.stringify(updatedRecentResults));
};
