import { CardTitle } from "@primitives/card";
import { useTranslation } from "react-i18next";
import { CardHeaderTabs } from "./card-header-tabs";

export const CardHeader = ({ searchTerm }: { searchTerm: string }) => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col pb-4">
      <div className="flex items-center pb-2">
        <CardTitle className="mr-4">{t("search-results")}</CardTitle>
        <CardHeaderTabs />
      </div>
      <span className="font-r text-sm text-secondary-text">
        {t("showing-search-results-for")}
        <span className="text-primary-text"> {searchTerm}</span>
      </span>
    </div>
  );
};
