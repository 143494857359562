import { AlertDialog } from "@primitives/alert-dialog.tsx";
import { Input } from "@primitives/input.tsx";
import {
  CategoryClusterContext,
  ClusterCategory,
} from "@pages/settings/category-cluster/category-cluster-context.tsx";
import { CategoryCard } from "@pages/settings/category-cluster/components/category-card.tsx";
import React, { FC, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { CamprType } from "../../../../../../api-contracts/categories";
import {
  CreateCategoryClusterRequest,
  CreateCategoryClusterResponse,
} from "../../../../../../api-contracts/category-clusters";
import { queryClient } from "../../../../query-client.ts";
import {
  useCreateCluster,
  useGetCategoryClusters,
} from "@api/category-clusters.ts";
import { toast } from "@hooks/use-toast.ts";

interface Props {
  open: boolean;
  onOpenChange: (open: boolean) => void;
}

export const CreateClusterDialog: FC<Props> = ({ open, onOpenChange }) => {
  const { clusterData } = useContext(CategoryClusterContext);
  const { mutateAsync: createCluster } = useCreateCluster();
  const { t } = useTranslation();
  const [clusterName, setClusterName] = useState("");

  const createClusters = async () => {
    const types: CamprType[] = clusterData.categoryTypes
      .filter((cat: ClusterCategory) => {
        return cat.selected;
      })
      .map((cat: ClusterCategory) => {
        return cat.category;
      });
    const cluster: CreateCategoryClusterRequest = {
      name: clusterName.toLowerCase(),
      categoryTypes: types,
    };
    try {
      await createCluster(cluster).then();
      await queryClient.invalidateQueries({
        queryKey: useGetCategoryClusters.getKey(),
      });
    } catch (error) {
      toast({
        title: t("request-failed-with"),
        description: t(
          decodeURIComponent(
            (error instanceof Error && error.message) || t("no-message"),
          ),
        ),
        variant: "destructive",
      });
    }
  };

  useEffect(() => {
    const selectedCat = clusterData?.categoryTypes.filter(
      (category: ClusterCategory) => {
        if (category.selected) return category.category;
      },
    );
  }, [clusterData]);

  return (
    <AlertDialog.Root open={open} onOpenChange={onOpenChange}>
      <AlertDialog.Content className="z-[2001]">
        <AlertDialog.Header>
          <AlertDialog.Title>{t("create-new-cluster")}</AlertDialog.Title>
          <AlertDialog.Description>
            <Input
              className={
                "font-neue text-[15px] font-normal text-secondary-text"
              }
              type={"text"}
              value={clusterName}
              onChange={(e) => setClusterName(e.target.value)}
              placeholder={"Name"}
            />
            <div className={"mb-4 mt-4 flex"}>
              {clusterData?.categoryTypes.map((category: ClusterCategory) => {
                if (category.selected)
                  return (
                    <CategoryCard
                      key={category.category}
                      category={category}
                      showCheck={false}
                    />
                  );
              })}
            </div>
          </AlertDialog.Description>
        </AlertDialog.Header>
        <AlertDialog.Footer className="relative">
          <AlertDialog.Cancel onClick={() => setClusterName("")}>
            {t("cancel")}
          </AlertDialog.Cancel>

          <AlertDialog.Action
            disabled={clusterName.length === 0}
            onClick={() => {
              createClusters().then();
              setClusterName("");
            }}
          >
            {t("save")}
          </AlertDialog.Action>
        </AlertDialog.Footer>
      </AlertDialog.Content>
    </AlertDialog.Root>
  );
};
