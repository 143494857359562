import React, {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import {
  Bridge,
  BridgeDevice,
  CheckBridgeCodeResponse,
  ConnectNewBridgeResponse,
  CreateBridgeDeviceRequest,
  CreateBridgeDeviceResponse,
  CreateBridgeRequest,
  CreateBridgeResponse,
  GetBridgesResponse,
  UpdateBridgeDeviceRequest,
  UpdateBridgeDeviceResponse,
  UpdateBridgeRequest,
  UpdateBridgeResponse,
} from "../../../../../api-contracts/bridges";
import {
  useCheckCodeCommand,
  useConnectBridgeCommand,
  useCreateDeviceCommand,
  useDeleteBridgeCommand,
  useDeleteDeviceCommand,
  useGetBridges,
  useReconnectBridgeCommand,
  useSendPrintCommand,
  useUpdateBridgeCommand,
  useUpdateDeviceCommand,
} from "@api/bridges.ts";
import { Loading } from "@primitives/loading.tsx";
import { queryClient } from "../../../query-client.ts";

interface BridgesContextProps {
  bridges?: GetBridgesResponse;
  selectedBridge?: Bridge | null;
  setSelectedBridge: (bridge: Bridge | null) => void;
  selectedDevice: BridgeDevice | null;
  setSelectedDevice: (device: BridgeDevice | null) => void;
  sendPrintCommand: (bridgeId: string, deviceId: string) => void;
  sheetState: BridgeSheetState;
  setSheetState: Dispatch<SetStateAction<BridgeSheetState>>;
  sendCheckCodeCommand: (
    code: string,
    onSuccess: (res: CheckBridgeCodeResponse) => void,
  ) => void;
  sendConnectBridgeCommand: (
    payload: CreateBridgeRequest,
    onSuccess: (res: CreateBridgeResponse) => void,
  ) => void;
  sendReconnectBridgeCommand: (
    bridgeId: string,
    code: string,
    onSuccess: (res: ConnectNewBridgeResponse) => void,
  ) => void;
  sendUpdateBridgeCommand: (
    bridgeId: string,
    payload: UpdateBridgeRequest,
    onSuccess: (res: UpdateBridgeResponse) => void,
  ) => void;
  sendDeleteBridgeCommand: (bridgeId: string) => void;
  sendCreateDeviceCommand: (
    bridgeId: string,
    payload: CreateBridgeDeviceRequest,
    onSuccess: (res: CreateBridgeDeviceResponse) => void,
  ) => void;
  sendUpdateDeviceCommand: (
    bridgeId: string,
    deviceId: string,
    payload: UpdateBridgeDeviceRequest,
    onSuccess: (res: UpdateBridgeDeviceResponse) => void,
  ) => void;
  sendDeleteDeviceCommand: (bridgeId: string, deviceId: string) => void;
}

export enum BridgeDeviceType {
  PRINTER = "PRINTER",
  CONTROL_UNIT = "CONTROL_UNIT",
  PAYMENT = "PAYMENT",
}

export const AllBridgeDeviceTypes = [
  { value: BridgeDeviceType.PRINTER, prettyName: "Printer" },
  { value: BridgeDeviceType.CONTROL_UNIT, prettyName: "Control Unit" },
  { value: BridgeDeviceType.PAYMENT, prettyName: "Payment" },
];

export enum BridgeSheetState {
  CLOSED,
  ADD_BRIDGE,
  UPDATE_BRIDGE,
  MANAGE_DEVICE,
}

export const BridgesContext = createContext<BridgesContextProps>(
  undefined as unknown as BridgesContextProps,
);

export const useBridgesPageContext = () => {
  const context = useContext(BridgesContext);

  if (!context) {
    throw new Error("context must be used in correct provider");
  }

  return context;
};

interface BridgesPageProviderProps {
  children: ReactNode;
}

export const BridgesProvider: React.FC<BridgesPageProviderProps> = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [isLoading, setLoading] = useState<boolean>(false);
  useEffect(() => {
    queryClient.invalidateQueries({
      queryKey: useGetBridges.getKey(),
    });
  }, []);
  const { data: bridges, isLoading: fetchLoader } = useGetBridges();
  const [selectedBridge, setSelectedBridge] = useState<Bridge | null>(null);
  const [selectedDevice, setSelectedDevice] = useState<BridgeDevice | null>(
    null,
  );
  const { mutate: mutatePrintCommand } = useSendPrintCommand();
  const { mutate: mutateCheckCodeCommand } = useCheckCodeCommand();
  const { mutate: mutateConnectBridgeCommand } = useConnectBridgeCommand();
  const { mutate: mutateReconnectBridgeCommand } = useReconnectBridgeCommand();
  const { mutate: mutateUpdateBridgeCommand } = useUpdateBridgeCommand();
  const { mutate: mutateDeleteBridgeCommand } = useDeleteBridgeCommand();
  const { mutate: mutateCreateDeviceCommand } = useCreateDeviceCommand();
  const { mutate: mutateUpdateDeviceCommand } = useUpdateDeviceCommand();
  const { mutate: mutateDeleteDeviceCommand } = useDeleteDeviceCommand();
  const [sheetState, setSheetState] = useState(BridgeSheetState.CLOSED);

  useEffect(() => {
    setLoading(fetchLoader);
  }, [fetchLoader]);

  const sendPrintCommand = (bridgeId: string, deviceId: string) =>
    mutatePrintCommand(
      {
        bridgeId,
        deviceId,
      },
      {
        onSuccess: () => console.log("Should print success to toaster here"),
        onError: () => console.log("Should print error to toaster here"),
      },
    );

  const sendCheckCodeCommand = (
    code: string,
    onSuccess: (res: CheckBridgeCodeResponse) => void,
  ) =>
    mutateCheckCodeCommand(
      { code },
      {
        onSuccess,
        onError: () =>
          console.log("Should print check code error to toaster here"),
      },
    );

  const sendConnectBridgeCommand = (
    payload: CreateBridgeRequest,
    onSuccess: (res: CreateBridgeResponse) => void,
  ) =>
    mutateConnectBridgeCommand(
      { payload },
      {
        onSuccess,
        onError: () => console.log("Should print error to toaster here"),
      },
    );

  const sendReconnectBridgeCommand = (
    bridgeId: string,
    code: string,
    onSuccess: (res: ConnectNewBridgeResponse) => void,
  ) =>
    mutateReconnectBridgeCommand(
      { bridgeId, code },
      {
        onSuccess,
        onError: () =>
          console.log("Should print reconnect bridge error to toaster here"),
      },
    );

  const sendUpdateBridgeCommand = (
    bridgeId: string,
    payload: UpdateBridgeRequest,
    onSuccess: (res: UpdateBridgeResponse) => void,
  ) =>
    mutateUpdateBridgeCommand(
      { bridgeId, payload },
      {
        onSuccess: (res) => {
          queryClient.invalidateQueries({
            queryKey: useGetBridges.getKey(),
          });
          onSuccess(res);
        },
        onError: () => console.log("Should print error to toaster here"),
      },
    );

  const sendDeleteBridgeCommand = (bridgeId: string) => {
    setLoading(true);

    mutateDeleteBridgeCommand(
      { bridgeId },
      {
        onError: () => console.log("Should print error to toaster here"),
        onSuccess: () => {
          queryClient.invalidateQueries({
            queryKey: useGetBridges.getKey(),
          });
        },
        onSettled: () => {
          setLoading(false);
        },
      },
    );
  };

  const sendCreateDeviceCommand = (
    bridgeId: string,
    payload: CreateBridgeDeviceRequest,
    onSuccess: (res: CreateBridgeDeviceResponse) => void,
  ) =>
    mutateCreateDeviceCommand(
      { bridgeId, payload },
      {
        onSuccess: (res) => {
          queryClient.invalidateQueries({
            queryKey: useGetBridges.getKey(),
          });
          onSuccess(res);
        },
        onError: () => console.log("Should print error to toaster here"),
      },
    );

  const sendUpdateDeviceCommand = (
    bridgeId: string,
    deviceId: string,
    payload: UpdateBridgeDeviceRequest,
    onSuccess: (res: UpdateBridgeDeviceResponse) => void,
  ) =>
    mutateUpdateDeviceCommand(
      { bridgeId, deviceId, payload },
      {
        onSuccess: (res) => {
          queryClient.invalidateQueries({
            queryKey: useGetBridges.getKey(),
          });
          onSuccess(res);
        },
        onError: () => console.log("Should print error to toaster here"),
      },
    );

  const sendDeleteDeviceCommand = (bridgeId: string, deviceId: string) =>
    mutateDeleteDeviceCommand(
      { bridgeId, deviceId },
      {
        onSuccess: () => {
          queryClient.invalidateQueries({
            queryKey: useGetBridges.getKey(),
          });
        },
        onError: () => console.log("Should print error to toaster here"),
      },
    );

  return (
    <BridgesContext.Provider
      value={{
        bridges,
        selectedBridge,
        setSelectedBridge,
        selectedDevice,
        setSelectedDevice,
        sendPrintCommand,
        sheetState,
        setSheetState,
        sendCheckCodeCommand,
        sendReconnectBridgeCommand,
        sendConnectBridgeCommand,
        sendUpdateBridgeCommand,
        sendDeleteBridgeCommand,
        sendCreateDeviceCommand,
        sendUpdateDeviceCommand,
        sendDeleteDeviceCommand,
      }}
    >
      {sheetState !== BridgeSheetState.CLOSED && (
        <div className="backdrop fixed inset-0 z-[100] flex items-center justify-center overflow-y-auto overflow-x-hidden bg-black/75 outline-none focus:outline-none" />
      )}
      <div className="relative z-0 h-full space-y-4 overflow-y-auto">
        {isLoading && <Loading className={"p0"} />}
        {children}
      </div>
    </BridgesContext.Provider>
  );
};
