import { Row, SortingFn } from "@tanstack/react-table";
import {
  Category,
  CategoryCapacity,
} from "../../../../../api-contracts/categories";
import { useTranslation } from "react-i18next";

type Capacity = {
  adults: {
    min: number;
    max: number;
  };
  children: {
    max: number;
  };
  teenagers: {
    max: number;
  };
  infants: {
    max: number;
  };
  total: {
    max: number;
  };
};

type DescriptionValue =
  | {
      internal: string | null;
    }
  | string;

interface CategoryOverride {
  adults: number;
  teenagers: number;
  children: number;
  infants: number;
}

export const formatCapacityShort = (
  capacity: Capacity,
  language: string = "en",
): string => {
  const acceptedLanguage = language === "sv-se" ? "sv-se" : "en";

  const labels = {
    "sv-se": { adults: "V", teenagers: "T", children: "B", infants: "S" },
    en: { adults: "A", teenagers: "T", children: "C", infants: "I" },
  };

  const parts: string[] = [];

  if (capacity.adults.max > 0) {
    parts.push(`${capacity.adults.max}${labels[acceptedLanguage].adults}`);
  }

  if (capacity.teenagers.max > 0) {
    parts.push(
      `${capacity.teenagers.max}${labels[acceptedLanguage].teenagers}`,
    );
  }

  if (capacity.children.max > 0) {
    parts.push(`${capacity.children.max}${labels[acceptedLanguage].children}`);
  }

  if (capacity.infants.max > 0) {
    parts.push(`${capacity.infants.max}${labels[acceptedLanguage].infants}`);
  }

  return parts.join(" / ");
};

export const formatCapacityLong = (
  capacity: CategoryOverride,
  language: string = "sv-se",
): string => {
  const acceptedLanguage = language === "sv-se" ? "sv-se" : "en";

  const labels = {
    "sv-se": {
      adults: "vuxen",
      teenagers: "tonåring",
      children: "barn",
      infants: "spädbarn",
    },
    en: {
      adults: "adult",
      teenagers: "teenager",
      children: "child",
      infants: "infant",
    },
  };

  const parts: string[] = [];

  if (capacity.adults > 0) {
    parts.push(`${capacity.adults} ${labels[acceptedLanguage].adults}`);
  }

  if (capacity.teenagers > 0) {
    parts.push(`${capacity.teenagers} ${labels[acceptedLanguage].teenagers}`);
  }

  if (capacity.children > 0) {
    parts.push(`${capacity.children} ${labels[acceptedLanguage].children}`);
  }

  if (capacity.infants > 0) {
    parts.push(`${capacity.infants} ${labels[acceptedLanguage].infants}`);
  }

  return parts.join(" + ");
};

/** Swedish currency atm */
export const formatPrice = (price: number) => {
  const svString = (
    <p>
      {price} <span className="text-secondary-text">SEK</span>
    </p>
  );
  return svString;
};

export const filterMinMaxCapacity = (
  categories: Category[],
): { minCapacity: number; maxCapacity: number } => {
  if (categories && categories.length > 0) {
    let minCapacity = Infinity;
    let maxCapacity = -Infinity;

    categories.forEach((category) => {
      const totalMaxCapacity = category.capacity.total.max;
      minCapacity = Math.min(minCapacity, totalMaxCapacity);
      maxCapacity = Math.max(maxCapacity, totalMaxCapacity);
    });

    return { minCapacity, maxCapacity };
  }

  return { minCapacity: 0, maxCapacity: 1 };
};

export const filterMinMaxBasePrice = (
  categories: Category[],
): { minBasePrice: number; maxBasePrice: number } => {
  if (categories.length > 0) {
    let minBasePrice = categories[0].minimumRate;
    let maxBasePrice = categories[0].minimumRate;

    for (const category of categories) {
      if (category.minimumRate < minBasePrice) {
        minBasePrice = category.minimumRate;
      }
      if (category.minimumRate > maxBasePrice) {
        maxBasePrice = category.minimumRate;
      }
    }
    return { minBasePrice, maxBasePrice };
  }

  return { minBasePrice: 0, maxBasePrice: 1 };
};

export const capacitySortingFn: SortingFn<Category> = (
  rowA: Row<Category>,
  rowB: Row<Category>,
  columnId: string,
) => {
  const getTotalMaxCapacity = (row: Row<Category>, columnId: string) => {
    const data = row.getValue(columnId) as CategoryCapacity;
    return (
      (data.adults?.max || 0) +
      (data.children?.max || 0) +
      (data.teenagers?.max || 0) +
      (data.infants?.max || 0)
    );
  };

  const totalA = getTotalMaxCapacity(rowA, columnId);
  const totalB = getTotalMaxCapacity(rowB, columnId);

  if (totalA !== totalB) {
    return totalA > totalB ? 1 : -1;
  }

  const getCategoryMax = (
    row: Row<Category>,
    columnId: string,
    category: keyof CategoryCapacity,
  ) => {
    const data = row.getValue(columnId) as CategoryCapacity;
    return data[category]?.max || 0;
  };

  const sortingOrder = ["adults", "teenagers", "children", "infants"] as const;
  for (const category of sortingOrder) {
    const categoryA = getCategoryMax(rowA, columnId, category);
    const categoryB = getCategoryMax(rowB, columnId, category);
    if (categoryA !== categoryB) {
      return categoryA > categoryB ? 1 : -1;
    }
  }

  return 0;
};

export const stringSortingFn: SortingFn<Category> = (
  rowA: Row<Category>,
  rowB: Row<Category>,
  columnId: string,
) => {
  const getStringValue = (value: DescriptionValue): string => {
    if (value && typeof value === "object" && "internal" in value) {
      return String(value.internal || "");
    }
    return String(value || "");
  };

  const valueA = getStringValue(rowA.getValue(columnId));
  const valueB = getStringValue(rowB.getValue(columnId));

  return valueA.localeCompare(valueB);
};

export const renderHeaderWithLineBreaks = (key: string) => {
  const { t } = useTranslation();
  const translation = t(key);
  const parts = translation.split("\\n");

  const text = parts.join("\n");

  return text;
};
