import { createMutation, createQuery } from "react-query-kit";
import { api } from "@shared/api/api.ts";
import { currentSite } from "@shared/context/site-context.tsx";
import {
  CreateCommunicationSphereRequest,
  CreateCommunicationSphereResponse,
  GetCommunicationSphereResponse,
  GetCommunicationSpheresResponse,
} from "../../../../api-contracts/communication-spheres";

export const COMMUNICATION_SPHERES_QUERY_KEY = "communication-spheres";

export const useGetCommunicationSpheres =
  createQuery<GetCommunicationSpheresResponse>({
    primaryKey: COMMUNICATION_SPHERES_QUERY_KEY,

    queryFn: async () => {
      return api.get(
        `/api/${currentSite?.id}/${COMMUNICATION_SPHERES_QUERY_KEY}`,
      );
    },
  });

export const useGetCommunicationSphere = createQuery<
  GetCommunicationSphereResponse,
  { id: string }
>({
  primaryKey: COMMUNICATION_SPHERES_QUERY_KEY,
  queryFn: async ({ queryKey: [_, { id }] }) => {
    return api.get(
      `/api/${currentSite?.id}/${COMMUNICATION_SPHERES_QUERY_KEY}/${id}`,
    );
  },
});

export const useCreateCommunicationSphere = createMutation<
  CreateCommunicationSphereResponse,
  CreateCommunicationSphereRequest
>({
  mutationFn: async (data) =>
    api.post(
      `/api/${currentSite?.id}/${COMMUNICATION_SPHERES_QUERY_KEY}`,
      data,
    ),
});
