import { CategoryClusterContextProvider } from "@pages/settings/category-cluster/category-cluster-context.tsx";
import { CategoryClusterPage } from "@pages/settings/category-cluster/category-cluster-page.tsx";

export const CategoryCluster = () => {
  return (
    <CategoryClusterContextProvider>
      <CategoryClusterPage />
    </CategoryClusterContextProvider>
  );
};
