import { Button } from "@primitives/button.tsx";
import { useEffect, useState } from "react";
import {
  LocationsAction,
  useLocationsPageContext,
} from "../../locations-context.tsx";
import { LocationsSettingsMenu } from "../../components/locations-settings-menu.tsx";
import { AssetOverview } from "../../../../../../../api-contracts/assets";
import { DefaultSideSheet } from "@primitives/default-sheet.tsx";
import { getFilter, sortByTitle } from "../../locations-utils.tsx";
import { ListItemWithButton } from "@primitives/list-item-with-button.tsx";
import { useTranslation } from "react-i18next";

export const AddAsset = () => {
  const {
    sharedState: { currentLocation, assets, actionType },
    sharedFunctions: { updateBookable, setActionType },
  } = useLocationsPageContext();
  const { t } = useTranslation();

  const [filtered, setFiltered] = useState<AssetOverview[]>([]);
  const [filteredBySearch, setFilteredBySearch] = useState<AssetOverview[]>([]);
  const [nonAllocated, setNonAllocated] = useState<AssetOverview[]>([]);
  const [added, setAdded] = useState<string[]>([]);

  useEffect(() => {
    setNonAllocated(() =>
      assets
        .filter(
          (it) => it.location?.id === null || it.locations?.[0]?.id === null,
        )
        .sort((a, b) => sortByTitle(a, b, null)),
    );
  }, [assets, setNonAllocated]);

  useEffect(() => {
    setFiltered((prev) => (prev.length > 0 ? prev : nonAllocated));
    setFilteredBySearch(() => nonAllocated);
  }, [nonAllocated, setFiltered, setFilteredBySearch]);

  const req = currentLocation?.location.id
    ? nonAllocated
        .filter((asset) => added.includes(asset.id))
        .map((it) => ({ ...it, locationId: currentLocation?.location.id }))
    : [];

  const onClickDone = () => {
    setActionType(LocationsAction.DEFAULT);
    req.map(
      (asset) =>
        currentLocation &&
        updateBookable(currentLocation.location.id, asset.id, () => null),
    );
  };

  const onClick = (value: AssetOverview) => {
    setAdded((prev) =>
      prev.includes(value.id)
        ? [...prev.filter((it) => it != value.id)]
        : [...prev, value.id],
    );
  };

  const onClickFilter = (filterIds: string[]) => {
    filterIds.length === 0 && setFiltered(nonAllocated);
    filterIds.length > 0 &&
      setFiltered(nonAllocated.filter(({ id }) => filterIds.includes(id)));
  };

  const onInputSearch = (value: string) => {
    value === "" && setFilteredBySearch(nonAllocated);
    value != "" &&
      setFilteredBySearch(
        nonAllocated.filter(({ name }) => name.includes(value)),
      );
  };

  return (
    <DefaultSideSheet
      title={`${t("add-bookable")}`}
      description={currentLocation?.location.name ?? ""}
      open={actionType === LocationsAction.ASSET_ADD}
      onOpenChange={() => setActionType(LocationsAction.DEFAULT)}
      side={"left"}
      className={"overflow-y-auto"}
    >
      <>
        <LocationsSettingsMenu
          filters={nonAllocated}
          onSearchInput={onInputSearch}
          onAddFilter={onClickFilter}
          filterMenuLabel={t("filter-bookables")}
        />
        <p className="pb-0 text-xs font-bold">
          {t("bookables-not-allocated")}{" "}
        </p>
        <div className="border-gray-150 flex-grow overflow-auto rounded-md border-[1.5px]">
          {getFilter<AssetOverview>(filtered, filteredBySearch).map(
            (item, i) => (
              <ListItemWithButton
                key={i}
                title={item.name}
                subtitle={`${item.types.map((type) => `${t(type).toLowerCase()} `)}`}
                buttonText={t("add")}
                secondaryButtonText={t("remove")}
                onClick={() => onClick(item)}
                clicked={() => added.includes(item.id)}
              />
            ),
          )}
        </div>
        <div className="flex w-full justify-end">
          <Button className="mb-3 mr-4" onClick={() => onClickDone()}>
            {t("done")}
          </Button>
        </div>
      </>
    </DefaultSideSheet>
  );
};
