import { AlertDialog } from "@primitives/alert-dialog.tsx";
import { Input } from "@primitives/input.tsx";
import {
  CategoryClusterContext,
  Cluster,
  ClusterCategory,
} from "@pages/settings/category-cluster/category-cluster-context.tsx";
import { CategoryCard } from "@pages/settings/category-cluster/components/category-card.tsx";
import React, { FC, useContext, useState } from "react";
import { CamprType } from "../../../../../../api-contracts/categories";
import { PatchCategoryClusterRequest } from "../../../../../../api-contracts/category-clusters";
import { queryClient } from "../../../../query-client.ts";
import {
  useGetCategoryClusters,
  usePatchClusterById,
} from "@api/category-clusters.ts";
import { toast } from "@hooks/use-toast.ts";
import { useTranslation } from "react-i18next";

interface Props {
  open: boolean;
  onOpenChange: (open: boolean) => void;
}

export const ChangeNameDialog: FC<Props> = ({ open, onOpenChange }) => {
  const [clusterName, setClusterName] = useState("");
  const { clusterData } = useContext(CategoryClusterContext);
  const { mutateAsync: patchCluster } = usePatchClusterById();
  const { t } = useTranslation();

  const patchClusters = async () => {
    const selectedCluster = clusterData.clusters.filter(
      (cl: Cluster) => cl.selected,
    );
    const types: CamprType[] = selectedCluster[0].categories.map(
      (cat: ClusterCategory) => {
        return cat.category;
      },
    );
    // @ts-ignore
    const cluster: PatchCategoryClusterRequest = {
      name: clusterName,
      categoryTypes: types,
    };
    try {
      await patchCluster({
        patch: cluster,
        id: selectedCluster[0].id ?? "",
      });
      await queryClient.invalidateQueries({
        queryKey: useGetCategoryClusters.getKey(),
      });
      toast({
        title: t("saved-succesfully", { name: clusterName }),
        variant: "success",
      });
    } catch (error) {
      toast({
        title: t("request-failed-with"),
        description: t(
          decodeURIComponent(
            (error instanceof Error && error.message) || t("no-message"),
          ),
        ),
        variant: "destructive",
      });
    }
  };

  return (
    <AlertDialog.Root open={open} onOpenChange={onOpenChange}>
      <AlertDialog.Content className="z-[2001]">
        <AlertDialog.Header>
          <AlertDialog.Title>{t("change-name")}</AlertDialog.Title>
          <AlertDialog.Description>
            <Input
              className={
                "font-neue text-[15px] font-normal text-secondary-text"
              }
              type={"text"}
              value={clusterName}
              onChange={(e) => setClusterName(e.target.value)}
              placeholder={"Name"}
            />
            <div className={"mb-4 mt-4 flex"}>
              {clusterData?.clusters
                .filter((cl: Cluster) => cl.selected)
                .map((cl: Cluster) =>
                  cl.categories.map((cat) => (
                    <CategoryCard
                      key={cat.category}
                      category={cat}
                      showCheck={false}
                    />
                  )),
                )}
            </div>
          </AlertDialog.Description>
        </AlertDialog.Header>
        <AlertDialog.Footer className="relative">
          <AlertDialog.Cancel>{t("cancel")}</AlertDialog.Cancel>

          <AlertDialog.Action
            onClick={() => {
              patchClusters().then();
              setClusterName("");
            }}
          >
            {t("save")}
          </AlertDialog.Action>
        </AlertDialog.Footer>
      </AlertDialog.Content>
    </AlertDialog.Root>
  );
};
