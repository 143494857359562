export const priceRuleOverrideKeyToGuestCounts = (overrideKey: string) => {
  const adults = Number(overrideKey.split("a")[0]);
  const teenagers = Number(overrideKey.split("i")[1].split("t")[0]);
  const children = Number(overrideKey.split("a")[1].split("c")[0]);
  const infants = Number(overrideKey.split("c")[1].split("i")[0]);

  return {
    adults,
    teenagers,
    children,
    infants,
    total: adults + teenagers + children + infants,
  };
};
