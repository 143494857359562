import { Card } from "@primitives/card";
import { CardHeader } from "./components/card-header";
import { UnderConstruction } from "@components/under-construction";
import { useEffect, useState } from "react";
import { NavigationArrows } from "@components/navigation-arrows";
import { ROUTES } from "@shared/types/navigation";

const SimpleSearchPage = () => {
  const [searchTerm, setSearchTerm] = useState(
    localStorage.getItem("lastSearchTerm") || "",
  );

  useEffect(() => {
    const handleStorageChange = () => {
      setSearchTerm(localStorage.getItem("lastSearchTerm") || "");
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
      if (!window.location.pathname.includes(ROUTES.SIMPLE_SEARCH)) {
        localStorage.removeItem("lastSearchTerm");
      }
    };
  }, []);

  return (
    <div className="h-full p-4">
      <div className="pb-4">
        <NavigationArrows rootPath="/" />
      </div>
      <CardHeader searchTerm={searchTerm} />
      <Card className="h-full">
        <UnderConstruction name={""} />
      </Card>
    </div>
  );
};

export default SimpleSearchPage;
