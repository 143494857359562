import {
  useReactTable,
  getCoreRowModel,
  getSortedRowModel,
  createColumnHelper,
  SortingState,
} from "@tanstack/react-table";
import { CategoryTypeEnum, useReceptionContext } from "../../reception-context";
import { ReceptionTable } from "../../components/reception-table";
import { TableHeader } from "../../components/table-header";
import { ReceptionBooking } from "../../../../../../api-contracts/reception";
import { HighlightText } from "@pages/settings/categories/components/highlight-text";
import { useTranslation } from "react-i18next";
import {
  ReceptionBookingWithLocation,
  formatGuests,
  formatPrice,
} from "../../reception-utils";
import { format } from "date-fns";
import { Lock } from "lucide-react";
import { Loading } from "@primitives/loading";
import { BookingState } from "../../../../../../api-contracts/reservations";
import { useNavigate } from "react-router-dom";
import { useProfileContext } from "@context/profile-context";
import { LocationReceptionTable } from "@pages/reception/components/location-reception-table";
import { useEffect, useState } from "react";

export const OccupantsTableContainer = () => {
  const [sorting, setSorting] = useState<SortingState>([]);
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const { module } = useProfileContext();
  const {
    occupants,
    columnVisibility,
    setColumnVisibility,
    searchTerm,
    isLoading,
    categoryType,
    showLocation,
  } = useReceptionContext();

  const handleRowClick = (booking: ReceptionBooking) => {
    navigate(`/${module}/reservations/${booking.reservationId}`);
  };

  const columnHelper = createColumnHelper<
    ReceptionBooking | ReceptionBookingWithLocation
  >();

  const occupantsColumnDefinition = [
    columnHelper.accessor("reservationId", {
      header: t("booking"),
      id: "reservationId",
      cell: ({ getValue }) => (
        <HighlightText text={getValue()} textToHighlight={searchTerm} />
      ),
      meta: {
        initialVisibility: true,
      },
    }),
    columnHelper.accessor("holder.name", {
      header: t("first-name"),
      id: "firstName",
      cell: ({ getValue }) => {
        const firstName = getValue();
        return (
          <HighlightText
            text={firstName ? firstName : ""}
            textToHighlight={searchTerm}
          />
        );
      },
      meta: {
        initialVisibility: true,
      },
    }),
    columnHelper.accessor("holder.surname", {
      header: t("surname"),
      id: "surname",
      cell: ({ getValue }) => {
        const surname = getValue();
        return (
          <HighlightText
            text={surname ? surname : ""}
            textToHighlight={searchTerm}
          />
        );
      },
      meta: {
        initialVisibility: true,
      },
    }),
    columnHelper.accessor(
      (row) => {
        const { adults, teenagers, children, infants } = row;

        return formatGuests(
          { adults, teenagers, children, infants },
          i18n.language,
        );
      },
      {
        header: t("guests"),
        id: "guests",
        meta: {
          initialVisibility: true,
        },
      },
    ),
    columnHelper.accessor("arrivalDate", {
      header: t("arrival-date"),
      id: "arrivalDate",
      cell: ({ getValue }) => format(getValue(), "yyyy-MM-dd"),
      meta: {
        initialVisibility: true,
      },
    }),
    columnHelper.accessor("departureDate", {
      header: t("departure-date"),
      id: "departureDate",
      cell: ({ getValue }) => format(getValue(), "yyyy-MM-dd"),
      meta: {
        initialVisibility: true,
      },
    }),
    columnHelper.accessor("numberOfNights", {
      header: t("number-of-nights"),
      id: "numberOfNights",
      meta: {
        initialVisibility: true,
      },
    }),
    columnHelper.accessor("category.name", {
      header: t("category"),
      id: "roomCategory",
      meta: {
        initialVisibility: true,
      },
    }),
    columnHelper.accessor("bookable.name", {
      header: categoryType === CategoryTypeEnum.HOTEL ? t("room") : t("unit"),
      id: "room",
      cell: ({ row }) => {
        const { lockedBookable, bookable } = row.original;
        return (
          <div className="flex w-full items-center justify-between">
            <p className="mr-2">{bookable.name}</p>
            {lockedBookable && (
              <Lock strokeWidth={1} size={16} className="ml-auto" />
            )}
          </div>
        );
      },
      meta: {
        initialVisibility: true,
      },
    }),
    columnHelper.accessor("paymentRemaining", {
      header: t("to-be-paid"),
      id: "paymentRemaining",
      cell: ({ getValue }) => formatPrice(getValue()),
      meta: {
        initialVisibility: true,
      },
    }),
    columnHelper.accessor("state", {
      header: t("status"),
      id: "state",
      meta: {
        initialVisibility: true,
      },
      cell: ({ getValue }) => {
        const stateLabels: Partial<Record<BookingState, string>> = {
          planned: t("not-checked-in"),
          "checked-in": t("checked-in"),
          "checked-out": t("checked-out"),
          "no-show": t("no-show"),
        };

        const stateClasses: Partial<Record<BookingState, string>> = {
          planned: "bg-status-warning-100 text-status-warning",
          "checked-in": "bg-status-success-100 text-status-success",
          "checked-out": "bg-status-disabled-100 text-status-disabled",
          "no-show": "bg-status-error-100 text-status-error",
        };

        const stateValue = getValue();
        const label = stateLabels[stateValue];
        const stateClassName = stateClasses[stateValue];
        return (
          <div
            className={`${stateClassName} flex items-center justify-center whitespace-nowrap rounded-[4px] px-2 py-1 font-extrabold`}
          >
            <p>{label}</p>
          </div>
        );
      },
    }),
  ];

  const occupantsTable = useReactTable({
    data: occupants,
    columns: occupantsColumnDefinition,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    onColumnVisibilityChange: setColumnVisibility,
    onSortingChange: (newSorting) => {
      if (!showLocation) {
        setSorting(newSorting);
      } else {
        setSorting([]);
      }
    },
    state: {
      columnVisibility,
      sorting,
    },
  });

  useEffect(() => {
    setSorting([]);
  }, [showLocation]);

  const cols = occupantsTable.getAllLeafColumns();
  if (isLoading || !occupants) return <Loading />;
  return (
    <div>
      <div className="p-4">
        <TableHeader columns={cols} tableType="occupants" />
      </div>
      {!showLocation ? (
        <ReceptionTable<ReceptionBooking>
          table={occupantsTable}
          onRowClick={handleRowClick}
        />
      ) : (
        <LocationReceptionTable
          table={occupantsTable}
          onRowClick={handleRowClick}
        />
      )}
    </div>
  );
};
