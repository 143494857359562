import { useGetPriceCalendar } from "@api/price-calendar";
import { queryClient } from "query-client";
import { useEffect } from "react";
import { CalendarHeader } from "./components/calendar-header";
import { YearLayoutCalendar } from "./components/year-layout-calendar";
import { PriceCalendarContextProvider } from "./price-calendar-context";

const PriceCalendarPage = () => {
  useEffect(() => {
    queryClient.invalidateQueries({ queryKey: useGetPriceCalendar.getKey() });
  }, []);

  return (
    <PriceCalendarContextProvider>
      <div className="flex h-full w-full flex-col overflow-x-auto">
        <CalendarHeader />
        <YearLayoutCalendar />
      </div>
    </PriceCalendarContextProvider>
  );
};

export default PriceCalendarPage;
