import React, {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import { Loading } from "@primitives/loading.tsx";
import {
  GetTargetGroupResponse,
  GetTargetGroupsResponse,
  PatchTargetGroupRequest,
} from "../../../../../api-contracts/target-groups";
import {
  useDeleteTargetGroup,
  useGetTargetGroups,
  useUpdateTargetGroup,
} from "@api/target-groups.ts";
import { useGetChannels } from "@api/channels.ts";
import { GetChannelsResponse } from "../../../../../api-contracts/channels";
import { useSharedContext } from "@context/shared-context.tsx";
import { useTranslation } from "react-i18next";
import { queryClient } from "query-client";

export const enum TargetGroupsActionType {
  DEFAULT = "DEFAULT",
  ADD = "ADD",
  EDIT_CURRENT = "EDIT_CURRENT",
  EDIT_SELECTED = "EDIT_SELECTED",
}

interface SharedStateProps {
  targetGroups: GetTargetGroupsResponse;
  action: TargetGroupsActionType;
  current: GetTargetGroupResponse | null;
  selectedTargetGroupDetails: GetTargetGroupResponse | null;
  getChannels: GetChannelsResponse | undefined;
}

interface SharedFunctionsProps {
  setTargetGroups: Dispatch<SetStateAction<GetTargetGroupsResponse>>;
  setAction: Dispatch<SetStateAction<TargetGroupsActionType>>;
  setCurrent: Dispatch<SetStateAction<GetTargetGroupResponse | null>>;
  setSelectedTargetGroupDetails: Dispatch<
    SetStateAction<GetTargetGroupResponse | null>
  >;
  handleRefetch: () => Promise<void | undefined | null>;
  deleteTargetGroup: (id: string, name: string) => void;
  updateTargetGroup: (id: string, req: PatchTargetGroupRequest) => void;
}

interface TargetGroupsPageContextProps {
  sharedState: SharedStateProps;
  sharedFunctions: SharedFunctionsProps;
}

interface TargetGroupsPageProviderProps {
  children: ReactNode;
}

const TargetGroupsContext = createContext<
  TargetGroupsPageContextProps | undefined
>(undefined);

export const useTargetGroupsPageContext = () => {
  const context = useContext(TargetGroupsContext);

  if (!context) {
    throw new Error("context must be used in correct provider");
  }

  return context;
};

export const TargetGroupsPageProvider: React.FC<
  TargetGroupsPageProviderProps
> = ({ children }) => {
  const {
    sharedState: { isLoading },
    sharedFunctions: { setLoading, toast },
  } = useSharedContext();

  const [action, setAction] = useState<TargetGroupsActionType>(
    TargetGroupsActionType.DEFAULT,
  );
  const [targetGroups, setTargetGroups] = useState<GetTargetGroupsResponse>([]);
  const [selectedTargetGroupDetails, setSelectedTargetGroupDetails] =
    useState<GetTargetGroupResponse | null>(null);
  const [current, setCurrent] = useState<GetTargetGroupResponse | null>(null);

  useEffect(() => {
    queryClient.invalidateQueries({
      queryKey: useGetTargetGroups.getKey(),
    });
    queryClient.invalidateQueries({
      queryKey: useGetChannels.getKey(),
    });
  }, []);

  const { data: getTargetGroups, refetch: refetchTargetGroups } =
    useGetTargetGroups();
  const { data: getChannels } = useGetChannels();
  const { mutate: delTargetGroup } = useDeleteTargetGroup();
  const { mutate: editTargetGroup } = useUpdateTargetGroup();

  const { t } = useTranslation();

  useEffect(() => {
    getTargetGroups && setTargetGroups(() => getTargetGroups);
  }, [getTargetGroups, setTargetGroups]);

  const handleRefetch = async () => {
    const res = await refetchTargetGroups();
    if (res.error) {
      toast({
        variant: "destructive",
        title: t("refetch-target-group-error", { error: res.error }),
      });
      return null;
    }
    return res.data && setTargetGroups(() => res.data);
  };

  const updateTargetGroup = (id: string, req: PatchTargetGroupRequest) => {
    setLoading(true);
    editTargetGroup(
      { id, body: req },
      {
        onError: (e) => {
          toast({
            variant: "destructive",
            title: t("update-target-group-error", { error: e.message }),
          });
        },
        onSuccess: async (res) => {
          toast({
            variant: "success",
            title: t("target-group-updated", { targetGroup: res.name }),
          });
          await handleRefetch();
          selectedTargetGroupDetails?.id === id &&
            setSelectedTargetGroupDetails(res);
        },
        onSettled: () => {
          setLoading(false);
          setAction(TargetGroupsActionType.DEFAULT);
        },
      },
    );
  };

  const deleteTargetGroup = (id: string, name: string) => {
    setLoading(true);
    delTargetGroup(
      { id: id },
      {
        onError: (e) => {
          toast({
            variant: "destructive",
            title: t("delete-target-group-error", { error: e.message }),
          });
        },
        onSuccess: async () => {
          toast({
            variant: "success",
            title: t("target-group-deleted", { targetGroup: name }),
          });
          await handleRefetch();
          selectedTargetGroupDetails?.id === id &&
            setSelectedTargetGroupDetails(null);
        },
        onSettled: () => {
          setLoading(false);
        },
      },
    );
  };

  const sharedState = {
    targetGroups,
    action,
    current,
    selectedTargetGroupDetails,
    getChannels,
  };
  const sharedFunctions = {
    setTargetGroups,
    setSelectedTargetGroupDetails,
    setAction,
    handleRefetch,
    setLoading,
    setCurrent,
    deleteTargetGroup,
    toast,
    updateTargetGroup,
  };

  return (
    <TargetGroupsContext.Provider value={{ sharedState, sharedFunctions }}>
      {isLoading && <Loading />}
      {action !== TargetGroupsActionType.DEFAULT && (
        <div className="backdrop fixed inset-0 z-[100] flex items-center justify-center overflow-y-auto overflow-x-hidden bg-black/75 outline-none focus:outline-none" />
      )}
      <div className="relative z-0 h-full space-y-4 overflow-y-auto p-4">
        {children}
      </div>
    </TargetGroupsContext.Provider>
  );
};
