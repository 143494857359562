import { useTranslation } from "react-i18next";
import { Button } from "@primitives/button";
import {
  AgeType,
  BookingGuest,
  GetBooking,
} from "../../../../../../../api-contracts/reservations";
import {
  Sheet,
  SheetContent,
  SheetHeader,
  SheetTitle,
} from "@primitives/sheet";
import { Label } from "@primitives/label";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@primitives/select";
import { useGetPriceRules } from "@api/price-rules";
import { useEffect, useMemo, useState } from "react";
import {
  format,
  subDays,
  eachDayOfInterval,
  addDays,
  isSameDay,
} from "date-fns";
import { useGetCategories } from "@api/categories";
import { useGetAllocableBookables } from "@api/bookables";
import { TimeSlot } from "../../../../../../../api-contracts/reservations/timeslots";
import { RadioGroup, RadioGroupItem } from "@primitives/radio-group";
import { DateRangePicker } from "@primitives/date-range-picker";
import { DateRange } from "react-day-picker";
import { uniqueId } from "lodash";
import { useGetPricing } from "@api/pricing";
import {
  dateWithTimeString,
  dateWithTimeStringISO,
  addHoursAndMinutesToDateISO,
} from "@shared/utils/helpers";
import { Id } from "../../../../../../../backend/src/shared/types/models";
import { priceRuleOverrideKeyToGuestCounts } from "@shared/utils/price-rules";

interface Props {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  onProceed: (newBooking: GetBooking) => void;
  defaultStart?: string;
  defaultEnd?: string;
}

export const AddBookingDrawer = ({
  open,
  onOpenChange,
  onProceed,
  defaultStart,
  defaultEnd,
}: Props) => {
  const { t, i18n } = useTranslation();
  const [priceRuleId, setPriceRuleId] = useState<string | null | undefined>();
  const [newBooking, setNewBooking] = useState<GetBooking>({
    id: "new" + uniqueId(),
    guests: [],
    category: {} as any,
    bookable: null,
    folioId: "new" + uniqueId(),
    paid: 0,
    state: {
      value: "planned",
      reason: null,
      note: null,
    },
    guestCount: {
      adults: 1,
      teenagers: 0,
      children: 0,
      infants: 0,
    },
    start: defaultStart || dateWithTimeStringISO(new Date(), "14:00"),
    end: defaultEnd || dateWithTimeStringISO(addDays(new Date(), 1), "12:00"),
    slots: [],
    notes: [],
    tags: [],
  });
  const [priceOption, setPriceOption] = useState<
    "bar-price" | "enter-new-price-code"
  >("bar-price");

  useEffect(() => {
    setNewBooking({
      id: "new" + uniqueId(),
      guests: [],
      category: {} as any,
      bookable: null,
      folioId: "new" + uniqueId(),
      paid: 0,
      state: {
        value: "planned",
        reason: null,
        note: null,
      },
      guestCount: {
        adults: 1,
        teenagers: 0,
        children: 0,
        infants: 0,
      },
      start: defaultStart || dateWithTimeStringISO(new Date(), "14:00"),
      end: defaultEnd || dateWithTimeStringISO(addDays(new Date(), 1), "12:00"),
      slots: [],
      notes: [],
      tags: [],
    });
  }, [open]);

  const {
    data: priceRules,
    isLoading: priceRulesIsLoading,
    isRefetching: priceRulesIsRefetching,
  } = useGetPriceRules({
    variables: {
      categoryTypes: [newBooking.category.type],
    },
    enabled: !!newBooking.category.type,
  });

  const {
    data: categories,
    isLoading: categoriesIsLoading,
    isRefetching: categoriesIsRefetching,
  } = useGetCategories({
    variables: {
      categoryTypes: ["room", "area", "bed", "dormitory"],
    },
  });

  const {
    data: bookables,
    isLoading: bookablesIsLoading,
    isRefetching: bookablesIsRefetching,
  } = useGetAllocableBookables({
    variables: {
      categoryId: newBooking.category.id,
      startDate: format(newBooking.start, "yyyy-MM-dd"),
      endDate: format(newBooking.end, "yyyy-MM-dd"),
    },
    enabled: !!newBooking.category.id,
  });

  const {
    data: pricing,
    isLoading: pricingIsLoading,
    isRefetching: pricingIsRefetching,
  } = useGetPricing({
    variables: {
      id: newBooking.category.id,
      slots: newBooking.slots.map((s) => ({
        startDate: format(s.start, "yyyy-MM-dd"),
        startTime:
          categories?.find((c) => newBooking.category.id === c.id)?.config!
            .startTimes[0] || "14.00",
      })),
      adults: newBooking.guestCount.adults,
      teenagers: newBooking.guestCount.teenagers,
      children: newBooking.guestCount.children,
      infants: newBooking.guestCount.infants,
    },
    enabled: !!newBooking.category.id && categories && open,
  });

  useEffect(() => {
    if (
      priceOption === "bar-price" ||
      !priceRules?.rules.find((r) => r.id === newBooking.slots[0]?.priceRuleId)
    ) {
      updateState(
        newBooking.category.id,
        newBooking.bookable?.id || null,
        newBooking.start,
        newBooking.end,
        newBooking.guestCount.adults,
        newBooking.guestCount.teenagers,
        newBooking.guestCount.children,
        newBooking.guestCount.infants,
        undefined,
      );
    }
  }, [pricing, priceOption]);

  const handleDatesChange = (values: { range: DateRange }) => {
    if (values.range.from && values.range.to) {
      updateState(
        newBooking.category.id,
        newBooking.bookable?.id || null,
        values.range.from,
        values.range.to,
        newBooking.guestCount.adults,
        newBooking.guestCount.teenagers,
        newBooking.guestCount.children,
        newBooking.guestCount.infants,
        priceOption === "bar-price" ? undefined : priceRuleId,
      );
    }
  };

  const handlePriceOptionChange = (
    option: "bar-price" | "enter-new-price-code",
  ) => {
    switch (option) {
      case "bar-price":
        updateState(
          newBooking.category.id,
          newBooking.bookable?.id || null,
          newBooking.start,
          newBooking.end,
          newBooking.guestCount.adults,
          newBooking.guestCount.teenagers,
          newBooking.guestCount.children,
          newBooking.guestCount.infants,
          undefined,
        );
        break;
      case "enter-new-price-code":
        updateState(
          newBooking.category.id,
          newBooking.bookable?.id || null,
          newBooking.start,
          newBooking.end,
          newBooking.guestCount.adults,
          newBooking.guestCount.teenagers,
          newBooking.guestCount.children,
          newBooking.guestCount.infants,
          priceRuleId,
        );
        break;
    }
    setPriceOption(option);
  };

  const updateState = (
    categoryId: string,
    bookableId: string | null,
    start: Date | string,
    end: Date | string,
    adults: number,
    teenagers: number,
    children: number,
    infants: number,
    priceRuleId?: string | null,
  ) => {
    const category = categories?.find((c) => c.id === categoryId);
    if (!category) {
      setNewBooking((newBooking) => ({
        ...newBooking,
        start: dateWithTimeStringISO(start, "14:00"),
        end: dateWithTimeStringISO(end, "12:00"),
      }));
      return;
    }

    adults =
      adults >= category.capacity.adults.min &&
      adults <= category.capacity.adults.max
        ? adults
        : category.capacity.adults.min || 1;
    teenagers = teenagers <= category.capacity.teenagers.max ? teenagers : 0;
    children = children <= category.capacity.children.max ? children : 0;
    infants = infants <= category.capacity.infants.max ? infants : 0;

    const bookable = bookables?.bookables.find((b) => b.id === bookableId);

    const dates = eachDayOfInterval({
      start: start,
      end: subDays(end, 1),
    });
    const newSlots: TimeSlot[] = dates.map((date) => {
      const pricer = pricing?.slots.find((s) => isSameDay(date, s.startDate));
      let pr = priceRules?.rules.find(
        (r) =>
          (priceRuleId && r.id === priceRuleId) ||
          (!priceRuleId && pricer?.ruleId && r.id === pricer.ruleId),
      );
      if (priceRuleId && !pr) {
        pr = priceRules?.rules.find(
          (r) => pricer?.ruleId && r.id === pricer.ruleId,
        );
        priceRuleId = pricer?.ruleId || null;
      }
      const priceRule = pr?.entries.find((e) => e.categoryId === categoryId);

      const priceRuleOverride = priceRule?.overrides.find((o) => {
        const guestCounts = priceRuleOverrideKeyToGuestCounts(o.overrideKey);
        return (
          guestCounts.adults === adults &&
          guestCounts.children === children &&
          guestCounts.teenagers === teenagers &&
          guestCounts.infants === infants
        );
      });

      const price = priceRuleOverride?.price || priceRule?.price || 0;
      return {
        id: "new" + uniqueId(),
        price: price,
        start: dateWithTimeStringISO(date, category.config!.startTimes[0]),
        end: addHoursAndMinutesToDateISO(
          dateWithTimeString(date, category.config!.startTimes[0]),
          category.config!.timeslotHours,
          category.config!.timeslotMinutes,
        ),
        priceRuleId: priceRuleId || pricer?.ruleId || null,
        priceRuleName: pr?.name || "",
        fullPrice: price,
        priceOverrideAmount: null,
        priceAdjustmentPercent: null,
      };
    });

    let adultsList: (BookingGuest & Id)[] = [];
    let teenagersList: (BookingGuest & Id)[] = [];
    let childrenList: (BookingGuest & Id)[] = [];
    let infantsList: (BookingGuest & Id)[] = [];

    for (let i = 0; i < adults; i++) {
      adultsList.push({
        id: "new" + uniqueId(),
        ageType: "adult" as AgeType,
        age: null,
        title: null,
        organization: null,
        start: dateWithTimeStringISO(start, category.config!.startTimes[0]),
        end: newSlots[newSlots.length - 1].end,
        name: null,
        surname: null,
        passportNumber: null,
        address: null,
        address2: null,
        city: null,
        state: null,
        zip: null,
        countryCode: null,
        phone: null,
        phone2: null,
        email: null,
        tags: [],
      });
    }

    for (let i = 0; i < teenagers; i++) {
      teenagersList.push({
        id: "new" + uniqueId(),
        ageType: "teenager" as AgeType,
        age: 16,
        title: null,
        organization: null,
        start: dateWithTimeStringISO(start, category.config!.startTimes[0]),
        end: newSlots[newSlots.length - 1].end,
        name: null,
        surname: null,
        passportNumber: null,
        address: null,
        address2: null,
        city: null,
        state: null,
        zip: null,
        countryCode: null,
        phone: null,
        phone2: null,
        email: null,
        tags: [],
      });
    }

    for (let i = 0; i < children; i++) {
      childrenList.push({
        id: "new" + uniqueId(),
        ageType: "child" as AgeType,
        age: 10,
        title: null,
        organization: null,
        start: dateWithTimeStringISO(start, category.config!.startTimes[0]),
        end: newSlots[newSlots.length - 1].end,
        name: null,
        surname: null,
        passportNumber: null,
        address: null,
        address2: null,
        city: null,
        state: null,
        zip: null,
        countryCode: null,
        phone: null,
        phone2: null,
        email: null,
        tags: [],
      });
    }

    for (let i = 0; i < infants; i++) {
      infantsList.push({
        id: "new" + uniqueId(),
        ageType: "infant" as AgeType,
        age: 1,
        title: null,
        organization: null,
        start: dateWithTimeStringISO(start, category.config!.startTimes[0]),
        end: newSlots[newSlots.length - 1].end,
        name: null,
        surname: null,
        passportNumber: null,
        address: null,
        address2: null,
        city: null,
        state: null,
        zip: null,
        countryCode: null,
        phone: null,
        phone2: null,
        email: null,
        tags: [],
      });
    }

    setPriceRuleId(newSlots[0]?.priceRuleId || null);
    setNewBooking((newBooking) => ({
      ...newBooking,
      start: dateWithTimeStringISO(start, category.config!.startTimes[0]),
      end: newSlots[newSlots.length - 1].end,
      slots: newSlots,
      category: category,
      bookable: bookable ? { ...bookable, lockedToBookable: false } : null,
      guestCount: {
        adults,
        teenagers,
        children,
        infants,
      },
      guests: [
        ...adultsList,
        ...teenagersList,
        ...childrenList,
        ...infantsList,
      ],
    }));
  };

  const total = useMemo(() => {
    return newBooking.slots.reduce((acc, slot) => {
      return acc + slot.price;
    }, 0);
  }, [newBooking]);

  return (
    <>
      <Sheet open={open} onOpenChange={onOpenChange}>
        <SheetContent
          side="left"
          className="flex w-[400px] flex-col overflow-y-auto sm:w-[600px]"
        >
          <SheetHeader>
            <SheetTitle className=" flex items-center justify-between text-primary-text">
              {t("add-unit")}
            </SheetTitle>
          </SheetHeader>

          <div className=" flex-1 overflow-y-auto text-primary-text">
            <div className="mt-4 bg-secondary-card-backplate p-4">
              <h3 className=" mb-4 text-lg font-bold">{t("dates")}</h3>
              <div>
                <DateRangePicker
                  min={2}
                  initialDateFrom={newBooking.start}
                  initialDateTo={newBooking.end}
                  onUpdate={handleDatesChange}
                  disabled={{
                    before: new Date(),
                  }}
                />
              </div>
            </div>

            <div className="mt-4 bg-secondary-card-backplate p-4">
              <h3 className=" mb-4 text-lg font-bold">{t("unit")}</h3>
              <div>
                <Label htmlFor="booking-change-category">{t("category")}</Label>
                <Select
                  key={newBooking.category.id}
                  value={newBooking.category.id}
                  onValueChange={(value) => {
                    const cat = categories?.find((c) => c.id === value);
                    updateState(
                      value,
                      null,
                      newBooking.start,
                      newBooking.end,
                      newBooking.guestCount.adults,
                      newBooking.guestCount.teenagers,
                      newBooking.guestCount.children,
                      newBooking.guestCount.infants,
                      priceOption === "bar-price"
                        ? undefined
                        : cat?.type === newBooking.category.type
                          ? priceRuleId
                          : undefined,
                    );
                  }}
                >
                  <SelectTrigger
                    className=" w-full"
                    id="booking-change-category"
                  >
                    <SelectValue />
                  </SelectTrigger>
                  <SelectContent>
                    {categories?.map((c) => (
                      <SelectItem key={c.id} value={c.id} className=" text-xs">
                        {t(c.name)}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </div>
              <div className="mt-2">
                <Label htmlFor="booking-change-asset">{t("unit")}</Label>
                <Select
                  key={newBooking.bookable?.id}
                  value={newBooking.bookable?.id}
                  onValueChange={(value) => {
                    updateState(
                      newBooking.category.id,
                      value,
                      newBooking.start,
                      newBooking.end,
                      newBooking.guestCount.adults,
                      newBooking.guestCount.teenagers,
                      newBooking.guestCount.children,
                      newBooking.guestCount.infants,
                      priceOption === "bar-price" ? undefined : priceRuleId,
                    );
                  }}
                >
                  <SelectTrigger className=" w-full" id="booking-change-asset">
                    <SelectValue />
                  </SelectTrigger>
                  <SelectContent>
                    {bookables?.bookables?.map((b) => (
                      <SelectItem key={b.id} value={b.id} className=" text-xs">
                        {t(b.name)}
                      </SelectItem>
                    ))}
                    {bookables?.bookables.length === 0 && (
                      <p className="p-2 text-center text-sm font-normal text-secondary-text">
                        {t("no-units-available")}
                      </p>
                    )}
                  </SelectContent>
                </Select>
              </div>
            </div>

            {newBooking.category.id && (
              <div className="mt-4 bg-secondary-card-backplate p-4">
                <h3 className=" mb-2 text-lg font-bold">{t("guests")}</h3>
                {newBooking.guestCount.adults +
                  newBooking.guestCount.teenagers +
                  newBooking.guestCount.children +
                  newBooking.guestCount.infants >
                  newBooking.category.capacity.total.max && (
                  <p className=" mb-4 text-xs font-normal text-status-error">
                    {t("{{category}}-allows-max-{{guests}}-guests", {
                      category: newBooking.category.name,
                      guests: newBooking.category.capacity.total.max,
                    })}
                  </p>
                )}
                <div>
                  <Select
                    value={String(newBooking.guestCount.adults)}
                    onValueChange={(v) => {
                      updateState(
                        newBooking.category.id,
                        newBooking.bookable?.id || null,
                        newBooking.start,
                        newBooking.end,
                        Number(v),
                        newBooking.guestCount.teenagers,
                        newBooking.guestCount.children,
                        newBooking.guestCount.infants,
                        priceOption === "bar-price" ? undefined : priceRuleId,
                      );
                    }}
                  >
                    <SelectTrigger className="min-w-[170px] text-xs">
                      <SelectValue placeholder={t("adults")} />
                    </SelectTrigger>
                    <SelectContent>
                      {Array.from(
                        { length: newBooking.category.capacity.adults.max + 1 },
                        (v, k) => k,
                      )
                        .filter(
                          (v) => v >= newBooking.category.capacity.adults.min,
                        )
                        .map((v) => (
                          <SelectItem
                            key={v}
                            value={String(v)}
                            className=" text-xs"
                          >
                            {String(v)} {t("adults")}
                          </SelectItem>
                        ))}
                    </SelectContent>
                  </Select>
                </div>
                <div className="mt-2">
                  <Select
                    value={String(newBooking.guestCount.teenagers)}
                    onValueChange={(v) => {
                      updateState(
                        newBooking.category.id,
                        newBooking.bookable?.id || null,
                        newBooking.start,
                        newBooking.end,
                        newBooking.guestCount.adults,
                        Number(v),
                        newBooking.guestCount.children,
                        newBooking.guestCount.infants,
                        priceOption === "bar-price" ? undefined : priceRuleId,
                      );
                    }}
                  >
                    <SelectTrigger className="min-w-[170px] text-xs">
                      <SelectValue placeholder={t("teenagers")} />
                    </SelectTrigger>
                    <SelectContent>
                      {Array.from(
                        {
                          length:
                            newBooking.category.capacity.teenagers.max + 1,
                        },
                        (v, k) => k,
                      ).map((v) => (
                        <SelectItem
                          key={v}
                          value={String(v)}
                          className=" text-xs"
                        >
                          {String(v)} {t("teenagers")}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                </div>
                <div className="mt-2">
                  <Select
                    value={String(newBooking.guestCount.children)}
                    onValueChange={(v) => {
                      updateState(
                        newBooking.category.id,
                        newBooking.bookable?.id || null,
                        newBooking.start,
                        newBooking.end,
                        newBooking.guestCount.adults,
                        newBooking.guestCount.teenagers,
                        Number(v),
                        newBooking.guestCount.infants,
                        priceOption === "bar-price" ? undefined : priceRuleId,
                      );
                    }}
                  >
                    <SelectTrigger className="min-w-[170px] text-xs">
                      <SelectValue placeholder={t("children")} />
                    </SelectTrigger>
                    <SelectContent>
                      {Array.from(
                        {
                          length: newBooking.category.capacity.children.max + 1,
                        },
                        (v, k) => k,
                      ).map((v) => (
                        <SelectItem
                          key={v}
                          value={String(v)}
                          className=" text-xs"
                        >
                          {String(v)} {t("children")}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                </div>
                <div className="mt-2">
                  <Select
                    value={String(newBooking.guestCount.infants)}
                    onValueChange={(v) => {
                      updateState(
                        newBooking.category.id,
                        newBooking.bookable?.id || null,
                        newBooking.start,
                        newBooking.end,
                        newBooking.guestCount.adults,
                        newBooking.guestCount.teenagers,
                        newBooking.guestCount.children,
                        Number(v),
                        priceOption === "bar-price" ? undefined : priceRuleId,
                      );
                    }}
                  >
                    <SelectTrigger className="min-w-[170px] text-xs">
                      <SelectValue placeholder={t("infants")} />
                    </SelectTrigger>
                    <SelectContent>
                      {Array.from(
                        {
                          length: newBooking.category.capacity.infants.max + 1,
                        },
                        (v, k) => k,
                      ).map((v) => (
                        <SelectItem
                          key={v}
                          value={String(v)}
                          className=" text-xs"
                        >
                          {String(v)} {t("infants")}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                </div>
              </div>
            )}

            {newBooking.category.id && (
              <div className="mt-4 bg-secondary-card-backplate p-4">
                <h3 className=" mb-4 text-lg font-bold">{t("price")}</h3>
                <RadioGroup
                  className="mb-4"
                  value={priceOption}
                  onValueChange={(v) =>
                    handlePriceOptionChange(
                      v as "bar-price" | "enter-new-price-code",
                    )
                  }
                >
                  <div className="flex items-center space-x-2">
                    <RadioGroupItem value="bar-price" id="bar-price" />
                    <Label htmlFor="bar-price">{t("bar-price")}</Label>
                  </div>
                  <div className="mt-2 flex items-center space-x-2">
                    <RadioGroupItem
                      value="enter-new-price-code"
                      id="enter-new-price-code"
                    />
                    <Label htmlFor="enter-new-price-code">
                      {t("enter-new-price-code")}
                    </Label>
                  </div>
                </RadioGroup>

                {priceOption === "enter-new-price-code" && (
                  <>
                    <Label htmlFor="booking-change-price-code">
                      {t("change-price-code-for-all-nights")}
                    </Label>
                    <Select
                      value={
                        newBooking.slots.every(
                          (s) =>
                            s.priceRuleId === newBooking.slots[0].priceRuleId,
                        )
                          ? newBooking.slots[0]?.priceRuleId || undefined
                          : undefined
                      }
                      onValueChange={(value) => {
                        setPriceRuleId(value);
                        updateState(
                          newBooking.category.id,
                          newBooking.bookable?.id || null,
                          newBooking.start,
                          newBooking.end,
                          newBooking.guestCount.adults,
                          newBooking.guestCount.teenagers,
                          newBooking.guestCount.children,
                          newBooking.guestCount.infants,
                          value || null,
                        );
                      }}
                    >
                      <SelectTrigger
                        className=" w-full"
                        id="booking-change-price-code"
                      >
                        <SelectValue />
                      </SelectTrigger>
                      <SelectContent>
                        {priceRules?.rules.map((r) => (
                          <SelectItem
                            key={r.id}
                            value={r.id}
                            className=" text-xs"
                          >
                            {t(r.name)}
                          </SelectItem>
                        ))}
                        {priceRules?.rules.length === 0 && (
                          <p className="p-2 text-center text-sm font-normal text-secondary-text">
                            {t("no-price-rules-available")}
                          </p>
                        )}
                      </SelectContent>
                    </Select>
                  </>
                )}
              </div>
            )}
          </div>

          <div className=" flex items-center justify-between space-x-2">
            <p className=" text-md font-bold">
              {t("total")}:{" "}
              {new Intl.NumberFormat(
                i18n.language === "sv-se" ? "sv-SE" : "en-GB",
                { maximumFractionDigits: 2 },
              ).format(total)}{" "}
              SEK
            </p>
            <div className=" flex justify-end space-x-2 ">
              <Button variant="outline" onClick={() => onOpenChange(false)}>
                {t("cancel")}
              </Button>
              <Button
                disabled={
                  !newBooking.bookable ||
                  newBooking.guestCount.adults +
                    newBooking.guestCount.children +
                    newBooking.guestCount.teenagers +
                    newBooking.guestCount.infants >
                    newBooking.category.capacity.total.max
                }
                onClick={() => {
                  onProceed(newBooking);
                  onOpenChange(false);
                }}
              >
                {t("apply")}
              </Button>
            </div>
          </div>
        </SheetContent>
      </Sheet>
    </>
  );
};

export default AddBookingDrawer;
