import { printTable } from "@pages/reception/reception-utils";
import { Button } from "@primitives/button";
import { CheckboxWithLabel } from "@primitives/checkbox-with-label";
import {
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuRoot,
  DropdownMenuTrigger,
} from "@primitives/dropdown-menu";
import { SwitchWithLabel } from "@primitives/switch-with-label";
import { Column } from "@tanstack/react-table";
import { Filter, Printer } from "lucide-react";
import { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import { FireList } from "../../../../../../api-contracts/reception";

const ICON_SIZE = 17;
const ICON_STROKE_WIDTH = 1;

export const FireListTableHeader = ({
  showAllGuests,
  setShowAllGuests,
  showLocations,
  setShowLocations,
  columns,
}: {
  showAllGuests: boolean;
  setShowAllGuests: Dispatch<SetStateAction<boolean>>;
  showLocations: boolean;
  setShowLocations: Dispatch<SetStateAction<boolean>>;
  columns: Column<FireList, unknown>[];
}) => {
  const { t } = useTranslation();
  return (
    <div className="flex items-center justify-between">
      <div className="flex">
        <div
          className={
            "flex h-10 items-center rounded-md bg-primary-button-backplate p-1 text-primary-text"
          }
        >
          <div className="mx-1">
            <SwitchWithLabel
              checked={showLocations}
              onCheckedChange={setShowLocations}
              label={t("locations")}
            />
          </div>
        </div>
        <div
          className={
            "mx-2 flex items-center rounded-md bg-primary-button-backplate p-1 text-primary-text "
          }
        >
          <div className="mx-1">
            <SwitchWithLabel
              checked={showAllGuests}
              onCheckedChange={setShowAllGuests}
              label={t("show-fellow-guests")}
            />
          </div>
        </div>
      </div>
      <div className="flex items-center">
        <ColumnVisibilityToggle columns={columns} />
        <Button variant={"secondary"} size="icon" onClick={printTable}>
          <Printer strokeWidth={ICON_STROKE_WIDTH} size={ICON_SIZE} />
        </Button>
      </div>
    </div>
  );
};

const ColumnVisibilityToggle = ({
  columns,
}: {
  columns: Column<FireList, unknown>[];
}) => {
  const { t } = useTranslation();
  return (
    <DropdownMenuRoot>
      <DropdownMenuTrigger asChild>
        <Button className="mx-2 flex" variant={"secondary"}>
          <Filter
            strokeWidth={ICON_STROKE_WIDTH}
            size={ICON_SIZE}
            className="mr-1"
          />
          {t("show")}
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent>
        <DropdownMenuLabel>{t("columns")}</DropdownMenuLabel>
        {columns.map((col) => {
          if (col.columnDef.enableHiding === false) return null;
          const label = col?.columnDef?.header?.toString();

          return (
            <div key={col.id} className="mx-2 flex h-[32px] items-center">
              <CheckboxWithLabel
                key={col.id}
                label={label}
                checked={col.getIsVisible()}
                onCheckedChange={() => col.toggleVisibility()}
              />
            </div>
          );
        })}
      </DropdownMenuContent>
    </DropdownMenuRoot>
  );
};
