import React, { FC, useContext } from "react";
import { Checkbox } from "@primitives/checkbox.tsx";
import { cn } from "@utils/css.ts";
import {
  CategoryClusterContext,
  Cluster,
} from "@pages/settings/category-cluster/category-cluster-context.tsx";
import { ClusterCategoryCard } from "@pages/settings/category-cluster/components/cluster-category-card.tsx";
import { useTranslation } from "react-i18next";
import { capitalizeFirstLetter } from "@utils/helpers.ts";

interface Props {
  cluster: Cluster;
}
export const ClusterCard: FC<Props> = ({ cluster }) => {
  const { t } = useTranslation();
  const { selectCluster } = useContext(CategoryClusterContext);
  return (
    <div className={"flex"}>
      <div
        className={cn(
          "mr-2 h-[200px] rounded-xl pl-[16px] pr-[16px] pt-5",
          cluster.selected
            ? "border-0 bg-amber-500-50"
            : "border border-component-border-color bg-primary-card-backplate",
        )}
      >
        <div>
          <div className={"flex justify-between"}>
            <div>
              <p className={"fw text-[14px] font-bold text-primary-text"}>
                {capitalizeFirstLetter(cluster.name)}
              </p>
              <p className={"mb-3 text-[12px] text-primary-text"}>
                {t("category-types")}
              </p>
            </div>
            <Checkbox
              onClick={(e) => {
                selectCluster(cluster.id);
                e.stopPropagation();
              }}
              checked={cluster.selected}
            />
          </div>
          <div className={"flex"}>
            {cluster.categories.map((cat, index) => {
              return (
                <ClusterCategoryCard
                  key={index}
                  cluster={cluster}
                  category={cat}
                  showCheck={true}
                />
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};
