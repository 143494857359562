import { useTranslation } from "react-i18next";
import { Button } from "@primitives/button";
import { GetBooking } from "../../../../../../../api-contracts/reservations";
import {
  Sheet,
  SheetContent,
  SheetHeader,
  SheetTitle,
} from "@primitives/sheet";
import { Label } from "@primitives/label";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@primitives/select";
import { useGetPriceRules } from "@api/price-rules";
import { useEffect, useMemo, useState } from "react";
import {
  format,
  eachDayOfInterval,
  isSameDay,
  addDays,
  subDays,
} from "date-fns";
import { useGetCategories } from "@api/categories";
import { useGetAllocableBookables } from "@api/bookables";
import { DateRangePicker } from "@primitives/date-range-picker";
import { DateRange } from "react-day-picker";
import { TimeSlot } from "../../../../../../../api-contracts/reservations/timeslots";
import { RadioGroup, RadioGroupItem } from "@primitives/radio-group";
import { uniqueId } from "lodash";
import { ConfirmDialog } from "@components/confirm-dialog";
import { useGetPricing } from "@api/pricing";
import {
  dateWithTimeString,
  dateWithTimeStringISO,
  addHoursAndMinutesToDateISO,
} from "@shared/utils/helpers";
import { priceRuleOverrideKeyToGuestCounts } from "@shared/utils/price-rules";

interface Props {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  availableOptions?: "asset" | "price" | "dates" | "all";
  onProceed: (updatedBooking: GetBooking) => void;
  booking: GetBooking;
  forceSave: boolean;
  dismissChanges: () => void;
  onSave: () => Promise<void>;
}

export const BookingChangeDrawer = ({
  open,
  onOpenChange,
  availableOptions,
  onProceed,
  booking,
  forceSave,
  dismissChanges,
  onSave,
}: Props) => {
  const { t, i18n } = useTranslation();
  const [bookableNotAvailableOpen, setBookableNotAvailableOpen] =
    useState<boolean>(false);
  const [changedBooking, setChangedBooking] = useState<GetBooking>(booking);
  const [priceOption, setPriceOption] = useState<
    | "keep-price"
    | "original-price"
    | "price-according-to-new-category"
    | "enter-new-price-code"
  >("original-price");
  const [saveLoading, setSaveLoading] = useState<boolean>(false);

  useEffect(() => {
    setChangedBooking(booking);
    setPriceOption(
      availableOptions === "price" ? "enter-new-price-code" : "keep-price",
    );
  }, [booking, availableOptions, open]);

  const {
    data: priceRules,
    isLoading: priceRulesIsLoading,
    isRefetching: priceRulesIsRefetching,
  } = useGetPriceRules({
    variables: {
      categoryTypes: [changedBooking.category.type],
    },
    enabled: !!changedBooking.category.type,
  });

  const {
    data: categories,
    isLoading: categoriesIsLoading,
    isRefetching: categoriesIsRefetching,
  } = useGetCategories({
    variables: {
      categoryTypes: ["room", "area", "bed", "dormitory"],
    },
  });

  const {
    data: bookables,
    isLoading: bookablesIsLoading,
    isRefetching: bookablesIsRefetching,
  } = useGetAllocableBookables({
    variables: {
      bookingId: changedBooking.id,
      categoryId: changedBooking.category.id,
      startDate: format(changedBooking.start, "yyyy-MM-dd"),
      endDate: format(changedBooking.end, "yyyy-MM-dd"),
    },
  });

  const {
    data: pricing,
    isLoading: pricingIsLoading,
    isRefetching: pricingIsRefetching,
  } = useGetPricing({
    variables: {
      id: changedBooking.category.id,
      slots: changedBooking.slots.map((s) => ({
        startDate: format(s.start, "yyyy-MM-dd"),
        startTime:
          categories?.find((c) => changedBooking.category.id === c.id)?.config!
            .startTimes[0] || "14.00",
      })),
      adults: changedBooking.guestCount.adults,
      teenagers: changedBooking.guestCount.teenagers,
      children: changedBooking.guestCount.children,
      infants: changedBooking.guestCount.infants,
    },
    enabled: !!changedBooking.category.id && categories && open,
  });

  useEffect(() => {
    handlePriceOptionChange(priceOption);
  }, [pricing, priceRules]);

  useEffect(() => {
    if (open && bookables && changedBooking?.bookable) {
      if (
        !bookables.bookables.find((b) => b.id === changedBooking.bookable?.id)
      ) {
        setChangedBooking({ ...changedBooking, bookable: null });
        setBookableNotAvailableOpen(true);
      }
    }
  }, [bookables]);

  const numGuests = useMemo(() => {
    return {
      adults: booking.guests.filter((g) => g.ageType === "adult").length,
      teenagers: booking.guests.filter((g) => g.ageType === "teenager").length,
      children: booking.guests.filter((g) => g.ageType === "child").length,
      infants: booking.guests.filter((g) => g.ageType === "infant").length,
    };
  }, [booking]);

  const total = useMemo(() => {
    return changedBooking.slots.reduce((acc, slot) => {
      return acc + slot.price;
    }, 0);
  }, [changedBooking]);

  const handleDatesChange = (values: { range: DateRange }) => {
    if (values.range.from && values.range.to) {
      updateState(
        changedBooking.slots.map((s) => ({
          date: s.start,
          priceRuleId: s.priceRuleId,
          priceOverrideAmount: s.priceOverrideAmount,
          priceAdjustmentPercent: s.priceAdjustmentPercent,
        })),
        changedBooking.category.id,
        changedBooking.bookable?.id || null,
        values.range.from,
        values.range.to,
      );
    }
  };

  const updateState = (
    rules: {
      date: string | Date;
      priceRuleId: string | null;
      priceOverrideAmount: number | null;
      priceAdjustmentPercent: number | null;
    }[],
    categoryId: string,
    bookableId: string | null,
    start: Date | string,
    end: Date | string,
  ) => {
    const category = categories?.find((c) => c.id === categoryId);
    if (!category) {
      setChangedBooking((changedBooking) => ({ ...changedBooking }));
      return;
    }

    const bookable =
      bookables?.bookables.find((b) => b.id === bookableId) ||
      (categoryId === booking.category.id && bookableId === booking.bookable?.id
        ? booking.bookable
        : undefined);

    const dates = eachDayOfInterval({
      start: start,
      end: subDays(end, 1),
    });
    const newSlots: TimeSlot[] = dates.map((date) => {
      const pricer = pricing?.slots.find((s) => isSameDay(date, s.startDate));
      const slot = booking.slots.find((s) => isSameDay(s.start, date));
      const rule = rules.find((r) => isSameDay(date, r.date));
      let priceRuleId = rule?.priceRuleId || null;
      const priceOverrideAmount = rule?.priceOverrideAmount || null;
      const priceAdjustmentPercent = rule?.priceAdjustmentPercent || null;

      let pr = priceRules?.rules.find(
        (r) =>
          (priceRuleId && r.id === priceRuleId) ||
          (!priceRuleId && pricer?.ruleId && r.id === pricer.ruleId),
      );
      if (priceRuleId && !pr) {
        pr = priceRules?.rules.find(
          (r) => pricer?.ruleId && r.id === pricer.ruleId,
        );
        priceRuleId = pricer?.ruleId || null;
      }
      const priceRule = pr?.entries.find((e) => e.categoryId === categoryId);
      const priceRuleOverride = priceRule?.overrides.find((o) => {
        const guestCounts = priceRuleOverrideKeyToGuestCounts(o.overrideKey);
        return (
          guestCounts.adults === numGuests.adults &&
          guestCounts.children === numGuests.children &&
          guestCounts.teenagers === numGuests.teenagers &&
          guestCounts.infants === numGuests.infants
        );
      });

      let fullPrice = priceRuleOverride?.price || priceRule?.price || 0;

      let price = priceOverrideAmount || fullPrice;

      if (priceAdjustmentPercent) {
        price = price * (1 - 0.01 * priceAdjustmentPercent);
      }

      return {
        id: slot?.id || uniqueId("new"),
        price: price,
        start: dateWithTimeStringISO(date, category.config!.startTimes[0]),
        end: addHoursAndMinutesToDateISO(
          dateWithTimeString(date, category.config!.startTimes[0]),
          category.config!.timeslotHours,
          category.config!.timeslotMinutes,
        ),
        priceRuleId: priceRuleId || pricer?.ruleId || null,
        priceRuleName: pr?.name || "",
        fullPrice: fullPrice,
        priceOverrideAmount: priceOverrideAmount,
        priceAdjustmentPercent: priceAdjustmentPercent,
      };
    });

    setChangedBooking((changedBooking) => ({
      ...changedBooking,
      start: dateWithTimeStringISO(start, category.config!.startTimes[0]),
      end: newSlots[newSlots.length - 1].end,
      slots: newSlots,
      category: category,
      bookable: bookable ? { ...bookable, lockedToBookable: false } : null,
    }));
  };

  const handlePriceOptionChange = (
    option:
      | "keep-price"
      | "original-price"
      | "price-according-to-new-category"
      | "enter-new-price-code",
  ) => {
    setPriceOption(option);

    switch (option) {
      case "keep-price":
        updateState(
          changedBooking.slots.map((s) => {
            const oldSlot = booking.slots.find((sOld) => sOld.id === s.id);

            return {
              date: s.start,
              priceRuleId: oldSlot?.priceRuleId || null,
              priceOverrideAmount:
                oldSlot?.priceOverrideAmount || oldSlot?.price || null,
              priceAdjustmentPercent: null,
            };
          }),
          changedBooking.category.id,
          changedBooking.bookable?.id || null,
          changedBooking.start,
          changedBooking.end,
        );
        break;
      case "original-price":
        updateState(
          changedBooking.slots.map((s) => {
            const oldSlot = booking.slots.find((sOld) => sOld.id === s.id);

            return {
              date: s.start,
              priceRuleId: oldSlot?.priceRuleId || null,
              priceOverrideAmount: oldSlot?.priceOverrideAmount || null,
              priceAdjustmentPercent: oldSlot?.priceAdjustmentPercent || null,
            };
          }),
          changedBooking.category.id,
          changedBooking.bookable?.id || null,
          changedBooking.start,
          changedBooking.end,
        );
        break;
      case "price-according-to-new-category":
      case "enter-new-price-code":
        updateState(
          changedBooking.slots.map((s) => ({
            date: s.start,
            priceRuleId: s.priceRuleId,
            priceOverrideAmount: null,
            priceAdjustmentPercent: null,
          })),
          changedBooking.category.id,
          changedBooking.bookable?.id || null,
          changedBooking.start,
          changedBooking.end,
        );
        break;
    }
  };

  const handleSaveReservation = async () => {
    setSaveLoading(true);
    await onSave();
    setSaveLoading(false);
  };

  return (
    <>
      <Sheet open={open} onOpenChange={onOpenChange}>
        <SheetContent
          side="left"
          className="flex w-[400px] flex-col overflow-y-auto sm:w-[600px]"
        >
          <SheetHeader>
            <SheetTitle className=" flex items-center justify-between text-primary-text">
              {availableOptions === "price" && t("change-price-code")}
              {availableOptions === "asset" && t("change-unit")}
              {availableOptions === "dates" && t("change-dates")}
              {availableOptions === "all" && t("change-booking")}
            </SheetTitle>
          </SheetHeader>

          {forceSave && (
            <div className=" mt-4">
              <p className=" text-xs font-normal text-secondary-text">
                {t(
                  "you-have-pending-changes-that-need-to-be-saved-or-dismissed-before-editing-booking-further",
                )}
              </p>

              <div className=" mt-4 flex justify-end space-x-2">
                <Button variant="outline" onClick={dismissChanges}>
                  {t("dismiss-changes")}
                </Button>
                <Button
                  onClick={handleSaveReservation}
                  loading={saveLoading}
                  variant="primary"
                >
                  {t("save")}
                </Button>
              </div>
            </div>
          )}

          {!forceSave && (
            <>
              <div className=" flex-1 overflow-y-auto text-primary-text">
                {(availableOptions === "dates" ||
                  availableOptions === "all") && (
                  <div className="mt-4 bg-secondary-card-backplate p-4">
                    <h3 className=" mb-4 text-lg font-bold">
                      {t("change-dates")}
                    </h3>
                    <div>
                      <DateRangePicker
                        min={2}
                        initialDateFrom={changedBooking.start}
                        initialDateTo={changedBooking.end}
                        onUpdate={handleDatesChange}
                        disabled={{
                          before: new Date(),
                        }}
                      />
                    </div>
                  </div>
                )}

                {(availableOptions === "asset" ||
                  availableOptions === "all") && (
                  <div className="mt-4 bg-secondary-card-backplate p-4">
                    <h3 className=" mb-4 text-lg font-bold">
                      {t("change-unit")}
                    </h3>
                    <div>
                      <Label htmlFor="booking-change-category">
                        {t("category")}
                      </Label>
                      <Select
                        key={changedBooking.category.id}
                        disabled={booking.bookable?.lockedToBookable}
                        value={changedBooking.category.id}
                        onValueChange={(value) => {
                          updateState(
                            changedBooking.slots.map((s) => ({
                              date: s.start,
                              priceRuleId: s.priceRuleId,
                              priceOverrideAmount: s.priceOverrideAmount,
                              priceAdjustmentPercent: s.priceAdjustmentPercent,
                            })),
                            value,
                            null,
                            changedBooking.start,
                            changedBooking.end,
                          );
                        }}
                      >
                        <SelectTrigger
                          className=" w-full"
                          id="booking-change-category"
                        >
                          <SelectValue />
                        </SelectTrigger>
                        <SelectContent>
                          {categories?.map((c) => (
                            <SelectItem
                              key={c.id}
                              value={c.id}
                              className=" text-xs"
                            >
                              {t(c.name)}
                            </SelectItem>
                          ))}
                        </SelectContent>
                      </Select>
                      {(changedBooking.guestCount.adults +
                        changedBooking.guestCount.children +
                        changedBooking.guestCount.teenagers +
                        changedBooking.guestCount.infants >
                        changedBooking.category.capacity.total.max ||
                        changedBooking.guestCount.adults >
                          changedBooking.category.capacity.adults.max ||
                        changedBooking.guestCount.adults <
                          changedBooking.category.capacity.adults.min ||
                        changedBooking.guestCount.teenagers >
                          changedBooking.category.capacity.teenagers.max ||
                        changedBooking.guestCount.children >
                          changedBooking.category.capacity.children.max ||
                        changedBooking.guestCount.infants >
                          changedBooking.category.capacity.infants.max) && (
                        <p className=" mt-2 text-xs font-normal text-status-error">
                          {t(
                            "the-{{guests}}-guests-of-the-booking-do-not-fit-in-{{category}}",
                            {
                              category: changedBooking.category.name,
                              guests: changedBooking.guests.length,
                            },
                          )}
                          .
                        </p>
                      )}
                    </div>
                    <div className="mt-2">
                      <Label htmlFor="booking-change-asset">{t("unit")}</Label>
                      <Select
                        key={changedBooking.bookable?.id}
                        disabled={booking.bookable?.lockedToBookable}
                        value={changedBooking.bookable?.id}
                        onValueChange={(value) => {
                          updateState(
                            changedBooking.slots.map((s) => ({
                              date: s.start,
                              priceRuleId: s.priceRuleId,
                              priceOverrideAmount: s.priceOverrideAmount,
                              priceAdjustmentPercent: s.priceAdjustmentPercent,
                            })),
                            changedBooking.category.id,
                            value,
                            changedBooking.start,
                            changedBooking.end,
                          );
                        }}
                      >
                        <SelectTrigger
                          className=" w-full"
                          id="booking-change-asset"
                        >
                          <SelectValue />
                        </SelectTrigger>
                        <SelectContent>
                          {bookables?.bookables?.map((b) => (
                            <SelectItem
                              key={b.id}
                              value={b.id}
                              className=" text-xs"
                            >
                              {t(b.name)}
                            </SelectItem>
                          ))}
                          {bookables?.bookables.length === 0 && (
                            <p className="p-2 text-center text-sm font-normal text-secondary-text">
                              {t("no-units-available")}
                            </p>
                          )}
                        </SelectContent>
                      </Select>
                    </div>
                  </div>
                )}

                {(availableOptions === "price" ||
                  availableOptions === "dates" ||
                  availableOptions === "asset" ||
                  availableOptions === "all") && (
                  <div className="mt-4 bg-secondary-card-backplate p-4">
                    <h3 className=" mb-4 text-lg font-bold">
                      {t("change-price-code")}
                    </h3>

                    {(availableOptions === "dates" ||
                      availableOptions === "asset" ||
                      availableOptions === "all") && (
                      <RadioGroup
                        className="mb-4"
                        value={priceOption}
                        onValueChange={(v) =>
                          handlePriceOptionChange(
                            v as
                              | "keep-price"
                              | "original-price"
                              | "price-according-to-new-category"
                              | "enter-new-price-code",
                          )
                        }
                      >
                        <div className="flex items-center space-x-2">
                          <RadioGroupItem value="keep-price" id="keep-price" />
                          <Label htmlFor="keep-price">
                            {t("keep-original-price")}
                          </Label>
                        </div>
                        <div className="mt-2 flex items-center space-x-2">
                          <RadioGroupItem
                            value="original-price"
                            id="original-price"
                          />
                          <Label htmlFor="original-price">
                            {t("keep-original-price-adjustment")}
                          </Label>
                        </div>
                        <div className="mt-2 flex items-center space-x-2">
                          <RadioGroupItem
                            value="price-according-to-new-category"
                            id="price-according-to-new-category"
                          />
                          <Label htmlFor="price-according-to-new-category">
                            {t("price-according-to-new-category")}
                          </Label>
                        </div>
                        <div className="mt-2 flex items-center space-x-2">
                          <RadioGroupItem
                            value="enter-new-price-code"
                            id="enter-new-price-code"
                          />
                          <Label htmlFor="enter-new-price-code">
                            {t("enter-new-price-code")}
                          </Label>
                        </div>
                      </RadioGroup>
                    )}

                    {priceOption === "enter-new-price-code" && (
                      <>
                        <Label htmlFor="booking-change-price-code">
                          {t("change-price-code-for-all-nights")}
                        </Label>
                        <Select
                          value={
                            changedBooking.slots.every(
                              (s) =>
                                s.priceRuleId ===
                                changedBooking.slots[0].priceRuleId,
                            )
                              ? changedBooking.slots[0].priceRuleId || undefined
                              : undefined
                          }
                          onValueChange={(value) => {
                            updateState(
                              changedBooking.slots.map((s) => ({
                                date: s.start,
                                priceRuleId: value || null,
                                priceOverrideAmount: null,
                                priceAdjustmentPercent: null,
                              })),
                              changedBooking.category.id,
                              changedBooking.bookable?.id || null,
                              changedBooking.start,
                              changedBooking.end,
                            );
                          }}
                        >
                          <SelectTrigger
                            className=" w-full"
                            id="booking-change-price-code"
                          >
                            <SelectValue />
                          </SelectTrigger>
                          <SelectContent>
                            {priceRules?.rules.map((r) => (
                              <SelectItem
                                key={r.id}
                                value={r.id}
                                className=" text-xs"
                              >
                                {t(r.name)}
                              </SelectItem>
                            ))}
                            {priceRules?.rules.length === 0 && (
                              <p className="p-2 text-center text-sm font-normal text-secondary-text">
                                {t("no-price-rules-available")}
                              </p>
                            )}
                          </SelectContent>
                        </Select>

                        <div className="mt-2">
                          {changedBooking.slots.map((slot, i) => (
                            <div
                              key={i}
                              className=" flex items-center justify-between border-t border-border-color py-2"
                            >
                              <p className=" text-sm font-bold">
                                {format(slot.start, "d MMMM")}
                              </p>
                              <div className=" w-1/2">
                                <Select
                                  value={
                                    changedBooking.slots[i].priceRuleId ||
                                    undefined
                                  }
                                  onValueChange={(value) => {
                                    updateState(
                                      changedBooking.slots.map((s, j) => ({
                                        date: s.start,
                                        priceRuleId:
                                          j === i
                                            ? value || null
                                            : s.priceRuleId,
                                        priceOverrideAmount: null,
                                        priceAdjustmentPercent: null,
                                      })),
                                      changedBooking.category.id,
                                      changedBooking.bookable?.id || null,
                                      changedBooking.start,
                                      changedBooking.end,
                                    );
                                  }}
                                >
                                  <SelectTrigger
                                    className=" w-full"
                                    id="price-code"
                                  >
                                    <SelectValue />
                                  </SelectTrigger>
                                  <SelectContent>
                                    {priceRules?.rules.map((r) => (
                                      <SelectItem
                                        key={r.id}
                                        value={r.id}
                                        className=" text-xs"
                                      >
                                        {t(r.name)}
                                      </SelectItem>
                                    ))}
                                    {priceRules?.rules.length === 0 && (
                                      <p className="p-2 text-center text-sm font-normal text-secondary-text">
                                        {t("no-price-rules-available")}
                                      </p>
                                    )}
                                  </SelectContent>
                                </Select>
                              </div>
                            </div>
                          ))}
                        </div>
                      </>
                    )}
                  </div>
                )}
              </div>

              <div className=" flex items-center justify-between space-x-2">
                <p className=" text-md font-bold">
                  {t("total")}:{" "}
                  {new Intl.NumberFormat(
                    i18n.language === "sv-se" ? "sv-SE" : "en-GB",
                    { maximumFractionDigits: 2 },
                  ).format(total)}{" "}
                  SEK
                </p>
                <div className=" flex justify-end space-x-2 ">
                  <Button variant="outline" onClick={() => onOpenChange(false)}>
                    {t("cancel")}
                  </Button>
                  <Button
                    disabled={
                      changedBooking.guestCount.adults +
                        changedBooking.guestCount.children +
                        changedBooking.guestCount.teenagers +
                        changedBooking.guestCount.infants >
                        changedBooking.category.capacity.total.max ||
                      changedBooking.guestCount.adults >
                        changedBooking.category.capacity.adults.max ||
                      changedBooking.guestCount.adults <
                        changedBooking.category.capacity.adults.min ||
                      changedBooking.guestCount.teenagers >
                        changedBooking.category.capacity.teenagers.max ||
                      changedBooking.guestCount.children >
                        changedBooking.category.capacity.children.max ||
                      changedBooking.guestCount.infants >
                        changedBooking.category.capacity.infants.max
                    }
                    onClick={() => {
                      onProceed(changedBooking);
                      onOpenChange(false);
                    }}
                  >
                    {t("apply")}
                  </Button>
                </div>
              </div>
            </>
          )}
        </SheetContent>
      </Sheet>
      <ConfirmDialog
        hideCancel
        proceedBtnText={t("continue")}
        title={t("assigned-unit-is-not-available")}
        description={t(
          "assigned-unit-is-not-available-those-dates-and-will-be-removed-from-the-booking-if-applying",
        )}
        onOpenChange={setBookableNotAvailableOpen}
        isOpen={bookableNotAvailableOpen}
        onProceed={() => {}}
      />
    </>
  );
};

export default BookingChangeDrawer;
