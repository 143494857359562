import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from "react-router-dom";
import ForgotPassword from "./auth/forgot-password";
import Login from "./auth/login";
import Logout from "./auth/logout";
import Protected from "./auth/protected";
import Verify from "./auth/verify";
import Root from "./root";
import Error from "./error";
import * as loginLoaderAction from "./auth/login.loader";
import ProtectedAdmin from "./auth/admin";
import { AdminView } from "./admin";
import LocationsPage from "./settings/locations";
import { BridgesPage } from "./settings/bridges";
import TitlesPage from "./settings/titles";
import CategoriesPage from "./settings/categories";
import Bookables from "./settings/bookables";
import ChannelsPage from "./settings/channels";
import TargetGroupsPage from "./settings/target-groups";
import CategoriesDetailPage from "./settings/categories/categories-detail";
import { StatusPage } from "./settings/status";
import CombinationDetailsPage from "./settings/bookables/combination-details";
import { GroupsPage } from "./settings/groups";
import CategoryGridPage from "./booking/category-grid";
import TagsPage from "./settings/tags";
import NotFound from "./not-found";
import UnallocatedPage from "./booking/unallocated";
import FixtureAndAmenityPage from "./settings/fixtures-and-amenities";
import {
  AMENITIES_PERMISSION,
  ASSET_GROUPS_PERMISSION,
  ASSETS_PERMISSION,
  BED_CAPACITY_PERMISSION,
  BRIDGES_PERMISSION,
  CATEGORIES_PERMISSION,
  CHANNELS_PERMISSION,
  COMMUNICATION_PERMISSION,
  FIXTURES_PERMISSION,
  LOCATIONS_PERMISSION,
  PRICE_CALENDAR_PERMISSION,
  PRICE_RULES_PERMISSION,
  RECEPTION_PERMISSION,
  RESERVATION_PERMISSION,
  ROUTES,
  TAGS_PERMISSION,
  TARGET_GROUPS_PERMISSION,
  TITLES_PERMISSION,
  CATEGORY_CLUSTERS_PERMISSION,
  COMMUNICATION_SPHERES_PERMISSION,
  SEARCH_PERMISSION,
} from "@shared/types/navigation";
import { Clusters, useProfileContext } from "@context/profile-context";
import { Loading } from "@primitives/loading";
import { Suspense, useEffect, useState } from "react";
import { BedTypesPage } from "@pages/settings/bed-types";
import AssetGridPage from "./booking/asset-grid";
import ReservationPage from "./booking/reservations/reservation";
import ReservationsPage from "./booking/reservations";
import PriceCalendarPage from "./booking/price-calendar";
import DashboardPage from "./dashboard/dashboard";
import ArrivalsPage from "./reception/arrivals";
import { CategoryTypeEnum } from "./reception/reception-context";
import { BookableDetails } from "pages/settings/bookables/bookable-details";
import DeparturesPage from "./reception/departures";
import OccupantsPage from "./reception/occupants";
import FireListPage from "./reception/fire-list";
import PriceRulesPage from "./booking/price-rules";
import CommunicationSpheresPage from "./settings/communication-spheres";
import CommunicationSphereDetailsPage from "./settings/communication-spheres/communication-sphere-details";
import { CategoryCluster } from "@pages/settings/category-cluster";
import SimpleSearchPage from "./simple-search";

type RouteType = {
  path: string;
  component: JSX.Element;
  permissions: string[];
};

interface Props {
  cluster: Clusters[];
}

export function Router(): JSX.Element {
  const { module, routePermissions, clusters } = useProfileContext();
  const [filteredRoutes, setFilteredRoutes] = useState<RouteType[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (!module || !routePermissions.length) {
      setIsLoading(true);
      return;
    }

    let clusterRoutes: RouteType[] = [];

    clusters.campr.forEach((cl) =>
      clusterRoutes.push({
        path: `${ROUTES.CATEGORY_GRID}/${cl.name}`,
        component: <CategoryGridPage tab={cl.categoryTypes} />,
        permissions: [RESERVATION_PERMISSION],
      }),
    );
    clusters.roomr.forEach((cl) =>
      clusterRoutes.push({
        path: `${ROUTES.CATEGORY_GRID}/${cl.name}`,
        component: <CategoryGridPage tab={cl.categoryTypes} />,
        permissions: [RESERVATION_PERMISSION],
      }),
    );
    clusterRoutes.push(
      {
        path: `${ROUTES.ASSET_GRID_HOTEL}`,
        component: <AssetGridPage tab="hotel" />,
        permissions: [RESERVATION_PERMISSION],
      },
      {
        path: `${ROUTES.ASSET_GRID_AREA}`,
        component: <AssetGridPage tab="area" />,
        permissions: [RESERVATION_PERMISSION],
      },
      {
        path: `${ROUTES.ASSET_GRID_HOSTEL}`,
        component: <AssetGridPage tab="hostel" />,
        permissions: [RESERVATION_PERMISSION],
      },
      {
        path: `${ROUTES.COMMUNICATION_SPHERES}`,
        component: <CommunicationSpheresPage />,
        permissions: [COMMUNICATION_SPHERES_PERMISSION],
      },
      {
        path: `${ROUTES.COMMUNICATION_SPHERES}/:id`,
        component: <CommunicationSphereDetailsPage />,
        permissions: [COMMUNICATION_SPHERES_PERMISSION],
      },
      {
        path: `${ROUTES.RESERVATIONS}/:id`,
        component: <ReservationPage />,
        permissions: [RESERVATION_PERMISSION],
      },
      {
        path: `${ROUTES.RESERVATIONS}`,
        component: <ReservationsPage />,
        permissions: [RESERVATION_PERMISSION],
      },
      {
        path: `${ROUTES.UNALLOCATED}/:from/:to`,
        component: <UnallocatedPage />,
        permissions: [RESERVATION_PERMISSION],
      },
      {
        path: `${ROUTES.PRICE_CALENDAR}`,
        component: <PriceCalendarPage />,
        permissions: [PRICE_CALENDAR_PERMISSION],
      },
      {
        path: `${ROUTES.PRICE_RULES}`,
        component: <PriceRulesPage tab={CategoryTypeEnum.HOTEL} />,
        permissions: [PRICE_RULES_PERMISSION],
      },
      {
        path: `${ROUTES.PRICE_RULES_HOTEL}`,
        component: <PriceRulesPage tab={CategoryTypeEnum.HOTEL} />,
        permissions: [PRICE_RULES_PERMISSION],
      },
      {
        path: `${ROUTES.PRICE_RULES_HOSTEL}`,
        component: <PriceRulesPage tab={CategoryTypeEnum.HOSTEL} />,
        permissions: [PRICE_RULES_PERMISSION],
      },
      {
        path: `${ROUTES.PRICE_RULES_AREA}`,
        component: <PriceRulesPage tab={CategoryTypeEnum.AREA} />,
        permissions: [PRICE_RULES_PERMISSION],
      },
      {
        path: `${ROUTES.LOCATIONS}`,
        component: <LocationsPage />,
        permissions: [LOCATIONS_PERMISSION],
      },
      {
        path: `${ROUTES.BOOKABLES}/${ROUTES.ASSETS}`,
        component: <Bookables tab="all" />,
        permissions: [ASSETS_PERMISSION],
      },
      {
        path: `${ROUTES.BOOKABLES}/${ROUTES.ROOMS}`,
        component: <Bookables tab="room" />,
        permissions: [ASSETS_PERMISSION],
      },
      {
        path: `${ROUTES.BOOKABLES}/${ROUTES.ROOMS}/:id`,
        component: <BookableDetails type={"room"} categoryTypes={["room"]} />,
        permissions: [ASSETS_PERMISSION],
      },
      {
        path: `${ROUTES.BOOKABLES}/${ROUTES.ROOMS}/:id/duplicate`,
        component: <BookableDetails type={"room"} categoryTypes={["room"]} />,
        permissions: [ASSETS_PERMISSION],
      },
      {
        path: `${ROUTES.BOOKABLES}/${ROUTES.AREAS}`,
        component: (
          <Bookables tab={module === "roomr" ? "area" : "campr-area"} />
        ),
        permissions: [ASSETS_PERMISSION],
      },
      {
        path: `${ROUTES.BOOKABLES}/${ROUTES.AREAS}/:id`,
        component:
          module === "roomr" ? (
            <BookableDetails type={"area"} categoryTypes={["area"]} />
          ) : (
            <BookableDetails
              type={"campr-area"}
              categoryTypes={["campr-area"]}
            />
          ),
        permissions: [ASSETS_PERMISSION],
      },
      {
        path: `${ROUTES.BOOKABLES}/${ROUTES.AREAS}/:id/duplicate`,
        component: <BookableDetails type={"area"} categoryTypes={["area"]} />,
        permissions: [ASSETS_PERMISSION],
      },
      {
        path: `${ROUTES.BOOKABLES}/${ROUTES.BEDS}`,
        component: <Bookables tab="bed" />,
        permissions: [ASSETS_PERMISSION],
      },
      {
        path: `${ROUTES.BOOKABLES}/${ROUTES.BEDS}/:id`,
        component: <BookableDetails type={"bed"} categoryTypes={["bed"]} />,
        permissions: [ASSETS_PERMISSION],
      },
      {
        path: `${ROUTES.BOOKABLES}/${ROUTES.BEDS}/:id/duplicate`,
        component: <BookableDetails type={"bed"} categoryTypes={["bed"]} />,
        permissions: [ASSETS_PERMISSION],
      },
      {
        path: `${ROUTES.BOOKABLES}/${ROUTES.DORMITORIES}`,
        component: <Bookables tab="dormitory" />,
        permissions: [ASSETS_PERMISSION],
      },
      {
        path: `${ROUTES.BOOKABLES}/${ROUTES.DORMITORIES}/:id`,
        component: <CombinationDetailsPage />,
        permissions: [ASSETS_PERMISSION],
      },
      {
        path: `${ROUTES.BOOKABLES}/${ROUTES.DORMITORIES}/:id/duplicate`,
        component: <CombinationDetailsPage />,
        permissions: [ASSETS_PERMISSION],
      },
      {
        path: `${ROUTES.BOOKABLES}/${ROUTES.COMBINATIONS}`,
        component: (
          <Bookables
            tab={module === "roomr" ? "combination" : "campr-combination"}
          />
        ),
        permissions: [ASSETS_PERMISSION],
      },
      {
        path: `${ROUTES.BOOKABLES}/${ROUTES.COMBINATIONS}/:id`,
        component: <CombinationDetailsPage />,
        permissions: [ASSETS_PERMISSION],
      },
      {
        path: `${ROUTES.GROUPS}`,
        component: <GroupsPage />,
        permissions: [ASSET_GROUPS_PERMISSION],
      },
      {
        path: `${ROUTES.BRIDGES}`,
        component: <BridgesPage />,
        permissions: [BRIDGES_PERMISSION],
      },
      {
        path: `${ROUTES.TITLES}`,
        component: <TitlesPage />,
        permissions: [TITLES_PERMISSION],
      },
      {
        path: `${ROUTES.CHANNELS}`,
        component: <ChannelsPage />,
        permissions: [CHANNELS_PERMISSION],
      },
      {
        path: `${ROUTES.TARGET_GROUPS}`,
        component: <TargetGroupsPage />,
        permissions: [TARGET_GROUPS_PERMISSION],
      },
      {
        path: `${ROUTES.CATEGORIES}`,
        component: <CategoriesPage />,
        permissions: [CATEGORIES_PERMISSION],
      },
      {
        path: `${ROUTES.CATEGORIES}/:id/`,
        component: <CategoriesDetailPage />,
        permissions: [CATEGORIES_PERMISSION],
      },
      {
        path: `${ROUTES.CATEGORIES}/:id/duplicate`,
        component: <CategoriesDetailPage />,
        permissions: [CATEGORIES_PERMISSION],
      },
      {
        path: `${ROUTES.STATUS}`,
        component: <StatusPage />,
        permissions: [RESERVATION_PERMISSION],
      },
      {
        path: `${ROUTES.TAGS}`,
        component: <TagsPage />,
        permissions: [TAGS_PERMISSION],
      },
      {
        path: `${ROUTES.AMENITIES}`,
        component: <FixtureAndAmenityPage />,
        permissions: [AMENITIES_PERMISSION, FIXTURES_PERMISSION],
      },
      {
        path: `${ROUTES.BED_TYPES}`,
        component: <BedTypesPage />,
        permissions: [BED_CAPACITY_PERMISSION],
      },
      {
        path: `${ROUTES.ARRIVALS}`,
        component: <ArrivalsPage tab={CategoryTypeEnum.ALL} />,
        permissions: [RECEPTION_PERMISSION],
      },
      {
        path: `${ROUTES.ARRIVALS_HOTEL}`,
        component: <ArrivalsPage tab={CategoryTypeEnum.HOTEL} />,
        permissions: [RECEPTION_PERMISSION],
      },
      {
        path: `${ROUTES.ARRIVALS_HOSTEL}`,
        component: <ArrivalsPage tab={CategoryTypeEnum.HOSTEL} />,
        permissions: [RECEPTION_PERMISSION],
      },
      {
        path: `${ROUTES.ARRIVALS_AREA}`,
        component: <ArrivalsPage tab={CategoryTypeEnum.AREA} />,
        permissions: [RECEPTION_PERMISSION],
      },
      {
        path: `${ROUTES.BOOKABLES}/${ROUTES.PLOTS}`,
        component: <Bookables tab={"plot"} />,
        permissions: [RECEPTION_PERMISSION],
      },
      {
        path: `${ROUTES.BOOKABLES}/${ROUTES.CABINS}`,
        component: <Bookables tab={"cabin"} />,
        permissions: [RECEPTION_PERMISSION],
      },
      {
        path: `${ROUTES.BOOKABLES}/${ROUTES.COMBINATIONS}`,
        component: <Bookables tab={"combination"} />,
        permissions: [RECEPTION_PERMISSION],
      },
      {
        path: `${ROUTES.BOOKABLES}/${ROUTES.OTHER_ACCOMMODATIONS}`,
        component: <Bookables tab={"misc"} />,
        permissions: [RECEPTION_PERMISSION],
      },
      {
        path: `${ROUTES.BOOKABLES}/${ROUTES.CABINS}/:id`,
        component: (
          <BookableDetails
            type={"cabin"}
            categoryTypes={["cabin", "caravan", "glamping"]}
          />
        ),
        permissions: [ASSETS_PERMISSION],
      },
      {
        path: `${ROUTES.BOOKABLES}/${ROUTES.PLOTS}/:id`,
        component: (
          <BookableDetails
            type={"plot"}
            categoryTypes={["tent", "motorhome", "caravan"]}
          />
        ),
        permissions: [ASSETS_PERMISSION],
      },
      {
        path: `${ROUTES.BOOKABLES}/${ROUTES.OTHER_ACCOMMODATIONS}/:id`,
        component: <BookableDetails type={"misc"} categoryTypes={["misc"]} />,
        permissions: [ASSETS_PERMISSION],
      },
      {
        path: `${ROUTES.BOOKABLES}/${ROUTES.COMBINATIONS}/:id`,
        component: <CombinationDetailsPage />,
        permissions: [ASSETS_PERMISSION],
      },
      {
        path: `${ROUTES.DEPARTURES}`,
        component: <DeparturesPage tab={CategoryTypeEnum.ALL} />,
        permissions: [RECEPTION_PERMISSION],
      },
      {
        path: `${ROUTES.DEPARTURES_HOTEL}`,
        component: <DeparturesPage tab={CategoryTypeEnum.HOTEL} />,
        permissions: [RECEPTION_PERMISSION],
      },
      {
        path: `${ROUTES.DEPARTURES_HOSTEL}`,
        component: <DeparturesPage tab={CategoryTypeEnum.HOSTEL} />,
        permissions: [RECEPTION_PERMISSION],
      },
      {
        path: `${ROUTES.DEPARTURES_AREA}`,
        component: <OccupantsPage tab={CategoryTypeEnum.AREA} />,
        permissions: [RECEPTION_PERMISSION],
      },
      {
        path: `${ROUTES.OCCUPANTS}`,
        component: <OccupantsPage tab={CategoryTypeEnum.ALL} />,
        permissions: [RECEPTION_PERMISSION],
      },
      {
        path: `${ROUTES.OCCUPANTS_HOTEL}`,
        component: <OccupantsPage tab={CategoryTypeEnum.HOTEL} />,
        permissions: [RECEPTION_PERMISSION],
      },
      {
        path: `${ROUTES.OCCUPANTS_HOSTEL}`,
        component: <OccupantsPage tab={CategoryTypeEnum.HOSTEL} />,
        permissions: [RECEPTION_PERMISSION],
      },
      {
        path: `${ROUTES.OCCUPANTS_AREA}`,
        component: <OccupantsPage tab={CategoryTypeEnum.AREA} />,
        permissions: [RECEPTION_PERMISSION],
      },
      {
        path: `${ROUTES.FIRE_LIST}`,
        component: <FireListPage />,
        permissions: [RECEPTION_PERMISSION],
      },
      {
        path: `${ROUTES.CATEGORY_CLUSTER}`,
        component: <CategoryCluster />,
        permissions: [CATEGORY_CLUSTERS_PERMISSION],
      },
      {
        path: `${ROUTES.SIMPLE_SEARCH}`,
        component: <SimpleSearchPage />,
        permissions: [SEARCH_PERMISSION],
      },
    );
    /** Defined routes and its permissions */
    const routes: RouteType[] = clusterRoutes;

    /** Filter routes based on permissions */
    const filteredRoutes = routes.filter((route) =>
      route.permissions.some((permission) =>
        routePermissions.includes(permission),
      ),
    );
    setFilteredRoutes(filteredRoutes);
    setIsLoading(false);
  }, [module, routePermissions, clusters]);

  /** Converting route object into a flattened array with permissions */
  const flattenedPermissions = Array.from(
    new Set(filteredRoutes.flatMap((route) => route.permissions)),
  );

  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route path={`/`} element={<Root />} errorElement={<Error />}>
        {/* Public pages */}
        <Route path="/login" element={<Login />} {...loginLoaderAction} />
        <Route path="/logout" element={<Logout />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/verify" element={<Verify />} />

        {/* Protected routes */}
        <Route
          element={
            <Protected
              routePermissions={flattenedPermissions}
              module={module}
            />
          }
        >
          <Route path={`/${module}`} element={<DashboardPage />} />
          {/** Mapping routes for the selected module */}
          {filteredRoutes.map(({ path, component }) => (
            <Route key={path} path={`/${module}/${path}`} element={component} />
          ))}

          <Route element={<ProtectedAdmin />}>
            {/* Admin Routes */}
            <Route path={`/:module/admin`} element={<AdminView />} />
          </Route>
        </Route>

        {/* Unmatched paths */}
        <Route
          path="*"
          element={
            !isLoading ? (
              <NotFound />
            ) : (
              <div className=" h-screen w-screen">
                <Loading />
              </div>
            )
          }
        />
      </Route>,
    ),
  );

  return (
    <Suspense
      fallback={
        <div className=" h-screen w-screen">
          <Loading />
        </div>
      }
    >
      <RouterProvider router={router} />
    </Suspense>
  );
}

export default Router;
