import { AlertDialog } from "@primitives/alert-dialog.tsx";
import React, { FC, useContext, useEffect, useState } from "react";
import { PatchCategoryClusterRequest } from "../../../../../../api-contracts/category-clusters";
import {
  CategoryClusterContext,
  Cluster,
  ClusterCategory,
} from "@pages/settings/category-cluster/category-cluster-context.tsx";
import { RadioGroup, RadioGroupItem } from "@primitives/radio-group.tsx";
import { Label } from "@primitives/label.tsx";
import { queryClient } from "../../../../query-client.ts";
import {
  useGetCategoryClusters,
  usePatchClusterById,
} from "@api/category-clusters.ts";
import { toast } from "@hooks/use-toast.ts";
import { useTranslation } from "react-i18next";
import { Space } from "@components/space.tsx";

interface Props {
  open: boolean;
  onOpenChange: (open: boolean) => void;
}

export const MoveCategoriesDialog: FC<Props> = ({ open, onOpenChange }) => {
  const { clusterData } = useContext(CategoryClusterContext);
  const { mutateAsync: patchCluster } = usePatchClusterById();
  const { t } = useTranslation();
  const [clusterToMoveTo, setClusterToMoveTo] = useState<Cluster>();

  const patchClusters = async () => {
    let selectedCategories = clusterData.categoryTypes
      .filter((cat: ClusterCategory) => cat.selected)
      .map((cat: ClusterCategory) => cat.category);
    if (clusterToMoveTo)
      selectedCategories = [
        ...selectedCategories,
        ...clusterToMoveTo.categories.map((cat) => cat.category),
      ];
    // @ts-ignore
    const cluster: PatchCategoryClusterRequest = {
      name: clusterToMoveTo?.name,
      categoryTypes: selectedCategories,
    };
    try {
      await patchCluster({
        patch: cluster,
        id: clusterToMoveTo?.id ?? "",
      });
      await queryClient.invalidateQueries({
        queryKey: useGetCategoryClusters.getKey(),
      });
      toast({
        title: t("saved-succesfully", { name: clusterToMoveTo?.name }),
        variant: "success",
      });
    } catch (error) {
      toast({
        title: t("request-failed-with"),
        description: t(
          decodeURIComponent(
            (error instanceof Error && error.message) || t("no-message"),
          ),
        ),
        variant: "destructive",
      });
    }
  };

  useEffect(() => {
    setClusterToMoveTo(clusterData?.clusters[0]);
  }, [clusterData?.clusters]);

  return (
    <AlertDialog.Root open={open} onOpenChange={onOpenChange}>
      <AlertDialog.Content className="z-[2001]">
        <AlertDialog.Header>
          <AlertDialog.Title>
            <div className={"mb-[15px] flex"}>
              <p>{t("move-category-types")}</p>
              <Space w={5} />
              <p>
                {clusterData?.categoryTypes.map((cl: ClusterCategory) =>
                  cl.selected ? t(cl.category) + " " : "",
                )}
              </p>
              <Space w={5} />
              <p>{t("to").toLowerCase()}:</p>
            </div>
          </AlertDialog.Title>
          <AlertDialog.Description>
            <RadioGroup defaultValue={clusterToMoveTo?.name}>
              {clusterData?.clusters.map((cl: Cluster) => (
                <div key={cl.name} className={"flex text-center"}>
                  <RadioGroupItem
                    value={cl.name}
                    onClick={() => setClusterToMoveTo(cl)}
                  />
                  <Label
                    className={
                      "ml-1 text-[14px] font-extrabold text-primary-text"
                    }
                  >
                    {cl.name}
                  </Label>
                </div>
              ))}
            </RadioGroup>
          </AlertDialog.Description>
        </AlertDialog.Header>
        <AlertDialog.Footer className="relative">
          <AlertDialog.Cancel>{t("cancel")}</AlertDialog.Cancel>

          <AlertDialog.Action onClick={patchClusters}>
            {t("move")}
          </AlertDialog.Action>
        </AlertDialog.Footer>
      </AlertDialog.Content>
    </AlertDialog.Root>
  );
};
