import { Button } from "@primitives/button";
import { CardTitle } from "@primitives/card";
import { useTranslation } from "react-i18next";
import { MessageOfDay } from "../../components/message-of-day";
import { useReceptionContext } from "../../reception-context";
import { DatePicker } from "../../components/date-picker";
import { ConfirmDialog } from "@components/confirm-dialog";
import { Dispatch, SetStateAction, useState } from "react";

import { X } from "lucide-react";
import { ReceptionBooking } from "../../../../../../api-contracts/reception";
import {
  useGetDeparturesByDate,
  usePostMultipleCheckOutByBookingId,
} from "@api/reception";
import { toast } from "@hooks/use-toast";
import { queryClient } from "query-client";

export const DeparturesCardHeader = () => {
  const { t } = useTranslation();
  const { departures, setDate, date, selectedBookings, setSelectedBookings } =
    useReceptionContext();

  const departuresCount = departures.length;

  return (
    <>
      <div className="flex items-center justify-between border-b border-highlighted-backplate p-4">
        <div className="flex flex-col">
          <CardTitle>{t("departures")}</CardTitle>
          <p className="text-xs font-normal text-secondary-text">
            {departuresCount === 1
              ? t("{{count}}-bookable-unit", { count: departuresCount })
              : t("{{count}}-bookable-units", { count: departuresCount })}
          </p>
        </div>
        <div className="flex h-16 flex-grow items-center justify-center text-center">
          {selectedBookings.length > 0 ? (
            <SelectedActionBar
              selectedBookings={selectedBookings}
              setSelectedBookings={setSelectedBookings}
            />
          ) : (
            <>
              <div>
                <DatePicker date={date} setDate={setDate} />
              </div>
              <div className="mx-2">
                <MessageOfDay />
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

const SelectedActionBar = ({
  selectedBookings,
  setSelectedBookings,
}: {
  selectedBookings: ReceptionBooking[];
  setSelectedBookings: Dispatch<SetStateAction<ReceptionBooking[]>>;
}) => {
  const { t } = useTranslation();
  const selectedBookingsLength = selectedBookings.length;
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { mutateAsync: checkOutMultiple } =
    usePostMultipleCheckOutByBookingId();

  const handleCheckout = () => {
    setIsOpen((prev) => !prev);
  };

  const onCheckOut = async () => {
    try {
      const bookingIds = selectedBookings.map((b) => b.bookingId);
      await checkOutMultiple({ bookingIds });
      setSelectedBookings([]);
      queryClient.invalidateQueries({
        queryKey: useGetDeparturesByDate.getKey(),
      });
      toast({
        title: t("changes-saved"),
        variant: "success",
      });
    } catch (error) {
      toast({
        title: t("request-failed-with"),
        description: t(
          decodeURIComponent(
            (error instanceof Error && error.message) || t("no-message"),
          ),
        ),
        variant: "destructive",
      });
    }
  };

  return (
    <div className="top-0 flex justify-center rounded-xl bg-solid-backplate p-2 px-4 shadow-lg">
      <Button size={"sm"} variant={"outline"} onClick={handleCheckout}>
        {t("check-in")}
      </Button>
      <Button
        size={"sm"}
        onClick={() => setSelectedBookings([])}
        className="ml-4"
      >
        <X size={12} className="mr-1" />
        {`${selectedBookingsLength} ${selectedBookingsLength > 1 ? t("marked_plural") : t("marked")}`}
      </Button>
      <ConfirmDialog
        isOpen={isOpen}
        onProceed={onCheckOut}
        onOpenChange={setIsOpen}
        proceedBtnText={t("check-out")}
        description={t("are-you-sure-you-want-to-check-out-selected")}
      />
    </div>
  );
};
