import { useTranslation } from "react-i18next";
import { ReceptionBooking } from "../../../../../../api-contracts/reception";
import { ConfirmDialog } from "@components/confirm-dialog";
import { toast } from "@hooks/use-toast";

interface CheckInDialogManagerProps {
  selectedBooking: {
    booking: ReceptionBooking | null;
    isOpen: boolean;
    dialogType: "confirm" | "paymentRemaining" | "undoCheckOut" | null;
  };
  onCheckOut: (bookingId: string) => void;
  onUndoCheckOut: (bookingId: string) => void;
  closeDialogs: () => void;
}

export const CheckOutDialogManager = ({
  selectedBooking,
  onCheckOut,
  onUndoCheckOut,
  closeDialogs,
}: CheckInDialogManagerProps) => {
  const { t } = useTranslation();

  const { booking, isOpen, dialogType } = selectedBooking;

  if (!booking) return;

  const onPaymentRemains = () => {
    // Payment/Folio page doesn't exists atm
    // TODO Navigate to Payment/Folio page
    toast({
      title: t("Payment remains - Should navigate to payment before check-out"),
      variant: "default",
      description: "TODO",
    });
  };

  return (
    <>
      {dialogType === "confirm" && isOpen && (
        <ConfirmDialog
          isOpen={isOpen}
          onOpenChange={closeDialogs}
          proceedBtnText={t("check-out")}
          onProceed={() => onCheckOut(booking.bookingId)}
          title={t("check-out")}
          description={t(
            "are-you-sure-to-check-out-{{name}}-{{surname}}-from-{{unit}}?",
            {
              name: booking.holder.name,
              surname: booking.holder.surname,
              unit: booking.bookable.name,
            },
          )}
        />
      )}
      {dialogType === "paymentRemaining" && isOpen && (
        <ConfirmDialog
          title={t("payment-remains")}
          description={t("payment-before-check-out-description")}
          isOpen={isOpen}
          onProceed={onPaymentRemains}
          onOpenChange={closeDialogs}
          proceedBtnText={t("go-to-payment")}
        />
      )}
      {dialogType === "undoCheckOut" && isOpen && (
        <ConfirmDialog
          title={t("undo-check-out")}
          description={t(
            "are-you-sure-to-undo-check-out-for-{{name}}-{{surname}}",
            {
              name: booking.holder.name,
              surname: booking.holder.surname,
            },
          )}
          isOpen={isOpen}
          onProceed={() => {
            onUndoCheckOut(booking.bookingId);
            closeDialogs();
          }}
          onOpenChange={closeDialogs}
          proceedBtnText={t("undo-check-out")}
        />
      )}
    </>
  );
};
