import React from "react";
import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTitle,
} from "./sheet";

interface DefaultSideSheetProps {
  title: string;
  description?: string;
  open: boolean;
  onOpenChange: (open: boolean) => void;
  className?: string;
  side?: "left" | "right" | null | undefined;
  children: React.ReactNode;
}
export const DefaultSideSheet: React.FC<DefaultSideSheetProps> = ({
  title,
  description,
  open,
  onOpenChange,
  className,
  side,
  children,
}) => {
  return (
    <Sheet open={open} onOpenChange={onOpenChange}>
      <SheetContent
        onPointerDownOutside={(e) => e.preventDefault()}
        onInteractOutside={(e) => e.preventDefault()}
        side={side}
        className={`z-[200] flex w-[400px] flex-col sm:w-[540px] ${className}`}
      >
        <SheetHeader>
          <SheetTitle>{title}</SheetTitle>
          <SheetDescription>{description}</SheetDescription>
        </SheetHeader>
        {children}
      </SheetContent>
    </Sheet>
  );
};
