import { AssetType, VisualAssetType } from "../../../../api-contracts/assets";

export const loadCSS = (href: string) => {
  let linkElement = document.querySelector(
    'link[data-module="dynamic"]',
  ) as HTMLLinkElement;

  if (linkElement) {
    linkElement.href = href;
  } else {
    linkElement = document.createElement("link");
    linkElement.rel = "stylesheet";
    linkElement.type = "text/css";
    linkElement.href = href;
    linkElement.setAttribute("data-module", "dynamic");
    document.head.appendChild(linkElement);
  }
};

export const getAssetTypes = (
  module: string,
  tab: VisualAssetType,
): AssetType[] => {
  switch (tab) {
    case "all":
      return module === "campr"
        ? [
            "tent",
            "motorhome",
            "mobile-home",
            "cabin",
            "misc",
            "caravan",
            "glamping",
            "campr-area",
          ]
        : ["room", "bed", "area", "dormitory"];
    case "plot":
      return ["tent", "caravan", "motorhome"];
    default:
      return [tab];
  }
};
