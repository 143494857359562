import { TabItem, TabNavbar } from "@components/tab-navbar";
import { useProfileContext } from "@context/profile-context";
import { ROUTES } from "@shared/types/navigation";
import { useTranslation } from "react-i18next";

export const CategoryTabs = ({
  tab,
  tabItems,
}: {
  tab: "all" | "hotel" | "area" | "hostel";
  tabItems: TabItem[];
}) => {
  return <TabNavbar value={tab.toString()} items={tabItems} />;
};
