import { CategoryCard } from "@pages/settings/category-cluster/components/category-card.tsx";
import { ClusterCard } from "@pages/settings/category-cluster/components/clusterCard.tsx";
import { Space } from "@components/space.tsx";
import React, { useContext, useEffect, useState } from "react";
import { Button } from "@primitives/button.tsx";
import {
  ClusterCategory,
  CategoryClusterContext,
  Cluster,
} from "@pages/settings/category-cluster/category-cluster-context.tsx";

import { useTranslation } from "react-i18next";
import { DeleteClusterDialog } from "@pages/settings/category-cluster/components/delete-cluster-dialog.tsx";
import { MoveCategoriesDialog } from "@pages/settings/category-cluster/components/move-categories-dialog.tsx";
import clsx from "clsx";
import { CreateClusterDialog } from "@pages/settings/category-cluster/components/create-cluster-dialog.tsx";
import { ChangeNameDialog } from "@pages/settings/category-cluster/components/change-name-dialog.tsx";
import { RemoveCategoriesFromClusterDialog } from "@pages/settings/category-cluster/components/remove-categories-from-cluster-dialog.tsx";

export const CategoryClusterPage = () => {
  const [openCreateDialog, setOpenCreateDialog] = useState(false);
  const [openChangeDialog, setOpenChangeDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openMoveDialog, setOpenMoveDialog] = useState(false);
  const [openRemoveCatDialog, setOpenRemoveCatDialog] = useState(false);
  const [selectedCategories, setSelectedCategories] = useState(0);
  const [selectedClusters, setSelectedClusters] = useState(0);
  const [selectedCategoryClusters, setSelectedCategoryClusters] = useState(0);
  const { clusterData, selectCategory, selectClusterCategory } = useContext(
    CategoryClusterContext,
  );
  const { t } = useTranslation();

  const Toolbar = () => {
    return (
      <>
        <div
          className={clsx(
            "flex h-[44px] items-center rounded-xl pl-2 pr-2",
            (selectedCategories > 0 ||
              selectedClusters > 0 ||
              selectedCategoryClusters > 0) &&
              "bg-solid-backplate",
          )}
        >
          {selectedCategories > 0 && (
            <Button
              onClick={() => setOpenCreateDialog((prevState) => !prevState)}
              variant={"outline"}
              className={"mr-2 h-7 rounded-xl"}
            >
              {t("create-new-cluster")}
            </Button>
          )}
          {selectedClusters === 1 && (
            <Button
              onClick={() => setOpenChangeDialog((prevState) => !prevState)}
              variant={"outline"}
              className={"mr-2 h-7 rounded-xl"}
            >
              {t("change-name")}
            </Button>
          )}
          {selectedCategories > 0 && clusterData.clusters.length > 0 && (
            <Button
              onClick={() => setOpenMoveDialog((prevState) => !prevState)}
              variant={"outline"}
              className={"mr-2 h-7 rounded-xl"}
            >
              {t("move-to")}
            </Button>
          )}
          {selectedClusters > 0 && (
            <Button
              onClick={() => setOpenDeleteDialog((prevState) => !prevState)}
              variant={"outline"}
              className={"mr-2 h-7 rounded-xl"}
            >
              {t("reset")}
            </Button>
          )}
          {selectedCategoryClusters > 0 && selectedClusters === 0 && (
            <Button
              onClick={() => setOpenRemoveCatDialog((prevState) => !prevState)}
              variant={"outline"}
              className={"mr-2 h-7 rounded-xl"}
            >
              {t("reset")}
            </Button>
          )}
          {(selectedCategories > 0 || selectedCategoryClusters > 0) && (
            <Button
              onClick={() => {
                clusterData.categoryTypes.map((cat: ClusterCategory) => {
                  if (cat.selected) return selectCategory(cat.category);
                });
                clusterData.clusters.map((cl) => {
                  cl.categories
                    .filter((cat) => cat.selected)
                    .map((cat) => selectClusterCategory(cat.category, cl.id));
                });
              }}
              className={"h-7 rounded-xl"}
            >
              X {selectedCategories + selectedCategoryClusters} {t("marked")}
            </Button>
          )}
        </div>
      </>
    );
  };

  useEffect(() => {
    const catLength = clusterData?.categoryTypes.filter(
      (cat: ClusterCategory) => cat.selected,
    );
    const clLength = clusterData?.clusters.filter((cl: Cluster) => cl.selected);
    const catClLength = clusterData?.clusters
      .flatMap((cl: Cluster) =>
        cl.categories.filter((cat: ClusterCategory) => cat.selected),
      )
      .map((cat: ClusterCategory) => cat.category);
    setSelectedCategories(catLength?.length);
    setSelectedClusters(clLength?.length);
    setSelectedCategoryClusters(catClLength?.length);
  }, [clusterData]);

  return (
    <>
      <RemoveCategoriesFromClusterDialog
        open={openRemoveCatDialog}
        onOpenChange={setOpenRemoveCatDialog}
      />
      <ChangeNameDialog
        open={openChangeDialog}
        onOpenChange={setOpenChangeDialog}
      />
      <CreateClusterDialog
        open={openCreateDialog}
        onOpenChange={setOpenCreateDialog}
      />
      <MoveCategoriesDialog
        open={openMoveDialog}
        onOpenChange={setOpenMoveDialog}
      />
      <DeleteClusterDialog
        clusters={clusterData?.clusters.filter((cl: Cluster) => cl.selected)}
        open={openDeleteDialog}
        onOpenChange={setOpenDeleteDialog}
      />

      <div className={"h-[calc(100vh_-_115px)] overflow-hidden p-4"}>
        <div className={"flex"}>
          <div className={"w-5/12"}>
            <p className={" font-itc text-3xl font-bold text-primary-text"}>
              {t("category-cluster")}
            </p>
            <p className={"pb-2 text-sm text-secondary-text"}>
              {t("category-cluster-desc")}
            </p>
          </div>
          <div className={"flex w-1/2 justify-items-start"}>
            <Toolbar />
          </div>
        </div>
        <Space h={10} />
        <div className={"align flex cursor-pointer items-center"}>
          {clusterData?.clusters.map((cl: Cluster) => (
            <ClusterCard key={cl.id} cluster={cl} />
          ))}
          {clusterData?.categoryTypes && (
            <div className={"mt-2 flex cursor-pointer"}>
              {clusterData?.categoryTypes?.map(
                (cat: ClusterCategory, index: number) => (
                  <CategoryCard key={index} category={cat} showCheck={true} />
                ),
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
};
