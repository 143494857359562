import {
  Column,
  SortingState,
  Table as TableType,
  flexRender,
} from "@tanstack/react-table";
import { useTranslation } from "react-i18next";
import {
  ChevronsUpDownIcon,
  ChevronDownIcon,
  ChevronUpIcon,
} from "lucide-react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@primitives/table";

type ReceptionTableProps<T> = {
  table: TableType<T>;
  onRowClick: (booking: T) => void;
  showAllGuests?: boolean;
};

export const ReceptionTable = <T,>({
  table,
  onRowClick,
  showAllGuests,
}: ReceptionTableProps<T>) => {
  const { t } = useTranslation();
  const getSortIcon = (
    column: Column<T, unknown>,
    sortingState: SortingState,
  ) => {
    const sort = sortingState.find((s) => s.id === column.id);
    if (!sort) return <ChevronsUpDownIcon size={13} />;
    return sort.desc ? (
      <ChevronDownIcon size={13} />
    ) : (
      <ChevronUpIcon size={13} />
    );
  };

  return (
    <>
      <Table id="table" className="whitespace-nowrap rounded-md print:block">
        <TableHeader>
          {table.getHeaderGroups().map((headerGroup) => (
            <TableRow key={headerGroup.id} className="group">
              {headerGroup.headers.map((header) => (
                <TableHead key={header.id}>
                  {header.isPlaceholder ? null : (
                    <div
                      className="flex cursor-pointer items-center"
                      {...{
                        onClick: header.column.getToggleSortingHandler(),
                      }}
                    >
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext(),
                      )}
                      {header.column.getCanSort() && (
                        <div className="ml-1">
                          {getSortIcon(header.column, table.getState().sorting)}
                        </div>
                      )}
                    </div>
                  )}
                </TableHead>
              ))}
            </TableRow>
          ))}
        </TableHeader>
        <TableBody>
          {table.getRowModel().rows?.length ? (
            table.getRowModel().rows.map((row) => (
              <TableRow
                key={row.id}
                className="group cursor-pointer"
                onClick={() => onRowClick(row.original)}
              >
                {row.getVisibleCells().map((cell) => {
                  return (
                    <TableCell
                      className={`${showAllGuests ? "align-top" : ""} h-16`}
                      key={cell.id}
                      style={{
                        width: `${cell.column.getSize()}px`,
                      }}
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext(),
                      )}
                    </TableCell>
                  );
                })}
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell
                colSpan={table.getAllLeafColumns().length}
                className="h-24 text-center"
              >
                {t("no-results")}.
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </>
  );
};
