import { createMutation, createQuery } from "react-query-kit";
import {
  DeleteMessageOfTheDayRequest,
  GetFireListResponse,
  GetOccupantsResponse,
  SaveMessageOfTheDayRequest,
  SaveMessageOfTheDayResponse,
  SplitNoShowRequest,
  SplitNoShowResponse,
} from "../../../../api-contracts/reception";
import { api } from "./api";
import { currentSite } from "@context/site-context";
import {
  CheckinRequest,
  GetReceptionArrivalsResponse,
  MultipleCheckinRequest,
  MultipleCheckinResponse,
} from "../../../../api-contracts/reception/arrivals";
import {
  GetReceptionDeparturesResponse,
  MultipleCheckoutRequest,
  MultipleCheckoutResponse,
} from "../../../../api-contracts/reception/departures";

export const ARRIVALS_QUERY_KEY = "reception/arrivals";
export const MOTD_QUERY_KEY = "reception/motd";
export const DEPARTURES_QUERY_KEY = "reception/departures";
export const OCCUPANTS_QUERY_KEY = "reception/occupants";
export const FIRE_LIST_QUERY_KEY = "reception/fire-list";

// -- ARRIVALS --
// GET /api/:siteId/reception/arrivals/:date
export const useGetArrivalsByDate = createQuery<
  GetReceptionArrivalsResponse,
  { date: string; type: string | string[] | undefined; showLocation?: boolean }
>({
  queryFn: async ({ queryKey: [, { date, type, showLocation }] }) => {
    const constructTypesQuery = (type: string | string[] | undefined) => {
      if (Array.isArray(type)) {
        return type.map((t) => `types=${t}`).join("&");
      }
      if (type) {
        return `types=${type}`;
      }
      return "";
    };
    const typesQuery = constructTypesQuery(type);
    const locationQuery = showLocation
      ? `${type === undefined ? "?" : "&"}locations=true`
      : "";
    return api.get(
      `/api/${currentSite?.id}/${ARRIVALS_QUERY_KEY}/${date}${typesQuery ? `?${typesQuery}` : ""}${locationQuery}`,
    );
  },
  primaryKey: ARRIVALS_QUERY_KEY,
});

// POST /api/:siteId/reception/arrivals/check-in/:bookingId
export const usePostCheckInByBookingId = createMutation({
  mutationFn: async (req: CheckinRequest) => {
    return api.post(
      `/api/${currentSite?.id}/${ARRIVALS_QUERY_KEY}/check-in/${req.bookingId}`,
      {
        categoryId: req.categoryId,
        bookableId: req.bookableId,
        keepPrice: req.keepPrice,
      },
    );
  },
});

// POST /api/:siteId/reception/arrivals/check-in
export const usePostMultipleCheckInByBookingId = createMutation<
  MultipleCheckinResponse,
  MultipleCheckinRequest
>({
  mutationFn: async (bookingIds: MultipleCheckinRequest) => {
    return api.post(
      `/api/${currentSite?.id}/${ARRIVALS_QUERY_KEY}/check-in`,
      bookingIds,
    );
  },
});

// POST /api/:siteId/reception/arrivals/undo-check-in/:bookingId
export const usePostUndoCheckInByBookingId = createMutation({
  mutationFn: async (bookingId: string) => {
    return api.post(
      `/api/${currentSite?.id}/${ARRIVALS_QUERY_KEY}/undo-check-in/${bookingId}`,
      {},
    );
  },
});

// POST /api/:siteId/reception/arrivals/split-no-show/:bookingId
export const usePostSplitNoShowByBookingId = createMutation<
  SplitNoShowResponse,
  SplitNoShowRequest & { bookingId: string }
>({
  mutationFn: async ({ bookingId, splitDate }) => {
    return api.post(
      `/api/${currentSite?.id}/${ARRIVALS_QUERY_KEY}/split-no-show/${bookingId}`,
      { splitDate },
    );
  },
});

// -- DEPARTURES --
// GET /api/:siteId/reception/departures/:date
export const useGetDeparturesByDate = createQuery<
  GetReceptionDeparturesResponse,
  { date: string; type: string | string[] | undefined; showLocation?: boolean }
>({
  queryFn: async ({ queryKey: [, { date, type, showLocation }] }) => {
    const constructTypesQuery = (type: string | string[] | undefined) => {
      if (Array.isArray(type)) {
        return type.map((t) => `types=${t}`).join("&");
      }
      if (type) {
        return `types=${type}`;
      }
      return "";
    };
    const typesQuery = constructTypesQuery(type);
    const locationQuery = showLocation
      ? `${type === undefined ? "?" : "&"}locations=true`
      : "";
    return api.get(
      `/api/${currentSite?.id}/${DEPARTURES_QUERY_KEY}/${date}${typesQuery ? `?${typesQuery}` : ""}${locationQuery}`,
    );
  },
  primaryKey: DEPARTURES_QUERY_KEY,
});

// POST /api/:siteId/reception/departures/check-out/:bookingId
export const usePostCheckOutByBookingId = createMutation({
  mutationFn: async (bookingId: string) => {
    return api.post(
      `/api/${currentSite?.id}/${DEPARTURES_QUERY_KEY}/check-out/${bookingId}`,
      {},
    );
  },
});

// POST /api/:siteId/reception/departures/check-out
export const usePostMultipleCheckOutByBookingId = createMutation<
  MultipleCheckoutResponse,
  MultipleCheckoutRequest
>({
  mutationFn: async (bookingIds: MultipleCheckoutRequest) => {
    return api.post(
      `/api/${currentSite?.id}/${DEPARTURES_QUERY_KEY}/check-out`,
      bookingIds,
    );
  },
});

// POST /api/:siteId/reception/arrivals/undo-check-in/:bookingId
export const usePostUndoCheckOutByBookingId = createMutation({
  mutationFn: async (bookingId: string) => {
    return api.post(
      `/api/${currentSite?.id}/${DEPARTURES_QUERY_KEY}/undo-check-in/${bookingId}`,
      {},
    );
  },
});

// -- MESSAGE OF THE DAY --
// PUT /api/:siteId/reception/motd
export const usePutMotd = createMutation<
  SaveMessageOfTheDayRequest,
  SaveMessageOfTheDayResponse
>({
  mutationFn: async ({ message, date }) =>
    api.put<SaveMessageOfTheDayResponse>(
      `/api/${currentSite?.id}/${MOTD_QUERY_KEY}`,
      {
        message,
        date,
      },
    ),
});

// DELETE /api/:siteId/reception/motd
export const useDeleteMotd = createMutation<
  DeleteMessageOfTheDayRequest,
  DeleteMessageOfTheDayRequest
>({
  mutationFn: async ({ message, date }) =>
    api.delete(`/api/${currentSite?.id}/${MOTD_QUERY_KEY}/&${message}&${date}`), // TODO Change to only send date as query param
});

// -- OCCUPANTS --
// GET /api/:siteId/reception/occupants/:date
export const useGetOccupantsByDate = createQuery<
  GetOccupantsResponse,
  { date: string; type: string | string[] | undefined; showLocation?: boolean }
>({
  queryFn: async ({ queryKey: [, { date, type, showLocation }] }) => {
    const constructTypesQuery = (type: string | string[] | undefined) => {
      if (Array.isArray(type)) {
        return type.map((t) => `types=${t}`).join("&");
      }
      if (type) {
        return `types=${type}`;
      }
      return "";
    };
    const typesQuery = constructTypesQuery(type);
    const locationQuery = showLocation
      ? `${type === undefined ? "?" : "&"}locations=true`
      : "";
    return api.get(
      `/api/${currentSite?.id}/${OCCUPANTS_QUERY_KEY}/${date}${typesQuery ? `?${typesQuery}` : ""}${locationQuery}`,
    );
  },
  primaryKey: OCCUPANTS_QUERY_KEY,
});

// -- FIRE LIST --
// GET /api/:siteId/reception/fire-list
export const useGetFireList = createQuery<
  GetFireListResponse,
  { showLocation?: boolean }
>({
  queryFn: async ({ queryKey: [, { showLocation }] }) => {
    const locationQuery = showLocation ? `?locations=true` : "";
    return api.get(
      `/api/${currentSite?.id}/${FIRE_LIST_QUERY_KEY}${locationQuery}`,
    );
  },
  primaryKey: FIRE_LIST_QUERY_KEY,
});
