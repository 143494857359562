import { Button } from "@primitives/button";
import { MessageSquare } from "lucide-react";
import { useTranslation } from "react-i18next";
import { useReceptionContext } from "../reception-context";
import { useEffect, useState } from "react";
import {
  DropdownMenuContent,
  DropdownMenuRoot,
  DropdownMenuTrigger,
} from "@primitives/dropdown-menu";
import { Label } from "@primitives/label";
import { Textarea } from "@primitives/textarea";
import {
  useDeleteMotd,
  useGetArrivalsByDate,
  useGetDeparturesByDate,
  useGetOccupantsByDate,
  usePutMotd,
} from "@api/reception";
import { queryClient } from "query-client";
import { toast } from "@hooks/use-toast";
import { format } from "date-fns";
import React from "react";

export const MessageOfDay = () => {
  const { t } = useTranslation();
  const { messageOfTheDay, date } = useReceptionContext();
  const hasMessage = Boolean(messageOfTheDay?.message);
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
  const [isEditing, setIsEditing] = useState<boolean>(!hasMessage);

  const [message, setMessage] = useState<string>("");
  const { mutateAsync: saveMotd } = usePutMotd();
  const { mutateAsync: deleteMotd } = useDeleteMotd();

  const closeDropdown = () => {
    setIsDropdownOpen(false);
  };

  const handleSaveMotd = async () => {
    const shouldDelete: boolean = message.length === 0;
    try {
      shouldDelete
        ? await deleteMotd({
            message,
            date: format(date ? date : new Date(), "yyyy-MM-dd"),
          })
        : await saveMotd({
            message,
            date: format(date ? date : new Date(), "yyyy-MM-dd"),
          });
      closeDropdown();
      const queryKeys = [
        useGetArrivalsByDate.getKey(),
        useGetOccupantsByDate.getKey(),
        useGetDeparturesByDate.getKey(),
      ];

      queryKeys.forEach((key) => {
        queryClient.invalidateQueries({ queryKey: key });
      });
      toast({
        title: t(
          `${shouldDelete ? "deleted-succesfully" : "saved-succesfully"}`,
          { name: message },
        ),
        variant: "success",
      });
    } catch (error) {
      toast({
        title: t("request-failed-with"),
        description: t(
          decodeURIComponent(
            (error instanceof Error && error.message) || t("no-message"),
          ),
        ),
        variant: "destructive",
      });
    }
  };

  useEffect(() => {
    setMessage(hasMessage ? messageOfTheDay?.message || "" : "");
    setIsEditing(!hasMessage);
  }, [isDropdownOpen]);

  return (
    <DropdownMenuRoot open={isDropdownOpen} onOpenChange={setIsDropdownOpen}>
      <DropdownMenuTrigger asChild>
        <div
          className={` flex h-10 w-80 cursor-pointer items-center justify-center rounded-md  px-4 py-2 ${hasMessage ? "bg-amber-500" : "bg-primary-button-backplate"}`}
        >
          <MessageSquare
            size={17}
            strokeWidth={1.5}
            className={`mr-2 flex-shrink-0 ${hasMessage ? "text-black" : "text-primary-icon-color"}`}
          />
          <p
            className={`line-clamp-2 break-words text-left ${!hasMessage ? "text-sm font-extrabold" : "text-xs font-normal text-black"}`}
          >
            {!hasMessage ? t("add-message") : messageOfTheDay?.message}
          </p>
        </div>
      </DropdownMenuTrigger>

      <DropdownMenuContent className=" w-[400px] p-4">
        <Label className="font-bold">
          {hasMessage ? t("message") : t("add-message")}
        </Label>
        {!isEditing ? (
          <div className="max-h-[45vh] overflow-y-auto pb-4">
            <p>
              {message.split("\n").map((line, index) => (
                <React.Fragment key={index}>
                  {line}
                  <br />
                </React.Fragment>
              ))}
            </p>
          </div>
        ) : (
          <div className="flex max-h-[45vh] flex-col overflow-y-auto px-1 py-4">
            <Textarea
              rows={10}
              autoFocus
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            />
          </div>
        )}
        <div className="mt-1 flex">
          <Button
            onClick={
              !isEditing ? () => setIsEditing((prev) => !prev) : handleSaveMotd
            }
          >
            {isEditing ? t("save") : t("change")}
          </Button>
          <Button className="ml-2" variant={"outline"} onClick={closeDropdown}>
            {t("cancel")}
          </Button>
        </div>
      </DropdownMenuContent>
    </DropdownMenuRoot>
  );
};
