import {
  useCreateGuestOrganization,
  useGetGuestOrganization,
  useGetGuestOrganizations,
  useUpdateGuestOrganization,
} from "@api/guest-organizations";
import { useDebounce } from "@hooks/use-debounce";
import { Button } from "@primitives/button";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandItem,
  CommandList,
} from "@primitives/command";
import { Input } from "@primitives/input";
import {
  PopoverContent,
  PopoverRoot,
  PopoverTrigger,
} from "@primitives/popover";
import { ChevronDown, ChevronUp, Plus, Search } from "lucide-react";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { GuestOrganization } from "../../../../../../../api-contracts/guest-organizations";
import { useToast } from "@hooks/use-toast";
import { queryClient } from "query-client";
import { cn } from "@shared/utils/css";
import { Switch } from "@primitives/switch";
import { Label } from "@primitives/label";

interface Props {
  value?: { id: string; name: string };
  onValueChange?: (value?: { id: string; name: string }) => void;
  id?: string;
  disabled?: boolean;
}

const initialOrg: GuestOrganization = {
  id: "",
  name: "",
  organizationNumber: "",
  address: "",
  address2: "",
  city: "",
  zip: "",
  country: "",
  phone: "",
  email: "",
  state: "",
  phone2: "",
  invoiceReference: "",
  invoiceName: "",
  invoiceAddress: "",
  invoiceAddress2: "",
  invoiceZip: "",
  invoiceCity: "",
  invoiceState: "",
  invoiceCountry: "",
};

const GuestOrganizationSelect = ({
  value,
  onValueChange,
  id,
  disabled,
}: Props) => {
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [open, setOpen] = React.useState<"search" | "new">();
  const [t] = useTranslation();
  const debouncedSearchTerm = useDebounce(searchTerm, 500);
  const [organization, setOrganization] =
    useState<GuestOrganization>(initialOrg);
  const [billingSame, setBillingSame] = useState<boolean>(true);

  const [createLoading, setCreateLoading] = useState<boolean>(false);
  const [updateLoading, setUpdateLoading] = useState<boolean>(false);
  const { toast } = useToast();

  const {
    data: guestOrganizationsData,
    isLoading: guestOrganizationsIsLoading,
    isRefetching: guestOrganizationsIsRefetching,
  } = useGetGuestOrganizations({
    variables: {
      limit: 50,
      page: 1,
      pattern: debouncedSearchTerm || "",
    },
  });

  const {
    data: fetchedOrganization,
    isLoading: fetchedOrganizationIsLoading,
    isRefetching: fetchedOrganizationIsRefetching,
  } = useGetGuestOrganization({
    variables: {
      id: value?.id || "",
    },
    enabled: !!value?.id,
  });

  const createGuestOrganization = useCreateGuestOrganization();
  const updateGuestOrganization = useUpdateGuestOrganization();

  useEffect(() => {
    setOrganization(
      fetchedOrganization ? { ...fetchedOrganization } : initialOrg,
    );
    setBillingSame(
      fetchedOrganization?.address === fetchedOrganization?.invoiceAddress &&
        fetchedOrganization?.address2 ===
          fetchedOrganization?.invoiceAddress2 &&
        fetchedOrganization?.city === fetchedOrganization?.invoiceCity &&
        fetchedOrganization?.invoiceCountry === fetchedOrganization?.country &&
        fetchedOrganization?.invoiceName === fetchedOrganization?.name &&
        fetchedOrganization?.invoiceState === fetchedOrganization?.state &&
        fetchedOrganization?.invoiceZip === fetchedOrganization?.zip,
    );
  }, [fetchedOrganization]);

  const handleCreate = async () => {
    try {
      setCreateLoading(true);
      const org = await createGuestOrganization.mutateAsync({
        name: organization.name.trim(),
        organizationNumber: organization.organizationNumber.trim(),
        address: organization.address?.trim() || null,
        address2: organization.address2?.trim() || null,
        city: organization.city?.trim() || null,
        zip: organization.zip?.trim() || null,
        country: organization.country?.trim() || null,
        phone: organization.phone?.trim() || null,
        email: organization.email?.trim() || null,
        state: organization.state?.trim() || null,
        phone2: organization.phone2?.trim() || null,
        invoiceReference: organization.invoiceReference?.trim() || null,
        invoiceName: billingSame
          ? organization.name.trim() || null
          : organization.invoiceName?.trim() || null,
        invoiceAddress: billingSame
          ? organization.address?.trim() || null
          : organization.invoiceAddress?.trim() || null,
        invoiceAddress2: billingSame
          ? organization.address2?.trim() || null
          : organization.invoiceAddress2?.trim() || null,
        invoiceZip: billingSame
          ? organization.zip?.trim() || null
          : organization.invoiceZip?.trim() || null,
        invoiceCity: billingSame
          ? organization.city?.trim() || null
          : organization.invoiceCity?.trim() || null,
        invoiceState: billingSame
          ? organization.state?.trim() || null
          : organization.invoiceState?.trim() || null,
        invoiceCountry: billingSame
          ? organization.country?.trim() || null
          : organization.country?.trim() || null,
      });

      setOrganization(org);
      onValueChange?.({ id: org.id, name: org.name });
      setOpen(undefined);
      queryClient.invalidateQueries({
        queryKey: useGetGuestOrganizations.getKey(),
      });
      toast({
        title: t("saved-succesfully", { name: organization.name }),
        className: "text-status-success",
        variant: "success",
      });
    } catch (err) {
      toast({
        title:
          t("request-failed-with") +
          ": " +
          t(decodeURIComponent((err as any)?.message || t("no-message"))),
        variant: "destructive",
        className: "text-status-error",
      });
    }
    setCreateLoading(false);
  };

  const handleUpdate = async () => {
    try {
      setUpdateLoading(true);
      const org = await updateGuestOrganization.mutateAsync({
        id: organization.id,
        name: organization.name.trim(),
        organizationNumber: organization.organizationNumber.trim(),
        address: organization.address?.trim() || null,
        address2: organization.address2?.trim() || null,
        city: organization.city?.trim() || null,
        zip: organization.zip?.trim() || null,
        country: organization.country?.trim() || null,
        phone: organization.phone?.trim() || null,
        email: organization.email?.trim() || null,
        state: organization.state?.trim() || null,
        phone2: organization.phone2?.trim() || null,
        invoiceReference: organization.invoiceReference?.trim() || null,
        invoiceName: billingSame
          ? organization.name.trim() || null
          : organization.invoiceName?.trim() || null,
        invoiceAddress: billingSame
          ? organization.address?.trim() || null
          : organization.invoiceAddress?.trim() || null,
        invoiceAddress2: billingSame
          ? organization.address2?.trim() || null
          : organization.invoiceAddress2?.trim() || null,
        invoiceZip: billingSame
          ? organization.zip?.trim() || null
          : organization.invoiceZip?.trim() || null,
        invoiceCity: billingSame
          ? organization.city?.trim() || null
          : organization.invoiceCity?.trim() || null,
        invoiceState: billingSame
          ? organization.state?.trim() || null
          : organization.invoiceState?.trim() || null,
        invoiceCountry: billingSame
          ? organization.country?.trim() || null
          : organization.country?.trim() || null,
      });

      setOrganization(org);
      onValueChange?.({ id: org.id, name: org.name });
      setOpen(undefined);
      queryClient.invalidateQueries({
        queryKey: useGetGuestOrganizations.getKey(),
      });
      toast({
        title: t("saved-succesfully", { name: organization.name }),
        className: "text-status-success",
        variant: "success",
      });
    } catch (err) {
      toast({
        title:
          t("request-failed-with") +
          ": " +
          t(decodeURIComponent((err as any)?.message || t("no-message"))),
        variant: "destructive",
        className: "text-status-error",
      });
    }
    setUpdateLoading(false);
  };

  return (
    <PopoverRoot
      open={!!open}
      onOpenChange={(o) =>
        disabled
          ? setOpen(undefined)
          : !o
            ? setOpen(undefined)
            : fetchedOrganization
              ? setOpen("new")
              : setOpen("search")
      }
    >
      <PopoverTrigger asChild disabled={disabled} className=" flex-1">
        {fetchedOrganization ? (
          <div
            className={cn(
              "flex h-full items-center justify-between rounded-lg bg-amber-500 px-4 py-2",
              {
                " opacity-70": disabled,
              },
            )}
          >
            <div>
              <p className=" text-sm font-extrabold">
                {fetchedOrganization.name}
              </p>
              <p className=" text-xs font-normal text-secondary-text">
                {fetchedOrganization.organizationNumber}
              </p>
              <p className=" text-xs font-normal text-secondary-text">
                {fetchedOrganization.email}
              </p>
            </div>
            <div>
              {open ? <ChevronUp size={18} /> : <ChevronDown size={18} />}
            </div>
          </div>
        ) : (
          <div className="relative h-full">
            <div className="pointer-events-none absolute inset-y-0 start-0 flex items-center ps-3">
              <Search className="text-primary-icon-color" size={17} />
            </div>
            <Input
              autoComplete="off"
              disabled={disabled}
              id={id}
              className=" h-full pe-20 ps-10"
              placeholder={t("search-organization")}
              value={searchTerm}
              onChange={(e) => {
                setSearchTerm(e.target.value);
                setOpen("search");
              }}
            />
            <div className="absolute inset-y-0 end-0 flex items-center pe-3">
              <Button
                disabled={disabled}
                size="sm"
                variant="outline"
                onClick={(e) => {
                  e.stopPropagation();
                  setOpen("new");
                }}
              >
                <Plus />
              </Button>
            </div>
          </div>
        )}
      </PopoverTrigger>
      <PopoverContent
        avoidCollisions={false}
        className="PopoverContent max-h-fit p-0"
        onOpenAutoFocus={(e) => e.preventDefault()}
      >
        {open === "search" && (
          <Command>
            <CommandList>
              <CommandEmpty>{t("no-organizations-found")}</CommandEmpty>
              <CommandGroup>
                {guestOrganizationsData?.map((o) => (
                  <CommandItem
                    key={o.id}
                    value={o.id}
                    onSelect={(value) => {
                      onValueChange &&
                        onValueChange(
                          guestOrganizationsData?.find((o) => o.id === value),
                        );
                      setOpen(undefined);
                    }}
                  >
                    <div>
                      <p className=" text-sm font-extrabold">{o.name}</p>
                      <p className=" text-xs font-normal text-secondary-text">
                        {o.organizationNumber}
                      </p>
                      <p className=" text-xs font-normal text-secondary-text">
                        {o.email}
                      </p>
                    </div>
                  </CommandItem>
                ))}
              </CommandGroup>
            </CommandList>
          </Command>
        )}
        {open === "new" && (
          <div className="p-4">
            <label
              className=" mt-1 block text-xs font-medium text-secondary-text"
              htmlFor="organizationNumber"
            >
              {t("organization-number")}*
            </label>
            <Input
              id="organizationNumber"
              value={organization?.organizationNumber}
              onChange={(e) =>
                setOrganization({
                  ...organization,
                  organizationNumber: e.target.value,
                })
              }
            />

            <label
              className="mt-1 block text-xs font-medium text-secondary-text"
              htmlFor="organizationName"
            >
              {t("organization-name")}*
            </label>
            <Input
              id="organizationName"
              value={organization?.name}
              onChange={(e) =>
                setOrganization({ ...organization, name: e.target.value })
              }
            />

            <label
              className=" mt-1 block text-xs font-medium text-secondary-text"
              htmlFor="organizationAddress1"
            >
              {t("address-1")}
            </label>
            <Input
              id="organizationAddress1"
              value={organization?.address || ""}
              onChange={(e) =>
                setOrganization({
                  ...organization,
                  address: e.target.value || "",
                })
              }
            />

            <label
              className=" mt-1 block text-xs font-medium text-secondary-text"
              htmlFor="organizationAddress2"
            >
              {t("address-2")}
            </label>
            <Input
              id="organizationAddress2"
              value={organization?.address2 || ""}
              onChange={(e) =>
                setOrganization({
                  ...organization,
                  address2: e.target.value || "",
                })
              }
            />

            <label
              className=" mt-1 block text-xs font-medium text-secondary-text"
              htmlFor="organizationZip"
            >
              {t("zip-code")}
            </label>
            <Input
              id="organizationZip"
              value={organization?.zip || ""}
              onChange={(e) =>
                setOrganization({
                  ...organization,
                  zip: e.target.value || "",
                })
              }
            />

            <label
              className=" mt-1 block text-xs font-medium text-secondary-text"
              htmlFor="organizationCity"
            >
              {t("city")}
            </label>
            <Input
              id="organizationCity"
              value={organization?.city || ""}
              onChange={(e) =>
                setOrganization({
                  ...organization,
                  city: e.target.value || "",
                })
              }
            />

            <label
              className=" mt-1 block text-xs font-medium text-secondary-text"
              htmlFor="organizationState"
            >
              {t("state/province/region")}
            </label>
            <Input
              id="organizationState"
              value={organization?.state || ""}
              onChange={(e) =>
                setOrganization({
                  ...organization,
                  state: e.target.value || "",
                })
              }
            />

            <label
              className=" mt-1 block text-xs font-medium text-secondary-text"
              htmlFor="organizationCountry"
            >
              {t("country")}
            </label>
            <Input
              id="organizationCountry"
              value={organization?.country || ""}
              onChange={(e) =>
                setOrganization({
                  ...organization,
                  country: e.target.value || "",
                })
              }
            />

            <label
              className=" mt-1 block text-xs font-medium text-secondary-text"
              htmlFor="organizationPhone1"
            >
              {t("phone-1")}
            </label>
            <Input
              id="organizationPhone1"
              value={organization?.phone || ""}
              onChange={(e) =>
                setOrganization({
                  ...organization,
                  phone: e.target.value || "",
                })
              }
            />

            <label
              className=" mt-1 block text-xs font-medium text-secondary-text"
              htmlFor="organizationPhone2"
            >
              {t("phone-2")}
            </label>
            <Input
              id="organizationPhone2"
              value={organization?.phone || ""}
              onChange={(e) =>
                setOrganization({
                  ...organization,
                  phone: e.target.value || "",
                })
              }
            />

            <label
              className=" mt-1 block text-xs font-medium text-secondary-text"
              htmlFor="organizationEmail"
            >
              {t("email")}
            </label>
            <Input
              id="organizationEmail"
              value={organization?.email || ""}
              onChange={(e) =>
                setOrganization({
                  ...organization,
                  email: e.target.value || "",
                })
              }
            />

            <h4 className=" text-md mt-1 font-light">{t("billing-address")}</h4>
            <div className="flex items-center space-x-2">
              <Switch
                id="organizationBillingSame"
                checked={billingSame}
                onCheckedChange={setBillingSame}
              />
              <Label htmlFor="organizationBillingSame">
                {t("same-as-above")}
              </Label>
            </div>

            <label
              className=" mt-1 block text-xs font-medium text-secondary-text"
              htmlFor="organizationInvoiceReference"
            >
              {t("reference")}
            </label>
            <Input
              id="organizationInvoiceReference"
              value={organization?.invoiceReference || ""}
              onChange={(e) =>
                setOrganization({
                  ...organization,
                  invoiceReference: e.target.value || "",
                })
              }
            />

            {!billingSame && (
              <>
                <label
                  className="mt-1 block text-xs font-medium text-secondary-text"
                  htmlFor="organizationInvoiceName"
                >
                  {t("organization-name")}
                </label>
                <Input
                  id="organizationInvoiceName"
                  value={organization?.invoiceName || ""}
                  onChange={(e) =>
                    setOrganization({
                      ...organization,
                      invoiceName: e.target.value,
                    })
                  }
                />

                <label
                  className=" mt-1 block text-xs font-medium text-secondary-text"
                  htmlFor="organizationInvoiceAddress1"
                >
                  {t("address-1")}
                </label>
                <Input
                  id="organizationInvoiceAddress1"
                  value={organization?.invoiceAddress || ""}
                  onChange={(e) =>
                    setOrganization({
                      ...organization,
                      invoiceAddress: e.target.value || "",
                    })
                  }
                />

                <label
                  className=" mt-1 block text-xs font-medium text-secondary-text"
                  htmlFor="organizationInvoiceAddress2"
                >
                  {t("address-2")}
                </label>
                <Input
                  id="organizationInvoiceAddress2"
                  value={organization?.invoiceAddress2 || ""}
                  onChange={(e) =>
                    setOrganization({
                      ...organization,
                      invoiceAddress2: e.target.value || "",
                    })
                  }
                />

                <label
                  className=" mt-1 block text-xs font-medium text-secondary-text"
                  htmlFor="organizationInvoiceZip"
                >
                  {t("zip-code")}
                </label>
                <Input
                  id="organizationInvoiceZip"
                  value={organization?.invoiceZip || ""}
                  onChange={(e) =>
                    setOrganization({
                      ...organization,
                      invoiceZip: e.target.value || "",
                    })
                  }
                />

                <label
                  className=" mt-1 block text-xs font-medium text-secondary-text"
                  htmlFor="organizationInvoiceCity"
                >
                  {t("city")}
                </label>
                <Input
                  id="organizationInvoiceCity"
                  value={organization?.invoiceCity || ""}
                  onChange={(e) =>
                    setOrganization({
                      ...organization,
                      invoiceCity: e.target.value || "",
                    })
                  }
                />

                <label
                  className=" mt-1 block text-xs font-medium text-secondary-text"
                  htmlFor="organizationInvoiceState"
                >
                  {t("state/province/region")}
                </label>
                <Input
                  id="organizationInvoiceState"
                  value={organization?.invoiceState || ""}
                  onChange={(e) =>
                    setOrganization({
                      ...organization,
                      invoiceState: e.target.value || "",
                    })
                  }
                />

                <label
                  className=" mt-1 block text-xs font-medium text-secondary-text"
                  htmlFor="organizationInvoiceCountry"
                >
                  {t("country")}
                </label>
                <Input
                  id="organizationInvoiceCountry"
                  value={organization?.invoiceCountry || ""}
                  onChange={(e) =>
                    setOrganization({
                      ...organization,
                      invoiceCountry: e.target.value || "",
                    })
                  }
                />
              </>
            )}

            <div className="flex space-x-2">
              {!fetchedOrganization && (
                <Button
                  className="mt-2"
                  size="sm"
                  variant="primary"
                  disabled={
                    !organization.name.trim() ||
                    !organization.organizationNumber.trim()
                  }
                  onClick={handleCreate}
                  loading={createLoading}
                >
                  {t("add")}
                </Button>
              )}
              {fetchedOrganization && (
                <Button
                  className="mt-2"
                  size="sm"
                  variant="primary"
                  disabled={
                    !organization.name.trim() ||
                    !organization.organizationNumber.trim()
                  }
                  onClick={handleUpdate}
                  loading={updateLoading}
                >
                  {t("save")}
                </Button>
              )}
              {fetchedOrganization && (
                <Button
                  className="mt-2"
                  size="sm"
                  variant="outline"
                  onClick={() => {
                    onValueChange?.(undefined);
                    setOpen("search");
                  }}
                >
                  {t("remove")}
                </Button>
              )}
            </div>
          </div>
        )}
      </PopoverContent>
    </PopoverRoot>
  );
};

export default GuestOrganizationSelect;
