import { Loader2 } from "lucide-react";
import {
  ComponentPropsWithoutRef,
  ElementRef,
  forwardRef,
  RefObject,
} from "react";
import { cn } from "@shared/utils/css.ts";

export type RefHandler = {
  divRef: RefObject<HTMLDivElement>;
  loaderRef: RefObject<SVGSVGElement>;
};

export const Loading = forwardRef<
  ElementRef<typeof Loader2>,
  ComponentPropsWithoutRef<typeof Loader2>
>(({ className, ...props }, ref) => (
  <div
    className={
      "relative z-20 flex h-full w-full items-center justify-center backdrop-blur-sm"
    }
  >
    <Loader2
      className={cn("h-20 w-20 animate-spin text-primary-color", className)}
      {...props}
      ref={ref}
    />
  </div>
));
