import { AuthUser } from "@context/auth-context";
import { LucideIcon } from "lucide-react";
import { ReactNode } from "react";

export interface NavSubItem {
  name: string;
  urls: string[];
  permissions: string[];
}

export interface NavItem {
  icon: LucideIcon;
  name: string;
  subItems?: NavSubItem[];
}

export interface MainNavProps {
  navItems: NavItem[];
  children: ReactNode;
  user?: AuthUser;
}

/** Permissions from backend/src/shared/utils/authorization/permissions.ts
 * This is the same values that profile/context API returns as site permissions
 */
export const ASSETS_PERMISSION = "assets";
export const BRIDGES_PERMISSION = "bridges";
export const LOCATIONS_PERMISSION = "locations";
export const BOOKABLES_PERMISSION = "bookables";
export const CATEGORIES_PERMISSION = "categories";
export const CATEGORY_GROUPS_PERMISSION = "category-groups";
export const ASSET_GROUPS_PERMISSION = "asset-groups";
export const COMMUNICATION_PERMISSION = "communication";
export const RECEPTION_PERMISSION = "reception";
export const GUEST_GROUPS_PERMISSION = "guest-groups";
export const IMAGES_PERMISSION = "images";
export const INVENTORY_PERMISSION = "inventory";
export const PAYMENTS_PERMISSION = "payments";
export const PRICE_RULES_PERMISSION = "price-rules";
export const PRICE_CALENDAR_PERMISSION = "price-calendar";
export const RESERVATION_PERMISSION = "reservation";
export const SITES_PERMISSION = "sites";
export const TITLES_PERMISSION = "titles";
export const USERS_PERMISSION = "users";
export const CHANNELS_PERMISSION = "channels";
export const COMBINATIONS_PERMISSION = "combinations";
export const FIXTURES_PERMISSION = "fixtures";
export const AMENITIES_PERMISSION = "amenities";
export const TARGET_GROUPS_PERMISSION = "target-groups";
export const TAGS_PERMISSION = "tags";
export const GUEST_PERMISSION = "guests";
export const GUEST_ORG_PERMISSION = "guest-organizations";
export const BED_CAPACITY_PERMISSION = "bed-capacity";
export const COMMUNICATION_SPHERES_PERMISSION = "communication-spheres";
export const NOTES_PERMISSION = "notes";
export const TRAVEL_AGENCIES_PERMISSION = "travel-agencies";
export const SITE_ADMIN_PERMISSION = "site-admin";
export const OVERBOOK_PERMISSION = "overbook-booking-category";
export const CATEGORY_CLUSTERS_PERMISSION = "category-clusters";
export const SEARCH_PERMISSION = "search";
//Module constants
export const ROOMR = "roomr";
export const CAMPR = "campr";

/** Navigation routes */
export const ROUTES = {
  ASSETS: "assets",
  BRIDGES: "bridges",
  LOCATIONS: "locations",
  ASSET_GRID_HOTEL: "asset-grid/hotel",
  ASSET_GRID_HOSTEL: "asset-grid/hostel",
  ASSET_GRID_AREA: "asset-grid/area",
  BOOKABLES: "bookables",
  ROOMS: "rooms",
  DORMITORIES: "dormitories",
  CATEGORIES: "categories",
  CATEGORY_GRID: "category-grid",
  CATEGORY_GRID_HOSTEL: "category-grid/hostel",
  CATEGORY_GRID_AREA: "category-grid/area",
  UNALLOCATED: "unallocated",
  CATEGORY_GROUPS: "category-groups",
  BOOKABLE_GROUPS: "bookable-groups",
  COMMUNICATION: "communication",
  DASHBOARD: "dashboard",
  GUEST_GROUPS: "guest-groups",
  GROUPS: "groups",
  IMAGES: "images",
  INVENTORY: "inventory",
  PAYMENTS: "payments",
  PRICE_RULES: "pricing",
  PRICE_RULES_HOTEL: "pricing/hotel",
  PRICE_RULES_AREA: "pricing/area",
  PRICE_RULES_HOSTEL: "pricing/hostel",
  PRICE_CALENDAR: "price-calendar",
  RESERVATIONS: "reservations",
  SITES: "sites",
  TITLES: "titles",
  USERS: "users",
  CHANNELS: "channels",
  COMBINATIONS: "combinations",
  FIXTURES: "fixtures",
  AMENITIES: "amenities",
  TARGET_GROUPS: "target-groups",
  TAGS: "tags",
  GUEST: "guests",
  GUEST_ORG: "guest-organizations",
  STATUS: "status",
  BEDS: "beds",
  AREAS: "areas",
  BED_TYPES: "bed-types",
  ARRIVALS: "arrivals",
  ARRIVALS_HOTEL: "arrivals/hotel",
  ARRIVALS_AREA: "arrivals/area",
  ARRIVALS_HOSTEL: "arrivals/hostel",
  PLOTS: "plots",
  CABINS: "cabins",
  OTHER_ACCOMMODATIONS: "other-accommodations",
  MISC: "all",
  DEPARTURES: "departures",
  DEPARTURES_HOTEL: "departures/hotel",
  DEPARTURES_AREA: "departures/area",
  DEPARTURES_HOSTEL: "departures/hostel",
  OCCUPANTS: "occupants",
  OCCUPANTS_HOTEL: "occupants/hotel",
  OCCUPANTS_AREA: "occupants/area",
  OCCUPANTS_HOSTEL: "occupants/hostel",
  CATEGORY_CLUSTER: "category-cluster",
  FIRE_LIST: "fire-list",
  COMMUNICATION_SPHERES: "communication-spheres",
  SIMPLE_SEARCH: "search",
};

export type ROUTES = (typeof ROUTES)[keyof typeof ROUTES];
