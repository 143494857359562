import { useState } from "react";
import { DefaultListItem } from "@components/default-list.tsx";
import { useSharedContext } from "@context/shared-context.tsx";
import { useTranslation } from "react-i18next";
import { DefaultAlert } from "@pages/settings/locations/components/locations-list-item.tsx";
import {
  ChannelsActionType,
  useChannelsPageContext,
} from "@pages/settings/channels/channels-context.tsx";
import { Channel } from "../../../../../../api-contracts/channels";
import { useDeleteChannel } from "@api/channels.ts";

export const ChannelsCardItem = ({ channel }: { channel: Channel }) => {
  const {
    sharedFunctions: { setActionType, setCurrent, handleRefetch },
  } = useChannelsPageContext();
  const {
    sharedFunctions: { toast, setLoading },
  } = useSharedContext();
  const { mutate: delChannel } = useDeleteChannel();
  const { t } = useTranslation();
  const [alertOpen, setAlertOpen] = useState<boolean>(false);

  const onEdit = (channel: Channel) => {
    setActionType(ChannelsActionType.EDIT);
    setCurrent(channel);
  };

  const onDelete = (channel: Channel) => {
    setLoading(true);
    delChannel(
      { id: channel.id },
      {
        onError: (e) => {
          toast({
            variant: "destructive",
            title: t("delete-channel-error", { error: e.message }),
          });
        },
        onSuccess: async () => {
          toast({
            variant: "success",
            title: t("channel-deleted", { channel: channel?.name }),
          });
          await handleRefetch();
        },
        onSettled: () => {
          setLoading(false);
        },
      },
    );
  };

  return (
    <DefaultListItem
      key={channel.id}
      title={channel.name}
      onEdit={() => onEdit(channel)}
      onDelete={() => setAlertOpen(true)}
    >
      <DefaultAlert
        alertOpen={alertOpen}
        setAlertOpen={setAlertOpen}
        title={t("are-you-sure-you-want-to-delete-channel", {
          channel: channel.name,
        })}
        description={t("channel-will-be-deleted")}
        primaryButtonText={t("yes-delete-channel", {
          channel: channel.name,
        })}
        onPrimaryButton={() => onDelete(channel)}
      />
    </DefaultListItem>
  );
};
