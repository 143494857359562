import { createMutation, createQuery } from "react-query-kit";
import { api } from "./api";
import { currentSite } from "@shared/context/site-context";
import {
  FolioBulkPayRequest,
  FolioBulkRequest,
  FolioBulkResponse,
  GetFolioResponse,
  PayFolioEntryRequest,
} from "../../../../api-contracts/payments";

export const FOLIO_KEY = "payments";

export const useGetFolio = createQuery<GetFolioResponse, { id: string }>({
  primaryKey: FOLIO_KEY,
  queryFn: async ({ queryKey: [, { id }] }) => {
    return api.get<GetFolioResponse>(
      `/api/${currentSite?.id}/${FOLIO_KEY}/folio/${id}`,
    );
  },
});

export const usePayFolio = createMutation<{}, PayFolioEntryRequest>({
  mutationFn: async ({ id, ...entries }) =>
    api.post(`/api/${currentSite?.id}/${FOLIO_KEY}/folio/${id}/pay`, {
      id,
      ...entries,
    }),
});

export const useGetFolios = createQuery<FolioBulkResponse, FolioBulkRequest>({
  primaryKey: FOLIO_KEY,
  queryFn: async ({ queryKey: [, data] }) => {
    return api.post<FolioBulkResponse>(
      `/api/${currentSite?.id}/${FOLIO_KEY}/folio-bulk/`,
      data,
    );
  },
});

export const usePayFolios = createMutation<{}, FolioBulkPayRequest>({
  mutationFn: async (data) =>
    api.post(`/api/${currentSite?.id}/${FOLIO_KEY}/folio-bulk/pay`, data),
});
