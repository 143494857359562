import { useGetLanguageSettings } from "@api/language-settings.ts";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@primitives/select";
import { useTranslation } from "react-i18next";

interface Props {
  value?: string;
  onValueChange?: (value?: string) => void;
  id?: string;
  disabled?: boolean;
}

const ConfigurationLanguageSelect = ({
  value,
  onValueChange,
  id,
  disabled,
}: Props) => {
  const [t] = useTranslation();

  const {
    data: languagesData,
    isLoading: languagesIsLoading,
    isRefetching: languagesIsRefetching,
  } = useGetLanguageSettings({});

  return (
    <Select
      disabled={disabled}
      value={value}
      onValueChange={(value) => onValueChange?.(value)}
    >
      <SelectTrigger id={id} className="text-xs">
        <SelectValue>
          {t(languagesData?.find((l) => l.code === value)?.name || "")}
        </SelectValue>
      </SelectTrigger>
      <SelectContent>
        {languagesData?.map((c, i) => (
          <SelectItem key={i} value={c.code} className=" text-xs">
            {t(c.name)}
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  );
};

export default ConfigurationLanguageSelect;
