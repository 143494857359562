import { AuthProvider } from "@shared/context/auth-context";
import { ThemeProvider } from "@shared/context/theme-context";
import Router from "./pages/router";
import { QueryClientProvider } from "@tanstack/react-query";
import React, { Suspense, useEffect } from "react";
import ReactDOM from "react-dom/client";
import "./i18n";
import "./sentry";
import "./index.css";
import { TooltipProvider } from "@radix-ui/react-tooltip";
import { queryClient } from "./query-client";
import { Loading } from "@shared/primitives/loading";
import { Toaster } from "@shared/primitives/toaster";
import { SharedProvider } from "@shared/context/shared-context.tsx";
import {
  ProfileContextProvider,
  useProfileContext,
} from "@context/profile-context";
import { loadCSS } from "@shared/utils/module-helper";

console.info(
  `${__NAME__}@${__VERSION__}_${__REVISION__}`,
  `(${import.meta.env.MODE})`,
);

/** Loading CSS file based on module */
const App = () => {
  const { module, activeCluster } = useProfileContext();
  useEffect(() => {
    let cssFile;
    switch (module) {
      case "roomr":
        cssFile = "/styles/roomr.css";
        break;
      case "campr":
        cssFile = "/styles/campr.css";
        break;
      default:
        cssFile = "./index.css";
        break;
    }
    loadCSS(cssFile);
  }, [module]);

  return (
    <Suspense
      fallback={
        <div className=" h-screen w-screen">
          <Loading />
        </div>
      }
    >
      <ThemeProvider>
        <TooltipProvider>
          <Toaster />
          <SharedProvider>
            {activeCluster.length > 0 && <Router />}
          </SharedProvider>
        </TooltipProvider>
      </ThemeProvider>
    </Suspense>
  );
};

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <ProfileContextProvider>
          <App />
        </ProfileContextProvider>
      </AuthProvider>
    </QueryClientProvider>
  </React.StrictMode>,
);
