import { useGetAllocableBookables } from "@api/bookables";
import { useGetCategories } from "@api/categories";
import { AlertDialog } from "@primitives/alert-dialog";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@primitives/select";
import { FC, SetStateAction, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { ReceptionBooking } from "../../../../../../api-contracts/reception";
import { format } from "date-fns";
import React from "react";
import { SwitchWithLabel } from "@primitives/switch-with-label";
import { Loading } from "@primitives/loading";
import { CategoryTypeEnum, useReceptionContext } from "../../reception-context";
import { PatchBookingRequest } from "../../../../../../api-contracts/reservations";
import { MultiSelect } from "@primitives/multi-select";
import { Category } from "../../../../../../api-contracts/categories";
import { CheckinRequest } from "../../../../../../api-contracts/reception/arrivals";

type Bookable = {
  name: string;
  id: string;
};

interface AllocateDialogProps {
  isOpen: boolean;
  title?: string;
  description?: string;
  onCheckIn?: (req: CheckinRequest) => void;
  onPatchBooking: (
    booking: ReceptionBooking,
    patchData: PatchBookingRequest,
  ) => void;
  onOpenChange: React.Dispatch<SetStateAction<boolean>>;
  proceedBtnText: string;
  onDiscard?: () => void;
  booking: ReceptionBooking;
  dialogType:
    | "checkInAssignedRoom"
    | "chooseBookable"
    | "assignBeforeCheckIn"
    | "paymentRemaining";
}

export const AllocateRoomDialog: FC<AllocateDialogProps> = ({
  isOpen = false,
  title = "Allocate asset",
  description = "Choose asset",
  onCheckIn,
  onPatchBooking,
  onOpenChange,
  proceedBtnText,
  onDiscard,
  booking,
  dialogType,
}) => {
  const { t } = useTranslation();
  const { categoryType } = useReceptionContext();
  const [keepPrice, setKeepPrice] = useState<boolean>(false);

  const initialCategoryId = booking.category.id;
  const initialBookableId = booking.bookable.id;
  const initialBookableName = booking.bookable.name;

  const [selectedCategory, setSelectedCategory] = useState<
    Category | undefined
  >(undefined);

  const [bookable, setBookable] = useState<Bookable | undefined>({
    id: String(initialBookableId),
    name: initialBookableName,
  });

  const descriptionText =
    dialogType === "chooseBookable"
      ? t("allocate-unit-for-{{name}}-{{surname}}", {
          name: booking.holder.name || t("guest"),
          surname: booking.holder.surname || "",
        })
      : booking.bookable?.name
        ? t("do-you-want-to-check-in-{{name}}-{{surname}}-in-{{unit}}?", {
            name: booking.holder.name || t("guest"),
            surname: booking.holder.surname || "",
            unit: bookable?.name || "",
          })
        : t("do-you-want-to-check-in-{{name}}-{{surname}}?", {
            name: booking.holder.name || t("guest"),
            surname: booking.holder.surname || "",
          });

  const mapCategoryTypeToQuery = (categoryType: string) => {
    switch (categoryType) {
      case CategoryTypeEnum.ALL:
        return ["room", "area", "bed", "dormitory"];
      case CategoryTypeEnum.HOTEL:
        return ["room"];
      case CategoryTypeEnum.AREA:
        return ["area"];
      case CategoryTypeEnum.HOSTEL:
        return ["bed", "dormitory"];
      default:
        return ["room", "area", "bed", "dormitory"];
    }
  };

  const categoryTypeQuery = mapCategoryTypeToQuery(categoryType);
  const {
    data: categoriesData,
    isLoading: categoriesIsLoading,
    refetch: refetchCategories,
  } = useGetCategories({
    variables: { categoryTypes: categoryTypeQuery as any },
  });

  const {
    data: bookablesData,
    isLoading: bookablesIsLoading,
    refetch: refetchBookables,
  } = useGetAllocableBookables({
    variables: {
      categoryId: selectedCategory?.id || initialCategoryId,
      startDate: format(booking.arrivalDate, "yyyy-MM-dd"),
      endDate: format(booking.departureDate, "yyyy-MM-dd"),
    },
  });

  const initialCategory = categoriesData?.find(
    (category) => String(category.id) === initialCategoryId,
  );
  const initialCategoryPrice = initialCategory?.minimumRate || 0;

  useEffect(() => {
    if (!selectedCategory && categoriesData) {
      const initialCategory = categoriesData.find(
        (category) => String(category.id) === initialCategoryId,
      );
      setSelectedCategory(initialCategory);
    }
  }, [categoriesData, initialCategoryId, selectedCategory]);

  const sortedCategories = categoriesData
    ? [...categoriesData].sort((a, b) => {
        const priceDiffA = a.minimumRate - initialCategoryPrice;
        const priceDiffB = b.minimumRate - initialCategoryPrice;
        return priceDiffA - priceDiffB;
      })
    : [];

  const combinedBookables = useMemo(() => {
    if (String(selectedCategory?.id) === initialCategoryId) {
      const isBookableInData = bookablesData?.bookables.some(
        (b) => b.id === initialBookableId,
      );

      if (!isBookableInData) {
        return [
          { id: initialBookableId, name: initialBookableName },
          ...(bookablesData?.bookables || []),
        ];
      }
    }

    return bookablesData?.bookables || [];
  }, [
    selectedCategory,
    initialCategoryId,
    bookablesData,
    initialBookableId,
    initialBookableName,
  ]);

  const handleCategoryChange = (categoryId: string) => {
    const newCategory = categoriesData?.find(
      (category) => String(category.id) === categoryId,
    );
    setSelectedCategory(newCategory || undefined);
  };

  const handleProceed = async () => {
    const hasCategoryChanged =
      String(selectedCategory?.id) !== initialCategoryId;
    const hasBookableChanged = String(bookable?.id) !== initialBookableId;
    try {
      if (hasCategoryChanged || hasBookableChanged) {
        const patchData: PatchBookingRequest = {
          categoryId: selectedCategory?.id || "",
          bookableId: bookable?.id ?? null,
          slots: [
            {
              priceOverrideAmount: keepPrice
                ? initialCategoryPrice
                : selectedCategory?.minimumRate ?? null,
              priceAdjustmentPercent: null,
              startDate: format(booking.arrivalDate, "yyyy-MM-dd"),
              startTime: booking.category.startTimes[0],
            },
          ],
        };

        await onPatchBooking(booking, patchData);
      }

      if (onCheckIn) {
        await onCheckIn({ bookingId: booking.bookingId });
      }
      refetchBookables();
      refetchCategories();
    } catch (error) {
      console.error("Error occurred during patch or check-in process:", error);
      return;
    }
  };

  useEffect(() => {
    const hasNoChanges = String(selectedCategory?.id) === initialCategoryId;

    setBookable(
      hasNoChanges
        ? { id: initialBookableId, name: initialBookableName }
        : undefined,
    );
  }, [
    selectedCategory,
    initialCategoryId,
    initialBookableId,
    initialBookableName,
  ]);

  return (
    <AlertDialog.Root open={isOpen} onOpenChange={onOpenChange}>
      <AlertDialog.Content>
        <AlertDialog.Header>
          <AlertDialog.Title>{title}</AlertDialog.Title>
          <AlertDialog.Description>{descriptionText}</AlertDialog.Description>
        </AlertDialog.Header>
        <div className="flex flex-col justify-between">
          <p>{t("category")}</p>
          <Select
            onValueChange={(value: string) => handleCategoryChange(value)}
            value={String(selectedCategory?.id)}
          >
            <SelectTrigger className="relative">
              <>
                <SelectValue />
                {categoriesIsLoading && (
                  <div className="flex">
                    <Loading />
                  </div>
                )}
              </>
            </SelectTrigger>
            <SelectContent>
              <div className="mb-1 flex p-2">
                <SwitchWithLabel
                  checked={keepPrice}
                  onCheckedChange={setKeepPrice}
                  label={t("keep-price")}
                />
              </div>

              {sortedCategories?.map((category) => {
                const isInitialCategory =
                  String(category.id) === initialCategoryId;

                const priceDiff = keepPrice
                  ? 0
                  : category.minimumRate - initialCategoryPrice;

                const priceDiffClassName =
                  priceDiff > 0
                    ? "bg-status-success-100 text-status-success"
                    : priceDiff < 0
                      ? "bg-status-warning-100 text-status-warning"
                      : "bg-status-disabled-100 text-status-disabled";

                return (
                  <SelectItem key={category.id} value={category.id}>
                    <div className="flex w-full items-center">
                      <span>{category.short}</span>
                      <span
                        className={`absolute right-10 rounded-[4px] px-2 text-xs font-extrabold ${priceDiffClassName}`}
                      >
                        {isInitialCategory || priceDiff === 0
                          ? `+/- 0 SEK`
                          : priceDiff > 0
                            ? `+ ${priceDiff} SEK`
                            : `- ${Math.abs(priceDiff)} SEK`}
                      </span>
                    </div>
                  </SelectItem>
                );
              })}
            </SelectContent>
          </Select>

          <p className="mt-2">{t("unit")}</p>

          <MultiSelect
            className="z-[200]"
            options={combinedBookables}
            value={bookable ? [bookable] : []}
            onChange={(val) => {
              const selected = Array.isArray(val) ? val[0] : val;

              if (selected && String(selected.id) === String(bookable?.id)) {
                setBookable(undefined);
              } else {
                setBookable({ id: String(selected.id), name: selected.name });
              }
            }}
            getName={(opt) => opt?.name || ""}
            multiple={false}
            placeholder={t("choose-unit")}
          />
        </div>
        <AlertDialog.Footer>
          <AlertDialog.Cancel>{t("cancel")}</AlertDialog.Cancel>
          {onDiscard && (
            <AlertDialog.Cancel onClick={() => onDiscard()}>
              {t("discard")}
            </AlertDialog.Cancel>
          )}
          <AlertDialog.Action
            disabled={dialogType !== "chooseBookable" && bookable === undefined}
            onClick={handleProceed}
          >
            {proceedBtnText}
          </AlertDialog.Action>
        </AlertDialog.Footer>
      </AlertDialog.Content>
    </AlertDialog.Root>
  );
};
