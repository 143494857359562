export const HighlightText = ({
  text,
  textToHighlight,
  className,
}: {
  text: string;
  textToHighlight: string;
  className?: string;
}) => {
  const parts = text.split(new RegExp(`(${textToHighlight})`, "gi"));
  return (
    <>
      {parts.map((part, index) => (
        <span
          key={index}
          className={`${
            part.toLowerCase() === textToHighlight.toLowerCase()
              ? "bg-amber-500-50"
              : ""
          } ${className}
          `}
        >
          {part}
        </span>
      ))}
    </>
  );
};
