import {
  Column,
  SortingState,
  Table as TableType,
  flexRender,
} from "@tanstack/react-table";
import { useTranslation } from "react-i18next";
import {
  ChevronsUpDownIcon,
  ChevronDownIcon,
  ChevronUpIcon,
} from "lucide-react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@primitives/table";
import { Fragment } from "react";

type ReceptionTableProps<T> = {
  table: TableType<T>;
  onRowClick: (booking: T) => void;
  showAllGuests?: boolean;
  showLocation?: boolean;
};

export const LocationReceptionTable = <T,>({
  table,
  onRowClick,
  showAllGuests,
  showLocation = true,
}: ReceptionTableProps<T>) => {
  const { t } = useTranslation();
  const rows = table.getRowModel().rows;

  const getSortIcon = (
    column: Column<T, unknown>,
    sortingState: SortingState,
  ) => {
    const sort = sortingState.find((s) => s.id === column.id);
    if (!sort) return <ChevronsUpDownIcon size={13} />;
    return sort.desc ? (
      <ChevronDownIcon size={13} />
    ) : (
      <ChevronUpIcon size={13} />
    );
  };

  const hasLocationName = (
    booking: T,
  ): booking is T & { locationName: string } =>
    (booking as any).locationName !== undefined;

  return (
    <Table id="table" className="whitespace-nowrap rounded-md print:block">
      <TableHeader>
        {table.getHeaderGroups().map((headerGroup) => (
          <TableRow key={headerGroup.id} className="group">
            {headerGroup.headers.map((header) => (
              <TableHead key={header.id}>
                {header.isPlaceholder ? null : (
                  <div
                    className="flex cursor-pointer items-center"
                    onClick={header.column.getToggleSortingHandler()}
                  >
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext(),
                    )}
                    {header.column.getCanSort() && !showLocation && (
                      <div className="ml-1">
                        {getSortIcon(header.column, table.getState().sorting)}
                      </div>
                    )}
                  </div>
                )}
              </TableHead>
            ))}
          </TableRow>
        ))}
      </TableHeader>
      <TableBody>
        {rows.length ? (
          rows.map((row, index) => {
            const locationRow = row.original as T & { locationName?: string };
            const locationName = locationRow.locationName;

            const previousLocationName =
              index > 0 && hasLocationName(rows[index - 1].original)
                ? (rows[index - 1].original as T & { locationName?: string })
                    .locationName
                : null;

            const showLocationHeader = locationName !== previousLocationName;

            return (
              <Fragment key={row.id}>
                {showLocationHeader && locationName && (
                  <TableRow className="pointer-events-none">
                    <TableCell
                      colSpan={row.getVisibleCells().length}
                      className="h-16 p-2 px-16 text-sm text-secondary-text"
                    >
                      {locationName}
                    </TableCell>
                  </TableRow>
                )}
                <TableRow
                  className="group cursor-pointer"
                  onClick={() => onRowClick(row.original)}
                >
                  {row.getVisibleCells().map((cell) => (
                    <TableCell
                      className={`${showAllGuests ? "align-top" : ""} h-16`}
                      key={cell.id}
                      style={{ width: `${cell.column.getSize()}px` }}
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext(),
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              </Fragment>
            );
          })
        ) : (
          <TableRow>
            <TableCell
              colSpan={table.getAllLeafColumns().length}
              className="h-24 text-center"
            >
              {t("no-results")}.
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
};
