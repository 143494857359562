import { TabNavbar } from "@components/tab-navbar.tsx";
import { ROUTES } from "@shared/types/navigation.ts";
import { useTranslation } from "react-i18next";
import { useProfileContext } from "@context/profile-context.tsx";
import { FC, useEffect, useState } from "react";
import {
  AssetType,
  VisualAssetType,
} from "../../../../../../../api-contracts/assets";

interface Props {
  tab: VisualAssetType;
}
export const CamprTabNavigation: FC<Props> = ({ tab }) => {
  const { module } = useProfileContext();
  const { t } = useTranslation();
  const camprTabItems = [
    {
      value: ["plot"],
      label: t("plot"),
      disabled: false,
      to: `/${module}/${ROUTES.BOOKABLES}/${ROUTES.PLOTS}`,
    },
    {
      value: ["cabin"],
      label: t("bookable-motor-home"),
      disabled: false,
      to: `/${module}/${ROUTES.BOOKABLES}/${ROUTES.CABINS}`,
    },
    {
      value: ["misc"],
      label: t("other-accommodation"),
      disabled: false,
      to: `/${module}/${ROUTES.BOOKABLES}/${ROUTES.OTHER_ACCOMMODATIONS}`,
    },
    {
      value: ["campr-area"],
      label: t("area"),
      disabled: false,
      to: `/${module}/${ROUTES.BOOKABLES}/${ROUTES.AREAS}`,
    },
    {
      value: ["campr-combination"],
      label: t("combinations"),
      disabled: false,
      to: `/${module}/${ROUTES.BOOKABLES}/${ROUTES.COMBINATIONS}`,
    },
    {
      value: ["all"],
      label: t("all"),
      disabled: false,
      to: `/${module}/${ROUTES.BOOKABLES}/${ROUTES.ASSETS}`,
    },
  ];
  return (
    <div className=" mb-4 mt-4">
      <TabNavbar value={tab} items={camprTabItems} />
    </div>
  );
};
