import { NavigationArrows } from "@components/navigation-arrows";
import { useProfileContext } from "@context/profile-context";
import { Button } from "@primitives/button";
import { Card } from "@primitives/card";
import { Checkbox } from "@primitives/checkbox";
import { Input } from "@primitives/input";
import { ROUTES } from "@shared/types/navigation";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  CommunicationChannel,
  GetDetailedCommunicationSphere,
} from "../../../../../../api-contracts/communication-spheres";
import { useParams } from "react-router-dom";
import { useGetCommunicationSphere } from "@api/communication-spheres";
import { queryClient } from "query-client";
import { useGetLanguageSettings } from "@api/language-settings";
import {
  AlertTriangle,
  Eye,
  Loader2,
  Mail,
  MessageSquare,
  Paperclip,
  Trash,
} from "lucide-react";
import { cn } from "@shared/utils/css";
import { Switch } from "@primitives/switch";
import { Textarea } from "@primitives/textarea";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@primitives/simpleTooltip";

type Unpacked<T> = T extends (infer U)[] ? U : T;

const initialSphere: GetDetailedCommunicationSphere = {
  id: "new",
  languages: [],
  triggers: [],
  name: "",
  status: {
    active: false,
    default: false,
  },
};

const supportedCommunicationChannels: CommunicationChannel[] = ["email", "sms"];

const CommunicationSphereDetailsPage = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const { module } = useProfileContext();
  const [sphere, setSphere] =
    useState<GetDetailedCommunicationSphere>(initialSphere);
  const [selectedTrigger, setSelectedTrigger] =
    useState<Unpacked<GetDetailedCommunicationSphere["triggers"]>>();
  const [isEditing, setIsEditing] = useState<boolean>(false);

  useEffect(() => {
    queryClient.invalidateQueries({
      queryKey: useGetCommunicationSphere.getKey(),
    });
  }, []);

  const {
    data: sphereData,
    isLoading: sphereLoading,
    isRefetching: sphereRefetching,
  } = useGetCommunicationSphere({
    variables: {
      id: id as string,
    },
    enabled: !!id,
  });

  const {
    data: languagesData,
    isLoading: languagesLoading,
    isRefetching: languagesRefetching,
  } = useGetLanguageSettings({});

  useEffect(() => {
    if (sphereData) {
      setSphere(sphereData);
      setSelectedTrigger(sphereData.triggers[0]);
    }
  }, [sphereData]);

  // TODO send null on body and title if empty strings

  return (
    <>
      <div className="p-4 text-primary-text">
        <NavigationArrows
          rootPath={`/${module}/${ROUTES.COMMUNICATION_SPHERES}`}
        />
        <Card className=" mt-4 flex max-w-full flex-nowrap items-center overflow-x-auto rounded-xl p-0">
          <div className=" w-[300px] min-w-[300px] max-w-[300px] pb-4 pl-4 pt-4">
            <Input
              value={sphere.name}
              onChange={(e) =>
                setSphere((sphere) => ({ ...sphere, name: e.target.value }))
              }
              placeholder={t("name")}
              disabled={!isEditing}
            />
            <div className=" mt-2 flex items-center space-x-2">
              <Checkbox
                id="standardSphere"
                disabled={!isEditing}
                checked={sphere.status.default}
                onCheckedChange={(checked) =>
                  setSphere((sphere) => ({
                    ...sphere,
                    status: {
                      active: sphere.status.active,
                      default: checked as boolean,
                    },
                  }))
                }
              />
              <label
                className={cn(
                  " mt-1 cursor-pointer text-sm font-extrabold text-primary-text",
                  { " text-secondary-text": !isEditing },
                )}
                htmlFor="standardSphere"
              >
                {t("standard-sphere")}
              </label>
            </div>
          </div>
          <div className=" flex max-w-[calc(100%-300px)] flex-grow flex-nowrap justify-between p-4">
            <div className=" relative flex max-w-full flex-shrink flex-grow flex-nowrap space-x-2 overflow-x-auto">
              {(languagesLoading || languagesRefetching) && (
                <div className="absolute z-20 flex h-full w-full items-center justify-center backdrop-blur-sm">
                  <Loader2 className="h-20 w-20 animate-spin text-primary-color" />
                </div>
              )}
              {languagesData?.map((l) => (
                <div
                  key={l.code}
                  className={cn(" rounded-lg bg-secondary-card-backplate", {
                    " bg-amber-500-50": sphere.languages.find(
                      (l2) => l.code === l2.code,
                    ),
                  })}
                >
                  <div className="p-4 pb-2 pt-3 text-center text-sm font-extrabold">
                    {l.short}
                  </div>
                  <div className=" bg-primary-card-backplate px-2 py-1 pt-2">
                    <Switch
                      disabled={!isEditing}
                      className=" data-[state=checked]:bg-highlighted-backplate"
                      checked={
                        !!sphere.languages.find((l2) => l.code === l2.code)
                      }
                      onCheckedChange={(checked) => {
                        if (checked) {
                          const newTriggers = sphere.triggers.map((t) => ({
                            ...t,
                            entries: t.entries.map((e) => ({
                              ...e,
                              content: [
                                ...e.content,
                                { language: l, body: "", title: "" },
                              ],
                            })),
                          }));
                          setSphere((sphere) => ({
                            ...sphere,
                            languages: [...sphere.languages, l],
                            triggers: newTriggers,
                          }));
                          setSelectedTrigger(
                            newTriggers.find(
                              (t) => t.triggerTypeId === selectedTrigger?.id,
                            ),
                          );
                        } else {
                          const newTriggers = sphere.triggers.map((t) => ({
                            ...t,
                            entries: t.entries.map((e) => ({
                              ...e,
                              content: e.content.filter(
                                (c) => c.language.code !== l.code,
                              ),
                            })),
                          }));
                          setSphere((sphere) => ({
                            ...sphere,
                            languages: sphere.languages.filter(
                              (l2) => l.code !== l2.code,
                            ),
                            triggers: newTriggers,
                          }));
                          setSelectedTrigger(
                            newTriggers.find(
                              (t) => t.triggerTypeId === selectedTrigger?.id,
                            ),
                          );
                        }
                      }}
                    />
                  </div>
                </div>
              ))}
            </div>
            <div className=" ml-2 flex flex-nowrap items-center space-x-2">
              {isEditing && (
                <Button
                  variant="secondary"
                  onClick={() => {
                    setIsEditing(false);
                    setSphere(sphereData ?? initialSphere);
                    setSelectedTrigger(
                      sphereData?.triggers.find(
                        (t) => t.triggerTypeId === selectedTrigger?.id,
                      ),
                    );
                  }}
                >
                  {t("cancel")}
                </Button>
              )}
              {isEditing && <Button>{t("save")}</Button>}
              {!isEditing && (
                <Button onClick={() => setIsEditing(true)}>{t("edit")}</Button>
              )}
            </div>
          </div>
        </Card>
        <div className=" mt-2 flex flex-nowrap">
          <Card className=" mr-2 w-[300px] rounded-xl p-0">
            {sphere.triggers.map((trig, i) => (
              <div
                key={trig.triggerTypeId}
                className={cn(
                  " flex h-[55px] cursor-pointer flex-nowrap items-center justify-between border-r-4 border-transparent p-3",
                  {
                    "border-amber-500 bg-amber-500-25":
                      selectedTrigger?.triggerTypeId === trig.triggerTypeId,
                    "rounded-t": i === 0,
                    "rounded-b": i === sphere.triggers.length - 1,
                  },
                )}
                onClick={() => setSelectedTrigger(trig)}
              >
                <p
                  className={cn(
                    " truncate text-sm font-normal text-secondary-text ",
                    {
                      " font-extrabold text-primary-text":
                        selectedTrigger?.triggerTypeId === trig.triggerTypeId,
                    },
                  )}
                >
                  {trig.name}
                </p>
                <div className=" flex flex-nowrap items-center space-x-2">
                  <div className=" flex flex-nowrap items-center space-x-2 ">
                    {trig.active &&
                      trig.entries.find((e) => e.type === "email") && (
                        <Tooltip delayDuration={0}>
                          <TooltipTrigger>
                            <Mail size={18} />
                          </TooltipTrigger>
                          <TooltipContent>{t("email")}</TooltipContent>
                        </Tooltip>
                      )}
                    {trig.active &&
                      trig.entries.find((e) => e.type === "sms") && (
                        <Tooltip delayDuration={0}>
                          <TooltipTrigger>
                            <MessageSquare size={18} />
                          </TooltipTrigger>
                          <TooltipContent>{t("sms")}</TooltipContent>
                        </Tooltip>
                      )}
                  </div>
                  {trig.active &&
                    trig.entries.some((e) =>
                      e.content.some(
                        (c) => !c.body?.trim() || !c.title?.trim(),
                      ),
                    ) && (
                      <div className=" rounded-lg bg-status-warning-100 p-2 ">
                        <AlertTriangle
                          className=" text-status-warning"
                          size={14}
                        />
                      </div>
                    )}
                  <Switch
                    disabled={!isEditing}
                    checked={trig.active}
                    onCheckedChange={(checked) => {
                      trig.active = checked;
                      setSphere({ ...sphere });
                    }}
                  />
                </div>
              </div>
            ))}
          </Card>

          <Card className=" flex-grow rounded-xl">
            {selectedTrigger && (
              <>
                <h2 className=" text-lg font-extrabold">
                  {selectedTrigger.name}
                </h2>
                <div className=" flex flex-nowrap">
                  <div className=" flex-grow">
                    {supportedCommunicationChannels.map((com, i) => {
                      const entry = selectedTrigger.entries.find(
                        (e) => e.type === com,
                      );
                      if (entry) {
                        return (
                          <TriggerEntry
                            key={com}
                            entry={entry}
                            isEditing={isEditing}
                            index={i}
                            onChange={(entry) => {
                              let newTrigger;
                              if (entry) {
                                newTrigger = {
                                  ...selectedTrigger,
                                  entries: selectedTrigger.entries.map((e) =>
                                    e.type === com ? entry : e,
                                  ),
                                };
                              } else {
                                newTrigger = {
                                  ...selectedTrigger,
                                  entries: selectedTrigger.entries.filter(
                                    (e) => e.type !== com,
                                  ),
                                };
                              }
                              const newTriggers = sphere.triggers.map((t) =>
                                t.triggerTypeId ===
                                selectedTrigger.triggerTypeId
                                  ? newTrigger
                                  : t,
                              );
                              setSphere({
                                ...sphere,
                                triggers: newTriggers,
                              });
                              setSelectedTrigger(newTrigger);
                            }}
                          />
                        );
                      } else {
                        return (
                          <div
                            key={com}
                            className={cn(
                              " rounded-xl bg-secondary-card-backplate p-4",
                              {
                                "mb-2":
                                  i < supportedCommunicationChannels.length - 1,
                              },
                            )}
                          >
                            <div className=" flex flex-nowrap items-center space-x-2">
                              <Checkbox
                                id={com}
                                disabled={!isEditing}
                                checked={false}
                                onCheckedChange={() => {
                                  selectedTrigger.entries.push({
                                    type: com,
                                    content: sphere.languages.map((l) => ({
                                      language: l,
                                      body: "",
                                      title: "",
                                    })),
                                  });
                                  setSphere({
                                    ...sphere,
                                    triggers: sphere.triggers.map((t) =>
                                      t.triggerTypeId ===
                                      selectedTrigger.triggerTypeId
                                        ? selectedTrigger
                                        : t,
                                    ),
                                  });
                                  setSelectedTrigger({ ...selectedTrigger });
                                }}
                              />
                              <label
                                className={cn(
                                  " mt-1 cursor-pointer text-sm font-extrabold text-primary-text",
                                  { " text-secondary-text": !isEditing },
                                )}
                                htmlFor={com}
                              >
                                {t(com)}
                              </label>
                            </div>
                          </div>
                        );
                      }
                    })}
                  </div>
                  <div className=" ml-2 w-[300px] rounded-xl bg-secondary-card-backplate p-4"></div>
                </div>
              </>
            )}
          </Card>
        </div>
      </div>
    </>
  );
};

interface TriggerEntryProps {
  entry: Unpacked<
    Unpacked<GetDetailedCommunicationSphere["triggers"]>["entries"]
  >;
  isEditing: boolean;
  index: number;
  onChange: (
    entry?: Unpacked<
      Unpacked<GetDetailedCommunicationSphere["triggers"]>["entries"]
    >,
  ) => void;
}

const TriggerEntry = ({
  entry,
  isEditing,
  index,
  onChange,
}: TriggerEntryProps) => {
  const { t } = useTranslation();

  const [selectedContent, setSelectedContent] = useState<
    Unpacked<TriggerEntryProps["entry"]["content"]>
  >(entry.content[0]);

  useEffect(() => {
    const content = entry.content.find(
      (c) => selectedContent.language.code === c.language.code,
    );
    if (!content) {
      setSelectedContent(entry.content[0]);
    } else {
      setSelectedContent(content);
    }
  }, [entry]);

  return (
    <div
      className={cn(" rounded-xl bg-secondary-card-backplate p-4", {
        "mb-2": index < supportedCommunicationChannels.length - 1,
      })}
    >
      <div className=" flex flex-nowrap items-center space-x-2 text-secondary-text">
        <Checkbox
          id={entry.type}
          disabled={!isEditing}
          checked={true}
          onCheckedChange={() => {
            onChange();
          }}
        />
        <label
          className={cn(
            " mt-1 cursor-pointer text-sm font-extrabold text-primary-text",
            { " text-secondary-text": !isEditing },
          )}
          htmlFor={entry.type}
        >
          {t(entry.type)}
        </label>
      </div>
      <div className=" mt-2 flex items-center justify-between">
        <div className=" flex flex-nowrap space-x-2 rounded-md bg-primary-card-backplate p-1">
          <div className=" flex flex-nowrap space-x-2 ">
            {entry.content.map((c) => (
              <div
                key={c.language.code}
                className={cn(
                  " flex cursor-pointer flex-nowrap space-x-2 bg-secondary-card-backplate p-2 text-sm font-normal",
                  {
                    " bg-status-warning-100 text-status-warning":
                      !c.body?.trim() || !c.title?.trim(),
                    " font-extrabold":
                      selectedContent.language.code === c.language.code,
                  },
                )}
                onClick={() => setSelectedContent(c)}
              >
                <p> {c.language.short}</p>
                {(!c.body?.trim() || !c.title?.trim()) && (
                  <AlertTriangle size={16} />
                )}
              </div>
            ))}
          </div>
        </div>
        <div className=" flex flex-nowrap space-x-2 ">
          <Button
            variant="secondary"
            size="sm"
            className=" text-secondary-text"
            disabled={!isEditing}
          >
            {t("insert-standard-text")}
          </Button>
          <Button
            variant="secondary"
            size="sm"
            className=" text-secondary-text"
            disabled={!isEditing}
          >
            <Trash size={12} />
          </Button>
          <Button
            variant="secondary"
            size="sm"
            className=" text-secondary-text"
            disabled={!isEditing}
          >
            <Paperclip size={12} />
          </Button>
          <Button
            variant="secondary"
            size="sm"
            className=" text-secondary-text"
          >
            <Eye size={12} />
          </Button>
        </div>
      </div>
      <div className=" mt-2">
        <Input
          disabled={!isEditing}
          placeholder={t("title")}
          defaultValue={selectedContent.title ?? undefined}
          onChange={(e) =>
            onChange({
              ...entry,
              content: entry.content.map((c) =>
                c.language.code === selectedContent.language.code
                  ? { ...c, title: e.target.value }
                  : c,
              ),
            })
          }
        />
        <Textarea
          disabled={!isEditing}
          className=" mt-2"
          placeholder={t("text")}
          defaultValue={selectedContent.body ?? undefined}
          onChange={(e) =>
            onChange({
              ...entry,
              content: entry.content.map((c) =>
                c.language.code === selectedContent.language.code
                  ? { ...c, body: e.target.value }
                  : c,
              ),
            })
          }
        />
      </div>
    </div>
  );
};

export default CommunicationSphereDetailsPage;
