import {
  CreatePriceRuleRequest,
  CreatePriceRuleResponse,
  DisablePriceRuleRequest,
  DisablePriceRuleResponse,
  EnablePriceRuleRequest,
  EnablePriceRuleResponse,
  GetPriceRulesResponse,
  UpdatePriceRuleRequest,
  UpdatePriceRuleResponse,
} from "../../../../api-contracts/price-rules";
import { api } from "./api";
import { createMutation, createQuery } from "react-query-kit";
import { currentSite } from "@shared/context/site-context";
import { CategoryType } from "../../../../api-contracts/categories";

export type PriceRulesResponse = Omit<GetPriceRulesResponse, 0>;

export const PRICE_RULES_KEY = "price-rules";
export const useGetPriceRules = createQuery<
  GetPriceRulesResponse,
  { categoryTypes: CategoryType[] }
>({
  queryFn: async ({ queryKey: [_, args] }) => {
    const types = args?.categoryTypes?.map((type) => `types=${type}`).join("&");
    return api.get<GetPriceRulesResponse>(
      `/api/${currentSite?.id}/${PRICE_RULES_KEY}${types ? `?${types}` : ""}`,
    );
  },
  primaryKey: PRICE_RULES_KEY,
});

export const useDisablePriceRule = createMutation({
  mutationFn: ({
    ruleId,
    ...data
  }: DisablePriceRuleRequest & { ruleId: string }) =>
    api.post<DisablePriceRuleResponse>(
      `/api/${currentSite?.id}/price-rules/${ruleId}/disable`,
      data,
    ),
});

export const useEnablePriceRule = createMutation({
  mutationFn: ({
    ruleId,
    ...data
  }: EnablePriceRuleRequest & { ruleId: string }) =>
    api.post<EnablePriceRuleResponse>(
      `/api/${currentSite?.id}/price-rules/${ruleId}/enable`,
      data,
    ),
});

export const useCreatePriceRule = createMutation({
  mutationFn: (data: CreatePriceRuleRequest) =>
    api.post<CreatePriceRuleResponse>(
      `/api/${currentSite?.id}/price-rules`,
      data,
    ),
});

type UpdatePriceRuleReq = UpdatePriceRuleRequest & {
  ruleId: string;
};

export const useUpdatePriceRule = createMutation({
  mutationFn: ({ ruleId, ...data }: UpdatePriceRuleReq) =>
    api.put<UpdatePriceRuleResponse>(
      `/api/${currentSite?.id}/price-rules/${ruleId}`,
      data,
    ),
});
