import {
  PopoverContent,
  PopoverRoot,
  PopoverTrigger,
} from "@primitives/popover";
import { ChevronDown, ChevronUp, X } from "lucide-react";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Tag } from "../../../../../../../api-contracts/tags";
import { getTextColor } from "@pages/settings/tags/tags-utils";
import { Button } from "@primitives/button";
import { Tooltip } from "@primitives/tooltip";
import { SearchBar } from "@primitives/search-bar";
import { useGetTags } from "@api/tags";

interface Props {
  value: Tag[];
  onValueChange?: (value: Tag[]) => void;
  disabled?: boolean;
  isEditing: boolean;
}

const TagsSelect = ({ value, onValueChange, disabled, isEditing }: Props) => {
  const [open, setOpen] = React.useState<boolean>();
  const [addTagsOpen, setAddTagsOpen] = React.useState<boolean>();
  const [searchTerm, setSearchTerm] = React.useState<string>();
  const [t] = useTranslation();

  const { data: tagsData, isLoading: tagsLoading } = useGetTags();

  const filteredTags = useMemo(() => {
    if (searchTerm) {
      return tagsData?.filter(
        (t) =>
          (t.active &&
            t.name.toLowerCase().includes(searchTerm.toLowerCase())) ||
          t.short.toLowerCase().includes(searchTerm.toLowerCase()),
      );
    } else {
      return tagsData?.filter((t) => t.active);
    }
  }, [tagsData, searchTerm]);

  return (
    <PopoverRoot open={open} onOpenChange={(o) => setOpen(o)}>
      <PopoverTrigger asChild disabled={disabled} className=" flex-1">
        <div className="flex h-full cursor-pointer items-center justify-between rounded-lg bg-solid-backplate px-4 py-2">
          <div>
            <p className=" text-sm font-medium">{t("tags")}</p>
          </div>
          <div className=" flex items-center space-x-4">
            <p className=" bg-amber-500-10 p-2 text-xs font-extrabold  text-accent-amber-700">
              {value.length}
            </p>
            {open ? <ChevronUp size={18} /> : <ChevronDown size={18} />}
          </div>
        </div>
      </PopoverTrigger>
      <PopoverContent
        avoidCollisions={false}
        className="PopoverContent p-0"
        onOpenAutoFocus={(e) => e.preventDefault()}
      >
        <div className="p-4">
          <h4 className=" text-xs font-normal text-secondary-text">
            {t("booking")}
          </h4>
          <div className=" flex flex-wrap gap-2">
            {value.map((tag) => (
              <Tooltip key={tag.id} content={tag.name}>
                <div
                  className=" flex space-x-2 rounded-lg p-2 text-xs font-extrabold"
                  style={{
                    backgroundColor: tag.color,
                    color: getTextColor(tag.color),
                  }}
                >
                  {isEditing && (
                    <X
                      size="14"
                      className=" cursor-pointer"
                      onClick={() => {
                        onValueChange?.(value.filter((t) => t.id !== tag.id));
                      }}
                    />
                  )}
                  <p>{tag.short}</p>
                </div>
              </Tooltip>
            ))}
            {!value.length && (
              <p className="w-full py-2 text-center text-xs font-normal text-secondary-text">
                {t("no-tags-added")}
              </p>
            )}
          </div>
          <div className=" mt-4">
            <PopoverRoot
              open={addTagsOpen}
              onOpenChange={(o) => setAddTagsOpen(o)}
            >
              <PopoverTrigger asChild disabled={disabled} className=" flex-1">
                <Button variant="outline">{t("add-tag")}</Button>
              </PopoverTrigger>
              <PopoverContent
                avoidCollisions={false}
                className="p-0"
                onOpenAutoFocus={(e) => e.preventDefault()}
              >
                <div className=" p-4">
                  <h4 className=" text-sm font-extrabold text-primary-text">
                    {t("add-tag")}
                  </h4>
                  <p className=" mb-2 text-sm font-normal text-secondary-text">
                    {t("click-a-tag-to-add-it")}
                  </p>

                  <SearchBar
                    placeholder={t("search")}
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />

                  <div className=" max-h-[400px] overflow-y-auto pt-4">
                    <div>
                      <p className=" mb-2 text-xs font-normal text-secondary-text">
                        ROOMR
                      </p>
                      <div className=" flex flex-wrap gap-2">
                        {filteredTags?.map((tag) => (
                          <Tooltip
                            delayDuration={0}
                            key={tag.id}
                            content={tag.name}
                          >
                            <div
                              className=" flex cursor-pointer space-x-2 rounded-lg p-2 text-xs font-extrabold"
                              onClick={() => {
                                if (!value.find((t) => t.id === tag.id)) {
                                  onValueChange?.([...value, tag]);
                                }
                              }}
                              style={{
                                backgroundColor: tag.color,
                                color: getTextColor(tag.color),
                              }}
                            >
                              <p>{tag.short}</p>
                            </div>
                          </Tooltip>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </PopoverContent>
            </PopoverRoot>
          </div>
        </div>
      </PopoverContent>
    </PopoverRoot>
  );
};

export default TagsSelect;
