import { Fragment, useState } from "react";
import { Check, ChevronDown } from "lucide-react";
import { Listbox, Transition } from "@headlessui/react";
import { useTranslation } from "react-i18next";
import { SearchBar } from "./search-bar";
import { cn } from "@shared/utils/css";
import { buttonVariants } from "./button-variants";

interface Props<Option> {
  options: Option[];
  value: Option[];
  onChange: (value: Option[]) => void;
  getName: (opt: Option) => string;
  placeholder?: string;
  className?: string;
  disabled?: boolean;
  multiple?: boolean;
  id?: string;
}

function MultiSelect<T extends { id: string | number }>({
  value,
  onChange,
  options,
  getName,
  placeholder,
  className,
  disabled,
  multiple = true,
  id,
}: Props<T>) {
  const [searchTerm, setSearchTerm] = useState<string>("");
  const { t } = useTranslation();

  const filteredOptions = options.filter((opt) =>
    getName(opt).toLowerCase().includes(searchTerm.toLowerCase()),
  );

  const isSelected = (opt: T) =>
    value.some((item) => String(item.id) === String(opt.id));

  return (
    <Listbox value={value} multiple={multiple} disabled={disabled}>
      <div className={`relative ${className}`}>
        <Listbox.Button
          id={id}
          className={cn(
            buttonVariants({ variant: "outline" }),
            "w-full items-center justify-between disabled:cursor-not-allowed disabled:opacity-50",
            "focus-visible:border-gray-400 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-gray-300 focus-visible:ring-offset-0",
            "px-4 data-[placeholder]:text-gray-400",
          )}
        >
          <span className="mr-6 flex items-center justify-between overflow-hidden whitespace-nowrap text-sm font-bold text-primary-text">
            <span className="truncate">
              {value?.map((v) => getName(v)).join(", ") || placeholder}
            </span>
          </span>
          <span className="pointer-events-none absolute inset-y-0 right-4 flex items-center">
            <ChevronDown
              className="h-4 w-4 text-primary-text opacity-50"
              aria-hidden="true"
            />
          </span>
        </Listbox.Button>

        <Transition
          as={Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Listbox.Options
            className={cn(
              "absolute mt-1 max-h-60 w-full overflow-auto rounded-md border border-gray-200 bg-white text-gray-950 shadow-md",
              "dark:border-gray-800 dark:bg-gray-950 dark:text-gray-50",
            )}
          >
            <SearchBar
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              placeholder={`${t("search")}...`}
              className="rounded-none border-l-0 border-r-0 border-t-0 bg-transparent text-sm font-normal focus-visible:ring-0"
            />
            {filteredOptions.map((opt, idx) => (
              <Listbox.Option
                onClick={() => {
                  if (multiple) {
                    if (isSelected(opt)) {
                      onChange(value.filter((o) => o.id !== opt.id));
                    } else {
                      onChange([...value, opt]);
                    }
                  } else {
                    onChange([opt]);
                  }
                }}
                key={opt.id}
                className={cn(
                  "relative flex w-full cursor-pointer select-none items-center rounded-sm py-1.5 pl-8 pr-2 text-sm",
                  "focus:bg-gray-100 focus:text-gray-900 dark:focus:bg-gray-800 dark:focus:text-gray-50",
                  "hover:bg-gray-100 dark:hover:bg-gray-800",
                )}
                value={opt}
              >
                <span className="block truncate text-sm font-medium">
                  {getName(opt)}
                </span>
                {isSelected(opt) && (
                  <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-primary-icon-color">
                    <Check className="h-4 w-4" aria-hidden="true" />
                  </span>
                )}
              </Listbox.Option>
            ))}
          </Listbox.Options>
        </Transition>
      </div>
    </Listbox>
  );
}

export { MultiSelect };
