import { useTranslation } from "react-i18next";
import { Button } from "@primitives/button";
import { GetBooking } from "../../../../../../../api-contracts/reservations";
import {
  Sheet,
  SheetContent,
  SheetHeader,
  SheetTitle,
} from "@primitives/sheet";
import { useEffect, useMemo, useState } from "react";
import {
  max,
  addDays,
  isBefore,
  format,
  eachDayOfInterval,
  subDays,
  isSameDay,
  isAfter,
  addHours,
  addMinutes,
} from "date-fns";
import DatePicker from "@primitives/date-picker";
import { enGB, sv } from "date-fns/locale";
import { useGetAllocableBookables, useGetAssetSchedule } from "@api/bookables";
import { useGetCategories } from "@api/categories";
import { useGetPricing } from "@api/pricing";
import {
  dateWithTimeString,
  dateWithTimeStringISO,
  addHoursAndMinutesToDateISO,
} from "@shared/utils/helpers";
import { TimeSlot } from "../../../../../../../api-contracts/reservations/timeslots";
import { useGetPriceRules } from "@api/price-rules";
import { uniqueId } from "lodash";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@primitives/select";
import { Label } from "@primitives/label";
import { RadioGroup, RadioGroupItem } from "@primitives/radio-group";
import { AssetEvent } from "../../../../../../../api-contracts/bookables/allocable";
import { Link } from "react-router-dom";
import { ROUTES } from "@shared/types/navigation";
import { useProfileContext } from "@context/profile-context";
import { Checkbox } from "@primitives/checkbox";
import { priceRuleOverrideKeyToGuestCounts } from "@shared/utils/price-rules";

interface Props {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  onProceed: (updatedBooking: GetBooking, newBooking?: GetBooking) => void;
  booking: GetBooking;
}

export const BookingChangeDepartureDrawer = ({
  open,
  onOpenChange,
  onProceed,
  booking,
}: Props) => {
  const { t, i18n } = useTranslation();
  const [changedBooking, setChangedBooking] = useState<GetBooking>(booking);
  const [newBooking, setNewBooking] = useState<GetBooking>(booking);
  const [priceOption, setPriceOption] = useState<
    "price-according-to-new-category" | "enter-new-price-code"
  >("price-according-to-new-category");
  const [checkoutDate, setCheckoutDate] = useState<Date>(new Date(booking.end));
  const [splitDate, setSplitDate] = useState<Date>(
    max([new Date(), booking.start]),
  );
  const [blockingEvents, setBlockingEvents] = useState<AssetEvent[]>([]);
  const [changeAsset, setChangeAsset] = useState<boolean>(false);
  const { module } = useProfileContext();

  useEffect(() => {
    setChangedBooking({
      ...booking,
    });
    setNewBooking({
      ...booking,
      state: {
        value: "planned",
      },
      id: "new" + uniqueId(),
      paid: 0,
      start: dateWithTimeStringISO(
        splitDate,
        booking.category.config!.startTimes[0],
      ),
      end: booking.end,
      slots: [],
      bookable: null,
    });
    setPriceOption("price-according-to-new-category");
    setSplitDate(new Date(booking.end));
    setCheckoutDate(new Date(booking.end));
    setChangeAsset(false);
    setBlockingEvents([]);
  }, [booking, open]);

  const {
    data: schedule,
    isLoading: scheduleIsLoading,
    isRefetching: scheduleIsRefetching,
  } = useGetAssetSchedule({
    variables: {
      from: booking.start,
      to: addHoursAndMinutesToDateISO(
        dateWithTimeString(
          subDays(checkoutDate, 1),
          booking.category.config!.startTimes[0],
        ),
        booking.category.config!.timeslotHours,
        booking.category.config!.timeslotMinutes,
      ),
      assetId: changedBooking.bookable?.assetId || "",
    },
    enabled: open,
  });

  useEffect(() => {
    const blockingEvents = schedule?.blocking.filter(
      (b) =>
        b.bookingId !== booking.id ||
        b.state === "out-of-order" ||
        b.state === "out-of-service",
    );
    if (blockingEvents?.length) {
      let latestSplit = new Date(booking.end);
      blockingEvents.forEach((e) => {
        if (isAfter(e.start, latestSplit)) {
          latestSplit = new Date(e.start);
        }
      });
      setSplitDate(latestSplit);
      setBlockingEvents(blockingEvents);
    } else {
      setSplitDate(checkoutDate);
      setBlockingEvents([]);
    }
  }, [schedule]);

  const {
    data: priceRulesNew,
    isLoading: priceRulesNewIsLoading,
    isRefetching: priceRulesNewIsRefetching,
  } = useGetPriceRules({
    variables: {
      categoryTypes: [newBooking.category.type],
    },
    enabled: !!newBooking.category.type,
  });

  const {
    data: priceRulesChanged,
    isLoading: priceRulesChangedIsLoading,
    isRefetching: priceRulesChangedIsRefetching,
  } = useGetPriceRules({
    variables: {
      categoryTypes: [changedBooking.category.type],
    },
    enabled: !!changedBooking.category.type,
  });

  const {
    data: categories,
    isLoading: categoriesIsLoading,
    isRefetching: categoriesIsRefetching,
  } = useGetCategories({
    variables: {
      categoryTypes: ["room", "area", "bed", "dormitory"],
    },
  });

  const {
    data: bookables,
    isLoading: bookablesIsLoading,
    isRefetching: bookablesIsRefetching,
  } = useGetAllocableBookables({
    variables: {
      categoryId: newBooking.category.id,
      startDate: format(newBooking.start, "yyyy-MM-dd"),
      endDate: format(newBooking.end, "yyyy-MM-dd"),
    },
    enabled:
      !isSameDay(newBooking.start, newBooking.end) &&
      !isAfter(newBooking.start, newBooking.end),
  });

  const {
    data: pricingNew,
    isLoading: pricingNewIsLoading,
    isRefetching: pricingNewIsRefetching,
  } = useGetPricing({
    variables: {
      id: newBooking.category.id,
      slots: newBooking.slots.map((s) => ({
        startDate: format(s.start, "yyyy-MM-dd"),
        startTime:
          categories?.find((c) => newBooking.category.id === c.id)?.config!
            .startTimes[0] || "14.00",
      })),
      adults: newBooking.guestCount.adults,
      teenagers: newBooking.guestCount.teenagers,
      children: newBooking.guestCount.children,
      infants: newBooking.guestCount.infants,
    },
    enabled:
      newBooking.slots.length > 0 &&
      !!newBooking.category.id &&
      categories &&
      open,
  });

  const {
    data: pricingChanged,
    isLoading: pricingChangedIsLoading,
    isRefetching: pricingChangedIsRefetching,
  } = useGetPricing({
    variables: {
      id: changedBooking.category.id,
      slots: changedBooking.slots.map((s) => ({
        startDate: format(s.start, "yyyy-MM-dd"),
        startTime:
          categories?.find((c) => changedBooking.category.id === c.id)?.config!
            .startTimes[0] || "14.00",
      })),
      adults: changedBooking.guestCount.adults,
      teenagers: changedBooking.guestCount.teenagers,
      children: changedBooking.guestCount.children,
      infants: changedBooking.guestCount.infants,
    },
    enabled: !!changedBooking.category.id && categories && open,
  });

  useEffect(() => {
    handlePriceOptionChange(priceOption);
  }, [pricingNew, pricingChanged, priceRulesNew, priceRulesChanged, splitDate]);

  useEffect(() => {
    setChangedBooking(booking);
  }, [booking, open]);

  const handleToChange = (date?: Date) => {
    if (date) {
      setCheckoutDate(date);
    }
  };

  const updateState = (
    rules: {
      date: string | Date;
      priceRuleId: string | null;
      priceOverrideAmount: number | null;
      priceAdjustmentPercent: number | null;
    }[],
    categoryId: string,
    bookableId: string | null,
    splitDate: Date | string,
  ) => {
    const datesChanged = eachDayOfInterval({
      start: booking.start,
      end: subDays(splitDate, 1),
    });

    const changedSlots: TimeSlot[] = datesChanged.map((date) => {
      const slot = booking.slots.find((s) => isSameDay(s.start, date));
      if (slot) {
        return slot;
      }
      const pricer = pricingChanged?.slots.find((s) =>
        isSameDay(date, s.startDate),
      );
      let pr = priceRulesChanged?.rules.find(
        (r) => pricer?.ruleId && r.id === pricer.ruleId,
      );

      const priceRule = pr?.entries.find(
        (e) => e.categoryId === changedBooking.category.id,
      );
      const priceRuleOverride = priceRule?.overrides.find((o) => {
        const guestCounts = priceRuleOverrideKeyToGuestCounts(o.overrideKey);
        return (
          guestCounts.adults === changedBooking.guestCount.adults &&
          guestCounts.children === changedBooking.guestCount.children &&
          guestCounts.teenagers === changedBooking.guestCount.teenagers &&
          guestCounts.infants === changedBooking.guestCount.infants
        );
      });

      let fullPrice = priceRuleOverride?.price || priceRule?.price || 0;

      let price = fullPrice;

      return {
        id: "new" + uniqueId(),
        price: price,
        start: dateWithTimeStringISO(
          date,
          changedBooking.category.config!.startTimes[0],
        ),
        end: addHoursAndMinutesToDateISO(
          dateWithTimeString(
            date,
            changedBooking.category.config!.startTimes[0],
          ),
          changedBooking.category.config!.timeslotHours,
          changedBooking.category.config!.timeslotMinutes,
        ),
        priceRuleId: pricer?.ruleId || null,
        priceRuleName: pr?.name || "",
        fullPrice: fullPrice,
        priceOverrideAmount: null,
        priceAdjustmentPercent: null,
      };
    });

    setChangedBooking({
      ...booking,
      end: addHoursAndMinutesToDateISO(
        dateWithTimeString(
          subDays(splitDate, 1),
          changedBooking.category.config!.startTimes[0],
        ),
        changedBooking.category.config!.timeslotHours,
        changedBooking.category.config!.timeslotMinutes,
      ),
      slots: changedSlots,
    });

    const category = categories?.find((c) => c.id === categoryId);
    if (!category || isSameDay(splitDate, checkoutDate)) {
      setNewBooking((newBooking) => ({
        ...newBooking,
        start: dateWithTimeStringISO(
          splitDate,
          newBooking.category.config!.startTimes[0],
        ),
        end: dateWithTimeStringISO(
          splitDate,
          newBooking.category.config!.startTimes[0],
        ),
        slots: [],
      }));
      return;
    }

    const start = splitDate;
    const end = checkoutDate;

    const bookable =
      bookables?.bookables.find((b) => b.id === bookableId) ||
      (categoryId === booking.category.id && bookableId === booking.bookable?.id
        ? booking.bookable
        : undefined);

    const dates = eachDayOfInterval({
      start: start,
      end: subDays(end, 1),
    });

    const newSlots: TimeSlot[] = dates.map((date) => {
      const pricer = pricingNew?.slots.find((s) =>
        isSameDay(date, s.startDate),
      );
      const slot = booking.slots.find((s) => isSameDay(s.start, date));
      const rule = rules.find((r) => isSameDay(date, r.date));
      let priceRuleId = rule?.priceRuleId || null;
      const priceOverrideAmount = rule?.priceOverrideAmount || null;
      const priceAdjustmentPercent = rule?.priceAdjustmentPercent || null;

      let pr = priceRulesNew?.rules.find(
        (r) =>
          (priceRuleId && r.id === priceRuleId) ||
          (!priceRuleId && pricer?.ruleId && r.id === pricer.ruleId),
      );
      if (priceRuleId && !pr) {
        pr = priceRulesNew?.rules.find(
          (r) => pricer?.ruleId && r.id === pricer.ruleId,
        );
        priceRuleId = pricer?.ruleId || null;
      }
      const priceRule = pr?.entries.find((e) => e.categoryId === categoryId);

      const priceRuleOverride = priceRule?.overrides.find((o) => {
        const guestCounts = priceRuleOverrideKeyToGuestCounts(o.overrideKey);
        return (
          guestCounts.adults === newBooking.guestCount.adults &&
          guestCounts.children === newBooking.guestCount.children &&
          guestCounts.teenagers === newBooking.guestCount.teenagers &&
          guestCounts.infants === newBooking.guestCount.infants
        );
      });

      let fullPrice = priceRuleOverride?.price || priceRule?.price || 0;

      let price = priceOverrideAmount || fullPrice;

      if (priceAdjustmentPercent) {
        price = price * (1 - 0.01 * priceAdjustmentPercent);
      }

      return {
        id: slot?.id || "new" + uniqueId(),
        price: price,
        start: dateWithTimeStringISO(date, category.config!.startTimes[0]),
        end: addHoursAndMinutesToDateISO(
          dateWithTimeString(date, category.config!.startTimes[0]),
          category.config!.timeslotHours,
          category.config!.timeslotMinutes,
        ),
        priceRuleId: priceRuleId || pricer?.ruleId || null,
        priceRuleName: pr?.name || "",
        fullPrice: fullPrice,
        priceOverrideAmount: priceOverrideAmount,
        priceAdjustmentPercent: priceAdjustmentPercent,
      };
    });

    setNewBooking((newBooking) => ({
      ...newBooking,
      start: dateWithTimeStringISO(start, category.config!.startTimes[0]),
      end: newSlots[newSlots.length - 1].end,
      slots: newSlots,
      category: category,
      bookable: bookable ? { ...bookable, lockedToBookable: false } : null,
    }));
  };

  const total = useMemo(() => {
    return (
      changedBooking.slots.reduce((acc, slot) => {
        return acc + slot.price;
      }, 0) +
      (blockingEvents.length > 0 && changeAsset
        ? newBooking.slots.reduce((acc, slot) => {
            return acc + slot.price;
          }, 0)
        : 0)
    );
  }, [changedBooking, newBooking, blockingEvents, changeAsset]);

  const handlePriceOptionChange = (
    option: "price-according-to-new-category" | "enter-new-price-code",
  ) => {
    setPriceOption(option);

    switch (option) {
      case "price-according-to-new-category":
      case "enter-new-price-code":
        updateState(
          newBooking.slots.map((s) => ({
            date: s.start,
            priceRuleId: s.priceRuleId,
            priceOverrideAmount: null,
            priceAdjustmentPercent: null,
          })),
          newBooking.category.id,
          newBooking.bookable?.id || null,
          splitDate,
        );
        break;
    }
  };

  return (
    <>
      <Sheet open={open} onOpenChange={onOpenChange}>
        <SheetContent
          side="left"
          className="flex w-[400px] flex-col overflow-y-auto sm:w-[600px]"
        >
          <SheetHeader>
            <SheetTitle className=" flex items-center justify-between text-primary-text">
              {t("change-departure")}
            </SheetTitle>
          </SheetHeader>

          <div className=" flex-1 overflow-y-auto text-primary-text">
            <div className="mt-4 bg-secondary-card-backplate p-4">
              <h3 className=" mb-4 text-lg font-bold">
                {t("change-departure")}
              </h3>
              <div>
                <DatePicker
                  value={checkoutDate}
                  onValueChange={handleToChange}
                  clearable={false}
                  calendarProps={{
                    disabled: {
                      before: max([new Date(), addDays(booking.start, 1)]),
                    },
                  }}
                />
                <p className=" ml-2 mt-2 text-sm font-normal">
                  {t("original-departure")}:{" "}
                  {format(booking.end, "do MMMM", {
                    locale: i18n.language === "sv-se" ? sv : enGB,
                  })}
                </p>
                {blockingEvents.length > 0 && (
                  <p className="ml-2 mt-2 text-xs font-normal text-status-warning ">
                    {t(
                      "{{asset}}-is-booked-from-{{from}}.-Before-you-extend-the-booking,-the-unit-allocation-of-the-bookings-below-must-be-changed-if-the-guest-is-to-be-able-to-stay-in-{{asset}}.",
                      {
                        asset: booking.bookable?.name,
                        from: format(splitDate, "do MMMM yyyy", {
                          locale: i18n.language === "sv-se" ? sv : enGB,
                        }),
                      },
                    )}
                  </p>
                )}

                <ul className=" ml-2 mt-4">
                  {blockingEvents.map((e) => (
                    <li key={e.start}>
                      <p className=" text-xs font-normal text-status-warning">
                        {e.reservationsId && (
                          <Link
                            className=" underline"
                            to={`/${module}/${ROUTES.RESERVATIONS}/${e.reservationsId}`}
                          >
                            #{e.reservationsId}
                          </Link>
                        )}{" "}
                        {format(e.start, "do MMMM yyyy", {
                          locale: i18n.language === "sv-se" ? sv : enGB,
                        })}{" "}
                        -{" "}
                        {format(e.end, "do MMMM yyyy", {
                          locale: i18n.language === "sv-se" ? sv : enGB,
                        })}{" "}
                        {t(e.state)}{" "}
                        {e.holder?.id
                          ? `${t("by")} ${e.holder.name} ${e.holder.surname}`
                          : ""}
                      </p>
                    </li>
                  ))}
                </ul>

                {blockingEvents.length > 0 && (
                  <div className=" ml-2 mt-4 flex items-center space-x-2">
                    <Checkbox
                      id="change-asset-checkbox"
                      checked={changeAsset}
                      onCheckedChange={(v) => setChangeAsset(v as boolean)}
                    />{" "}
                    <label
                      className=" cursor-pointer text-sm font-extrabold"
                      htmlFor="change-asset-checkbox"
                    >
                      {t("change-unit-from-{{from}}", {
                        from: format(splitDate, "do MMMM yyyy", {
                          locale: i18n.language === "sv-se" ? sv : enGB,
                        }),
                      })}
                    </label>
                  </div>
                )}
              </div>
            </div>

            {blockingEvents.length > 0 && changeAsset && (
              <div className="mt-4 bg-secondary-card-backplate p-4">
                <h3 className=" mb-4 text-lg font-bold">{t("unit")}</h3>
                <div>
                  <Label htmlFor="booking-change-category">
                    {t("category")}
                  </Label>
                  <Select
                    key={newBooking.category.id}
                    value={newBooking.category.id}
                    onValueChange={(value) => {
                      updateState(
                        newBooking.slots.map((s) => ({
                          date: s.start,
                          priceRuleId: s.priceRuleId,
                          priceAdjustmentPercent: s.priceAdjustmentPercent,
                          priceOverrideAmount: s.priceOverrideAmount,
                        })),
                        value,
                        null,
                        splitDate,
                      );
                    }}
                  >
                    <SelectTrigger
                      className=" w-full"
                      id="booking-change-category"
                    >
                      <SelectValue />
                    </SelectTrigger>
                    <SelectContent>
                      {categories?.map((c) => (
                        <SelectItem
                          key={c.id}
                          value={c.id}
                          className=" text-xs"
                        >
                          {t(c.name)}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                  {changeAsset &&
                    (newBooking.guestCount.adults +
                      newBooking.guestCount.children +
                      newBooking.guestCount.teenagers +
                      newBooking.guestCount.infants >
                      newBooking.category.capacity.total.max ||
                      newBooking.guestCount.adults >
                        newBooking.category.capacity.adults.max ||
                      newBooking.guestCount.adults <
                        newBooking.category.capacity.adults.min ||
                      newBooking.guestCount.teenagers >
                        newBooking.category.capacity.teenagers.max ||
                      newBooking.guestCount.children >
                        newBooking.category.capacity.children.max ||
                      newBooking.guestCount.infants >
                        newBooking.category.capacity.infants.max) && (
                      <p className=" mt-2 text-xs font-normal text-status-error">
                        {t(
                          "the-{{guests}}-guests-of-the-booking-do-not-fit-in-{{category}}",
                          {
                            category: newBooking.category.name,
                            guests: newBooking.guests.length,
                          },
                        )}
                        .
                      </p>
                    )}
                </div>
                <div className="mt-2">
                  <Label htmlFor="booking-change-asset">{t("unit")}</Label>
                  <Select
                    key={newBooking.bookable?.id}
                    value={newBooking.bookable?.id}
                    onValueChange={(value) => {
                      updateState(
                        newBooking.slots.map((s) => ({
                          date: s.start,
                          priceRuleId: s.priceRuleId,
                          priceAdjustmentPercent: s.priceAdjustmentPercent,
                          priceOverrideAmount: s.priceOverrideAmount,
                        })),
                        newBooking.category.id,
                        value,
                        splitDate,
                      );
                    }}
                  >
                    <SelectTrigger
                      className=" w-full"
                      id="booking-change-asset"
                    >
                      <SelectValue />
                    </SelectTrigger>
                    <SelectContent>
                      {bookables?.bookables?.map((b) => (
                        <SelectItem
                          key={b.id}
                          value={b.id}
                          className=" text-xs"
                        >
                          {t(b.name)}
                        </SelectItem>
                      ))}
                      {bookables?.bookables.length === 0 && (
                        <p className="p-2 text-center text-sm font-normal text-secondary-text">
                          {t("no-units-available")}
                        </p>
                      )}
                    </SelectContent>
                  </Select>
                </div>
              </div>
            )}

            {blockingEvents.length > 0 && changeAsset && (
              <div className="mt-4 bg-secondary-card-backplate p-4">
                <h3 className=" mb-4 text-lg font-bold">{t("price")}</h3>

                <RadioGroup
                  className="mb-4"
                  value={priceOption}
                  onValueChange={(v) =>
                    handlePriceOptionChange(
                      v as
                        | "price-according-to-new-category"
                        | "enter-new-price-code",
                    )
                  }
                >
                  <div className="mt-2 flex items-center space-x-2">
                    <RadioGroupItem
                      value="price-according-to-new-category"
                      id="price-according-to-new-category"
                    />
                    <Label htmlFor="price-according-to-new-category">
                      {t("bar-price")}
                    </Label>
                  </div>
                  <div className="mt-2 flex items-center space-x-2">
                    <RadioGroupItem
                      value="enter-new-price-code"
                      id="enter-new-price-code"
                    />
                    <Label htmlFor="enter-new-price-code">
                      {t("enter-new-price-code")}
                    </Label>
                  </div>
                </RadioGroup>

                {priceOption === "enter-new-price-code" && (
                  <>
                    <Label htmlFor="booking-change-price-code">
                      {t("change-price-code-for-all-nights")}
                    </Label>
                    <Select
                      value={
                        newBooking.slots.every(
                          (s) =>
                            s.priceRuleId === newBooking.slots[0].priceRuleId,
                        )
                          ? newBooking.slots[0]?.priceRuleId || undefined
                          : undefined
                      }
                      onValueChange={(value) => {
                        updateState(
                          newBooking.slots.map((s) => ({
                            date: s.start,
                            priceRuleId: value || null,
                            priceAdjustmentPercent: null,
                            priceOverrideAmount: null,
                          })),
                          newBooking.category.id,
                          newBooking.bookable?.id || null,
                          splitDate,
                        );
                      }}
                    >
                      <SelectTrigger
                        className=" w-full"
                        id="booking-change-price-code"
                      >
                        <SelectValue />
                      </SelectTrigger>
                      <SelectContent>
                        {priceRulesNew?.rules.map((r) => (
                          <SelectItem
                            key={r.id}
                            value={r.id}
                            className=" text-xs"
                          >
                            {t(r.name)}
                          </SelectItem>
                        ))}
                        {priceRulesNew?.rules.length === 0 && (
                          <p className="p-2 text-center text-sm font-normal text-secondary-text">
                            {t("no-price-rules-available")}
                          </p>
                        )}
                      </SelectContent>
                    </Select>

                    <div className="mt-2">
                      {newBooking.slots.map((slot, i) => (
                        <div
                          key={slot.id}
                          className=" flex items-center justify-between border-t border-border-color py-2"
                        >
                          <p className=" text-sm font-bold">
                            {format(slot.start, "d MMMM")}
                          </p>
                          <div className=" w-1/2">
                            <Select
                              value={
                                newBooking.slots[i].priceRuleId || undefined
                              }
                              onValueChange={(value) => {
                                updateState(
                                  newBooking.slots.map((s, j) => ({
                                    date: s.start,
                                    priceRuleId:
                                      j === i ? value || null : s.priceRuleId,
                                    priceAdjustmentPercent: null,
                                    priceOverrideAmount: null,
                                  })),
                                  newBooking.category.id,
                                  newBooking.bookable?.id || null,
                                  splitDate,
                                );
                              }}
                            >
                              <SelectTrigger
                                className=" w-full"
                                id="price-code"
                              >
                                <SelectValue />
                              </SelectTrigger>
                              <SelectContent>
                                {priceRulesNew?.rules.map((r) => (
                                  <SelectItem
                                    key={r.id}
                                    value={r.id}
                                    className=" text-xs"
                                  >
                                    {t(r.name)}
                                  </SelectItem>
                                ))}
                                {priceRulesNew?.rules.length === 0 && (
                                  <p className="p-2 text-center text-sm font-normal text-secondary-text">
                                    {t("no-price-rules-available")}
                                  </p>
                                )}
                              </SelectContent>
                            </Select>
                          </div>
                        </div>
                      ))}
                    </div>
                  </>
                )}
              </div>
            )}
          </div>

          <div className=" flex items-center justify-between space-x-2">
            <p className=" text-md font-bold">
              {t("total")}:{" "}
              {new Intl.NumberFormat(
                i18n.language === "sv-se" ? "sv-SE" : "en-GB",
                { maximumFractionDigits: 2 },
              ).format(total)}{" "}
              SEK
            </p>
            <div className=" flex justify-end space-x-2 ">
              <Button variant="outline" onClick={() => onOpenChange(false)}>
                {t("cancel")}
              </Button>
              <Button
                onClick={() => {
                  if (newBooking.slots) {
                    onProceed(changedBooking, newBooking);
                  } else {
                    onProceed(changedBooking);
                  }
                  onOpenChange(false);
                }}
                disabled={
                  (blockingEvents.length > 0 && !changeAsset) ||
                  (blockingEvents.length > 0 &&
                    changeAsset &&
                    !newBooking.bookable) ||
                  newBooking.guestCount.adults +
                    newBooking.guestCount.children +
                    newBooking.guestCount.teenagers +
                    newBooking.guestCount.infants >
                    newBooking.category.capacity.total.max ||
                  newBooking.guestCount.adults >
                    newBooking.category.capacity.adults.max ||
                  newBooking.guestCount.adults <
                    newBooking.category.capacity.adults.min ||
                  newBooking.guestCount.teenagers >
                    newBooking.category.capacity.teenagers.max ||
                  newBooking.guestCount.children >
                    newBooking.category.capacity.children.max ||
                  newBooking.guestCount.infants >
                    newBooking.category.capacity.infants.max
                }
              >
                {t("apply")}
              </Button>
            </div>
          </div>
        </SheetContent>
      </Sheet>
    </>
  );
};

export default BookingChangeDepartureDrawer;
