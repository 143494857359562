import { HighlightText } from "@pages/settings/categories/components/highlight-text";
import { PanelTop, X } from "lucide-react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { NavSubItem } from "../main-nav";
import { RecentItem, SearchNavItem, saveRecentSearch } from "./search-utils";

export const PageResults = ({
  pages = [],
  searchTerm,
  isRecentResult = false,
  onDelete,
}: {
  pages: SearchNavItem[];
  searchTerm: string;
  isRecentResult?: boolean;
  onDelete?: (item: RecentItem) => void;
}) => {
  const { t } = useTranslation();

  const matchingItems = pages.flatMap((page) => {
    return page.subItems
      ? page.subItems
          .filter((subItem) => {
            const searchLower = searchTerm.toLowerCase();

            if (isRecentResult) return true;

            if (searchLower.includes(" ")) {
              const [parentSearch, subItemSearch] = searchLower.split(" ");
              return (
                t(page.name).toLowerCase().includes(parentSearch) &&
                t(subItem.name).toLowerCase().includes(subItemSearch)
              );
            }

            return (
              t(page.name).toLowerCase().includes(searchLower) ||
              t(subItem.name).toLowerCase().includes(searchLower)
            );
          })
          .map((subItem) => {
            const searchParts = searchTerm.includes(" ")
              ? searchTerm.split(" ")
              : [searchTerm];

            return {
              parentName: page.name,
              subItem: subItem,
              searchParts,
            };
          })
      : [];
  });

  return (
    <ul>
      {matchingItems.length > 0 ? (
        matchingItems.slice(0, 3).map((item, index) => (
          <li key={index} className="group cursor-pointer py-2 hover:bg-hover">
            <PagesHighlightedBreadcrumb
              parent={item.parentName}
              child={item.subItem}
              searchParts={item.searchParts}
              isRecentResult={isRecentResult}
              onDelete={onDelete}
            />
          </li>
        ))
      ) : (
        <span className="px-4 text-xs italic">{t("no-results")}</span>
      )}
    </ul>
  );
};

const PagesHighlightedBreadcrumb = ({
  parent,
  child,
  searchParts,
  isRecentResult,
  onDelete,
}: {
  parent: string;
  child: NavSubItem;
  searchParts: string[];
  isRecentResult: boolean;
  onDelete?: (item: RecentItem) => void;
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const navigationUrl = child.urls[0];

  const onClick = () => {
    if (!navigationUrl) return;
    saveRecentSearch({
      type: "pages",
      pages: [
        {
          name: parent,
          subItems: [{ name: child.name, urls: [navigationUrl] }],
        },
      ],
    });
    navigate(navigationUrl);
  };
  const handleDelete = () => {
    onDelete &&
      onDelete({
        type: "pages",
        pages: [
          {
            name: parent,
            subItems: [{ name: child.name, urls: [navigationUrl] }],
          },
        ],
      });
  };

  const parentHighlight =
    searchParts.length > 1 ? searchParts[0] : searchParts[0];
  const subItemHighlight =
    searchParts.length > 1 ? searchParts[1] : searchParts[0];

  return (
    <button
      onClick={onClick}
      className="group flex w-full items-center justify-between overflow-hidden whitespace-nowrap px-2"
    >
      <div className="flex items-center">
        <div className="mr-2 rounded-lg bg-secondary-card-backplate p-1 text-primary-icon-color">
          <PanelTop strokeWidth={0.5} size={16} />
        </div>
        <span className="mr-2">
          <HighlightText
            text={t(parent)}
            textToHighlight={parentHighlight}
            className="text-secondary-text"
          />
          <span className="text-secondary-text">&nbsp;&gt;&nbsp;</span>
          <HighlightText
            text={t(child.name)}
            textToHighlight={subItemHighlight}
            className="text-primary-text"
          />
        </span>
      </div>

      {isRecentResult && (
        <button
          className="mx-2 hidden items-center group-hover:flex"
          onClick={(event) => {
            event.stopPropagation();
            handleDelete();
          }}
        >
          <X size={15} />
        </button>
      )}
    </button>
  );
};
